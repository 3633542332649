import React from "react";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyle } from "theme";
import { Router } from "Router";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "api/config/query";
import { getCurrentTheme } from "api/modules/weather";
import { ISettings, getSettings } from "utils/settings";
import { Helmet } from "react-helmet";
import { ConfigProvider } from "antd";

const settings = getSettings();
export type ThemeMode = "light" | "dark" | null;

export const ThemeModeContext = React.createContext<ThemeMode | null>(null);
export const SetThemeModeContext = React.createContext<((theme: ThemeMode) => void) | null>(null);

export const SettingsContext = React.createContext<ISettings>(settings);

export const App: React.FC = () => {
  const [themeMode, setThemeMode] = React.useState<ThemeMode>(null);
  const searchParams = new URLSearchParams(document.location.search);

  React.useEffect(() => {
    let currentTheme: ThemeMode = "light";
    getCurrentTheme()
      .then((t) => {
        currentTheme = t;
      })
      .catch(() => {
        currentTheme = "light";
      })
      .finally(() => {
        const themeId = searchParams.get("dark") === "true" ? "dark" : currentTheme;
        setThemeMode(themeId);
        document.body.id = `${themeId}-theme`;
      });
  }, []);

  const basePath = `${process.env.PUBLIC_URL}/websites/${settings.name}`;

  return (
    <>
      <Helmet>
        <title>{settings.title}</title>
        <link rel="icon" href={`${basePath}/favicon.ico`} />
        <link rel="apple-touch-icon" href={`${basePath}/logo192.png`} />
        <link rel="manifest" href={`${basePath}/manifest.json`} />
      </Helmet>
      <SetThemeModeContext.Provider value={setThemeMode}>
        <ThemeModeContext.Provider value={themeMode}>
          <SettingsContext.Provider value={settings}>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider theme={theme}>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: theme.colors.grey800,
                      colorBorder: theme.colors.grey800,
                      colorInfoBorder: theme.colors.transparent,
                    },
                    components: {
                      Alert: {
                        colorInfoBg: "#E3F8FF",
                        colorText: "#0B69A3",
                      },
                      Button: {
                        borderColorDisabled: "rgba(0, 0, 0, 0.15)",
                        colorTextDisabled: "rgba(0, 0, 0, 0.4)",
                        colorBgContainerDisabled: "rgba(0, 0, 0, 0.04)",
                      },
                    },
                  }}
                >
                  <GlobalStyle />
                  <Router />
                </ConfigProvider>
              </ThemeProvider>
            </QueryClientProvider>
          </SettingsContext.Provider>
        </ThemeModeContext.Provider>
      </SetThemeModeContext.Provider>
    </>
  );
};
