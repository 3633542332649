import { useQuery } from "@tanstack/react-query";
import { request } from "api/config/axios";
import { IDeviceType } from "types/common";

const getDeviceTypes = async () => {
  return request<IDeviceType[]>({
    url: "/device-types",
    method: "GET",
  });
};

export const useDeviceTypes = () => {
  return useQuery<IDeviceType[]>(["device-types"], getDeviceTypes);
};
