import { Button } from "antd";
import { Flex, Modal, Typography } from "components";
import React from "react";
import { capitalizeFirstLetter } from "utils/string";

type AdminWarningModalProps = {
  isOpen: boolean;
  isLoading?: boolean;
  handleClose(): void;
  handleConfirm(): void;

  entity?: string;
  header?: string;
  title?: string;
  confirmTitle?: string;
};

export const AdminWarningModal: React.FC<AdminWarningModalProps> = ({
  isOpen,
  entity = "",
  isLoading = false,
  handleClose,
  handleConfirm,
  header,
  title,
  confirmTitle,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={isLoading ? undefined : handleClose}
      header={
        <Typography size={32} height={42} weight={700}>
          {header || `Delete ${capitalizeFirstLetter(entity)}`}
        </Typography>
      }
    >
      <Typography size={18} height={20}>
        {title || `Are you sure you want to delete ${entity}?`}
      </Typography>
      <Flex columnGap={24} margin="40px 0 0 0" justify="center">
        <Button size="large" onClick={handleClose} disabled={isLoading}>
          Close
        </Button>
        <Button type="primary" size="large" danger onClick={handleConfirm} loading={isLoading}>
          {confirmTitle || "Delete"}
        </Button>
      </Flex>
    </Modal>
  );
};
