import React from "react";

export const SmartHelpIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.7709 13.8496L12 15.5L12.2291 13.8496C12.8495 9.38145 13.2894 8.90945 17.4596 8.24545L19 8L17.4596 7.75455C13.2894 7.09048 12.8488 6.61848 12.2291 2.15038L12 0.5L11.7709 2.15038C11.1505 6.61855 10.7106 7.09055 6.54036 7.75455L5 8L6.54036 8.24545C10.7111 8.91017 11.1506 9.38087 11.7709 13.8496ZM10.8157 6.73112C11.4215 6.08203 11.7363 5.17252 12 3.70112C12.2637 5.17247 12.5785 6.08195 13.1843 6.73112C13.7901 7.38028 14.639 7.71745 16.0123 8C14.639 8.28255 13.7902 8.6198 13.1843 9.26888C12.5784 9.91797 12.2637 10.8275 12 12.2989C11.7363 10.8275 11.4215 9.91805 10.8157 9.26888C10.2099 8.61972 9.36102 8.28255 7.98771 8C9.36097 7.71745 10.2098 7.3802 10.8157 6.73112Z"
        fill="#857F72" stroke="#857F72" strokeWidth="0.3" />
      <path
        d="M4.86909 17.6198L5 18.5L5.13091 17.6198C5.48542 15.2368 5.73681 14.985 8.1198 14.6309L9 14.5L8.1198 14.3691C5.73677 14.0149 5.48504 13.7632 5.13091 11.3802L5 10.5L4.86909 11.3802C4.51458 13.7632 4.26319 14.015 1.8802 14.3691L1 14.5L1.8802 14.6309C4.26349 14.9854 4.5146 15.2365 4.86909 17.6198ZM4.32326 13.8233C4.66944 13.4771 4.84931 12.992 5 12.2073C5.15069 12.992 5.33056 13.477 5.67674 13.8233C6.02292 14.1695 6.50799 14.3493 7.29274 14.5C6.50802 14.6507 6.02296 14.8306 5.67674 15.1767C5.33052 15.5229 5.15069 16.008 5 16.7927C4.84931 16.008 4.66944 15.523 4.32326 15.1767C3.97708 14.8305 3.49201 14.6507 2.70726 14.5C3.49198 14.3493 3.97704 14.1694 4.32326 13.8233Z"
        fill="#857F72" stroke="#857F72" strokeWidth="0.35" />
    </svg>
  );
};
