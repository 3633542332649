import { AdminForm, AdminTable, Flex } from "components";
import React from "react";
import { Input, Form, Select } from "antd";
import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { Routes } from "constants/routes";
import { IDeviceFormData, useCreateDevice, useDeviceById, useUpdateDevice } from "api/modules/devices";
import { useNodes } from "api/modules/node";
import { INode } from "types/common";
import { useMetrics } from "api/modules/metrics";
import { useDataListener } from "hooks/useDataListener";
import { useDeviceModels } from "api/modules/device-models";
import { columns } from "./columns";

const FormRow = styled(Flex)`
  column-gap: 40px;
  flex-wrap: wrap;
`;

const FormItem = styled(Form.Item)`
  min-width: 300px;
  width: 300px;
`;

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    number: "${label} is not a valid number!",
  },
};

export const AdminDeviceDetailsPage: React.FC<{ isNew?: boolean }> = ({ isNew }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const fixedNode: INode | undefined = location.state;

  const params = useParams();
  const deviceId = params.deviceId!;

  const { data: device, isFetching: isDeviceLoading, isError: isDeviceError } = useDeviceById(deviceId, !isNew);
  const { data: nodes, isFetching: isNodesLoading } = useNodes({ enabled: !fixedNode });
  const { data: deviceModels, isLoading: isDeviceModelsLoading } = useDeviceModels();
  const { data: initialMetrics, isFetching: isMetricLoading } = useMetrics(
    { deviceId, isAdmin: true },
    { enabled: !!deviceId },
  );

  React.useEffect(() => {
    if (isDeviceError) {
      navigate(Routes.AdminDevices);
    }
  }, [isDeviceError]);

  const [metrics] = useDataListener(initialMetrics, isMetricLoading, { enabled: !!deviceId });

  const [form] = Form.useForm<IDeviceFormData>();
  const { mutate: createDevice, isLoading: isCreateLoading } = useCreateDevice();
  const { mutate: updateDevice, isLoading: isUpdateLoading } = useUpdateDevice(deviceId);

  const handleSave = (values: IDeviceFormData) => {
    const actionFn = isNew ? createDevice : updateDevice;

    actionFn(values, {
      onSuccess: ({ device_id }) => {
        const navigatePage = fixedNode
          ? `${Routes.AdminNodes}/${fixedNode.node_id}#devices`
          : `${Routes.AdminDevices}/${device_id}`;
        navigate(navigatePage);
        toast.success(`Device has been ${isNew ? "created" : "updated"} successfully!`);
      },
    });
  };

  const isActionLoading = isCreateLoading || isUpdateLoading;

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <Flex direction="column" rowGap={40}>
      <AdminForm
        title={`${isNew ? "Create" : "Update"} Device`}
        handleSave={form.submit}
        handleCancel={() => navigate(-1)}
        isLoading={isActionLoading}
      >
        {isDeviceLoading ? (
          <Skeleton count={10} />
        ) : (
          <Form
            layout="vertical"
            form={form}
            onFinish={handleSave}
            size="large"
            validateMessages={validateMessages}
            initialValues={{
              description: device?.description,
              equip_id: device?.equip_id,
              model_id: device?.model_id?.toString(),
              node_id: (fixedNode?.node_id || device?.node_id)?.toString(),
            }}
            style={{ width: "fit-content" }}
            disabled={isActionLoading}
          >
            <FormRow>
              <FormItem label="Description" name="description" rules={[{ required: true }]}>
                <Input placeholder="Description" />
              </FormItem>
              <FormItem label="Equipment ID" name="equip_id" rules={[{ required: true }]}>
                <Input placeholder="Equipment ID" />
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem label="Device Model" name="model_id" rules={[{ required: true }]}>
                <Select
                  placeholder="Choose Device Model"
                  loading={isDeviceModelsLoading}
                  showSearch
                  filterOption={filterOption}
                  options={deviceModels?.map(({ model_id, designation }) => ({
                    label: designation,
                    value: model_id.toString(),
                  }))}
                  disabled={!isNew}
                />
              </FormItem>
              <FormItem label="Node" name="node_id" rules={[{ required: true }]}>
                <Select
                  placeholder="Choose Node"
                  filterOption={filterOption}
                  loading={isNodesLoading}
                  showSearch
                  options={(fixedNode ? [fixedNode] : nodes)?.map(({ node_id, description }) => ({
                    label: description,
                    value: node_id.toString(),
                  }))}
                  disabled={!isNew || !!fixedNode}
                />
              </FormItem>
            </FormRow>
          </Form>
        )}
      </AdminForm>
      {!isNew && (
        <AdminForm title="Device Metrics" sectionId="metrics">
          <AdminTable data={metrics} columns={columns} isLoading={isMetricLoading} entity="metric" rowKey="metric_id" />
        </AdminForm>
      )}
    </Flex>
  );
};
