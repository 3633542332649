import React from "react";

export const EyeOffIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.99878 3L6.58794 6.58916M20.9988 21L17.41 17.4112M13.8736 18.8246C13.2665 18.9398 12.6399 19 11.9992 19C7.52159 19 3.73128 16.0571 2.45703 12C2.80393 10.8955 3.33729 9.87361 4.02021 8.97118M9.87746 9.87868C10.4204 9.33579 11.1704 9 11.9988 9C13.6556 9 14.9988 10.3431 14.9988 12C14.9988 12.8284 14.663 13.5784 14.1201 14.1213M9.87746 9.87868L14.1201 14.1213M9.87746 9.87868L6.58794 6.58916M14.1201 14.1213L6.58794 6.58916M14.1201 14.1213L17.41 17.4112M6.58794 6.58916C8.14776 5.58354 10.0053 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C20.8345 14.2507 19.3533 16.1585 17.41 17.4112"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
