import React from "react";
import { ThemeModeContext } from "App";
import { Flex, Icon, Input, Typography } from "components";
import styled from "styled-components";
import { CircleCheckIcon, ReloadIcon } from "assets";
import { useUpdateNode } from "api/modules/node";
import { MenuContentProps } from "../../types";

const EnergyRateInput = styled(Input)<{ $hasIcon?: boolean }>`
  ${(props) => props.$hasIcon && "padding-right: 45px;"}
`;

const InputEndIcon = styled(Icon)`
  position: absolute;
  right: 13px;
`;

const LoadingIcon = styled(InputEndIcon)`
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  animation: rotation 2s infinite linear;
`;

export const EnergyRate: React.FC<MenuContentProps> = (props) => {
  const { mutate: updateNode, isLoading, isSuccess } = useUpdateNode(props.node.node_id.toString());
  const [rate, setRate] = React.useState<string | undefined>(props.node.energy_rate?.toString());

  const applyRate = () => {
    if (rate && !Number.isNaN(parseFloat(rate))) {
      updateNode({
        energy_rate: parseFloat(rate),
      });
    }
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (evt) => {
    if (["e", "E", "+", "-"].includes(evt.key)) {
      evt.preventDefault();
    }
  };

  const isDarkTheme = React.useContext(ThemeModeContext) === "dark";
  return (
    <Flex direction="column" rowGap={32}>
      <Flex columnGap={8} align="center">
        <Flex width="110px" height="42px" align="center" position="relative">
          <EnergyRateInput
            placeholder="0.00"
            type="number"
            step={0.01}
            min={0}
            max={100}
            $hasIcon
            onBlur={applyRate}
            onKeyDown={handleKeyDown}
            onChange={setRate}
            value={rate}
          />
          {isLoading && <LoadingIcon icon={ReloadIcon} />}
          {isSuccess && <InputEndIcon icon={CircleCheckIcon} size={16} />}
        </Flex>

        <Typography size={16} weight={400} height={24} color={isDarkTheme ? "blueGrey100" : "grey800"}>
          CHF / kWh
        </Typography>
      </Flex>
      <Typography size={16} weight={400} height={24} color={isDarkTheme ? "blueGrey100" : "grey800"}>
        Durch die Einspeisung von überschüssigem Strom können Sie mit Ihrer Photovoltaik-Anlage Geld verdienen. Der
        Rückliefertarif wird zwischen Ihnen und dem lokalen Energieversorger vereinbart.
      </Typography>
    </Flex>
  );
};
