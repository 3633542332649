import React from "react";

export const KosterHouseLogoIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="42" height="42" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="42" height="42" fill="#857F72" />
      <path d="M15.9835 6.5L7 21.2942H11.3075L20.376 6.5H15.9835Z" fill="white" />
      <path d="M11.0241 6.5H7V19.1767L11.0241 12.4572V6.5Z" fill="white" />
      <path d="M14.1982 18.6685L16.267 21.2942H20.9996L16.3237 15.1958L14.1982 18.6685Z" fill="white" />
    </svg>
  );
};
