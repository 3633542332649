import { Button, Flex, Textarea, Typography } from "components";
import { styled } from "styled-components";

export const Styles = {
  SubTitle: styled(Typography)`
    ${(props) => props.theme.breakpoints.mobile} {
      font-size: 18px;
      line-height: 26px;
    }
  `,
  Description: styled(Typography)`
    ${(props) => props.theme.breakpoints.mobile} {
      font-size: 12px;
      line-height: 16px;
    }
  `,

  TextareaTitle: styled(Typography)`
    ${(props) => props.theme.breakpoints.mobile} {
      font-size: 14px;
      line-height: 20px;
    }
  `,

  StyledButton: styled(Button)`
    ${(props) => props.theme.breakpoints.mobile} {
      width: 100%;
    }
  `,

  StyledTextarea: styled(Textarea)`
    ${(props) => props.theme.breakpoints.mobile} {
      font-size: 14px;
    }
  `,

  Container: styled(Flex)`
    ${(props) => props.theme.breakpoints.mobile} {
      row-gap: 16px;
    }
  `,
  SuccessTile: styled(Flex)`
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.white};
  `,
  ImagePreview: styled.img`
    width: 100px;
    height: 100px;
    object-fit: contain;
  `,
};
