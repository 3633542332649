import React from "react";
import { Typography, Flex } from "components";
import styled from "styled-components";
import { Button } from "antd";

type AdminFormProps = {
  title: string;
  handleCancel?(): void;
  handleSave?(): void;
  isLoading?: boolean;
  sectionId?: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  > span {
    padding-left: 64px;
  }

  ${(props) => props.theme.breakpoints.tablet} {
    > span {
      padding-left: 24px;
    }
  }
`;

const FormTile = styled(Flex)`
  padding: 32px 64px;
  background-color: ${(props) => props.theme.colors.white};
  flex-direction: column;
  row-gap: 64px;

  ${(props) => props.theme.breakpoints.tablet} {
    padding: 16px 24px;
  }
`;

const FormTileContent = styled.div``;

const ButtonContainer = styled(Flex)`
  column-gap: 24px;
`;

export const AdminForm: React.FC<React.PropsWithChildren<AdminFormProps>> = ({
  title,
  children,
  handleCancel,
  handleSave,
  isLoading = false,
  sectionId,
}) => {
  return (
    <Container id={sectionId}>
      <Typography size={32} height={60} weight={500}>
        {title}
      </Typography>
      <FormTile>
        <FormTileContent>{children}</FormTileContent>
        {(handleCancel || handleSave) && (
          <ButtonContainer>
            {handleCancel && (
              <Button size="large" onClick={handleCancel} disabled={isLoading}>
                Cancel
              </Button>
            )}
            {handleSave && (
              <Button type="primary" size="large" onClick={handleSave} loading={isLoading}>
                Save
              </Button>
            )}
          </ButtonContainer>
        )}
      </FormTile>
    </Container>
  );
};
