import React from "react";
import { Flex } from "components";
import { styled } from "styled-components";

const Container = styled(Flex)`
  &::after {
    content: "\\00B0";
    margin-left: 2px;
    color: ${(props) => props.theme.colors.grey500};
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;

    #dark-theme & {
      color: ${(props) => props.theme.colors.blueGrey200};
    }
  }
`;

export const Temperature: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <Container>{children}</Container>;
};
