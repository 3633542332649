import { AdminForm, AdminTable, Flex, Modal } from "components";
import React from "react";
import { Input, Form, Select, Collapse, Button } from "antd";
import { UserRole } from "constants/roles";
import styled from "styled-components";
import { capitalizeFirstLetter } from "utils/string";
import {
  IUserFormData,
  useAddUserDevice,
  useCreateUser,
  useDeleteUserDevice,
  useUpdateUser,
  useUserById,
  useUserDevices,
} from "api/modules/users";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { Routes } from "constants/routes";
import { FormInstance, RuleObject } from "antd/es/form";
import { INodeModelDevice, useAvailableDevices } from "api/modules/devices";
import { useOpenableState } from "hooks/useOpenableState";
import { columns } from "pages/AdminDevicePage/columns";

const FormRow = styled(Flex)`
  column-gap: 40px;
  flex-wrap: wrap;
`;

const FormItem = styled(Form.Item)`
  min-width: 300px;
  width: 300px;
`;

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
  },
};

const getPasswordFields = () => {
  return (
    <>
      <FormItem
        label="Password"
        name="password"
        rules={[{ required: true }, { min: 8, message: "Password must contain at least 8 characters!" }]}
      >
        <Input.Password placeholder="Set New Password" />
      </FormItem>
      <FormItem
        label="Confirm Password"
        name="confirm_password"
        dependencies={["password"]}
        rules={[
          { required: true },
          (ruleForm: unknown) => ({
            validator(_: RuleObject, value: string) {
              if (!value || (ruleForm as FormInstance).getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Password that you entered do not match!"));
            },
          }),
        ]}
      >
        <Input.Password placeholder="Confirm Password" />
      </FormItem>
    </>
  );
};

export const AdminUserDetailsPage: React.FC<{ isNew?: boolean }> = ({ isNew }) => {
  const navigate = useNavigate();
  const params = useParams();
  const userId = params.userId!;

  const [isPasswordSectionOpen, setIsPasswordSectionOpen] = React.useState(false);
  const [modalSearch, setModalSearch] = React.useState("");
  const [isOpen, { open, close }] = useOpenableState();

  const { data: user, isFetching: isUserLoading, isError: isUserError } = useUserById(userId, !isNew);
  const { data: userDevices, isFetching: isUserDevicesLoading } = useUserDevices(userId, !isNew);

  const nodeId = userDevices?.[0]?.node_id;
  const { data: availableDevices, isFetching: isAvailableDevicesLoading } = useAvailableDevices(
    { nodeId, userId },
    { enabled: !isNew && !isUserDevicesLoading && isOpen },
  );

  React.useEffect(() => {
    if (isUserError) {
      navigate(Routes.AdminUsers);
    }
  }, [isUserError]);

  const [form] = Form.useForm<IUserFormData>();
  const { mutate: createUser, isLoading: isCreateLoading } = useCreateUser();
  const { mutate: updateUser, isLoading: isUpdateLoading } = useUpdateUser(userId);
  const { mutate: addUserDevice, isLoading: isAddDeviceLoading } = useAddUserDevice(userId);
  const { mutate: deleteUserDevice, isLoading: isDeleteDeviceLoading } = useDeleteUserDevice(userId);

  const handleSave = (values: IUserFormData) => {
    const actionFn = isNew ? createUser : updateUser;

    actionFn(values, {
      onSuccess: ({ user_id }) => {
        navigate(`${Routes.AdminUsers}/${user_id}`);
        toast.success(`User has been ${isNew ? "created" : "updated"} successfully!`);
      },
    });
  };

  const isActionLoading = isCreateLoading || isUpdateLoading;

  const filteredModalDevices = availableDevices?.filter((device) => {
    return [device.node_name, device.description, device.equip_id].some((text) =>
      text.toLowerCase().includes(modalSearch.toLowerCase()),
    );
  });
  return (
    <Flex direction="column" rowGap={40}>
      <AdminForm
        title={`${isNew ? "Create" : "Update"} User`}
        handleSave={form.submit}
        handleCancel={() => navigate(Routes.AdminUsers)}
        isLoading={isActionLoading}
      >
        {isUserLoading ? (
          <Skeleton count={10} />
        ) : (
          <Form
            layout="vertical"
            form={form}
            onFinish={handleSave}
            size="large"
            validateMessages={validateMessages}
            initialValues={{
              email: user?.email,
              firstname: user?.firstname,
              lastname: user?.lastname,
              role: user?.role,
            }}
            style={{ width: "fit-content" }}
            disabled={isActionLoading}
          >
            <FormRow>
              <FormItem label="First Name" name="firstname" rules={[{ required: true }]}>
                <Input placeholder="First Name" />
              </FormItem>
              <FormItem label="Last Name" name="lastname" rules={[{ required: true }]}>
                <Input placeholder="Last Name" />
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem label="Email" name="email" rules={[{ type: "email", required: true }]}>
                <Input placeholder="Email" disabled={!isNew} />
              </FormItem>
              <FormItem label="Role" name="role" rules={[{ required: true }]}>
                <Select placeholder="Choose Role">
                  {Object.values(UserRole).map((role) => (
                    <Select.Option value={role} key={role}>
                      {capitalizeFirstLetter(role)}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </FormRow>
            {isNew ? (
              getPasswordFields()
            ) : (
              <Collapse
                items={[{ label: "Change Password", children: isPasswordSectionOpen ? getPasswordFields() : null }]}
                size="small"
                style={{ marginTop: 24 }}
                onChange={() => {
                  setIsPasswordSectionOpen(!isPasswordSectionOpen);
                }}
              />
            )}
          </Form>
        )}
      </AdminForm>
      {!isNew && (
        <AdminForm title="User Devices" sectionId="devices">
          <AdminTable
            data={userDevices}
            columns={columns}
            isLoading={isUserDevicesLoading || isDeleteDeviceLoading}
            entity="device"
            rowKey="device_id"
            buttonTitle="Add Device"
            handleButtonClick={open}
            actions={[
              {
                key: "remove",
                label: "Remove",
                onAction: (device: INodeModelDevice) => {
                  deleteUserDevice({ deviceId: device.device_id });
                },
              },
            ]}
          />
        </AdminForm>
      )}
      <Modal isOpen={isOpen} maxHeight="90vh" width="100%" onClose={close}>
        <AdminTable
          data={filteredModalDevices}
          columns={[
            ...columns,
            {
              key: "actions",
              title: "Action",
              align: "center",
              render: (_: never, row: INodeModelDevice) => (
                <Button onClick={() => addUserDevice({ deviceId: row.device_id })} type="primary">
                  Add
                </Button>
              ),
            },
          ]}
          isLoading={isAvailableDevicesLoading || isAddDeviceLoading}
          entity="device"
          rowKey="device_id"
          onSearch={setModalSearch}
        />
      </Modal>
    </Flex>
  );
};
