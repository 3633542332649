import React from "react";

export const SceneIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.5 4.5V19.5M15.5 4.5V19.5M4.625 19.5H20.375C20.9963 19.5 21.5 18.9963 21.5 18.375V5.625C21.5 5.00368 20.9963 4.5 20.375 4.5H4.625C4.00368 4.5 3.5 5.00368 3.5 5.625V18.375C3.5 18.9963 4.00368 19.5 4.625 19.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
