import React from "react";
import { AdminTable } from "components";
import { useDeleteNode, useNodes } from "api/modules/node";
import { INode } from "types/common";
import { useDeleteModalEntity } from "hooks/useDeleteEntity";
import { Routes } from "constants/routes";
import { useNavigate } from "react-router-dom";
import { columns } from "./columns";

export const AdminNodePage: React.FC = () => {
  const navigate = useNavigate();
  const { data: nodes, isLoading } = useNodes();
  const { Component: DeleteModalComponent, openModal } = useDeleteModalEntity(useDeleteNode, "node");

  return (
    <>
      <AdminTable
        data={nodes}
        columns={columns}
        isLoading={isLoading}
        entity="node"
        rowKey="node_id"
        handleButtonClick={() => navigate(Routes.AdminNewNode)}
        actions={[
          {
            key: "delete",
            label: "Delete",
            onAction: (row: INode) => openModal(row.node_id),
          },
        ]}
        includePadding
      />
      <DeleteModalComponent />
    </>
  );
};
