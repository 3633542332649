import { Button, Flex, Input, Logo, NotificationTile, NotificationTileProps, Typography } from "components";
import React from "react";
import { styled } from "styled-components";
import { useLogin } from "api/modules/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "constants/routes";
import { isEmailValid } from "utils/field-validation";
import axios, { HttpStatusCode } from "axios";
import { PasswordInput } from "./components/PasswordInput/PasswordInput";

const Container = styled(Flex)`
  flex-direction: column;
  row-gap: 32px;

  border-radius: 5px;
  background: ${(props) => props.theme.colors.grey50};

  box-shadow: 3.269838333129883px 3.269838333129883px 6.539676666259766px 0px rgba(174, 174, 192, 0.4),
    -2.1798925399780273px -2.1798925399780273px 6.539676666259766px 0px #fff;

  padding: 56px 96px 72px 96px;
  margin: 100px auto 0;
  width: 576px;

  #dark-theme & {
    background: ${(props) => props.theme.colors.blueGrey800};
    box-shadow: ${(props) => props.theme.boxShadow.defaultDark};
  }

  ${(props) => props.theme.breakpoints.mobile} {
    width: 100%;
    padding: 16px;
    margin: 40px 0 0;
  }
`;

export const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mutate: login, isSuccess, isError, error } = useLogin();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [emailError, setEmailError] = React.useState("");
  const [notification, setNotification] = React.useState<NotificationTileProps | null>(location.state?.notification);

  const handleLogin: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!isEmailValid(email)) {
      setEmailError("Please enter a valid email");
      return;
    }
    setNotification(null);
    login({ email, password });
  };

  const handleEmailChange = (value: string) => {
    setEmailError("");
    setEmail(value);
  };

  React.useEffect(() => {
    if (isSuccess) {
      navigate(location.state?.nextRoute || Routes.Overview);
    }
    if (isError) {
      setPassword("");
      const message =
        axios.isAxiosError(error) && error.response?.status === HttpStatusCode.Unauthorized
          ? "Sie haben eine ungültige E-Mail Adresse oder ein ungültiges Passwort eingegeben. Bitte versuchen Sie es erneut oder kontaktieren Sie Koster."
          : "Interner Serverfehler.";
      setNotification({
        variant: "error",
        message,
      });
    }
  }, [isSuccess, isError]);

  return (
    <Container>
      <Flex direction="column" rowGap={48}>
        <Flex justify="center" align="center" width="100%">
          <Logo />
        </Flex>
        <Typography size={32} height={42} weight={500} spacing={-0.6} center>
          Bei Ihrem Konto anmelden
        </Typography>
      </Flex>
      {notification && <NotificationTile {...notification} />}
      <form onSubmit={handleLogin}>
        <Flex direction="column" rowGap={24}>
          <Input
            title="E-Mail"
            name="username"
            autoComplete="email"
            value={email}
            onChange={handleEmailChange}
            hasError={Boolean(emailError)}
            error={emailError}
          />
          <PasswordInput name="password" autoComplete="password" value={password} onChange={setPassword} />
          <Button size="large" disabled={!email || !password}>
            Einloggen
          </Button>
        </Flex>
      </form>
    </Container>
  );
};
