import { Flex, Typography } from "components";
import { styled } from "styled-components";

export const Styles = {
  SectionContainer: styled(Flex)`
    ${(props) => props.theme.breakpoints.tablet} {
      row-gap: 22px;
      flex-direction: column;
      align-items: flex-start;
    }
  `,
  TilesContainer: styled(Flex)`
    ${(props) => props.theme.breakpoints.tablet} {
      flex-direction: column;
      align-items: flex-start;
    }
  `,
  OverviewTile: styled(Flex).attrs({ align: "center" })`
    box-shadow: -2.17989px -2.17989px 6.53968px #ffffff, 3.26984px 3.26984px 6.53968px rgba(174, 174, 192, 0.4);
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.grey50};
    box-sizing: border-box;
    padding: 16px 40px;
    gap: 40px;

    #dark-theme & {
      background: ${(props) => props.theme.colors.blueGrey700};
      box-shadow: ${(props) => props.theme.boxShadow.defaultDark};
    }

    ${(props) => props.theme.breakpoints.tablet} {
      padding: 16px;
    }

    ${(props) => props.theme.breakpoints.mobile} {
      width: 100%;
      gap: 16px;
      flex-wrap: wrap;
    }
  `,
  Title: styled(Typography)`
    ${(props) => props.theme.breakpoints.mobile} {
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
    }
  `,
  SubTitle: styled(Typography)`
    ${(props) => props.theme.breakpoints.mobile} {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
    }
  `,
};
