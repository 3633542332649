import React from "react";
import { styled } from "styled-components";
import { ColorName } from "theme";
import { AddPrefixToObject } from "types/misc";

type TypographyProps = {
  size?: number;
  height?: number;
  weight?: number;
  spacing?: number;
  color?: ColorName;
  className?: string;
  noWrap?: boolean;
  center?: boolean;
};

const Span = styled.span<AddPrefixToObject<TypographyProps, "$">>`
  font-size: ${(props) => (props.$size ? `${props.$size}px` : "inherit")};
  line-height: ${(props) => (props.$height ? `${props.$height}px` : "inherit")};
  font-weight: ${(props) => props.$weight || 400};
  color: ${(props) => (props.$color ? props.theme.colors[props.$color] : "inherit")};
  letter-spacing: ${(props) => (props.$spacing ? `${props.$spacing}px` : "normal")};
  white-space: ${(props) => (props.$noWrap ? "nowrap" : "normal")};
  text-align: ${(props) => (props.$center ? "center" : "left")};
`;

export const Typography: React.FC<React.PropsWithChildren<TypographyProps>> = ({ children, className, ...rest }) => {
  const { size, height, weight, spacing, color, noWrap, center } = rest;
  return (
    <Span
      $size={size}
      $height={height}
      $weight={weight}
      $spacing={spacing}
      $color={color}
      $noWrap={noWrap}
      $center={center}
      className={className}
    >
      {children}
    </Span>
  );
};
