import React from "react";
import { IDataMetric, IDataRaw } from "types/common";

export const useDataListener = (
  initialData: IDataMetric[] | undefined,
  isLoading: boolean,
  options: { enabled: boolean } = { enabled: true },
) => {
  const [data, setData] = React.useState<IDataMetric[]>();
  React.useEffect(() => {
    let eventSource: EventSource;
    if (options.enabled) {
      eventSource = new EventSource(`${process.env.REACT_APP_API_URL || "/api/"}sse/data`, {
        withCredentials: true,
      });
      eventSource.onmessage = ({ data: metricData }) => {
        try {
          const metric: IDataRaw = JSON.parse(metricData);
          setData((prevMetrics) =>
            prevMetrics?.map((prevMetric) =>
              metric.device_id === prevMetric.device_id && metric.metric_id === prevMetric.metric_id
                ? { ...prevMetric, ...metric }
                : prevMetric,
            ),
          );
        } catch {}
      };
    }
    return () => eventSource?.close();
  }, [options.enabled]);

  React.useEffect(() => {
    if (initialData && !isLoading) {
      setData(initialData);
    }
  }, [initialData, isLoading]);
  return [data];
};
