import { CircleCheckIcon, ErrorXCircleIcon, ExclemationWarningIcon } from "assets";
import { Flex } from "components/Flex";
import { Icon } from "components/Icon";
import { Typography } from "components/Typography";
import React from "react";
import { styled } from "styled-components";
import { ColorName } from "theme";

const Container = styled(Flex)<{ $backgroundColor: ColorName }>`
  padding: 16px;
  border-radius: 6px;
  background: ${(props) => props.theme.colors[props.$backgroundColor]};

  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

  & svg {
    min-width: 20px;
  }
`;

export type NotificationTileProps = {
  message: string;
  variant: "success" | "warning" | "error";
};

export const NotificationTile: React.FC<NotificationTileProps> = ({ variant, message }) => {
  let icon = CircleCheckIcon;
  let color: ColorName = "green800";
  let backgroundColor: ColorName = "green50";
  if (variant === "error") {
    icon = ErrorXCircleIcon;
    color = "red800";
    backgroundColor = "red50";
  } else if (variant === "warning") {
    icon = ExclemationWarningIcon;
    color = "yellow800";
    backgroundColor = "yellow100";
  }
  return (
    <Container $backgroundColor={backgroundColor}>
      <Flex columnGap={12}>
        <Icon icon={icon} />
        <Typography size={14} height={20} weight={500} color={color}>
          {message}
        </Typography>
      </Flex>
    </Container>
  );
};
