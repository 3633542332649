import React from "react";

export const TrashCanIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.73438 3.95312H7.5625C7.65703 3.95312 7.73438 3.87578 7.73438 3.78125V3.95312H14.2656V3.78125C14.2656 3.87578 14.343 3.95312 14.4375 3.95312H14.2656V5.5H15.8125V3.78125C15.8125 3.02285 15.1959 2.40625 14.4375 2.40625H7.5625C6.8041 2.40625 6.1875 3.02285 6.1875 3.78125V5.5H7.73438V3.95312ZM18.5625 5.5H3.4375C3.05723 5.5 2.75 5.80723 2.75 6.1875V6.875C2.75 6.96953 2.82734 7.04688 2.92188 7.04688H4.21953L4.7502 18.2832C4.78457 19.0158 5.39043 19.5938 6.12305 19.5938H15.877C16.6117 19.5938 17.2154 19.018 17.2498 18.2832L17.7805 7.04688H19.0781C19.1727 7.04688 19.25 6.96953 19.25 6.875V6.1875C19.25 5.80723 18.9428 5.5 18.5625 5.5ZM15.7115 18.0469H6.28848L5.76855 7.04688H16.2314L15.7115 18.0469Z"
        fill="#A39E93"
      />
    </svg>
  );
};
