import axios, { AxiosRequestConfig } from "axios";
import { Routes } from "constants/routes";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "/api",
  withCredentials: true,
});

export const request = async <T>(options: AxiosRequestConfig) => {
  const result = await axiosClient<T>(options);
  return result.data;
};

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 403) {
      if (window.location.pathname !== Routes.Login) {
        window.location.href = Routes.Login;
      }
    }
    throw error;
  },
);
