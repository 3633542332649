import React from "react";
import { Typography, Flex } from "components";
import styled from "styled-components";
import { Button, Divider } from "antd";

type AdminFormProps = {
  title: string;
  firstColumnChildren: React.ReactNode;
  secondColumnChildren: React.ReactNode;
  firstColumnHandleSecondaryButtonAction?(): void;
  firstColumnHandlePrimaryButtonAction?(): void;
  secondColumnHandleSecondaryButtonAction?(): void;
  secondColumnHandlePrimaryButtonAction?(): void;
  firstColumnPrimaryButtonText?: string;
  firstColumnSecondaryButtonText?: string;
  secondColumnPrimaryButtonText?: string;
  secondColumnSecondaryButtonText?: string;
  firstColumnPrimaryButtonIsDisabled?: boolean;
  secondColumnPrimaryButtonIsDisabled?: boolean;
  isLoading?: boolean;
  sectionId?: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  > span {
    padding-left: 64px;
  }

  ${(props) => props.theme.breakpoints.tablet} {
    > span {
      padding-left: 24px;
    }
  }
`;

const FormTile = styled(Flex)`
  padding: 32px 64px;
  background-color: ${(props) => props.theme.colors.white};
  flex-direction: column;
  row-gap: 64px;

  ${(props) => props.theme.breakpoints.tablet} {
    padding: 16px 24px;
  }
`;

const FormColumnContainer = styled(Flex)`
  display: flex;
  column-gap: 32px;
`;

const FormColumn = styled(Flex)`
  flex-direction: column;
  width: 50%;
  justify-content: space-between;
`;

const FormTileContent = styled.div``;

const ButtonContainer = styled(Flex)`
  column-gap: 24px;
  padding-top: 16px;
`;

export const AdminFormTwoColumns: React.FC<React.PropsWithChildren<AdminFormProps>> = ({
  title,
  firstColumnChildren,
  secondColumnChildren,
  firstColumnHandleSecondaryButtonAction,
  firstColumnHandlePrimaryButtonAction,
  secondColumnHandleSecondaryButtonAction,
  secondColumnHandlePrimaryButtonAction,
  firstColumnPrimaryButtonText = "Save",
  firstColumnSecondaryButtonText = "Cancel",
  secondColumnPrimaryButtonText = "Save",
  secondColumnSecondaryButtonText = "Cancel",
  firstColumnPrimaryButtonIsDisabled = false,
  secondColumnPrimaryButtonIsDisabled = false,
  isLoading = false,
  sectionId,
}) => {
  return (
    <Container id={sectionId}>
      <Typography size={32} height={60} weight={500}>
        {title}
      </Typography>
      <FormTile>
        <FormColumnContainer>
          <FormColumn>
            <FormTileContent>{firstColumnChildren}</FormTileContent>
            {(firstColumnHandleSecondaryButtonAction || firstColumnHandlePrimaryButtonAction) && (
              <ButtonContainer>
                {firstColumnHandleSecondaryButtonAction && (
                  <Button size="large" onClick={firstColumnHandleSecondaryButtonAction} disabled={isLoading}>
                    {firstColumnSecondaryButtonText}
                  </Button>
                )}
                {firstColumnHandlePrimaryButtonAction && (
                  <Button
                    type="primary"
                    size="large"
                    onClick={firstColumnHandlePrimaryButtonAction}
                    loading={isLoading}
                    disabled={firstColumnPrimaryButtonIsDisabled}
                  >
                    {firstColumnPrimaryButtonText}
                  </Button>
                )}
              </ButtonContainer>
            )}
          </FormColumn>
          <Divider type="vertical" style={{ height: "auto", background: "#E8E6E1" }} />
          <FormColumn>
            <FormTileContent>{secondColumnChildren}</FormTileContent>
            {(secondColumnHandleSecondaryButtonAction || secondColumnHandlePrimaryButtonAction) && (
              <ButtonContainer>
                {secondColumnHandleSecondaryButtonAction && (
                  <Button size="large" onClick={secondColumnHandleSecondaryButtonAction} disabled={isLoading}>
                    {secondColumnSecondaryButtonText}
                  </Button>
                )}
                {secondColumnHandlePrimaryButtonAction && (
                  <Button
                    type="primary"
                    size="large"
                    onClick={secondColumnHandlePrimaryButtonAction}
                    loading={isLoading}
                    disabled={secondColumnPrimaryButtonIsDisabled}
                  >
                    {secondColumnPrimaryButtonText}
                  </Button>
                )}
              </ButtonContainer>
            )}
          </FormColumn>
        </FormColumnContainer>
      </FormTile>
    </Container>
  );
};
