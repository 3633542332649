import { useEffect, useState } from "react";
import { breakpoints } from "theme";

type ScreenName = "mobile" | "tablet" | "desktop";

type Media = Record<ScreenName, boolean>;

const subscribers = new Set<() => void>();

const media: Media = {
  mobile: false,
  tablet: false,
  desktop: false,
};

const calculate = () => {
  const windowWidth = window.innerWidth;
  let update = false;
  const isDesktop = windowWidth >= breakpoints.desktop;
  if (media.desktop !== isDesktop) {
    media.desktop = isDesktop;
    update = true;
  }

  const isTablet = windowWidth <= breakpoints.tablet && windowWidth > breakpoints.mobile;
  if (media.tablet !== isTablet) {
    media.tablet = isTablet;
    update = true;
  }

  const isMobile = windowWidth <= breakpoints.mobile;
  if (media.mobile !== isMobile) {
    media.mobile = isMobile;
    update = true;
  }
  return update;
};

calculate();

window.addEventListener("resize", () => {
  if (calculate()) {
    subscribers.forEach((subscriber) => {
      subscriber();
    });
  }
});

export const useBreakpoints = () => {
  const [state, setState] = useState<Media>(media);

  useEffect(() => {
    const subscriber = () => {
      setState({ ...media });
    };
    subscribers.add(subscriber);
    return () => {
      subscribers.delete(subscriber);
    };
  }, []);

  return state;
};
