import React from "react";
import { Flex, FlowLine, Typography } from "components";
import { DarkHouseIcon, HouseIcon } from "assets";
import { Metrics } from "constants/metrics";
// import { NavLink } from "react-router-dom";
// import { Routes } from "constants/routes";
import { SettingsContext } from "App";
import { IHomeDeviceProps } from "../common";
import { Styles } from "../../styles";

export const House = React.forwardRef<HTMLDivElement, IHomeDeviceProps>(({ isDarkTheme, metric, position }, ref) => {
  const solarValue = +(metric?.[Metrics.Power]?.value || 0).toFixed(2);

  const settings = React.useContext(SettingsContext);

  return (
    <Styles.AbsoluteDiv left={position.container.left} top={position.container.top}>
      {/* <NavLink to={`${Routes.Devices}/9`}> */}
      <Flex position="relative">
        <Styles.AdaptiveIcon icon={isDarkTheme ? DarkHouseIcon : HouseIcon} $mobileWidth={135} $mobileHeight={137} />
        <Styles.AbsoluteDiv left={position.inner?.left} top={position.inner?.top} ref={ref}>
          <Styles.AdaptiveIcon
            icon={isDarkTheme ? settings.darkHouseLogo : settings.houseLogo}
            $mobileWidth={28}
            $mobileHeight={28}
          />
        </Styles.AbsoluteDiv>
        {metric?.[Metrics.Power] && (
          <Styles.AbsoluteDiv left={position.line.left} top={position.line.top}>
            <Flex direction="column" align="center" rowGap={10}>
              <Styles.DeviceTitle>Erzeugung</Styles.DeviceTitle>
              <Flex direction="column" align="center">
                <Styles.SolarLabel>
                  <Flex columnGap={4} align="center">
                    <Typography size={20} height={24} weight={500} color="green800">
                      {solarValue}
                    </Typography>
                    <Typography size={8} height={11} weight={400} spacing={-0.5} color="green400">
                      {metric[Metrics.Power].unit}
                    </Typography>
                  </Flex>
                </Styles.SolarLabel>
                <FlowLine
                  vertical
                  lineColors={solarValue === 0 ? ["#B9B2A8"] : ["#199473", "#8EEDC7"]}
                  color="green500"
                  size={position.line.size}
                  noAnimation={solarValue === 0}
                  noFlow={solarValue === 0}
                />
              </Flex>
            </Flex>
          </Styles.AbsoluteDiv>
        )}
      </Flex>
      {/* </NavLink> */}
    </Styles.AbsoluteDiv>
  );
});
