import React from "react";

export const EquipmentIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.5 20.25H18.5M11 17.25V20.25M14 17.25V20.25M3.875 17.25H21.125C21.7463 17.25 22.25 16.7463 22.25 16.125V4.875C22.25 4.25368 21.7463 3.75 21.125 3.75H3.875C3.25368 3.75 2.75 4.25368 2.75 4.875V16.125C2.75 16.7463 3.25368 17.25 3.875 17.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
