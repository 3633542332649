import React from "react";

export const OutsideIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.7685 7.69454C19.5078 7.69454 20.9179 6.30784 20.9179 4.59727C20.9179 2.88669 19.5078 1.5 17.7685 1.5C16.0292 1.5 14.6191 2.88669 14.6191 4.59727C14.6191 6.30784 16.0292 7.69454 17.7685 7.69454Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M15.8091 9.41961L10.8132 10.9228L7.36635 14.5548C7.36635 14.5548 6.45335 15.9168 7.50997 16.8752C8.5666 17.8337 9.9002 16.8752 9.9002 16.8752L12.5674 13.8486L13.9831 13.3643L12.7623 18.0254L11.8698 22.9084L8.5153 28.0133C8.5153 28.0133 7.99212 29.2441 9.15133 30.1219C10.3105 30.9996 11.4082 29.8192 11.4082 29.8192L15.0294 24.4419L15.8091 21.5766L17.789 23.0799L19.7791 29.5771C19.7791 29.5771 20.6511 31.0097 21.995 30.3035C23.3388 29.5973 23.0618 28.3967 23.0618 28.3967L20.7845 21.052L16.4964 17.7631L17.3273 14.464L19.6047 16.734L23.308 17.7227C23.308 17.7227 24.7442 17.8034 24.9699 16.512C25.1956 15.2207 24.0774 14.5851 24.0774 14.5851L21.164 13.667L18.107 9.98458C17.5119 9.31798 16.6317 9.29651 15.8091 9.41961Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};
