import { AdminForm, AdminTable, AdminWarningModal, Flex, Icon } from "components";
import React from "react";
import { Input, Form, Button } from "antd";
import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { Routes } from "constants/routes";
import {
  IMqttNode,
  useCreateNode,
  useGenerateNodePassword,
  useNodeById,
  useNodeDevices,
  useUpdateNode,
} from "api/modules/node";
import { useDeleteModalEntity } from "hooks/useDeleteEntity";
import { INodeModelDevice, useDeleteDevice } from "api/modules/devices";
import { columns } from "pages/AdminDevicePage/columns";
import { throttle } from "utils/throttle";
import { useOpenableState } from "hooks/useOpenableState";
import { CopyIcon } from "assets";

const FormRow = styled(Flex)`
  column-gap: 40px;
  flex-wrap: wrap;
`;

const FormItem = styled(Form.Item)`
  min-width: 300px;
  width: 300px;
`;

const CopyInput = styled(Input)`
  & * {
    cursor: pointer;
  }

  &:hover {
    border-color: #423d33;
    box-shadow: 0 0 0 2px rgba(19, 17, 4, 0.53);
    outline: 0;
  }
`;

const getCopyInput = (onClick: React.MouseEventHandler<HTMLInputElement>) => {
  return <CopyInput readOnly onClick={onClick} suffix={<Icon icon={CopyIcon} color="grey800" onClick={onClick} />} />;
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    number: "${label} is not a valid number!",
  },
};

export const AdminNodeDetailsPage: React.FC<{ isNew?: boolean }> = ({ isNew }) => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const params = useParams();
  const nodeId = params.nodeId!;

  const { data: node, isFetching: isNodeLoading, isError: isNodeError } = useNodeById(nodeId, !isNew);
  const { data: devices, isFetching: isDevicesLoading } = useNodeDevices(nodeId, !isNew);

  React.useEffect(() => {
    if (isNodeError) {
      navigate(Routes.AdminNodes);
    }
  }, [isNodeError]);

  React.useEffect(() => {
    if (hash && !isDevicesLoading) {
      document
        .getElementById(hash.replace("#", ""))
        ?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }, [hash, isDevicesLoading]);

  const [form] = Form.useForm<IMqttNode>();
  const { mutate: createNode, isLoading: isCreateLoading } = useCreateNode();
  const { mutate: updateNode, isLoading: isUpdateLoading } = useUpdateNode(nodeId);
  const { mutate: generateNewPassword, isLoading: isGenerateLoading } = useGenerateNodePassword();

  const handleSave = (values: IMqttNode) => {
    if (isNew) {
      createNode(values, {
        onSuccess: ({ node_id, password }) => {
          form.setFieldValue("password", password);
          navigate(`${Routes.AdminNodes}/${node_id}`);
          toast.warn(
            `Please note: the password is only showed once. Please copy it and store it in a safe place for configuration of the node!`,
          );
          toast.success(`Node has been created successfully!`);
        },
      });
    } else {
      updateNode(
        { address: values.address, zip: values.zip, description: values.description, city: values.city },
        {
          onSuccess: () => {
            toast.success(`Node has been updated successfully!`);
          },
        },
      );
    }
  };

  const { Component: DeleteModalComponent, openModal: openDeleteModal } = useDeleteModalEntity(
    useDeleteDevice,
    "device",
  );
  const [isConfirmModalOpen, { open, close }] = useOpenableState();

  const handleGenerateClick = () => {
    close();
    generateNewPassword(nodeId, {
      onSuccess: ({ password }) => {
        toast.warn(
          `Please note: the password is only showed once. Please copy it and store it in a safe place for configuration of the node!`,
        );
        toast.success(`Node password has been changed successfully!`);
        form.setFieldValue("password", password);
      },
    });
  };

  const handleCopyInput = throttle((formName: string) => {
    navigator.clipboard.writeText(form.getFieldValue(formName));
    toast.success("Copied!", { autoClose: 1000 });
  }, 1500);

  const isActionLoading = isCreateLoading || isUpdateLoading;
  return (
    <Flex direction="column" rowGap={40}>
      <AdminForm
        title={`${isNew ? "Create" : "Update"} Node`}
        handleSave={form.submit}
        handleCancel={() => navigate(Routes.AdminNodes)}
        isLoading={isActionLoading}
      >
        {isNodeLoading ? (
          <Skeleton count={10} />
        ) : (
          <Form
            layout="vertical"
            form={form}
            onFinish={handleSave}
            size="large"
            validateMessages={validateMessages}
            initialValues={node ? { ...node } : {}}
            style={{ width: "fit-content" }}
            disabled={isActionLoading}
          >
            <FormRow>
              <FormItem label="Description" name="description" rules={[{ required: true }]}>
                <Input placeholder="Description" />
              </FormItem>
              <FormItem label="Address" name="address" rules={[{ required: true }]}>
                <Input placeholder="Address" />
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem
                label="Zip"
                name="zip"
                rules={[
                  {
                    type: "integer",
                    min: 0,
                    transform(value: string) {
                      return Number(value);
                    },
                  },
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Zip" type="number" min={0} />
              </FormItem>
              <FormItem label="City" name="city" rules={[{ required: true }]}>
                <Input placeholder="City" />
              </FormItem>
            </FormRow>
            {!isNew && (
              <>
                <FormRow>
                  <FormItem label="Username" name="username">
                    {getCopyInput(() => handleCopyInput("username"))}
                  </FormItem>
                  <FormItem label="Client ID" name="node_id">
                    {getCopyInput(() => handleCopyInput("node_id"))}
                  </FormItem>
                </FormRow>
                {form.getFieldValue("password") && (
                  <FormRow>
                    <FormItem label="Password" name="password">
                      {getCopyInput(() => handleCopyInput("password"))}
                    </FormItem>
                  </FormRow>
                )}
                <Button loading={isGenerateLoading} style={{ marginTop: 24 }} onClick={open}>
                  Generate New Password
                </Button>
              </>
            )}
          </Form>
        )}
      </AdminForm>
      {!isNew && (
        <AdminForm title="Node Devices" sectionId="devices">
          <AdminTable
            data={devices}
            columns={columns}
            isLoading={isDevicesLoading}
            entity="device"
            rowKey="device_id"
            buttonTitle="Add Device"
            handleButtonClick={() => navigate(Routes.AdminNewDevice, { state: node })}
            actions={[
              {
                key: "delete",
                label: "Delete",
                onAction: (device: INodeModelDevice) => openDeleteModal(device.device_id),
              },
            ]}
          />
        </AdminForm>
      )}
      <DeleteModalComponent />
      <AdminWarningModal
        isOpen={isConfirmModalOpen}
        handleClose={close}
        handleConfirm={handleGenerateClick}
        header="Warning"
        title="Please note: when updating the password, the connection of the Node with the app will stop and
        you need to reconfigure the new password on the Node. The new password is only
        showed once. Please copy it and store it in a safe place for configuration of the node!"
        confirmTitle="Generate"
      />
    </Flex>
  );
};
