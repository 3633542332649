import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { request } from "api/config/axios";
import { queryClient } from "api/config/query";
import { IDevice, IModel, INode } from "types/common";

export interface INodeModelDevice extends INode, IDevice, IModel {
  node_name: string;
}

export interface IDeviceFormData extends Omit<IDevice, "device_id"> {}

const getDevices = async () => {
  return request<INodeModelDevice[]>({
    url: "/devices",
    method: "GET",
  });
};

export const useDevices = (options?: UseQueryOptions<INodeModelDevice[]>) => {
  return useQuery<INodeModelDevice[]>(["devices"], getDevices, options);
};

const getAvailableDevices = async (data: { nodeId?: string | number; userId: string | number }) => {
  return request<INodeModelDevice[]>({
    url: "/devices/available",
    method: "POST",
    data,
  });
};

export const useAvailableDevices = (
  data: { nodeId?: string | number; userId: string | number },
  options?: UseQueryOptions<INodeModelDevice[]>,
) => {
  return useQuery<INodeModelDevice[]>(
    ["available-devices", data.userId, data.nodeId],
    () => getAvailableDevices(data),
    options,
  );
};

const getDeviceById = async (id: string) => {
  return request<INodeModelDevice>({
    url: `/devices/${id}`,
    method: "GET",
  });
};

export const useDeviceById = (id: string, enabled = true) => {
  return useQuery<INodeModelDevice>(["device", id], () => getDeviceById(id), { enabled });
};

const createDevice = async (data: IDeviceFormData) => {
  return request<IDevice>({
    url: "/devices",
    method: "POST",
    data,
  });
};

export const useCreateDevice = () =>
  useMutation({
    mutationFn: createDevice,
  });

const updateDevice = async (id: string, data: IDeviceFormData) => {
  return request<IDevice>({
    url: `/devices/${id}`,
    method: "PATCH",
    data,
  });
};

export const useUpdateDevice = (id: string) =>
  useMutation({
    mutationFn: (data: IDeviceFormData) => updateDevice(id, data),
  });

const deleteDevice = async (id: number | string) => {
  return request<boolean>({
    url: `/devices/${id}`,
    method: "DELETE",
  });
};

export const useDeleteDevice = () =>
  useMutation({
    mutationFn: deleteDevice,
    onSuccess: () => queryClient.invalidateQueries(["devices"]),
  });
