import { TimeRangeId } from "constants/time-range";
import dayjs from "dayjs";

export type PickerDateType = Date | null;
export type PickerDateRangeType = [PickerDateType, PickerDateType];

export const getDates = (timeRange: TimeRangeId, date?: PickerDateType | PickerDateRangeType): PickerDateRangeType => {
  if (Array.isArray(date)) {
    return date;
  }
  const currentDate = date || new Date();
  if (timeRange === TimeRangeId.Day) {
    return [dayjs(currentDate).startOf("d").toDate(), dayjs(currentDate).endOf("d").toDate()];
  }
  if (timeRange === TimeRangeId.Week) {
    const dayOfWeek = dayjs(currentDate).day();
    return [
      dayjs(currentDate)
        .startOf("d")
        .subtract(dayOfWeek - 1, "day")
        .toDate(),
      dayjs(currentDate)
        .endOf("d")
        .add(7 - dayOfWeek, "day")
        .toDate(),
    ];
  }
  if (timeRange === TimeRangeId.Month) {
    return [dayjs(currentDate).startOf("M").toDate(), dayjs(currentDate).endOf("M").toDate()];
  }
  if (timeRange === TimeRangeId.Year) {
    return [dayjs(currentDate).startOf("y").toDate(), dayjs(currentDate).endOf("y").toDate()];
  }
  return [dayjs(currentDate).subtract(1, "d").startOf("d").toDate(), dayjs(currentDate).endOf("d").toDate()];
};

export const getChartLabels = (timeRange: TimeRangeId, startDate: Date | null, endDate?: Date | null) => {
  switch (timeRange) {
    case TimeRangeId.Day: {
      const minutes = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];
      const times = [];
      for (let i = 0; i < 24; i++) {
        for (let j = 0; j < minutes.length; j++) {
          times.push(`${i}:${minutes[j]}`);
        }
      }
      return [...times, ""];
    }
    case TimeRangeId.Week: {
      return Array.from({ length: 7 }, (_, i) => dayjs(startDate).add(i, "day").format("DD.MM"));
    }

    case TimeRangeId.Month:
      return Array.from({ length: dayjs(startDate).endOf("month").date() }, (_, i) => `${i + 1}`);
    case TimeRangeId.Year:
      return Array.from({ length: 12 }, (_, i) => `${i + 1}`);
    case TimeRangeId.Custom: {
      const length = endDate ? dayjs(endDate).date() - dayjs(startDate).date() + 1 : 1;
      return Array.from({ length }, (_, i) => dayjs(startDate).add(i, "day").format("DD.MM"));
    }
    default:
      return [];
  }
};

export const getDatePickerInputValue = (timeRange: TimeRangeId, startDate: PickerDateType, endDate: PickerDateType) => {
  switch (timeRange) {
    case TimeRangeId.Day: {
      const sDate = dayjs(startDate);
      if (sDate.isSame(dayjs(), "day")) {
        return `Heute, ${sDate.format("DD. MMMM YYYY")}`;
      }
      return sDate.format("DD. MMMM YYYY");
    }
    case TimeRangeId.Custom:
    case TimeRangeId.Week:
      return `${dayjs(startDate).format("DD.MM.YYYY")} - ${endDate ? dayjs(endDate).format("DD.MM.YYYY") : ""}`;
    case TimeRangeId.Month:
      return dayjs(startDate).format("MMMM YYYY");
    case TimeRangeId.Year:
      return dayjs(startDate).format("YYYY");
    default:
      return "";
  }
};
