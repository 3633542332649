import React from "react";
import { toast } from "react-toastify";
import { UseMutationResult } from "@tanstack/react-query";
import { capitalizeFirstLetter } from "utils/string";
import { AdminWarningModal } from "components";
import { useOpenableState } from "./useOpenableState";

export const useDeleteModalEntity = (
  useDeleteAction: () => UseMutationResult<boolean, unknown, string | number>,
  entity: string,
) => {
  const [idToDelete, setIdToDelete] = React.useState<number>();
  const [isOpen, { open, close }] = useOpenableState();

  const { mutate: deleteEntity, isLoading } = useDeleteAction();

  const openModal = (id: number) => {
    setIdToDelete(id);
    open();
  };

  const closeModal = () => {
    setIdToDelete(undefined);
    close();
  };

  const handleDeleteEntity = () => {
    if (idToDelete) {
      deleteEntity(idToDelete, {
        onSuccess: () => {
          closeModal();
          toast.success(`${capitalizeFirstLetter(entity)} has been deleted successfully!`);
        },
      });
    }
  };

  return {
    Component: () => (
      <AdminWarningModal
        isOpen={isOpen}
        handleClose={closeModal}
        handleConfirm={handleDeleteEntity}
        entity={entity}
        isLoading={isLoading}
      />
    ),
    openModal,
  };
};
