import { Flex } from "components";
import { styled } from "styled-components";

export const Styles = {
  Container: styled(Flex)`
    background-color: ${(props) => props.theme.colors.grey50};
    box-shadow: -3.5px -3.5px 10px #ffffff, 5.44973px 5.44973px 10px #e8e6e1;
    border-radius: 5px;
    padding: 40px 56px;

    #dark-theme & {
      background-color: ${(props) => props.theme.colors.blueGrey800};
      box-shadow: ${(props) => props.theme.boxShadow.defaultDark};
    }

    ${(props) => props.theme.breakpoints.mobile} {
      padding: 24px 16px;
    }
  `,
  ChartsContainer: styled(Flex)`
    ${(props) => props.theme.breakpoints.tablet} {
      flex-direction: column;
    }
  `,
  DoughnutChartContainer: styled(Flex)`
    ${(props) => props.theme.breakpoints.tablet} {
      flex-direction: row;
      gap: 24px;
      justify-content: center;
      width: 100%;
      flex-wrap: wrap;
    }
  `,
  TitleContainer: styled(Flex)`
    padding-bottom: 32px;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey100};

    #dark-theme & {
      border-bottom: 1px solid ${(props) => props.theme.colors.blueGrey600};
    }

    ${(props) => props.theme.breakpoints.mobile} {
      padding-bottom: 24px;

      & > span {
        font-size: 18px;
        line-height: 24px;
      }
    }
  `,
};
