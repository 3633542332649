import { ThemeModeContext } from "App";
import { IDeviceAlarm } from "api/modules/alarms";
import { ExclamationTriangleIcon, InfoIcon } from "assets";
import { Card, Flex, Icon, IconProps, Typography } from "components";
import { Routes } from "constants/routes";
import dayjs from "dayjs";
import { HeaderActiveElement } from "layouts/PageLayout/PageLayout";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { styled } from "styled-components";
import { ColorName, theme } from "theme";

const Container = styled(Flex)`
  width: 100%;
  flex-direction: column;
  row-gap: 32px;
  max-width: 576px;
  margin: 0 auto;
`;

const NotificationContainer = styled(Card)`
  padding: 12px 12px 12px 24px;
  box-shadow: 3.269838333129883px 3.269838333129883px 6.539676666259766px 0px rgba(174, 174, 192, 0.4),
    -2.1798925399780273px -2.1798925399780273px 6.539676666259766px 0px #fff;
  background-color: ${(props) => props.theme.colors.grey50};
`;

// const UnreadDot = styled.div<{ $unread: boolean }>`
//   width: 4px;
//   height: 4px;
//   border-radius: 4px;
//   background: ${(props) => (props.$unread ? props.theme.colors.green300 : props.theme.colors.transparent)};
//   margin-left: -12px;
// `;

const iconsByPriority: Record<number, { icon: IconProps["icon"]; color: ColorName }> = {
  1: { icon: ExclamationTriangleIcon, color: "red500" },
  2: { icon: ExclamationTriangleIcon, color: "orange500" },
  3: { icon: InfoIcon, color: "grey500" },
};

const NotificationItem: React.FC<{
  notification: IDeviceAlarm;
  handleClick: () => void;
  isDarkTheme: boolean;
}> = ({ notification, handleClick, isDarkTheme }) => {
  return (
    <NotificationContainer onClick={handleClick}>
      <Flex direction="column" rowGap={8} width="100%">
        <Typography color="blue500" size={12} height={16} weight={400}>
          {dayjs(notification.ts).format("DD.MM.YYYY [·] HH:mm")}
        </Typography>
        <Flex columnGap={8} align="center">
          {/* <UnreadDot $unread={notification.unread} /> */}

          <Flex justify="space-between" width="100%">
            <Typography size={16} height={26} weight={500}>
              {notification.description}
            </Typography>
            <Icon
              icon={iconsByPriority[notification.priority].icon}
              color={theme.colors[iconsByPriority[notification.priority].color]}
            />
          </Flex>
        </Flex>
        <Typography size={14} height={21} weight={400} color={isDarkTheme ? "blueGrey200" : "grey600"}>
          {notification.msg}
        </Typography>
      </Flex>
    </NotificationContainer>
  );
};

export const Notifications: React.FC<{
  alarms: IDeviceAlarm[];
  toggleActiveElement: (element: HeaderActiveElement) => void;
  isLoading: boolean;
}> = ({ alarms, toggleActiveElement, isLoading }) => {
  const isDarkTheme = React.useContext(ThemeModeContext) === "dark";

  const navigate = useNavigate();

  React.useEffect(() => {
    toast.dismiss();
  }, []);

  const handleNotificationClick = (id: number) => {
    navigate(`${Routes.Devices}/${id}`);
    toggleActiveElement(null);
  };
  return (
    <Container>
      {isLoading ? (
        <Skeleton count={5} />
      ) : (
        <Flex direction="column" rowGap={24}>
          {alarms.map((alarm) => (
            <NotificationItem
              notification={alarm}
              handleClick={() => handleNotificationClick(alarm.device_id)}
              key={alarm.alarm_id}
              isDarkTheme={isDarkTheme}
            />
          ))}
        </Flex>
      )}
    </Container>
  );
};
