import { Flex, FlexProps, Typography } from "components";
import React from "react";
import { styled } from "styled-components";

const Tile = styled(Flex)`
  padding: 0px 12px 2px;
  border: 1px solid ${(props) => props.theme.colors.grey100};
  border-radius: 50px;

  #dark-theme & {
    border: 1px solid ${(props) => props.theme.colors.blueGrey400};
  }
`;

export const Label: React.FC<React.PropsWithChildren<FlexProps>> = ({ children, ...rest }) => {
  return <Tile {...rest}>{children}</Tile>;
};

export const UnitLabel: React.FC<{ isDarkTheme: boolean; value: string | number; unit: string } & FlexProps> = ({
  isDarkTheme,
  value,
  unit,
  ...rest
}) => {
  return (
    <Label {...rest}>
      <Flex columnGap={2} align="center">
        <Typography size={14} height={21} weight={500} color={isDarkTheme ? "white" : "grey900"}>
          {value}
        </Typography>
        <Typography size={8} height={11} weight={400} spacing={-0.5} color={isDarkTheme ? "blueGrey100" : "grey400"}>
          {unit}
        </Typography>
      </Flex>
    </Label>
  );
};
