import { Flex } from "components";
import React from "react";
import { useMetrics } from "api/modules/metrics";
import { Metrics } from "constants/metrics";
import { DeviceType, homeDeviceTypes } from "constants/device-types";
import { useCurrentWeather } from "api/modules/weather";
import { useDataListener } from "hooks/useDataListener";
import { useOutletContext } from "react-router-dom";
import { IOutletContext } from "layouts";
import { EnergyFlow, PageHeader, EnergyCharts, PageFooter } from "./components";
import { getIconByIconName, getIllustrationByIconName } from "./helpers";

export interface IWeatherMetrics {
  temperature?: number;
  icon: string | null;
  illustration: React.FC | null;
}

export interface IHomeMetrics {
  [device: string]: {
    [metric: string]: {
      unit: string;
      value: number;
      error?: boolean;
      deviceId: number;
    };
  };
}

export const OverviewPage: React.FC = () => {
  const { data: initialMetrics, isLoading: isMetricsLoading } = useMetrics({
    metrics: [Metrics.Level, Metrics.Power, Metrics.HeatingWater, Metrics.ServiceWater],
  });
  const { data: weather, isLoading: isWeatherLoading } = useCurrentWeather();

  const { alarms, node, isNodeLoading } = useOutletContext<IOutletContext>();
  const [metrics] = useDataListener(initialMetrics, isMetricsLoading);

  const weatherMetrics: IWeatherMetrics = React.useMemo(() => {
    const temperature = weather?.temperature;
    const iconPath = weather?.icon;
    return {
      temperature,
      icon: getIconByIconName(iconPath),
      illustration: getIllustrationByIconName(iconPath),
    };
  }, [weather]);

  const devices = React.useMemo(() => {
    return metrics?.filter(
      ({ metric, device_type }) => metric === Metrics.Power && !homeDeviceTypes.includes(device_type as DeviceType),
    );
  }, [metrics]);

  const homeMetrics: IHomeMetrics = React.useMemo(() => {
    const result: IHomeMetrics = {};
    metrics?.forEach((data) => {
      if (homeDeviceTypes.includes(data.device_type as DeviceType)) {
        if (
          [Metrics.Level, Metrics.Power, Metrics.HeatingWater, Metrics.ServiceWater].includes(data.metric as Metrics)
        ) {
          result[data.device_type] = {
            ...(result[data.device_type] ? result[data.device_type] : {}),
            [data.metric]: {
              value: data.float_value || 0,
              unit: data.unit,
              error: alarms?.find(
                (alarm) => alarm.active && alarm.device_id === data.device_id && alarm.metric_id === data.metric_id,
              )?.active,
              deviceId: data.device_id,
            },
          };
        }
      }
    });
    return result;
  }, [metrics, alarms]);

  return (
    <Flex direction="column" rowGap={40}>
      <Flex direction="column" rowGap={24} width="100%">
        <PageHeader
          metrics={weatherMetrics}
          node={node}
          isNodeLoading={isNodeLoading}
          isWeatherLoading={isWeatherLoading}
        />
        <Flex direction="column" rowGap={72}>
          <EnergyFlow
            devices={devices || []}
            illustration={weatherMetrics.illustration}
            metrics={homeMetrics}
            isLoading={isMetricsLoading}
          />
          <EnergyCharts isBatteryConnected={!!metrics?.find((device) => device.device_type === DeviceType.Battery)} />
        </Flex>
        <PageFooter energyRate={node?.energy_rate} isEnergyRateLoading={isNodeLoading} />
      </Flex>
    </Flex>
  );
};
