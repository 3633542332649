import { ThemeModeContext } from "App";
import { PhotoIcon } from "assets";
import { Flex, Icon, Typography } from "components";
import React from "react";
import { styled } from "styled-components";

const Container = styled(Flex)`
  border: 1px solid ${(props) => props.theme.colors.grey100};
  border-radius: 4px;
  padding: 8px 16px;
  background-color: ${(props) => props.theme.colors.white};
  align-items: center;
  width: fit-content;
  column-gap: 8px;

  #dark-theme & {
    background-color: ${(props) => props.theme.colors.blueGrey50};
  }
`;

export const FileInput: React.FC<{ onChange(files: FileList): void; multiple?: boolean }> = ({
  onChange,
  multiple = false,
}) => {
  const isDarkTheme = React.useContext(ThemeModeContext) === "dark";

  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef.current?.click();
  };
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files) {
      onChange(event.target.files);
    }
  };

  return (
    <Container onClick={handleClick}>
      <Icon icon={PhotoIcon} color={isDarkTheme ? "blueGrey200" : "grey100"} size={24} />
      <Typography size={14} weight={400} height={20} color={isDarkTheme ? "blueGrey800" : "grey800"}>
        Weiteres Foto hinzufügen
      </Typography>

      <input
        type="file"
        onChange={handleChange}
        ref={inputRef}
        style={{ display: "none" }}
        accept="image/*"
        multiple={multiple}
      />
    </Container>
  );
};
