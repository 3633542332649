import React from "react";
import { Flex, FlowLine } from "components";
import { Metrics } from "constants/metrics";
import { CarAreaIcon, CarIcon, DarkCarAreaIcon, DarkCarIcon } from "assets";
import { useBreakpoints } from "hooks/useBreakpoints";
import { NavLink } from "react-router-dom";
import { Routes } from "constants/routes";
import { IHomeDeviceProps } from "../common";
import { Styles } from "../../styles";

export const Car: React.FC<IHomeDeviceProps> = ({ isDarkTheme, metric, position }) => {
  const { mobile } = useBreakpoints();
  if (!metric) {
    return null;
  }
  const levelValue = Math.round(metric[Metrics.Level]?.value || 0);
  const powerValue = +(metric[Metrics.Power]?.value || 0).toFixed(2);
  const deviceId = (metric[Metrics.Power] || metric[Metrics.Level])?.deviceId;

  return (
    <Styles.AbsoluteDiv right={position.container.right} bottom={position.container.bottom}>
      <Flex direction="column" align="center" rowGap={12}>
        <Flex position="relative">
          <Styles.AbsoluteDiv left={mobile ? 25 : 30} top={mobile ? 4 : -1}>
            <NavLink to={`${Routes.Devices}/${deviceId}`}>
              <Styles.AdaptiveIcon icon={isDarkTheme ? DarkCarIcon : CarIcon} $mobileWidth={71} $mobileHeight={53} />
            </NavLink>
            {!!levelValue && (
              <Styles.AbsoluteDiv left={position.level?.left} top={position.level?.top}>
                <Styles.DeviceLabel>
                  {levelValue}
                  {metric[Metrics.Level].unit}
                </Styles.DeviceLabel>
              </Styles.AbsoluteDiv>
            )}
          </Styles.AbsoluteDiv>
          <Styles.CarAreaAdaptiveIcon
            icon={isDarkTheme ? DarkCarAreaIcon : CarAreaIcon}
            $mobileWidth={121}
            $mobileHeight={70}
            value={levelValue}
            isDark={isDarkTheme}
          />
        </Flex>
        <Styles.DeviceTitle>Elektroauto</Styles.DeviceTitle>
      </Flex>
      {metric[Metrics.Power] && (
        <Styles.AbsoluteDiv right={position.line.right} bottom={position.line.bottom}>
          <FlowLine
            rotate={position.line.rotate || "31deg"}
            lineColors={powerValue === 0 ? ["#B9B2A8"] : ["#FFB088", "#DE3A11"]}
            color="orange500"
            size={position.line.size}
            noAnimation={powerValue === 0}
            noFlow={powerValue === 0}
          />
          <Styles.AbsoluteDiv right={position.inner?.right || -25} bottom={position.inner?.bottom || -67}>
            <Styles.PowerLabel
              isDarkTheme={isDarkTheme}
              value={Math.abs(powerValue)}
              unit={metric[Metrics.Power].unit}
            />
          </Styles.AbsoluteDiv>
        </Styles.AbsoluteDiv>
      )}
    </Styles.AbsoluteDiv>
  );
};
