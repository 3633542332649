import React from "react";

export const HouseIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="229" height="226" viewBox="0 0 229 226" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.3"
        d="M228.837 168.967L92.1782 84.3535L0.326172 141.145L137.475 225.759L228.837 168.967Z"
        fill="url(#paint0_linear_646_106)"
      />
      <path
        d="M88.9043 87.8002L191.181 151.201L195.116 81.0495L88.9043 5.53564V87.8002Z"
        fill="url(#paint1_linear_646_106)"
      />
      <path
        d="M2.10352 54.801L88.906 5.53564V87.8002L6.85799 138.499L2.10352 54.801Z"
        fill="url(#paint2_linear_646_106)"
      />
      <path d="M132.252 82.9711L147.689 92.3421V93.0272L132.252 83.6561V82.9711Z" fill="#F2F2F2" />
      <path d="M195.116 75.0668L137.92 0.254883L94.584 26.0426L151.778 100.856L195.116 75.0668Z" fill="#D3CEC4" />
      <path d="M51.9388 52.5523L137.922 0.254883L88.0866 2.50357L2.10352 54.801L51.9388 52.5523Z" fill="#A39E93" />
      <path d="M51.9365 52.5523L94.5835 26.0426V32.7795L51.9365 59.2891V52.5523Z" fill="#857F72" />
      <path d="M2.10352 54.8009L51.9388 52.5522V59.2891L2.10352 61.5378V54.8009Z" fill="#A39E93" />
      <path d="M94.584 26.0426L151.778 100.856V107.579L94.584 32.7672V26.0426Z" fill="#B9B2A8" />
      <path d="M151.778 100.856L195.116 75.0668V81.7913L151.778 107.579V100.856Z" fill="#B9B2A8" />
      <path d="M2.10352 61.5379L6.85799 61.3228V144.386L2.10352 141.414V61.5379Z" fill="url(#paint3_linear_646_106)" />
      <path d="M195.118 81.7914L191.183 84.1322V155.97L195.118 153.604V81.7914Z" fill="url(#paint4_linear_646_106)" />
      <path
        d="M141.866 82.0103L102.841 29.2107L136.724 8.13843L175.142 61.3175L141.866 82.0103Z"
        fill="url(#paint5_linear_646_106)"
      />
      <path
        d="M142.289 80.2747L103.264 27.4735L137.147 6.40277L175.565 59.5803L142.289 80.2747Z"
        fill="url(#paint6_linear_646_106)"
      />
      <path
        d="M175.565 59.5802L175.141 61.3174L141.865 82.0103L142.289 80.2746L175.565 59.5802Z"
        fill="url(#paint7_linear_646_106)"
      />
      <path
        d="M103.265 27.4735L102.841 29.2107L141.866 82.0103L142.29 80.2747L103.265 27.4735Z"
        fill="url(#paint8_linear_646_106)"
      />
      <path
        d="M137.352 16.806L135.093 13.7832L138.005 11.9738L140.263 14.9966L137.352 16.806Z"
        fill="url(#paint9_linear_646_106)"
      />
      <path
        d="M133.686 19.2237L131.427 16.2008L134.339 14.3899L136.597 17.4127L133.686 19.2237Z"
        fill="url(#paint10_linear_646_106)"
      />
      <path
        d="M130.02 21.6398L127.761 18.617L130.673 16.806L132.931 19.8304L130.02 21.6398Z"
        fill="url(#paint11_linear_646_106)"
      />
      <path
        d="M126.354 24.0573L124.097 21.0345L127.008 19.2236L129.267 22.2464L126.354 24.0573Z"
        fill="url(#paint12_linear_646_106)"
      />
      <path
        d="M122.687 26.4735L120.43 23.4507L123.341 21.6397L125.6 24.6641L122.687 26.4735Z"
        fill="url(#paint13_linear_646_106)"
      />
      <path
        d="M119.023 28.8912L116.766 25.8683L119.676 24.0574L121.936 27.0802L119.023 28.8912Z"
        fill="url(#paint14_linear_646_106)"
      />
      <path
        d="M115.359 31.3073L113.1 28.2844L116.01 26.4735L118.27 29.4979L115.359 31.3073Z"
        fill="url(#paint15_linear_646_106)"
      />
      <path
        d="M111.692 33.725L109.433 30.7021L112.345 28.8912L114.603 31.914L111.692 33.725Z"
        fill="url(#paint16_linear_646_106)"
      />
      <path
        d="M140.266 20.9041L138.007 17.8813L140.918 16.0703L143.177 19.0947L140.266 20.9041Z"
        fill="url(#paint17_linear_646_106)"
      />
      <path
        d="M136.6 23.3217L134.341 20.2988L137.253 18.4879L139.511 21.5107L136.6 23.3217Z"
        fill="url(#paint18_linear_646_106)"
      />
      <path
        d="M132.933 25.7378L130.674 22.715L133.586 20.9041L135.844 23.9284L132.933 25.7378Z"
        fill="url(#paint19_linear_646_106)"
      />
      <path
        d="M129.267 28.1554L127.008 25.1326L129.92 23.3217L132.178 26.3445L129.267 28.1554Z"
        fill="url(#paint20_linear_646_106)"
      />
      <path
        d="M125.601 30.5716L123.344 27.5487L126.255 25.7378L128.512 28.7606L125.601 30.5716Z"
        fill="url(#paint21_linear_646_106)"
      />
      <path
        d="M121.935 32.9892L119.678 29.9664L122.589 28.1554L124.848 31.1783L121.935 32.9892Z"
        fill="url(#paint22_linear_646_106)"
      />
      <path
        d="M118.271 35.4053L116.014 32.3825L118.925 30.5715L121.184 33.5944L118.271 35.4053Z"
        fill="url(#paint23_linear_646_106)"
      />
      <path
        d="M114.604 37.8229L112.347 34.8001L115.258 32.9891L117.517 36.012L114.604 37.8229Z"
        fill="url(#paint24_linear_646_106)"
      />
      <path
        d="M143.177 25.0021L140.919 21.9793L143.83 20.1683L146.089 23.1927L143.177 25.0021Z"
        fill="url(#paint25_linear_646_106)"
      />
      <path
        d="M139.513 27.4197L137.255 24.3969L140.166 22.5859L142.425 25.6088L139.513 27.4197Z"
        fill="url(#paint26_linear_646_106)"
      />
      <path
        d="M135.847 29.8359L133.588 26.8131L136.499 25.0021L138.758 28.025L135.847 29.8359Z"
        fill="url(#paint27_linear_646_106)"
      />
      <path
        d="M132.18 32.2535L129.921 29.2307L132.833 27.4197L135.091 30.4426L132.18 32.2535Z"
        fill="url(#paint28_linear_646_106)"
      />
      <path
        d="M128.516 34.6696L126.257 31.6468L129.169 29.8358L131.427 32.8587L128.516 34.6696Z"
        fill="url(#paint29_linear_646_106)"
      />
      <path
        d="M124.849 37.0872L122.59 34.0644L125.502 32.2534L127.76 35.2763L124.849 37.0872Z"
        fill="url(#paint30_linear_646_106)"
      />
      <path
        d="M121.184 39.5033L118.927 36.4804L121.838 34.6695L124.097 37.6923L121.184 39.5033Z"
        fill="url(#paint31_linear_646_106)"
      />
      <path
        d="M117.518 41.921L115.261 38.8967L118.172 37.0873L120.431 40.1101L117.518 41.921Z"
        fill="url(#paint32_linear_646_106)"
      />
      <path
        d="M146.09 29.1001L143.832 26.0773L146.743 24.2663L149.002 27.2892L146.09 29.1001Z"
        fill="url(#paint33_linear_646_106)"
      />
      <path
        d="M142.425 31.5177L140.167 28.4949L143.078 26.6839L145.337 29.7068L142.425 31.5177Z"
        fill="url(#paint34_linear_646_106)"
      />
      <path
        d="M138.76 33.9339L136.502 30.9111L139.413 29.1001L141.672 32.123L138.76 33.9339Z"
        fill="url(#paint35_linear_646_106)"
      />
      <path
        d="M135.095 36.3515L132.836 33.3271L135.747 31.5177L138.006 34.5406L135.095 36.3515Z"
        fill="url(#paint36_linear_646_106)"
      />
      <path
        d="M131.429 38.7676L129.17 35.7448L132.082 33.9338L134.34 36.9567L131.429 38.7676Z"
        fill="url(#paint37_linear_646_106)"
      />
      <path
        d="M127.762 41.1853L125.503 38.1609L128.415 36.3515L130.673 39.3743L127.762 41.1853Z"
        fill="url(#paint38_linear_646_106)"
      />
      <path
        d="M124.098 43.6013L121.839 40.5785L124.751 38.7676L127.009 41.7904L124.098 43.6013Z"
        fill="url(#paint39_linear_646_106)"
      />
      <path
        d="M120.432 46.019L118.175 42.9946L121.086 41.1852L123.345 44.2081L120.432 46.019Z"
        fill="url(#paint40_linear_646_106)"
      />
      <path
        d="M149.006 33.1981L146.747 30.1753L149.659 28.3643L151.917 31.3872L149.006 33.1981Z"
        fill="url(#paint41_linear_646_106)"
      />
      <path
        d="M145.338 35.6157L143.08 32.5914L145.991 30.782L148.25 33.8048L145.338 35.6157Z"
        fill="url(#paint42_linear_646_106)"
      />
      <path
        d="M141.674 38.0318L139.416 35.009L142.327 33.198L144.586 36.2209L141.674 38.0318Z"
        fill="url(#paint43_linear_646_106)"
      />
      <path
        d="M138.007 40.4495L135.749 37.4252L138.66 35.6158L140.919 38.6386L138.007 40.4495Z"
        fill="url(#paint44_linear_646_106)"
      />
      <path
        d="M134.34 42.8656L132.082 39.8428L134.993 38.0319L137.252 41.0547L134.34 42.8656Z"
        fill="url(#paint45_linear_646_106)"
      />
      <path
        d="M130.677 45.2833L128.418 42.2589L131.329 40.4495L133.588 43.4723L130.677 45.2833Z"
        fill="url(#paint46_linear_646_106)"
      />
      <path
        d="M127.011 47.6994L124.752 44.6766L127.664 42.8657L129.922 45.8885L127.011 47.6994Z"
        fill="url(#paint47_linear_646_106)"
      />
      <path
        d="M123.345 50.1156L121.086 47.0927L123.998 45.2833L126.256 48.3062L123.345 50.1156Z"
        fill="url(#paint48_linear_646_106)"
      />
      <path
        d="M151.918 37.2961L149.659 34.2733L152.571 32.4623L154.829 35.4852L151.918 37.2961Z"
        fill="url(#paint49_linear_646_106)"
      />
      <path
        d="M148.253 39.7138L145.994 36.6894L148.906 34.88L151.164 37.9029L148.253 39.7138Z"
        fill="url(#paint50_linear_646_106)"
      />
      <path
        d="M144.586 42.1299L142.327 39.107L145.239 37.2961L147.497 40.3189L144.586 42.1299Z"
        fill="url(#paint51_linear_646_106)"
      />
      <path
        d="M140.922 44.5475L138.664 41.5231L141.575 39.7137L143.834 42.7366L140.922 44.5475Z"
        fill="url(#paint52_linear_646_106)"
      />
      <path
        d="M137.255 46.9637L134.997 43.9408L137.908 42.1299L140.167 45.1527L137.255 46.9637Z"
        fill="url(#paint53_linear_646_106)"
      />
      <path
        d="M133.589 49.3798L131.331 46.357L134.242 44.5476L136.501 47.5704L133.589 49.3798Z"
        fill="url(#paint54_linear_646_106)"
      />
      <path
        d="M129.926 51.7975L127.667 48.7746L130.578 46.9637L132.837 49.9865L129.926 51.7975Z"
        fill="url(#paint55_linear_646_106)"
      />
      <path
        d="M126.259 54.2135L124 51.1907L126.912 49.3798L129.17 52.4041L126.259 54.2135Z"
        fill="url(#paint56_linear_646_106)"
      />
      <path
        d="M154.832 41.3942L152.573 38.3714L155.484 36.5604L157.743 39.5833L154.832 41.3942Z"
        fill="url(#paint57_linear_646_106)"
      />
      <path
        d="M151.166 43.8118L148.907 40.7874L151.819 38.978L154.077 42.0009L151.166 43.8118Z"
        fill="url(#paint58_linear_646_106)"
      />
      <path
        d="M147.5 46.228L145.241 43.2052L148.153 41.3942L150.411 44.4171L147.5 46.228Z"
        fill="url(#paint59_linear_646_106)"
      />
      <path
        d="M143.834 48.644L141.575 45.6212L144.487 43.8118L146.745 46.8346L143.834 48.644Z"
        fill="url(#paint60_linear_646_106)"
      />
      <path
        d="M140.168 51.0616L137.91 48.0388L140.821 46.2279L143.08 49.2507L140.168 51.0616Z"
        fill="url(#paint61_linear_646_106)"
      />
      <path
        d="M136.502 53.4777L134.244 50.4549L137.155 48.644L139.414 51.6683L136.502 53.4777Z"
        fill="url(#paint62_linear_646_106)"
      />
      <path
        d="M132.837 55.8955L130.579 52.8726L133.49 51.0617L135.749 54.0845L132.837 55.8955Z"
        fill="url(#paint63_linear_646_106)"
      />
      <path
        d="M129.172 58.3115L126.914 55.2887L129.825 53.4777L132.084 56.5021L129.172 58.3115Z"
        fill="url(#paint64_linear_646_106)"
      />
      <path
        d="M157.743 45.4921L155.485 42.4693L158.396 40.6584L160.655 43.6812L157.743 45.4921Z"
        fill="url(#paint65_linear_646_106)"
      />
      <path
        d="M154.078 47.9083L151.82 44.8855L154.731 43.0761L156.99 46.0989L154.078 47.9083Z"
        fill="url(#paint66_linear_646_106)"
      />
      <path
        d="M150.414 50.3259L148.155 47.3031L151.068 45.4922L153.325 48.515L150.414 50.3259Z"
        fill="url(#paint67_linear_646_106)"
      />
      <path
        d="M146.747 52.7421L144.488 49.7193L147.401 47.9084L149.658 50.9327L146.747 52.7421Z"
        fill="url(#paint68_linear_646_106)"
      />
      <path
        d="M143.083 55.1597L140.824 52.1369L143.736 50.3259L145.994 53.3488L143.083 55.1597Z"
        fill="url(#paint69_linear_646_106)"
      />
      <path
        d="M139.417 57.5758L137.158 54.5529L140.07 52.742L142.328 55.7664L139.417 57.5758Z"
        fill="url(#paint70_linear_646_106)"
      />
      <path
        d="M135.752 59.9935L133.494 56.9707L136.405 55.1597L138.664 58.1826L135.752 59.9935Z"
        fill="url(#paint71_linear_646_106)"
      />
      <path
        d="M132.085 62.4096L129.827 59.3868L132.738 57.5759L134.997 60.5987L132.085 62.4096Z"
        fill="url(#paint72_linear_646_106)"
      />
      <path
        d="M160.657 49.5901L158.399 46.5673L161.31 44.7563L163.569 47.7792L160.657 49.5901Z"
        fill="url(#paint73_linear_646_106)"
      />
      <path
        d="M156.992 52.0062L154.734 48.9834L157.645 47.1725L159.904 50.1968L156.992 52.0062Z"
        fill="url(#paint74_linear_646_106)"
      />
      <path
        d="M153.326 54.424L151.068 51.4011L153.979 49.5902L156.238 52.613L153.326 54.424Z"
        fill="url(#paint75_linear_646_106)"
      />
      <path
        d="M149.662 56.84L147.403 53.8172L150.314 52.0063L152.573 55.0307L149.662 56.84Z"
        fill="url(#paint76_linear_646_106)"
      />
      <path
        d="M145.995 59.2577L143.736 56.2349L146.649 54.424L148.906 57.4468L145.995 59.2577Z"
        fill="url(#paint77_linear_646_106)"
      />
      <path
        d="M142.331 61.6739L140.072 58.651L142.985 56.8401L145.242 59.8629L142.331 61.6739Z"
        fill="url(#paint78_linear_646_106)"
      />
      <path
        d="M138.664 64.0915L136.405 61.0686L139.318 59.2577L141.575 62.2805L138.664 64.0915Z"
        fill="url(#paint79_linear_646_106)"
      />
      <path
        d="M135 66.5076L132.742 63.4847L135.653 61.6738L137.912 64.6966L135 66.5076Z"
        fill="url(#paint80_linear_646_106)"
      />
      <path
        d="M163.573 53.6882L161.313 50.6654L164.226 48.8544L166.483 51.8773L163.573 53.6882Z"
        fill="url(#paint81_linear_646_106)"
      />
      <path
        d="M159.905 56.1043L157.647 53.0815L160.558 51.2706L162.817 54.2949L159.905 56.1043Z"
        fill="url(#paint82_linear_646_106)"
      />
      <path
        d="M156.241 58.522L153.983 55.4992L156.894 53.6882L159.153 56.7111L156.241 58.522Z"
        fill="url(#paint83_linear_646_106)"
      />
      <path
        d="M152.574 60.938L150.316 57.9152L153.227 56.1042L155.486 59.1271L152.574 60.938Z"
        fill="url(#paint84_linear_646_106)"
      />
      <path
        d="M148.909 63.3557L146.651 60.3329L149.562 58.522L151.821 61.5448L148.909 63.3557Z"
        fill="url(#paint85_linear_646_106)"
      />
      <path
        d="M145.243 65.7719L142.984 62.749L145.897 60.9381L148.154 63.9609L145.243 65.7719Z"
        fill="url(#paint86_linear_646_106)"
      />
      <path
        d="M141.578 68.1895L139.319 65.1652L142.232 63.3558L144.489 66.3786L141.578 68.1895Z"
        fill="url(#paint87_linear_646_106)"
      />
      <path
        d="M137.912 70.6056L135.653 67.5828L138.566 65.7719L140.823 68.7947L137.912 70.6056Z"
        fill="url(#paint88_linear_646_106)"
      />
      <path
        d="M166.486 57.7862L164.227 54.7633L167.139 52.9524L169.396 55.9752L166.486 57.7862Z"
        fill="url(#paint89_linear_646_106)"
      />
      <path
        d="M162.82 60.2024L160.561 57.1796L163.473 55.3687L165.73 58.3915L162.82 60.2024Z"
        fill="url(#paint90_linear_646_106)"
      />
      <path
        d="M159.154 62.62L156.895 59.5972L159.807 57.7863L162.064 60.8091L159.154 62.62Z"
        fill="url(#paint91_linear_646_106)"
      />
      <path
        d="M155.488 65.0361L153.23 62.0133L156.141 60.2023L158.4 63.2252L155.488 65.0361Z"
        fill="url(#paint92_linear_646_106)"
      />
      <path
        d="M151.822 67.4538L149.564 64.4294L152.475 62.62L154.734 65.6428L151.822 67.4538Z"
        fill="url(#paint93_linear_646_106)"
      />
      <path
        d="M148.156 69.8699L145.898 66.8471L148.809 65.0361L151.068 68.059L148.156 69.8699Z"
        fill="url(#paint94_linear_646_106)"
      />
      <path
        d="M144.492 72.2875L142.233 69.2632L145.144 67.4538L147.403 70.4766L144.492 72.2875Z"
        fill="url(#paint95_linear_646_106)"
      />
      <path
        d="M140.825 74.7036L138.566 71.6807L141.479 69.8698L143.736 72.8926L140.825 74.7036Z"
        fill="url(#paint96_linear_646_106)"
      />
      <path d="M191.182 151.201L88.9054 87.8002L6.85742 138.499L109.622 201.9L191.182 151.201Z" fill="#FAF9F7" />
      <path d="M109.623 201.9L191.183 151.201V155.97L109.623 207.291V201.9Z" fill="#B9B2A8" />
      <path d="M6.85742 138.499L109.622 201.9V207.291L6.85742 144.386V138.499Z" fill="#D3CEC4" />
      <defs>
        <linearGradient
          id="paint0_linear_646_106"
          x1="181.404"
          y1="202.062"
          x2="49.9414"
          y2="107.355"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3CEC4" />
          <stop offset="1" stopColor="#E8E6E1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_646_106"
          x1="148.62"
          y1="53.3249"
          x2="128.09"
          y2="105.463"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#E7E6E5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_646_106"
          x1="39.1136"
          y1="59.9834"
          x2="55.6947"
          y2="79.7759"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFDFD" />
          <stop offset="1" stopColor="#EBEAE8" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_646_106"
          x1="33.3836"
          y1="64.8556"
          x2="-19.5643"
          y2="125.573"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A39E93" />
          <stop offset="1" stopColor="#B9B2A8" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_646_106"
          x1="191.183"
          y1="118.881"
          x2="195.118"
          y2="118.881"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A39E93" />
          <stop offset="1" stopColor="#B9B2A8" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_646_106"
          x1="104.528"
          y1="30.5239"
          x2="170.693"
          y2="54.611"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_646_106"
          x1="104.95"
          y1="28.7868"
          x2="171.116"
          y2="52.8738"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E8E8E8" />
          <stop offset="0.32" stopColor="white" />
          <stop offset="1" stopColor="#C5C5C5" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_646_106"
          x1="148.035"
          y1="66.3263"
          x2="168.479"
          y2="73.7689"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_646_106"
          x1="98.5089"
          y1="44.675"
          x2="144.56"
          y2="61.439"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_646_106"
          x1="139.354"
          y1="75.0616"
          x2="137.886"
          y2="28.8481"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_646_106"
          x1="135.625"
          y1="75.1798"
          x2="134.157"
          y2="28.9664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_646_106"
          x1="131.895"
          y1="75.298"
          x2="130.428"
          y2="29.0862"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_646_106"
          x1="128.166"
          y1="75.4162"
          x2="126.699"
          y2="29.2043"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_646_106"
          x1="124.436"
          y1="75.5345"
          x2="122.968"
          y2="29.3227"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_646_106"
          x1="120.709"
          y1="75.6529"
          x2="119.24"
          y2="29.441"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_646_106"
          x1="116.98"
          y1="75.7712"
          x2="115.511"
          y2="29.5592"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_646_106"
          x1="113.249"
          y1="75.8894"
          x2="111.782"
          y2="29.6775"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_646_106"
          x1="142.152"
          y1="74.9725"
          x2="140.684"
          y2="28.7591"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_646_106"
          x1="138.423"
          y1="75.0907"
          x2="136.955"
          y2="28.8788"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_646_106"
          x1="134.692"
          y1="75.2089"
          x2="133.225"
          y2="28.9971"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_646_106"
          x1="130.963"
          y1="75.3272"
          x2="129.496"
          y2="29.1154"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_646_106"
          x1="127.234"
          y1="75.4456"
          x2="125.767"
          y2="29.2336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_646_106"
          x1="123.505"
          y1="75.5638"
          x2="122.036"
          y2="29.3519"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_646_106"
          x1="119.778"
          y1="75.682"
          x2="118.309"
          y2="29.4702"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_646_106"
          x1="116.047"
          y1="75.8018"
          x2="114.58"
          y2="29.5884"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_646_106"
          x1="144.949"
          y1="74.8834"
          x2="143.48"
          y2="28.6715"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_646_106"
          x1="141.221"
          y1="75.0017"
          x2="139.753"
          y2="28.7897"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_646_106"
          x1="137.491"
          y1="75.1199"
          x2="136.023"
          y2="28.9081"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_646_106"
          x1="133.76"
          y1="75.2381"
          x2="132.293"
          y2="29.0263"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_646_106"
          x1="130.033"
          y1="75.3565"
          x2="128.566"
          y2="29.1446"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_646_106"
          x1="126.303"
          y1="75.4747"
          x2="124.834"
          y2="29.2628"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_646_106"
          x1="122.575"
          y1="75.5944"
          x2="121.106"
          y2="29.381"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_646_106"
          x1="118.846"
          y1="75.7128"
          x2="117.377"
          y2="29.501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_646_106"
          x1="147.746"
          y1="74.7943"
          x2="146.277"
          y2="28.5824"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_646_106"
          x1="144.018"
          y1="74.9124"
          x2="142.549"
          y2="28.7006"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_646_106"
          x1="140.289"
          y1="75.0308"
          x2="138.821"
          y2="28.819"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_646_106"
          x1="136.559"
          y1="75.1491"
          x2="135.092"
          y2="28.9372"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_646_106"
          x1="132.83"
          y1="75.2674"
          x2="131.363"
          y2="29.0554"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_646_106"
          x1="129.1"
          y1="75.3871"
          x2="127.631"
          y2="29.1738"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_646_106"
          x1="125.373"
          y1="75.5054"
          x2="123.904"
          y2="29.2935"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_646_106"
          x1="121.644"
          y1="75.6236"
          x2="120.175"
          y2="29.4118"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_646_106"
          x1="150.546"
          y1="74.7051"
          x2="149.077"
          y2="28.4933"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_646_106"
          x1="146.815"
          y1="74.8234"
          x2="145.346"
          y2="28.6116"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_646_106"
          x1="143.088"
          y1="74.9416"
          x2="141.619"
          y2="28.7298"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_646_106"
          x1="139.356"
          y1="75.0601"
          x2="137.889"
          y2="28.8481"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_646_106"
          x1="135.626"
          y1="75.1798"
          x2="134.159"
          y2="28.9664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_646_106"
          x1="131.899"
          y1="75.298"
          x2="130.43"
          y2="29.0862"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_646_106"
          x1="128.17"
          y1="75.4163"
          x2="126.701"
          y2="29.2045"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_646_106"
          x1="124.441"
          y1="75.5347"
          x2="122.972"
          y2="29.3228"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_646_106"
          x1="153.342"
          y1="74.616"
          x2="151.873"
          y2="28.4042"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_646_106"
          x1="149.614"
          y1="74.7343"
          x2="148.145"
          y2="28.5225"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint51_linear_646_106"
          x1="145.884"
          y1="74.8526"
          x2="144.415"
          y2="28.6407"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint52_linear_646_106"
          x1="142.155"
          y1="74.9724"
          x2="140.688"
          y2="28.759"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint53_linear_646_106"
          x1="138.425"
          y1="75.0907"
          x2="136.958"
          y2="28.8789"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint54_linear_646_106"
          x1="134.696"
          y1="75.209"
          x2="133.227"
          y2="28.9972"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint55_linear_646_106"
          x1="130.969"
          y1="75.3273"
          x2="129.5"
          y2="29.1154"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint56_linear_646_106"
          x1="127.239"
          y1="75.4455"
          x2="125.77"
          y2="29.2336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint57_linear_646_106"
          x1="156.14"
          y1="74.527"
          x2="154.673"
          y2="28.3152"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint58_linear_646_106"
          x1="152.411"
          y1="74.6452"
          x2="150.942"
          y2="28.4334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint59_linear_646_106"
          x1="148.682"
          y1="74.7652"
          x2="147.213"
          y2="28.5517"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint60_linear_646_106"
          x1="144.952"
          y1="74.8834"
          x2="143.484"
          y2="28.6714"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint61_linear_646_106"
          x1="141.222"
          y1="75.0016"
          x2="139.755"
          y2="28.7897"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint62_linear_646_106"
          x1="137.493"
          y1="75.1199"
          x2="136.024"
          y2="28.908"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint63_linear_646_106"
          x1="133.765"
          y1="75.2382"
          x2="132.296"
          y2="29.0263"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint64_linear_646_106"
          x1="130.037"
          y1="75.3563"
          x2="128.568"
          y2="29.1445"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint65_linear_646_106"
          x1="158.936"
          y1="74.4378"
          x2="157.469"
          y2="28.226"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint66_linear_646_106"
          x1="155.208"
          y1="74.5578"
          x2="153.739"
          y2="28.3443"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint67_linear_646_106"
          x1="151.48"
          y1="74.676"
          x2="150.011"
          y2="28.4641"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint68_linear_646_106"
          x1="147.75"
          y1="74.7943"
          x2="146.281"
          y2="28.5825"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint69_linear_646_106"
          x1="144.022"
          y1="74.9125"
          x2="142.554"
          y2="28.7007"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint70_linear_646_106"
          x1="140.293"
          y1="75.0308"
          x2="138.825"
          y2="28.8188"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint71_linear_646_106"
          x1="136.564"
          y1="75.1491"
          x2="135.095"
          y2="28.9372"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint72_linear_646_106"
          x1="132.834"
          y1="75.2674"
          x2="131.365"
          y2="29.0556"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint73_linear_646_106"
          x1="161.734"
          y1="74.3502"
          x2="160.267"
          y2="28.1369"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint74_linear_646_106"
          x1="158.006"
          y1="74.4686"
          x2="156.537"
          y2="28.2566"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint75_linear_646_106"
          x1="154.277"
          y1="74.5869"
          x2="152.808"
          y2="28.375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint76_linear_646_106"
          x1="150.549"
          y1="74.7051"
          x2="149.08"
          y2="28.4933"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint77_linear_646_106"
          x1="146.818"
          y1="74.8234"
          x2="145.35"
          y2="28.6116"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint78_linear_646_106"
          x1="143.091"
          y1="74.9418"
          x2="141.623"
          y2="28.7298"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint79_linear_646_106"
          x1="139.361"
          y1="75.06"
          x2="137.892"
          y2="28.8481"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint80_linear_646_106"
          x1="135.633"
          y1="75.1782"
          x2="134.164"
          y2="28.9664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint81_linear_646_106"
          x1="164.534"
          y1="74.2613"
          x2="163.067"
          y2="28.0493"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint82_linear_646_106"
          x1="160.803"
          y1="74.3796"
          x2="159.334"
          y2="28.1676"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint83_linear_646_106"
          x1="157.076"
          y1="74.4978"
          x2="155.607"
          y2="28.286"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint84_linear_646_106"
          x1="153.346"
          y1="74.616"
          x2="151.877"
          y2="28.4042"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint85_linear_646_106"
          x1="149.617"
          y1="74.7344"
          x2="148.149"
          y2="28.5224"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint86_linear_646_106"
          x1="145.887"
          y1="74.8527"
          x2="144.419"
          y2="28.6407"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint87_linear_646_106"
          x1="142.159"
          y1="74.9709"
          x2="140.69"
          y2="28.7591"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint88_linear_646_106"
          x1="138.43"
          y1="75.0907"
          x2="136.961"
          y2="28.8789"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint89_linear_646_106"
          x1="167.331"
          y1="74.1721"
          x2="165.864"
          y2="27.9602"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint90_linear_646_106"
          x1="163.602"
          y1="74.2905"
          x2="162.133"
          y2="28.0787"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint91_linear_646_106"
          x1="159.873"
          y1="74.4087"
          x2="158.404"
          y2="28.1969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint92_linear_646_106"
          x1="156.144"
          y1="74.527"
          x2="154.675"
          y2="28.3151"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint93_linear_646_106"
          x1="152.414"
          y1="74.6453"
          x2="150.946"
          y2="28.4333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint94_linear_646_106"
          x1="148.685"
          y1="74.7636"
          x2="147.217"
          y2="28.5517"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint95_linear_646_106"
          x1="144.957"
          y1="74.8833"
          x2="143.488"
          y2="28.6715"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint96_linear_646_106"
          x1="141.227"
          y1="75.0016"
          x2="139.758"
          y2="28.7897"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
      </defs>
    </svg>
  );
};
