import React from "react";

export const DarkKosterHouseLogoIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="42" height="42" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="42" height="42" fill="#6A7797" />
      <path d="M22.8335 9.28516L10 30.4197H16.1536L29.1086 9.28516H22.8335Z" fill="white" />
      <path d="M15.7488 9.28516H10V27.3947L15.7488 17.7954V9.28516Z" fill="white" />
      <path d="M20.2832 26.668L23.2386 30.419H29.9994L23.3195 21.707L20.2832 26.668Z" fill="white" />
    </svg>
  );
};
