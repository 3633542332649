import React from "react";
import { Button, Flex } from "components";
import { useNavigate } from "react-router-dom";
import { useLogout } from "api/modules/auth";
import { Routes } from "constants/routes";
import { ChatIcon, CustomerSupportIcon, InfoIcon, SettingsIcon } from "assets";
import { UserRole } from "constants/roles";
import { MenuContentProps, MenuItem } from "../../types";
import { SettingsMenu } from "../SettingsMenu";
import { MenuItem as MenuItemComponent } from "../MenuItem";

export const MainMenu: React.FC<MenuContentProps> = (props) => {
  const { handleCloseMenu, user, setActiveContent } = props;
  const navigate = useNavigate();
  const { mutate: logout, isSuccess } = useLogout();
  const handleLogout = () => logout();

  React.useEffect(() => {
    if (isSuccess) {
      handleCloseMenu();
      navigate(Routes.Login, {
        state: { notification: { variant: "success", message: "Sie wurden erfolgreich abgemeldet." } },
      });
    }
  }, [isSuccess]);

  const menuItems: MenuItem[] = [
    {
      icon: SettingsIcon,
      title: "Einstellungen",
      nestedContent: SettingsMenu,
    },
    {
      icon: ChatIcon,
      title: "Kontakt",
    },
    {
      icon: InfoIcon,
      title: "Rechtliche Informationen",
    },
    ...(user.role === UserRole.Admin
      ? [
          {
            icon: CustomerSupportIcon,
            title: "Admin-Benutzeroberfläche",
            onClick: () => {
              navigate(Routes.Admin);
              handleCloseMenu();
            },
          },
        ]
      : []),
  ];
  return (
    <>
      <Flex direction="column" rowGap={8}>
        {menuItems.map((item) => (
          <MenuItemComponent key={item.title} setActiveContent={setActiveContent} item={item} />
        ))}
      </Flex>
      <Button variant="secondary" onClick={handleLogout}>
        Abmelden
      </Button>
    </>
  );
};
