import React from "react";
import { Checkbox } from "antd";
import type { ColumnsType } from "antd/es/table";
import { ISolarUser } from "types/common";
import { NavLink } from "react-router-dom";
import { Routes } from "constants/routes";
import styled from "styled-components";

const LinkCell = styled(NavLink)`
  text-decoration: underline;
  color: ${(props) => props.theme.colors.blue500};
`;

export const columns: ColumnsType<ISolarUser> = [
  {
    title: "ID",
    dataIndex: "user_id",
    key: "user_id",
    sorter: (a, b) => a.user_id - b.user_id,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => `${a.firstname} ${a.lastname}`.localeCompare(`${b.firstname} ${b.lastname}`),
    render: (_, record) => (
      <LinkCell to={`${Routes.AdminUsers}/${record.user_id}`}>{`${record.firstname} ${record.lastname}`}</LinkCell>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    sorter: (a, b) => a.email.localeCompare(b.email),
    key: "email",
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
    sorter: (a, b) => a.email.localeCompare(b.email),
    render: (role: string) => role.toUpperCase(),
  },
  {
    title: "Enabled",
    dataIndex: "enabled",
    key: "enabled",
    align: "center",
    render: (enabled) => <Checkbox checked={enabled} />,
  },
];
