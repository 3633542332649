import React from "react";

export const DarkClearSky: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="466" height="180" viewBox="0 0 466 180" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_bdd_1137_17968)">
        <mask id="path-1-inside-1_1137_17968" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M86.4908 38.3203C89.6975 36.9405 92.0203 40.9764 91.0837 44.3394C89.7363 49.178 90.1381 54.5173 92.6167 59.3359C96.2411 66.3822 103.354 70.4633 110.761 70.5552C114.254 70.5986 117.496 73.954 115.279 76.6544C113.205 79.1807 110.592 81.3337 107.5 82.924C95.3858 89.1552 80.5138 84.386 74.2826 72.2718C68.0514 60.1575 72.8206 45.2855 84.9349 39.0543C85.4488 38.7899 85.9678 38.5454 86.4908 38.3203Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.4908 38.3203C89.6975 36.9405 92.0203 40.9764 91.0837 44.3394C89.7363 49.178 90.1381 54.5173 92.6167 59.3359C96.2411 66.3822 103.354 70.4633 110.761 70.5552C114.254 70.5986 117.496 73.954 115.279 76.6544C113.205 79.1807 110.592 81.3337 107.5 82.924C95.3858 89.1552 80.5138 84.386 74.2826 72.2718C68.0514 60.1575 72.8206 45.2855 84.9349 39.0543C85.4488 38.7899 85.9678 38.5454 86.4908 38.3203Z"
          fill="url(#paint0_linear_1137_17968)"
        />
        <path
          d="M115.279 76.6544L116.825 77.9234L115.279 76.6544ZM86.4908 38.3203L87.2813 40.1575L86.4908 38.3203ZM94.3952 58.4211C92.1559 54.0677 91.7928 49.2485 93.0104 44.876L89.1571 43.8029C87.6799 49.1075 88.1203 54.9668 90.8382 60.2507L94.3952 58.4211ZM110.786 68.5554C104.089 68.4723 97.6677 64.7832 94.3952 58.4211L90.8382 60.2507C94.8145 67.9812 102.618 72.4544 110.736 72.5551L110.786 68.5554ZM113.733 75.3853C111.829 77.7045 109.43 79.6825 106.585 81.1455L108.415 84.7025C111.754 82.985 114.581 80.657 116.825 77.9234L113.733 75.3853ZM106.585 81.1455C95.4532 86.8715 81.7871 82.489 76.0611 71.357L72.5041 73.1866C79.2406 86.2831 95.3184 91.439 108.415 84.7025L106.585 81.1455ZM76.0611 71.357C70.3352 60.2249 74.7176 46.5588 85.8497 40.8328L84.02 37.2758C70.9235 44.0122 65.7676 60.09 72.5041 73.1866L76.0611 71.357ZM85.8497 40.8328C86.3228 40.5894 86.8003 40.3644 87.2813 40.1575L85.7003 36.4832C85.1352 36.7263 84.5748 36.9904 84.02 37.2758L85.8497 40.8328ZM110.736 72.5551C111.901 72.5695 113.025 73.1579 113.615 73.8773C113.897 74.2207 113.991 74.5103 114.004 74.7025C114.015 74.8571 113.986 75.0778 113.733 75.3853L116.825 77.9234C117.681 76.8807 118.078 75.6694 117.995 74.4341C117.915 73.2363 117.395 72.1779 116.707 71.3396C115.358 69.6953 113.114 68.5843 110.786 68.5554L110.736 72.5551ZM93.0104 44.876C93.6354 42.6319 93.1935 40.1695 91.9889 38.4179C91.3746 37.5249 90.502 36.7338 89.374 36.3253C88.211 35.9041 86.9382 35.9505 85.7003 36.4832L87.2813 40.1575C87.6468 40.0002 87.8668 40.0337 88.0119 40.0862C88.1921 40.1515 88.4429 40.3209 88.6931 40.6847C89.2174 41.4469 89.4687 42.6839 89.1571 43.8029L93.0104 44.876Z"
          fill="url(#paint1_linear_1137_17968)"
          mask="url(#path-1-inside-1_1137_17968)"
        />
      </g>
      <g filter="url(#filter1_bdd_1137_17968)">
        <path
          d="M102.318 39.2782C102.651 38.1479 104.252 38.1479 104.585 39.2782V39.2782C104.699 39.6631 105 39.9642 105.385 40.0777V40.0777C106.515 40.4109 106.515 42.0119 105.385 42.3451V42.3451C105 42.4586 104.699 42.7597 104.585 43.1446V43.1446C104.252 44.275 102.651 44.275 102.318 43.1446V43.1446C102.204 42.7597 101.903 42.4586 101.518 42.3451V42.3451C100.388 42.0119 100.388 40.4109 101.518 40.0777V40.0777C101.903 39.9642 102.204 39.6631 102.318 39.2782V39.2782Z"
          fill="url(#paint2_linear_1137_17968)"
        />
        <path
          d="M101.801 41.0369C102.512 40.8274 103.068 40.2716 103.277 39.561C103.296 39.4966 103.322 39.4737 103.339 39.4621C103.363 39.4455 103.402 39.4305 103.452 39.4305C103.502 39.4305 103.541 39.4455 103.565 39.4621C103.581 39.4737 103.607 39.4966 103.626 39.561C103.836 40.2716 104.391 40.8274 105.102 41.0369C105.167 41.0559 105.189 41.0819 105.201 41.0986C105.218 41.1223 105.233 41.1614 105.233 41.2114C105.233 41.2614 105.218 41.3005 105.201 41.3243C105.189 41.341 105.167 41.367 105.102 41.386C104.391 41.5955 103.836 42.1512 103.626 42.8619C103.607 42.9263 103.581 42.9491 103.565 42.9608C103.541 42.9774 103.502 42.9924 103.452 42.9924C103.402 42.9924 103.363 42.9774 103.339 42.9608C103.322 42.9491 103.296 42.9263 103.277 42.8619C103.068 42.1512 102.512 41.5955 101.801 41.386C101.737 41.367 101.714 41.341 101.702 41.3243C101.686 41.3005 101.671 41.2614 101.671 41.2114C101.671 41.1614 101.686 41.1223 101.702 41.0986C101.714 41.0819 101.737 41.0559 101.801 41.0369Z"
          stroke="url(#paint3_linear_1137_17968)"
          strokeWidth="2"
        />
      </g>
      <g filter="url(#filter2_bdd_1137_17968)">
        <path
          d="M106.826 55.7006C107.264 54.2133 109.371 54.2133 109.809 55.7006V55.7006C109.959 56.2071 110.355 56.6032 110.861 56.7526V56.7526C112.349 57.191 112.349 59.2976 110.861 59.7361V59.7361C110.355 59.8854 109.959 60.2815 109.809 60.788V60.788C109.371 62.2753 107.264 62.2753 106.826 60.788V60.788C106.676 60.2815 106.28 59.8854 105.774 59.7361V59.7361C104.287 59.2976 104.287 57.191 105.774 56.7526V56.7526C106.28 56.6032 106.676 56.2071 106.826 55.7006V55.7006Z"
          fill="url(#paint4_linear_1137_17968)"
        />
        <path
          d="M106.057 57.7117C106.889 57.4664 107.54 56.8156 107.785 55.9834C107.942 55.4524 108.694 55.4524 108.85 55.9834C109.095 56.8156 109.746 57.4664 110.578 57.7117C111.109 57.8683 111.109 58.6203 110.578 58.7769C109.746 59.0222 109.095 59.6731 108.85 60.5052C108.694 61.0362 107.942 61.0362 107.785 60.5052C107.54 59.6731 106.889 59.0222 106.057 58.7769C105.526 58.6203 105.526 57.8683 106.057 57.7117Z"
          stroke="url(#paint5_linear_1137_17968)"
          strokeWidth="2"
        />
      </g>
      <g filter="url(#filter3_bdd_1137_17968)">
        <path
          d="M123.179 50.5333C123.46 49.5815 124.808 49.5815 125.089 50.5333V50.5333C125.184 50.8575 125.438 51.111 125.762 51.2066V51.2066C126.714 51.4872 126.714 52.8354 125.762 53.116V53.116C125.438 53.2116 125.184 53.4651 125.089 53.7893V53.7893C124.808 54.7411 123.46 54.7411 123.179 53.7893V53.7893C123.084 53.4651 122.83 53.2116 122.506 53.116V53.116C121.554 52.8354 121.554 51.4872 122.506 51.2066V51.2066C122.83 51.111 123.084 50.8575 123.179 50.5333V50.5333Z"
          fill="url(#paint6_linear_1137_17968)"
        />
        <path
          d="M124.134 53.4916C123.941 52.8538 123.441 52.3547 122.804 52.1613C123.441 51.9679 123.941 51.4687 124.134 50.831C124.327 51.4687 124.826 51.9679 125.464 52.1613C124.826 52.3547 124.327 52.8538 124.134 53.4916Z"
          stroke="url(#paint7_linear_1137_17968)"
          strokeWidth="2"
        />
      </g>
      <g filter="url(#filter4_bdd_1137_17968)">
        <path
          d="M117.705 36.2384C117.985 35.2865 119.333 35.2865 119.614 36.2384V36.2384C119.71 36.5625 119.963 36.8161 120.287 36.9116V36.9116C121.239 37.1923 121.239 38.5405 120.287 38.8211V38.8211C119.963 38.9166 119.71 39.1702 119.614 39.4943V39.4943C119.333 40.4462 117.985 40.4462 117.705 39.4943V39.4943C117.609 39.1702 117.356 38.9166 117.031 38.8211V38.8211C116.08 38.5405 116.08 37.1923 117.031 36.9116V36.9116C117.356 36.8161 117.609 36.5625 117.705 36.2384V36.2384Z"
          fill="url(#paint8_linear_1137_17968)"
        />
        <path
          d="M118.659 39.1966C118.466 38.5589 117.967 38.0598 117.329 37.8664C117.967 37.6729 118.466 37.1738 118.659 36.5361C118.853 37.1738 119.352 37.6729 119.99 37.8664C119.352 38.0598 118.853 38.5589 118.659 39.1966Z"
          stroke="url(#paint9_linear_1137_17968)"
          strokeWidth="2"
        />
      </g>
      <defs>
        <filter
          id="filter0_bdd_1137_17968"
          x="0.545898"
          y="0.0419922"
          width="153.46"
          height="150.619"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1137_17968" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-21" dy="15" />
          <feGaussianBlur stdDeviation="25" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.113438 0 0 0 0 0.148981 0 0 0 0 0.275 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_1137_17968" result="effect2_dropShadow_1137_17968" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="19" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.619608 0 0 0 0 0.423529 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_1137_17968" result="effect3_dropShadow_1137_17968" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_1137_17968" result="shape" />
        </filter>
        <filter
          id="filter1_bdd_1137_17968"
          x="29.6709"
          y="3.43066"
          width="105.562"
          height="105.562"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1137_17968" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-21" dy="15" />
          <feGaussianBlur stdDeviation="25" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.113438 0 0 0 0 0.148981 0 0 0 0 0.275 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_1137_17968" result="effect2_dropShadow_1137_17968" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="9" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.607843 0 0 0 0 0.392157 0 0 0 0 1 0 0 0 0.56 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_1137_17968" result="effect3_dropShadow_1137_17968" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_1137_17968" result="shape" />
        </filter>
        <filter
          id="filter2_bdd_1137_17968"
          x="33.6582"
          y="19.585"
          width="107.318"
          height="107.318"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1137_17968" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-21" dy="15" />
          <feGaussianBlur stdDeviation="25" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.113438 0 0 0 0 0.148981 0 0 0 0 0.275 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_1137_17968" result="effect2_dropShadow_1137_17968" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="9" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.996078 0 0 0 0 0.764706 0 0 0 0 0.0117647 0 0 0 0.47 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_1137_17968" result="effect3_dropShadow_1137_17968" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_1137_17968" result="shape" />
        </filter>
        <filter
          id="filter3_bdd_1137_17968"
          x="50.792"
          y="14.8193"
          width="104.684"
          height="104.684"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1137_17968" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-21" dy="15" />
          <feGaussianBlur stdDeviation="25" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.113438 0 0 0 0 0.148981 0 0 0 0 0.275 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_1137_17968" result="effect2_dropShadow_1137_17968" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="9" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.607843 0 0 0 0 0.392157 0 0 0 0 1 0 0 0 0.56 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_1137_17968" result="effect3_dropShadow_1137_17968" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_1137_17968" result="shape" />
        </filter>
        <filter
          id="filter4_bdd_1137_17968"
          x="45.3174"
          y="0.524414"
          width="104.684"
          height="104.684"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1137_17968" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-21" dy="15" />
          <feGaussianBlur stdDeviation="25" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.113438 0 0 0 0 0.148981 0 0 0 0 0.275 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_1137_17968" result="effect2_dropShadow_1137_17968" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="9" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.996078 0 0 0 0 0.764706 0 0 0 0 0.0117647 0 0 0 0.47 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_1137_17968" result="effect3_dropShadow_1137_17968" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_1137_17968" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_1137_17968"
          x1="78.6032"
          y1="42.7998"
          x2="101.168"
          y2="86.6695"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stopColor="#D6C2FF" />
          <stop offset="1" stopColor="#7E2FFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1137_17968"
          x1="79.8786"
          y1="19.285"
          x2="92.3755"
          y2="76.8268"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1137_17968"
          x1="103.189"
          y1="35.5344"
          x2="103.189"
          y2="47.092"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stopColor="#D6C2FF" />
          <stop offset="1" stopColor="#7E2FFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1137_17968"
          x1="103.452"
          y1="35.4326"
          x2="103.452"
          y2="42.5801"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1137_17968"
          x1="108.318"
          y1="50.6406"
          x2="108.318"
          y2="65.848"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE600" />
          <stop offset="1" stopColor="#FF7A00" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1137_17968"
          x1="108.318"
          y1="50.6406"
          x2="108.318"
          y2="60.0452"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1137_17968"
          x1="123.913"
          y1="47.3807"
          x2="123.913"
          y2="57.1134"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stopColor="#D6C2FF" />
          <stop offset="1" stopColor="#7E2FFF" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1137_17968"
          x1="124.134"
          y1="47.2949"
          x2="124.134"
          y2="53.3138"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1137_17968"
          x1="118.659"
          y1="33"
          x2="118.659"
          y2="42.7327"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE600" />
          <stop offset="1" stopColor="#FF7A00" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1137_17968"
          x1="118.659"
          y1="33"
          x2="118.659"
          y2="39.0189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
