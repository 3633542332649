import { Typography } from "components/Typography";
import React from "react";
import { styled } from "styled-components";

type ButtonProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: "primary" | "secondary";
  size?: "default" | "large" | "small" | "fit";
  disabled?: boolean;
};

const StyledButton = styled.button<ButtonProps>`
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: ${(props) => {
    switch (props.size) {
      case "large":
        return "12px 24px";
      case "small":
        return "4px 8px";
      default:
        return "8px 16px";
    }
  }};
  outline: none;
  border: none;
  cursor: pointer;
  font: inherit;
  width: ${(props) => `${props.size === "fit" ? "fit-content" : "auto"}`};

  ${(props) =>
    props.variant === "primary" &&
    `
    color: ${props.theme.colors.white};
    background-color: ${props.theme.colors.grey900};
    #dark-theme & {
      background-color: ${props.theme.colors.blueGrey50};
      color: ${props.theme.colors.blueGrey800};
    }
    `}
  ${(props) =>
    props.variant === "secondary" &&
    `
    color: ${props.theme.colors.grey800};
    border: 1px solid ${props.theme.colors.grey200};
    background-color: ${props.theme.colors.transparent};
    #dark-theme & {
      color: ${props.theme.colors.blueGrey50};
      border: 1px solid ${props.theme.colors.blueGrey300};
    }
    `}

  &:hover {
    ${(props) =>
      props.variant === "primary" &&
      `
      background-color: ${props.theme.colors.grey600};
      #dark-theme & {
        background-color: ${props.theme.colors.blueGrey200};
      }
    `}
    ${(props) =>
      props.variant === "secondary" &&
      `
      border: 1px solid ${props.theme.colors.grey300};
      #dark-theme & {
        border: 1px solid ${props.theme.colors.blueGrey50};
      }
    `}
  }

  &:disabled {
    background: ${(props) => props.theme.colors.grey100};
    cursor: default;
    #dark-theme & {
      color: ${(props) => props.theme.colors.blueGrey400};
      ${(props) => props.variant === "primary" && `background: ${props.theme.colors.blueGrey700};`}
      ${(props) => props.variant === "secondary" && ` border: 1px solid ${props.theme.colors.blueGrey400};`}
    }
  }

  &:focus {
    ${(props) =>
      props.variant === "primary" &&
      `
        box-shadow: 0px 0px 0px 2px #fff, 0px 0px 0px 4px #423d33, 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
        #dark-theme & {
          box-shadow: 0px 0px 0px 2px #242934, 0px 0px 0px 4px #E6E8ED, 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
        }
      `}
    ${(props) =>
      props.variant === "secondary" &&
      `
      box-shadow: 0px 0px 0px 2px ${props.theme.colors.grey300};
      border: 1px solid ${props.theme.colors.transparent};
      #dark-theme & {
        box-shadow: 0px 0px 0px 2px ${props.theme.colors.blueGrey50};
      }
      `}
  }
`;

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  variant = "primary",
  size = "default",
  ...props
}) => {
  const textSize = size === "large" ? 16 : 14;
  const lineHeight = size === "large" ? 24 : 20;
  return (
    <StyledButton {...props} variant={variant} size={size}>
      {typeof children === "string" ? (
        <Typography size={textSize} height={lineHeight} weight={500}>
          {children}
        </Typography>
      ) : (
        children
      )}
    </StyledButton>
  );
};
