import { createGlobalStyle } from "styled-components";

export const breakpoints = {
  mobile: 480,
  tablet: 768,
  desktop: 1200,
};

// default css is for screen from 768px to 1200px
export const breakpointMedia = {
  mobile: `@media (max-width: ${breakpoints.mobile}px)`,
  tablet: `@media (max-width: ${breakpoints.tablet}px)`,
  desktop: `@media (min-width: ${breakpoints.desktop}px)`,
};

const colors = {
  transparent: "transparent",
  clickBlue: "#138EFF4D",

  black: "#000000",
  white: "#ffffff",

  grey50: "#FAF9F7",
  grey100: "#E8E6E1",
  grey200: "#D3CEC4",
  grey300: "#B9B2A8",
  grey400: "#A39E93",
  grey500: "#857F72",
  grey600: "#625D52",
  grey700: "#504A40",
  grey800: "#423D33",
  grey900: "#27241D",

  blue400: "#40C3F7",
  blue500: "#2BB0ED",
  blue700: "#127FBF",

  blueGrey50: "#E6E8ED",
  blueGrey100: "#CDD2DC",
  blueGrey200: "#B4BBCB",
  blueGrey300: "#9BA5BA",
  blueGrey400: "#838DA8",
  blueGrey500: "#6A7797",
  blueGrey600: "#58647F",
  blueGrey700: "#475066",
  blueGrey800: "#363D4D",
  blueGrey900: "#242934",

  red50: "#FFE3E3",
  red200: "#FF9B9B",
  red300: "#F86A6A",
  red400: "#DC5055",
  red500: "#E12D39",
  red700: "#AB091E",
  red800: "#8A041A",

  green50: "#EFFCF6",
  green100: "#C6F7E2",
  green200: "#8EEDC7",
  green200op30: "rgba(142,237,199,0.3)",
  green300: "#65D6AD",
  green400: "#3EBD93",
  green500: "#27AB83",
  green600: "#199473",
  green700: "#147D64",
  green800: "#0C6B58",
  green900: "#014D40",

  orange500: "#FFB088",
  orange900: "#841003",

  yellow100: "#FFF3C4",
  yellow600: "#DE911D",
  yellow800: "#B44D12",

  orangeBorder: "#E67635",
  errorDark: "#A03530",
};

const boxShadow = {
  default: "1px 3px 8px rgba(153, 153, 153, 0.2)",
  defaultDark: "5px 5px 10px 0px rgba(38, 44, 55, 0.70), -10px -10px 20px 0px rgba(72, 80, 99, 0.50)",
};

export const theme = {
  colors,
  breakpoints: breakpointMedia,
  boxShadow,
};

export type AppTheme = typeof theme;
export type ColorName = keyof AppTheme["colors"];

export const BodyHiddenOverflowStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    height: 100dvh;
    width: 100dvw;
    box-sizing: border-box;
    background-color: ${(props) => props.theme.colors.grey50};
    font-family: 'Switzer', sans-serif;
    color: ${(props) => props.theme.colors.grey900};
    overflow-x: hidden;
  }

  #dark-theme {
    color: ${(props) => props.theme.colors.blueGrey50};
    background-color: ${(props) => props.theme.colors.blueGrey800};
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  #dark-theme .react-loading-skeleton {
    --base-color: ${(props) => props.theme.colors.blueGrey600};
    --highlight-color:${(props) => props.theme.colors.blueGrey500};
  }

  :root {
    --toastify-font-family: 'Switzer', sans-serif;
    --toastify-toast-min-height: 52px;
  }
`;
