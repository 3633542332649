import React from "react";

export const ClearSky: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="460" height="180" viewBox="0 0 460 180" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M385.454 49.5C385.454 62.2003 395.968 72.5459 409 72.5459C422.032 72.5459 432.546 62.2003 432.546 49.5C432.546 36.7997 422.032 26.4541 409 26.4541C395.968 26.4541 385.454 36.7997 385.454 49.5Z"
        fill="url(#paint0_linear_1491_51645)"
        stroke="url(#paint1_linear_1491_51645)"
        strokeWidth="2.90828"
      />
      <defs>
        <linearGradient id="paint0_linear_1491_51645" x1="409" y1="25" x2="409" y2="74" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE600" />
          <stop offset="1" stopColor="#FF7A00" />
        </linearGradient>
        <linearGradient id="paint1_linear_1491_51645" x1="409" y1="25" x2="409" y2="74" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
