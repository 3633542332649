import React from "react";
import { Card, Flex, Icon, Label, Typography, UnitLabel } from "components";
import { styled } from "styled-components";
import {
  Battery100Icon,
  Battery20Icon,
  Battery40Icon,
  DarkBattery20Icon,
  DarkBattery100Icon,
  DarkBattery40Icon,
  DarkBattery60Icon,
  DarkBattery80Icon,
  Battery80Icon,
  Battery60Icon,
  Battery0Icon,
  DarkBattery0Icon,
} from "assets";

export const CARD_TOP_OFFSET = 24;

const AdaptiveIcon = styled(Icon)<{ $mobileWidth: number; $mobileHeight: number }>`
  ${(props) => props.theme.breakpoints.tablet} {
    width: ${(props) => props.$mobileWidth}px;
    height: ${(props) => props.$mobileHeight}px;
  }
`;

const BatteryAdaptiveIcon: React.FC<{ isDarkTheme: boolean; value: number }> = ({ value, isDarkTheme }) => {
  let icon = isDarkTheme ? DarkBattery100Icon : Battery100Icon;
  if (value === 0) {
    icon = isDarkTheme ? DarkBattery0Icon : Battery0Icon;
  } else if (value > 0 && value < 20) {
    icon = isDarkTheme ? DarkBattery20Icon : Battery20Icon;
  } else if (value >= 20 && value < 40) {
    icon = isDarkTheme ? DarkBattery40Icon : Battery40Icon;
  } else if (value >= 40 && value < 60) {
    icon = isDarkTheme ? DarkBattery60Icon : Battery60Icon;
  } else if (value >= 60 && value < 80) {
    icon = isDarkTheme ? DarkBattery80Icon : Battery80Icon;
  } else if (value >= 80 && value <= 100) {
    icon = isDarkTheme ? DarkBattery100Icon : Battery100Icon;
  }
  return <AdaptiveIcon icon={icon} $mobileWidth={55} $mobileHeight={53} />;
};

export const Styles = {
  EnergyFlow: styled(Flex)`
    z-index: 1;
    ${(props) => props.theme.breakpoints.mobile} {
      flex-direction: column;
    }
  `,
  FlowLineContainer: styled.div`
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
  `,
  AbsoluteDiv: styled.div<{ left?: number; right?: number; top?: number; bottom?: number; $zIndex?: number }>`
    position: absolute;
    ${(props) => props.left !== undefined && `left: ${props.left}px;`}
    ${(props) => props.right !== undefined && `right: ${props.right}px;`}
    ${(props) => props.top !== undefined && `top: ${props.top}px;`}
    ${(props) => props.bottom !== undefined && `bottom: ${props.bottom}px;`}
    ${(props) => props.$zIndex && `z-index: ${props.$zIndex}`};
  `,
  WeatherContainer: styled.div`
    position: absolute;
    top: -40px;
    animation: fade 5s;
    animation-iteration-count: infinite;
    ${(props) => props.theme.breakpoints.mobile} {
      > svg {
        width: 320px;
        height: 126px;
      }
    }

    @keyframes fade {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.4;
      }
    }
  `,
  HouseContainer: styled(Flex)`
    width: 690px;
    height: 580px;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 40px;

    ${(props) => props.theme.breakpoints.tablet} {
      width: 480px;
      height: 500px;
    }

    ${(props) => props.theme.breakpoints.mobile} {
      width: 350px;
      height: 370px;
    }
  `,
  RelativeDiv: styled.div`
    position: relative;
    width: 100%;
  `,
  FlowCardContainer: styled.div<{ $deviceCount: number }>`
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: ${(props) => (props.$deviceCount < 3 ? "center" : "flex-start")};

    @media (max-width: 1000px) {
      justify-content: flex-start;
    }

    ${(props) => props.theme.breakpoints.tablet} {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 24px 16px;
    }
  `,
  FlowCard: styled(Card)<{ $active: boolean }>`
    flex-direction: column;
    row-gap: 8px;
    width: 260px;
    height: 100%;
    padding: 12px;
    position: relative;
    box-sizing: border-box;
    z-index: 10;
    color: ${(props) => props.theme.colors.grey400};

    border: 1px solid ${(props) => (props.$active ? props.theme.colors.orange500 : props.theme.colors.transparent)};

    ${(props) => props.theme.breakpoints.tablet} {
      width: 100%;
      word-break: break-word;
    }
  `,
  FlowCardTitle: styled(Typography).attrs({ size: 16, height: 24, weight: 400 })`
    ${(props) => props.theme.breakpoints.mobile} {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
  `,
  DeviceTitle: styled(Typography).attrs({ size: 14, height: 18, color: "grey600", weight: 400 })`
    #dark-theme & {
      color: ${(props) => props.theme.colors.blueGrey50};
    }

    ${(props) => props.theme.breakpoints.mobile} {
      display: none;
    }
  `,
  DeviceLabel: styled(Typography).attrs({ size: 18, height: 27, weight: 400 })<{ $error?: boolean }>`
    color: ${(props) => (props.$error ? props.theme.colors.red700 : props.theme.colors.grey900)};

    #dark-theme & {
      color: ${(props) => (props.$error ? props.theme.colors.red700 : props.theme.colors.blueGrey50)};
    }

    ${(props) => props.theme.breakpoints.mobile} {
      font-size: 14px;
      line-height: 18px;
    }
  `,
  AdaptiveIcon,
  BatteryAdaptiveIcon,
  CarAreaAdaptiveIcon: styled(AdaptiveIcon)<{ value?: number; isDark: boolean }>`
    ${(props) =>
      props.value &&
      `> path {
        &:nth-child(n + 2) {
          fill: ${props.isDark ? props.theme.colors.blueGrey200 : props.theme.colors.grey200};
        }
        &:nth-child(n + ${Math.ceil(props.value / 20) * -1 + 7}) {
          fill: ${props.theme.colors.green200};
        }
    }`}
  `,
  PowerLabel: styled(UnitLabel)`
    min-width: 67px;
    display: flex;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 50px;
    border: 1px solid ${(props) => props.theme.colors.grey300};
    color: ${(props) => props.theme.colors.grey300};
    background-color: ${(props) => props.theme.colors.grey50};

    #dark-theme & {
      background-color: #58647f;
      border: 1px solid ${(props) => props.theme.colors.blueGrey300};
      > div {
        span:first-child {
          color: ${(props) => props.theme.colors.blueGrey50};
        }
        span:last-child {
          color: ${(props) => props.theme.colors.blueGrey100};
        }
      }
    }

    ${(props) => props.theme.breakpoints.mobile} {
      min-width: 60px;
      padding: 4px 0px;
    }
  `,
  SolarLabel: styled(Label)`
    width: 90px;
    display: flex;
    justify-content: center;
    padding: 6px 16px 8px;
    border-radius: 50px;
    border: 1px solid ${(props) => props.theme.colors.green400};
    background-color: ${(props) => props.theme.colors.green100};
  `,
};
