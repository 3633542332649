import React from "react";

export const OverviewIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.75 11.9998L11.7045 3.04533C12.1438 2.60599 12.8562 2.60599 13.2955 3.04532L22.25 11.9998M5 9.74983V19.8748C5 20.4961 5.50368 20.9998 6.125 20.9998H10.25V16.1248C10.25 15.5035 10.7537 14.9998 11.375 14.9998H13.625C14.2463 14.9998 14.75 15.5035 14.75 16.1248V20.9998H18.875C19.4963 20.9998 20 20.4962 20 19.8748V9.74983M8.75 20.9998H17"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
