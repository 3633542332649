import { EquipmentIcon, OverviewIcon, SceneIcon } from "assets/icons";
import { Flex, Icon, Typography } from "components";
import { Routes } from "constants/routes";
import React from "react";
import { NavLink } from "react-router-dom";
import { styled } from "styled-components";

const navItems = [
  {
    icon: OverviewIcon,
    name: "Übersicht",
    route: Routes.Overview,
  },
  {
    icon: SceneIcon,
    name: "Szenen",
    route: Routes.Scenes,
  },
  {
    icon: EquipmentIcon,
    name: "Geräte",
    route: Routes.Devices,
  },
];

const Container = styled(Flex)`
  position: fixed;
  bottom: 20px;
  left: 20px;

  @media (min-width: 1600px) {
    bottom: auto;
    top: 200px;
    left: calc((100% - 1200px) / 2 - 100px);
  }

  @media (max-width: 1400px) {
    flex-direction: row;
    left: auto;
    margin: 0 auto;
    padding: 16px 70px;
  }

  ${(props) => props.theme.breakpoints.mobile} {
    bottom: 0;
    left: 0;
    top: auto;
  }

  z-index: 10;
  padding: 32px 24px;
  gap: 40px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.grey100};
  box-shadow: 0px 2px 15px 0px rgba(174, 174, 192, 0.3);
  flex-direction: column;

  #dark-theme & {
    box-shadow: ${(props) => props.theme.boxShadow.defaultDark};
    background-color: ${(props) => props.theme.colors.blueGrey900};
  }

  ${(props) => props.theme.breakpoints.mobile} {
    padding: 16px 0;
    border-radius: 20px 20px 0px 0px;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 56px;
  }
`;

const NavigationItem = styled(Flex)<{ $active: boolean }>`
  text-decoration: none;

  color: ${(props) => (props.$active ? props.theme.colors.grey900 : props.theme.colors.grey400)};

  @media (hover: hover) {
    &:hover {
      color: ${(props) => props.theme.colors.grey500};
    }
  }

  #dark-theme & {
    color: ${(props) => (props.$active ? props.theme.colors.blueGrey50 : props.theme.colors.blueGrey400)};

    @media (hover: hover) {
      &:hover {
        color: ${(props) => props.theme.colors.blueGrey300};
      }
    }
  }
`;

export const Navigation: React.FC = () => {
  return (
    <Container>
      {navItems.map((item) => {
        return (
          <NavLink to={item.route} key={item.name}>
            {({ isActive }) => (
              <NavigationItem direction="column" rowGap={4} align="center" key={item.name} $active={isActive}>
                <Icon icon={item.icon} color="inherit" />
                <Typography size={12} height={16} weight={400}>
                  {item.name}
                </Typography>
              </NavigationItem>
            )}
          </NavLink>
        );
      })}
    </Container>
  );
};
