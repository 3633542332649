import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { request } from "api/config/axios";
import { IChartData, IDataMetric } from "types/common";

interface IMetricsRequestParams {
  deviceTypes?: string[];
  metrics?: string[];
  deviceId?: string;
  isAdmin?: boolean;
}

interface IDateRangeParams {
  startDate?: string;
  endDate?: string;
}

const getMetrics = async (params?: IMetricsRequestParams) => {
  return request<IDataMetric[]>({
    url: "/metrics",
    method: "GET",
    params,
  });
};

export const useMetrics = (params?: IMetricsRequestParams, options?: UseQueryOptions<IDataMetric[]>) => {
  return useQuery<IDataMetric[]>(["metrics", params], () => getMetrics(params), options);
};

const getProducedEnergy = async (params?: IDateRangeParams) => {
  return request<number>({
    url: "/metrics/produced-energy",
    method: "GET",
    params,
  });
};

export const useProducedEnergy = (params?: IDateRangeParams, options?: UseQueryOptions<number>) => {
  return useQuery<number>(["produced-energy", params?.startDate], () => getProducedEnergy(params), options);
};

const getChartsData = async (params?: IDateRangeParams & { daily?: boolean }) => {
  return request<IChartData>({
    url: "/metrics/charts",
    method: "GET",
    params,
  });
};

export const useChartsData = (params?: IDateRangeParams & { daily?: boolean }) => {
  return useQuery<IChartData>(["charts-data", params], () => getChartsData(params), { refetchInterval: 60000 });
};
