import React from "react";

export const ElectricPoleIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="61" height="144" viewBox="0 0 61 144" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.5"
        d="M31.4135 143.242L0 125.298L29.5858 107.709L61 125.653L31.4135 143.242Z"
        fill="url(#paint0_linear_646_13)"
      />
      <path
        d="M23.5896 120.097V125.69C23.5896 127.958 26.4637 129.797 30.0085 129.797C33.5532 129.797 36.4273 127.958 36.4273 125.69V120.097H23.5889H23.5896Z"
        fill="url(#paint1_linear_646_13)"
      />
      <path
        d="M30.0096 124.204C33.5547 124.204 36.4285 122.366 36.4285 120.098C36.4285 117.83 33.5547 115.991 30.0096 115.991C26.4646 115.991 23.5908 117.83 23.5908 120.098C23.5908 122.366 26.4646 124.204 30.0096 124.204Z"
        fill="url(#paint2_linear_646_13)"
      />
      <path
        d="M26.6084 2.42142V119.206C26.6084 120.408 28.1312 121.383 30.0091 121.383C31.887 121.383 33.4098 120.409 33.4098 119.206V2.42142H26.6092H26.6084Z"
        fill="url(#paint3_linear_646_13)"
      />
      <path
        d="M30.0093 4.59762C31.8871 4.59762 33.4093 3.62365 33.4093 2.42221C33.4093 1.22076 31.8871 0.246796 30.0093 0.246796C28.1316 0.246796 26.6094 1.22076 26.6094 2.42221C26.6094 3.62365 28.1316 4.59762 30.0093 4.59762Z"
        fill="url(#paint4_linear_646_13)"
      />
      <path d="M55.657 38.627L57.1363 37.6088L7.13168 6.24103L5.65234 7.25927L55.657 38.627Z" fill="#FAF9F7" />
      <path d="M55.6543 40.4119V38.6271L57.1336 37.6089V39.3937L55.6543 40.4119Z" fill="#DEDFE0" />
      <path d="M5.65234 7.25931V9.04491L55.657 40.4119V38.6271L5.65234 7.25931Z" fill="#D3CEC4" />
      <path
        d="M50.9307 35.0608V36.9861C50.9307 38.1874 52.4534 39.1623 54.3314 39.1623C56.2093 39.1623 57.7321 38.1882 57.7321 36.9861V35.0608H50.9314H50.9307Z"
        fill="url(#paint5_linear_646_13)"
      />
      <path
        d="M54.3326 37.237C56.2103 37.237 57.7325 36.263 57.7325 35.0616C57.7325 33.8601 56.2103 32.8861 54.3326 32.8861C52.4548 32.8861 50.9326 33.8601 50.9326 35.0616C50.9326 36.263 52.4548 37.237 54.3326 37.237Z"
        fill="url(#paint6_linear_646_13)"
      />
      <path
        d="M50.9307 32.5748V34.5002C50.9307 35.7015 52.4534 36.6764 54.3314 36.6764C56.2093 36.6764 57.7321 35.7023 57.7321 34.5002V32.5748H50.9314H50.9307Z"
        fill="url(#paint7_linear_646_13)"
      />
      <path
        d="M54.3326 34.751C56.2103 34.751 57.7325 33.777 57.7325 32.5756C57.7325 31.3741 56.2103 30.4002 54.3326 30.4002C52.4548 30.4002 50.9326 31.3741 50.9326 32.5756C50.9326 33.777 52.4548 34.751 54.3326 34.751Z"
        fill="url(#paint8_linear_646_13)"
      />
      <path
        d="M53.032 31.4748V32.2111C53.032 32.6704 53.6143 33.043 54.3322 33.043C55.0502 33.043 55.6325 32.6704 55.6325 32.2111V31.4748H53.0312H53.032Z"
        fill="url(#paint9_linear_646_13)"
      />
      <path
        d="M54.3315 32.3068C55.0496 32.3068 55.6317 31.9343 55.6317 31.4749C55.6317 31.0154 55.0496 30.6429 54.3315 30.6429C53.6134 30.6429 53.0312 31.0154 53.0312 31.4749C53.0312 31.9343 53.6134 32.3068 54.3315 32.3068Z"
        fill="url(#paint10_linear_646_13)"
      />
      <path
        d="M37.5479 26.7791V28.7045C37.5479 29.9058 39.0706 30.8807 40.9486 30.8807C42.8265 30.8807 44.3493 29.9066 44.3493 28.7045V26.7791H37.5486H37.5479Z"
        fill="url(#paint11_linear_646_13)"
      />
      <path
        d="M40.9478 28.9546C42.8255 28.9546 44.3477 27.9806 44.3477 26.7791C44.3477 25.5777 42.8255 24.6037 40.9478 24.6037C39.0701 24.6037 37.5479 25.5777 37.5479 26.7791C37.5479 27.9806 39.0701 28.9546 40.9478 28.9546Z"
        fill="url(#paint12_linear_646_13)"
      />
      <path
        d="M37.5479 24.2932V26.2186C37.5479 27.4199 39.0706 28.3948 40.9486 28.3948C42.8265 28.3948 44.3493 27.4207 44.3493 26.2186V24.2932H37.5486H37.5479Z"
        fill="url(#paint13_linear_646_13)"
      />
      <path
        d="M40.9478 26.4686C42.8255 26.4686 44.3477 25.4946 44.3477 24.2931C44.3477 23.0917 42.8255 22.1177 40.9478 22.1177C39.0701 22.1177 37.5479 23.0917 37.5479 24.2931C37.5479 25.4946 39.0701 26.4686 40.9478 26.4686Z"
        fill="url(#paint14_linear_646_13)"
      />
      <path
        d="M39.6463 23.1924V23.9287C39.6463 24.388 40.2286 24.7606 40.9465 24.7606C41.6644 24.7606 42.2467 24.388 42.2467 23.9287V23.1924H39.6455H39.6463Z"
        fill="url(#paint15_linear_646_13)"
      />
      <path
        d="M40.9457 24.0243C41.6638 24.0243 42.246 23.6518 42.246 23.1924C42.246 22.7329 41.6638 22.3605 40.9457 22.3605C40.2276 22.3605 39.6455 22.7329 39.6455 23.1924C39.6455 23.6518 40.2276 24.0243 40.9457 24.0243Z"
        fill="url(#paint16_linear_646_13)"
      />
      <path
        d="M19.0352 12.9438V14.8691C19.0352 16.0704 20.5579 17.0454 22.4359 17.0454C24.3138 17.0454 25.8366 16.0712 25.8366 14.8691V12.9438H19.0359H19.0352Z"
        fill="url(#paint17_linear_646_13)"
      />
      <path
        d="M22.4361 15.1192C24.3138 15.1192 25.836 14.1452 25.836 12.9438C25.836 11.7423 24.3138 10.7684 22.4361 10.7684C20.5584 10.7684 19.0361 11.7423 19.0361 12.9438C19.0361 14.1452 20.5584 15.1192 22.4361 15.1192Z"
        fill="url(#paint18_linear_646_13)"
      />
      <path
        d="M19.0352 10.4578V12.3832C19.0352 13.5845 20.5579 14.5594 22.4359 14.5594C24.3138 14.5594 25.8366 13.5853 25.8366 12.3832V10.4578H19.0359H19.0352Z"
        fill="url(#paint19_linear_646_13)"
      />
      <path
        d="M22.4361 12.6333C24.3138 12.6333 25.836 11.6593 25.836 10.4579C25.836 9.25641 24.3138 8.28244 22.4361 8.28244C20.5584 8.28244 19.0361 9.25641 19.0361 10.4579C19.0361 11.6593 20.5584 12.6333 22.4361 12.6333Z"
        fill="url(#paint20_linear_646_13)"
      />
      <path
        d="M21.1365 9.35703V10.0933C21.1365 10.5526 21.7188 10.9253 22.4367 10.9253C23.1547 10.9253 23.7369 10.5526 23.7369 10.0933V9.35703H21.1357H21.1365Z"
        fill="url(#paint21_linear_646_13)"
      />
      <path
        d="M22.437 10.189C23.155 10.189 23.7372 9.81652 23.7372 9.35707C23.7372 8.89761 23.155 8.52515 22.437 8.52515C21.7189 8.52515 21.1367 8.89761 21.1367 9.35707C21.1367 9.81652 21.7189 10.189 22.437 10.189Z"
        fill="url(#paint22_linear_646_13)"
      />
      <path
        d="M5.65234 4.66141V6.58675C5.65234 7.78805 7.17512 8.76298 9.05305 8.76298C10.931 8.76298 12.4538 7.78887 12.4538 6.58675V4.66141H5.65311H5.65234Z"
        fill="url(#paint23_linear_646_13)"
      />
      <path
        d="M9.05229 6.83679C10.93 6.83679 12.4522 5.86282 12.4522 4.66137C12.4522 3.45993 10.93 2.48596 9.05229 2.48596C7.17455 2.48596 5.65234 3.45993 5.65234 4.66137C5.65234 5.86282 7.17455 6.83679 9.05229 6.83679Z"
        fill="url(#paint24_linear_646_13)"
      />
      <path
        d="M5.65234 2.17545V4.10079C5.65234 5.30209 7.17512 6.27702 9.05305 6.27702C10.931 6.27702 12.4538 5.30291 12.4538 4.10079V2.17545H5.65311H5.65234Z"
        fill="url(#paint25_linear_646_13)"
      />
      <path
        d="M9.05229 4.35082C10.93 4.35082 12.4522 3.37686 12.4522 2.17541C12.4522 0.973965 10.93 0 9.05229 0C7.17455 0 5.65234 0.973965 5.65234 2.17541C5.65234 3.37686 7.17455 4.35082 9.05229 4.35082Z"
        fill="url(#paint26_linear_646_13)"
      />
      <path
        d="M7.75174 1.07541V1.81171C7.75174 2.27099 8.33402 2.64363 9.05196 2.64363C9.76991 2.64363 10.3522 2.27099 10.3522 1.81171V1.07541H7.75098H7.75174Z"
        fill="url(#paint27_linear_646_13)"
      />
      <path
        d="M9.05218 1.90734C9.77027 1.90734 10.3524 1.53488 10.3524 1.07542C10.3524 0.615963 9.77027 0.2435 9.05218 0.2435C8.33408 0.2435 7.75195 0.615963 7.75195 1.07542C7.75195 1.53488 8.33408 1.90734 9.05218 1.90734Z"
        fill="url(#paint28_linear_646_13)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_646_13"
          x1="46.2063"
          y1="136.144"
          x2="-5.86057"
          y2="93.8957"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3CEC4" />
          <stop offset="1" stopColor="#E8E6E1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_646_13"
          x1="34.3992"
          y1="124.947"
          x2="24.5819"
          y2="124.947"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A39E93" />
          <stop offset="1" stopColor="#FAF9F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_646_13"
          x1="29.9921"
          y1="123.241"
          x2="30.0368"
          y2="116.182"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B9B2A8" />
          <stop offset="1" stopColor="#FAF9F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_646_13"
          x1="32.3352"
          y1="61.902"
          x2="27.1343"
          y2="61.902"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B9B2A8" />
          <stop offset="1" stopColor="#E8E6E1" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_646_13"
          x1="30.0002"
          y1="4.08686"
          x2="30.0238"
          y2="0.347332"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A39E93" />
          <stop offset="1" stopColor="#E8E6E1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_646_13"
          x1="56.6575"
          y1="37.112"
          x2="51.4565"
          y2="37.112"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A39E93" />
          <stop offset="1" stopColor="#E8E6E1" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_646_13"
          x1="54.3234"
          y1="36.727"
          x2="54.3471"
          y2="32.9875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A39E93" />
          <stop offset="1" stopColor="#E8E6E1" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_646_13"
          x1="56.6575"
          y1="34.626"
          x2="51.4565"
          y2="34.626"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A39E93" />
          <stop offset="1" stopColor="#E8E6E1" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_646_13"
          x1="54.3234"
          y1="34.2411"
          x2="54.3471"
          y2="30.5007"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B9B2A8" />
          <stop offset="1" stopColor="#E8E6E1" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_646_13"
          x1="55.2217"
          y1="32.2593"
          x2="53.2325"
          y2="32.2593"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AAA9A9" />
          <stop offset="1" stopColor="#F0F0F0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_646_13"
          x1="54.3277"
          y1="32.1115"
          x2="54.3364"
          y2="30.6814"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3CEC4" />
          <stop offset="1" stopColor="#FAF9F7" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_646_13"
          x1="43.2739"
          y1="28.8295"
          x2="38.073"
          y2="28.8295"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B9B2A8" />
          <stop offset="1" stopColor="#FAF9F7" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_646_13"
          x1="40.9387"
          y1="28.4446"
          x2="40.9623"
          y2="24.7051"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A39E93" />
          <stop offset="1" stopColor="#E8E6E1" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_646_13"
          x1="43.2739"
          y1="26.3436"
          x2="38.073"
          y2="26.3436"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B9B2A8" />
          <stop offset="1" stopColor="#FAF9F7" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_646_13"
          x1="40.9387"
          y1="25.9586"
          x2="40.9623"
          y2="22.2191"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B9B2A8" />
          <stop offset="1" stopColor="#E8E6E1" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_646_13"
          x1="41.8359"
          y1="23.9769"
          x2="39.8475"
          y2="23.9769"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AAA9A9" />
          <stop offset="1" stopColor="#F0F0F0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_646_13"
          x1="40.9427"
          y1="23.8298"
          x2="40.9515"
          y2="22.3997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3CEC4" />
          <stop offset="1" stopColor="#FAF9F7" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_646_13"
          x1="24.7612"
          y1="14.9942"
          x2="19.561"
          y2="14.9942"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B9B2A8" />
          <stop offset="1" stopColor="#FAF9F7" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_646_13"
          x1="22.4269"
          y1="14.6093"
          x2="22.4497"
          y2="10.8697"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A39E93" />
          <stop offset="1" stopColor="#E8E6E1" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_646_13"
          x1="24.7612"
          y1="12.5082"
          x2="19.561"
          y2="12.5082"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B9B2A8" />
          <stop offset="1" stopColor="#FAF9F7" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_646_13"
          x1="22.4269"
          y1="12.1233"
          x2="22.4497"
          y2="8.38379"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B9B2A8" />
          <stop offset="1" stopColor="#FAF9F7" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_646_13"
          x1="23.3269"
          y1="10.1415"
          x2="21.3377"
          y2="10.1415"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AAA9A9" />
          <stop offset="1" stopColor="#F0F0F0" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_646_13"
          x1="22.4331"
          y1="9.99449"
          x2="22.4419"
          y2="8.56438"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3CEC4" />
          <stop offset="1" stopColor="#FAF9F7" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_646_13"
          x1="11.3791"
          y1="6.7126"
          x2="6.17823"
          y2="6.7126"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A39E93" />
          <stop offset="1" stopColor="#E8E6E1" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_646_13"
          x1="9.04315"
          y1="6.32685"
          x2="9.0668"
          y2="2.58732"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A39E93" />
          <stop offset="1" stopColor="#E8E6E1" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_646_13"
          x1="11.3791"
          y1="4.22582"
          x2="6.17823"
          y2="4.22582"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AAA9A9" />
          <stop offset="1" stopColor="#F0F0F0" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_646_13"
          x1="9.04315"
          y1="3.84088"
          x2="9.0668"
          y2="0.101353"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B9B2A8" />
          <stop offset="1" stopColor="#E8E6E1" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_646_13"
          x1="9.94139"
          y1="1.85911"
          x2="7.95295"
          y2="1.85911"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AAA9A9" />
          <stop offset="1" stopColor="#F0F0F0" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_646_13"
          x1="9.04837"
          y1="1.71203"
          x2="9.05801"
          y2="0.281917"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3CEC4" />
          <stop offset="1" stopColor="#F0F0F0" />
          <stop offset="1" stopColor="#FAF9F7" />
        </linearGradient>
      </defs>
    </svg>
  );
};
