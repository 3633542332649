import React from "react";

export const WashingMachineIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.9999 19.624C14.9877 19.624 17.4099 17.251 17.4099 14.3237C17.4099 11.3965 14.9877 9.02344 11.9999 9.02344C9.01199 9.02344 6.58984 11.3965 6.58984 14.3237C6.58984 17.251 9.01199 19.624 11.9999 19.624Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path d="M20.5 1.5H3.5V22.5H20.5V1.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
      <path
        d="M6.58984 4.32617H11.62"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M14.4531 4.32617H17.1633"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
