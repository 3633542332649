export enum DeviceType {
  Weather = "weather",
  SolarPanel = "photovoltaic",
  PowerGrid = "power_grid",
  Battery = "battery",
  Charger = "charger",
  Heatpump = "heatpump",
}

export const homeDeviceTypes = [
  DeviceType.Battery,
  DeviceType.Charger,
  DeviceType.SolarPanel,
  DeviceType.PowerGrid,
  DeviceType.Heatpump,
];
