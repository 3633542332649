import { useQuery } from "@tanstack/react-query";
import { ThemeMode } from "App";
import { request } from "api/config/axios";
import { IWeatherForecast, IWeatherRaw } from "types/common";

const getCurrentWeather = async () => {
  return request<IWeatherRaw>({
    url: "/weather/current",
    method: "GET",
  });
};

export const useCurrentWeather = () => {
  return useQuery<IWeatherRaw>(["weather-current"], getCurrentWeather);
};

const getForecastWeather = async () => {
  return request<IWeatherForecast[]>({
    url: "/weather/forecast",
    method: "GET",
  });
};

export const useForecastWeather = () => {
  return useQuery<IWeatherForecast[]>(["weather-forecast"], getForecastWeather);
};

export const getCurrentTheme = () => {
  return request<ThemeMode>({
    url: "/weather/theme",
    method: "GET",
  });
};
