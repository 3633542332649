import React from "react";

export const PlusIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.00002 2.66675V13.3334M13.3334 8.00008L2.66669 8.00008"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
