import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { styled } from "styled-components";
import { Button, Flex, Modal, Typography } from "../../../components";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { ArrowLeftIcon, ArrowRightIcon } from "../../../assets";

const PDFDocumentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  text-align: center;

  canvas {
    width: 100% !important;
    height: auto !important;
    user-select: none;
  }

  .textLayer {
    min-width: 0 !important;
    width: 100% !important;
    min-height: 0 !important;
    height: auto !important;
  }

  .annotationLayer {
    min-width: 0 !important;
    width: 100% !important;
    min-height: 0 !important;
    height: auto !important;
  }
`;

interface PdfViewerProps {
  pdf: string;
  page: number;
  isOpen: boolean;
  onClose: (event?: React.MouseEvent) => void;
}

export const PdfViewer: React.FC<PdfViewerProps> = ({ pdf, page, isOpen, onClose }) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(page);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  });

  const onDocumentLoadSuccess = ({ numPages: docPages }: { numPages: number }) => {
    setNumPages(docPages);
  };

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const highlightPattern = (text: string, pattern: string) => text.replace(pattern, (value) => `<mark>${value}</mark>`);
  const textRenderer = (textItem: { str: string }, searchText: string) => highlightPattern(textItem.str, searchText);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      maxHeight="90vh"
      width="95%"
      header={
        <Typography size={20} height={20} weight={800}>
          Bedienungsanleitung
        </Typography>
      }
      footer={
        <Flex direction="column" rowGap={8} alignSelf="center" align="center" justify="center" width="100%">
          <Typography>
            Seite {pageNumber || (numPages ? 1 : "--")} von {numPages || "--"}
          </Typography>
          <Flex direction="row" align="center" justify="center" columnGap={8}>
            <Button disabled={pageNumber <= 1} onClick={previousPage} size="small">
              <ArrowLeftIcon />
            </Button>
            <Button disabled={pageNumber >= numPages} onClick={nextPage} size="small">
              <ArrowRightIcon />
            </Button>
          </Flex>
        </Flex>
      }
    >
      <PDFDocumentWrapper>
        <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            pageNumber={pageNumber}
            customTextRenderer={(textItem) =>
              // TODO: search text for highlighting
              textRenderer(textItem, "")
            }
          />
        </Document>
      </PDFDocumentWrapper>
    </Modal>
  );
};
