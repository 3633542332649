import { Flex, Icon, Typography } from "components";
import React from "react";
import { useStack } from "hooks/useStack";
import { styled } from "styled-components";
import { BackIcon } from "assets";
import { ThemeModeContext } from "App";
import { MenuContentProps, MenuProps } from "./types";

const Container = styled(Flex)`
  width: 100%;
  flex-direction: column;
  row-gap: 32px;
  max-width: 376px;
  margin: 0 auto;
`;

export type MenuStackElement = { Content: React.FC<MenuContentProps>; title: string };

export const Menu: React.FC<MenuProps & { initialMenuContent: MenuStackElement }> = (props) => {
  const [contentStack, { pop, push }] = useStack<MenuStackElement>([props.initialMenuContent]);

  const isDarkTheme = React.useContext(ThemeModeContext) === "dark";

  if (!contentStack.length) {
    return null;
  }
  const { title, Content } = contentStack.at(-1)!;
  const contentElement = <Content {...props} setActiveContent={(c, t) => push({ Content: c, title: t })} />;
  return (
    <Container>
      <Flex direction="column" rowGap={32}>
        {contentStack.length > 1 ? (
          <>
            <Flex direction="column" rowGap={8}>
              <Flex align="center" columnGap={8} onClick={pop}>
                <Icon icon={BackIcon} color={isDarkTheme ? "blueGrey200" : "grey200"} />
                <Typography size={14} height={21} color={isDarkTheme ? "blueGrey100" : "grey600"}>
                  Zurück
                </Typography>
              </Flex>
              <Typography size={22} height={32} weight={500}>
                {title}
              </Typography>
            </Flex>
            {contentElement}
          </>
        ) : (
          <>
            {title && (
              <Typography size={22} height={32} weight={500}>
                {title}
              </Typography>
            )}
            {contentElement}
          </>
        )}
      </Flex>
    </Container>
  );
};
