import React from "react";

export const DarkAgrolaHouseLogoIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="42" height="42" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 20.0425C0.0670652 9.56912 9.01548 1 20.0046 1C30.9937 1 40.0667 9.56912 39.9996 20.0425C39.9326 30.5158 31.1087 39.1611 20.0046 39.085C9.01548 39.1611 0.0670652 30.6206 0 20.0425Z"
        fill="url(#paint0_radial_2291_33338)"
      />
      <path
        d="M0 20.0425C0.0670652 9.56912 9.01548 1 20.0046 1C30.9937 1 40.0667 9.56912 39.9996 20.0425C39.9326 30.5158 31.1087 39.1611 20.0046 39.085C9.01548 39.1611 0.0670652 30.6206 0 20.0425Z"
        fill="url(#paint1_radial_2291_33338)"
      />
      <mask
        id="mask0_2291_33338"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="1"
        width="40"
        height="39"
      >
        <path
          d="M0 20.0425C0.0670652 9.56912 9.01548 1 20.0046 1C30.9937 1 40.0667 9.56912 39.9996 20.0425C39.9326 30.5158 31.1087 39.1611 20.0046 39.085C9.01548 39.1611 0.0670652 30.6206 0 20.0425Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2291_33338)">
        <path d="M23.3321 20.0414L19.998 22.117L5.72266 6.74023L17.3633 15.0142L23.3321 20.0414Z" fill="#018741" />
        <path
          d="M8.49149 36.4942L5.72266 33.3903L17.3345 28.2679L39.993 20.0605L8.49149 36.4942Z"
          fill="url(#paint2_linear_2291_33338)"
        />
      </g>
      <path d="M5.73047 6.74219L40.0008 20.0624H31.1195L5.73047 6.74219Z" fill="#FFEC00" />
      <path d="M5.73047 6.74219L31.1195 20.0624H22.6022L5.73047 6.74219Z" fill="#F9B339" />
      <path d="M5.73047 33.3922L40.0008 20.0625H31.1195L5.73047 33.3922Z" fill="#F9B339" />
      <path d="M5.73047 33.3922L31.1195 20.0625H22.6022L5.73047 33.3922Z" fill="#FFEC00" />
      <defs>
        <radialGradient
          id="paint0_radial_2291_33338"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.0438 20.0677) scale(19.6209 19.499)"
        >
          <stop stopColor="#019A3F" />
          <stop offset="0.45" stopColor="#009D3F" />
          <stop offset="0.64" stopColor="#00963C" />
          <stop offset="0.79" stopColor="#02923A" />
          <stop offset="0.92" stopColor="#017B2E" />
          <stop offset="1" stopColor="#025B21" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_2291_33338"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.5952 12.3638) scale(21.8686 21.7328)"
        >
          <stop stopColor="white" stopOpacity="0.8" />
          <stop offset="0.57" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_2291_33338"
          x1="10.7526"
          y1="38.7507"
          x2="34.6218"
          y2="14.7323"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#03772B" />
          <stop offset="0.19" stopColor="#018640" />
        </linearGradient>
      </defs>
    </svg>
  );
};
