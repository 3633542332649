import React from "react";

export const DarkElectricPoleIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="61" height="144" viewBox="0 0 61 144" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.5"
        d="M31.4135 143.241L0 125.297L29.5858 107.708L61 125.652L31.4135 143.241Z"
        fill="url(#paint0_linear_1184_15939)"
      />
      <path
        d="M23.5906 120.098V125.691C23.5906 127.958 26.4647 129.798 30.0094 129.798C33.5542 129.798 36.4282 127.958 36.4282 125.691V120.098H23.5898H23.5906Z"
        fill="url(#paint1_linear_1184_15939)"
      />
      <path
        d="M30.0096 124.204C33.5547 124.204 36.4285 122.366 36.4285 120.098C36.4285 117.83 33.5547 115.991 30.0096 115.991C26.4646 115.991 23.5908 117.83 23.5908 120.098C23.5908 122.366 26.4646 124.204 30.0096 124.204Z"
        fill="url(#paint2_linear_1184_15939)"
      />
      <path
        d="M26.6094 2.4209V119.206C26.6094 120.407 28.1321 121.382 30.0101 121.382C31.888 121.382 33.4108 120.408 33.4108 119.206V2.4209H26.6101H26.6094Z"
        fill="url(#paint3_linear_1184_15939)"
      />
      <path
        d="M30.0103 4.59789C31.888 4.59789 33.4102 3.62393 33.4102 2.42248C33.4102 1.22104 31.888 0.24707 30.0103 0.24707C28.1326 0.24707 26.6104 1.22104 26.6104 2.42248C26.6104 3.62393 28.1326 4.59789 30.0103 4.59789Z"
        fill="url(#paint4_linear_1184_15939)"
      />
      <path d="M55.657 38.6272L57.1363 37.609L7.13168 6.24121L5.65234 7.25945L55.657 38.6272Z" fill="#838DA8" />
      <path d="M55.6543 40.4104V38.6257L57.1336 37.6074V39.3922L55.6543 40.4104Z" fill="#838DA8" />
      <path d="M5.65234 7.25977V9.04537L55.657 40.4123V38.6275L5.65234 7.25977Z" fill="#58647F" />
      <path
        d="M50.9297 35.0605V36.9859C50.9297 38.1872 52.4525 39.1621 54.3304 39.1621C56.2083 39.1621 57.7311 38.188 57.7311 36.9859V35.0605H50.9305H50.9297Z"
        fill="url(#paint5_linear_1184_15939)"
      />
      <path
        d="M54.3326 37.2366C56.2103 37.2366 57.7325 36.2626 57.7325 35.0612C57.7325 33.8597 56.2103 32.8857 54.3326 32.8857C52.4548 32.8857 50.9326 33.8597 50.9326 35.0612C50.9326 36.2626 52.4548 37.2366 54.3326 37.2366Z"
        fill="url(#paint6_linear_1184_15939)"
      />
      <path
        d="M50.9297 32.5752V34.5005C50.9297 35.7018 52.4525 36.6768 54.3304 36.6768C56.2083 36.6768 57.7311 35.7027 57.7311 34.5005V32.5752H50.9305H50.9297Z"
        fill="url(#paint7_linear_1184_15939)"
      />
      <path
        d="M54.3326 34.7502C56.2103 34.7502 57.7325 33.7763 57.7325 32.5748C57.7325 31.3734 56.2103 30.3994 54.3326 30.3994C52.4548 30.3994 50.9326 31.3734 50.9326 32.5748C50.9326 33.7763 52.4548 34.7502 54.3326 34.7502Z"
        fill="url(#paint8_linear_1184_15939)"
      />
      <path
        d="M53.032 31.4746V32.2109C53.032 32.6702 53.6143 33.0428 54.3322 33.0428C55.0502 33.0428 55.6325 32.6702 55.6325 32.2109V31.4746H53.0312H53.032Z"
        fill="url(#paint9_linear_1184_15939)"
      />
      <path
        d="M54.3315 32.3064C55.0496 32.3064 55.6317 31.934 55.6317 31.4745C55.6317 31.015 55.0496 30.6426 54.3315 30.6426C53.6134 30.6426 53.0312 31.015 53.0312 31.4745C53.0312 31.934 53.6134 32.3064 54.3315 32.3064Z"
        fill="url(#paint10_linear_1184_15939)"
      />
      <path
        d="M37.5488 26.7793V28.7046C37.5488 29.9059 39.0716 30.8809 40.9495 30.8809C42.8275 30.8809 44.3502 29.9068 44.3502 28.7046V26.7793H37.5496H37.5488Z"
        fill="url(#paint11_linear_1184_15939)"
      />
      <path
        d="M37.5488 26.7793V28.7046C37.5488 29.9059 39.0716 30.8809 40.9495 30.8809C42.8275 30.8809 44.3502 29.9068 44.3502 28.7046V26.7793H37.5496H37.5488Z"
        fill="url(#paint12_linear_1184_15939)"
      />
      <path
        d="M40.9488 28.9534C42.8265 28.9534 44.3487 27.9794 44.3487 26.778C44.3487 25.5765 42.8265 24.6025 40.9488 24.6025C39.071 24.6025 37.5488 25.5765 37.5488 26.778C37.5488 27.9794 39.071 28.9534 40.9488 28.9534Z"
        fill="url(#paint13_linear_1184_15939)"
      />
      <path
        d="M37.5488 24.293V26.2183C37.5488 27.4196 39.0716 28.3945 40.9495 28.3945C42.8275 28.3945 44.3502 27.4204 44.3502 26.2183V24.293H37.5496H37.5488Z"
        fill="url(#paint14_linear_1184_15939)"
      />
      <path
        d="M40.9488 26.467C42.8265 26.467 44.3487 25.4931 44.3487 24.2916C44.3487 23.0902 42.8265 22.1162 40.9488 22.1162C39.071 22.1162 37.5488 23.0902 37.5488 24.2916C37.5488 25.4931 39.071 26.467 40.9488 26.467Z"
        fill="url(#paint15_linear_1184_15939)"
      />
      <path
        d="M39.6472 23.1924V23.9287C39.6472 24.388 40.2295 24.7606 40.9475 24.7606C41.6654 24.7606 42.2477 24.388 42.2477 23.9287V23.1924H39.6465H39.6472Z"
        fill="url(#paint16_linear_1184_15939)"
      />
      <path
        d="M40.9467 24.0242C41.6648 24.0242 42.2469 23.6517 42.2469 23.1923C42.2469 22.7328 41.6648 22.3604 40.9467 22.3604C40.2286 22.3604 39.6465 22.7328 39.6465 23.1923C39.6465 23.6517 40.2286 24.0242 40.9467 24.0242Z"
        fill="url(#paint17_linear_1184_15939)"
      />
      <path
        d="M19.0352 12.9424V14.8677C19.0352 16.069 20.5579 17.044 22.4359 17.044C24.3138 17.044 25.8366 16.0698 25.8366 14.8677V12.9424H19.0359H19.0352Z"
        fill="url(#paint18_linear_1184_15939)"
      />
      <path
        d="M22.4351 15.1194C24.3129 15.1194 25.8351 14.1454 25.8351 12.944C25.8351 11.7425 24.3129 10.7686 22.4351 10.7686C20.5574 10.7686 19.0352 11.7425 19.0352 12.944C19.0352 14.1454 20.5574 15.1194 22.4351 15.1194Z"
        fill="url(#paint19_linear_1184_15939)"
      />
      <path
        d="M19.0352 10.457V12.3824C19.0352 13.5837 20.5579 14.5586 22.4359 14.5586C24.3138 14.5586 25.8366 13.5845 25.8366 12.3824V10.457H19.0359H19.0352Z"
        fill="url(#paint20_linear_1184_15939)"
      />
      <path
        d="M22.4351 12.634C24.3129 12.634 25.8351 11.6601 25.8351 10.4586C25.8351 9.25717 24.3129 8.2832 22.4351 8.2832C20.5574 8.2832 19.0352 9.25717 19.0352 10.4586C19.0352 11.6601 20.5574 12.634 22.4351 12.634Z"
        fill="url(#paint21_linear_1184_15939)"
      />
      <path
        d="M21.1365 9.35645V10.0928C21.1365 10.552 21.7188 10.9247 22.4367 10.9247C23.1547 10.9247 23.7369 10.552 23.7369 10.0928V9.35645H21.1357H21.1365Z"
        fill="url(#paint22_linear_1184_15939)"
      />
      <path
        d="M22.437 10.1892C23.155 10.1892 23.7372 9.81677 23.7372 9.35731C23.7372 8.89785 23.155 8.52539 22.437 8.52539C21.7189 8.52539 21.1367 8.89785 21.1367 9.35731C21.1367 9.81677 21.7189 10.1892 22.437 10.1892Z"
        fill="url(#paint23_linear_1184_15939)"
      />
      <path
        d="M5.65234 4.66211V6.58745C5.65234 7.78875 7.17512 8.76368 9.05305 8.76368C10.931 8.76368 12.4538 7.78957 12.4538 6.58745V4.66211H5.65311H5.65234Z"
        fill="url(#paint24_linear_1184_15939)"
      />
      <path
        d="M9.05229 6.83715C10.93 6.83715 12.4522 5.86319 12.4522 4.66174C12.4522 3.46029 10.93 2.48633 9.05229 2.48633C7.17455 2.48633 5.65234 3.46029 5.65234 4.66174C5.65234 5.86319 7.17455 6.83715 9.05229 6.83715Z"
        fill="url(#paint25_linear_1184_15939)"
      />
      <path
        d="M5.65234 2.17578V4.10113C5.65234 5.30242 7.17512 6.27736 9.05305 6.27736C10.931 6.27736 12.4538 5.30324 12.4538 4.10113V2.17578H5.65311H5.65234Z"
        fill="url(#paint26_linear_1184_15939)"
      />
      <path
        d="M9.05229 4.35082C10.93 4.35082 12.4522 3.37686 12.4522 2.17541C12.4522 0.973965 10.93 0 9.05229 0C7.17455 0 5.65234 0.973965 5.65234 2.17541C5.65234 3.37686 7.17455 4.35082 9.05229 4.35082Z"
        fill="url(#paint27_linear_1184_15939)"
      />
      <path
        d="M7.75272 1.0752V1.8115C7.75272 2.27077 8.33499 2.64342 9.05294 2.64342C9.77088 2.64342 10.3532 2.27077 10.3532 1.8115V1.0752H7.75195H7.75272Z"
        fill="url(#paint28_linear_1184_15939)"
      />
      <path
        d="M9.05218 1.90603C9.77027 1.90603 10.3524 1.53356 10.3524 1.07411C10.3524 0.61465 9.77027 0.242188 9.05218 0.242188C8.33408 0.242188 7.75195 0.61465 7.75195 1.07411C7.75195 1.53356 8.33408 1.90603 9.05218 1.90603Z"
        fill="url(#paint29_linear_1184_15939)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1184_15939"
          x1="42.3898"
          y1="136.97"
          x2="-9.22229"
          y2="95.7101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#475066" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1184_15939"
          x1="34.4002"
          y1="124.948"
          x2="24.5829"
          y2="124.948"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1184_15939"
          x1="29.9921"
          y1="123.241"
          x2="30.0368"
          y2="116.182"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1184_15939"
          x1="32.3362"
          y1="61.9015"
          x2="27.1353"
          y2="61.9015"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1184_15939"
          x1="30.0012"
          y1="4.08714"
          x2="30.0248"
          y2="0.347606"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1184_15939"
          x1="56.6565"
          y1="37.1113"
          x2="51.4556"
          y2="37.1113"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1184_15939"
          x1="54.3234"
          y1="36.7266"
          x2="54.3471"
          y2="32.9863"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#586480" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1184_15939"
          x1="56.6565"
          y1="34.626"
          x2="51.4556"
          y2="34.626"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1184_15939"
          x1="54.3234"
          y1="34.2403"
          x2="54.3471"
          y2="30.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#586480" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1184_15939"
          x1="55.2215"
          y1="32.2587"
          x2="53.2324"
          y2="32.2587"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1184_15939"
          x1="54.328"
          y1="32.1114"
          x2="54.337"
          y2="30.681"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#586480" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1184_15939"
          x1="43.2749"
          y1="28.8297"
          x2="38.0739"
          y2="28.8297"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6A7797" />
          <stop offset="1" stopColor="#CDD2DC" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1184_15939"
          x1="43.2756"
          y1="28.8301"
          x2="38.0747"
          y2="28.8301"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1184_15939"
          x1="40.9396"
          y1="28.4434"
          x2="40.9633"
          y2="24.7031"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#586480" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1184_15939"
          x1="43.2756"
          y1="26.3438"
          x2="38.0747"
          y2="26.3438"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1184_15939"
          x1="40.9396"
          y1="25.9571"
          x2="40.9633"
          y2="22.2167"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#586480" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1184_15939"
          x1="41.8367"
          y1="23.9765"
          x2="39.8476"
          y2="23.9765"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1184_15939"
          x1="40.9432"
          y1="23.8292"
          x2="40.9523"
          y2="22.3988"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#586480" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_1184_15939"
          x1="24.7619"
          y1="14.9932"
          x2="19.561"
          y2="14.9932"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_1184_15939"
          x1="22.426"
          y1="14.6094"
          x2="22.4496"
          y2="10.8691"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#586480" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_1184_15939"
          x1="24.7619"
          y1="12.5078"
          x2="19.561"
          y2="12.5078"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_1184_15939"
          x1="22.426"
          y1="12.1241"
          x2="22.4496"
          y2="8.38374"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#586480" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_1184_15939"
          x1="23.326"
          y1="10.1406"
          x2="21.3369"
          y2="10.1406"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_1184_15939"
          x1="22.4334"
          y1="9.99422"
          x2="22.4425"
          y2="8.56384"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#586480" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_1184_15939"
          x1="11.3791"
          y1="6.7129"
          x2="6.17823"
          y2="6.7129"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_1184_15939"
          x1="9.04315"
          y1="6.32721"
          x2="9.0668"
          y2="2.58686"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#586480" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_1184_15939"
          x1="11.3791"
          y1="4.22657"
          x2="6.17823"
          y2="4.22657"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_1184_15939"
          x1="9.04315"
          y1="3.84088"
          x2="9.0668"
          y2="0.100536"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#586480" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_1184_15939"
          x1="9.94218"
          y1="1.85931"
          x2="7.95308"
          y2="1.85931"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_1184_15939"
          x1="9.04868"
          y1="1.71102"
          x2="9.05773"
          y2="0.280634"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#586480" />
          <stop offset="1" stopColor="#838DA8" />
        </linearGradient>
      </defs>
    </svg>
  );
};
