import React from "react";

export const CustomerSupportIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.625 10.625C2.625 10.3489 2.84886 10.125 3.125 10.125H5.875C6.15114 10.125 6.375 10.3489 6.375 10.625V15.25C6.375 15.5261 6.15114 15.75 5.875 15.75H3.125C2.84886 15.75 2.625 15.5261 2.625 15.25V10.625Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M17.625 10.625C17.625 10.3489 17.8489 10.125 18.125 10.125H20.875C21.1511 10.125 21.375 10.3489 21.375 10.625V15.25C21.375 15.5261 21.1511 15.75 20.875 15.75H18.125C17.8489 15.75 17.625 15.5261 17.625 15.25V10.625Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M6.375 10.125V8.25C6.375 6.75816 6.96763 5.32742 8.02252 4.27252C9.07742 3.21763 10.5082 2.625 12 2.625C13.4918 2.625 14.9226 3.21763 15.9775 4.27252C17.0324 5.32742 17.625 6.75816 17.625 8.25V15.75C17.625 16.7446 17.2299 17.6984 16.5267 18.4017C15.8234 19.1049 14.8696 19.5 13.875 19.5"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M13.375 20.9062C13.6511 20.9062 13.875 20.6824 13.875 20.4062V18.5937C13.875 18.3176 13.6511 18.0938 13.375 18.0938H10.625C10.3489 18.0938 10.125 18.3176 10.125 18.5938V20.4063C10.125 20.6824 10.3489 20.9062 10.625 20.9062H13.375Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};
