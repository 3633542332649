import React from "react";
import type { ColumnsType } from "antd/es/table";
import { INodeModelDevice } from "api/modules/devices";
import { NavLink } from "react-router-dom";
import { styled } from "styled-components";
import { Routes } from "constants/routes";

const LinkCell = styled(NavLink)`
  text-decoration: underline;
  color: ${(props) => props.theme.colors.blue500};
`;

export const columns: ColumnsType<INodeModelDevice> = [
  {
    title: "ID",
    dataIndex: "model_id",
    key: "model_id",
    sorter: (a, b) => a.model_id - b.model_id,
  },
  {
    title: "Designation",
    dataIndex: "designation",
    key: "designation",
    sorter: (a, b) => a.designation.localeCompare(b.designation),
    render: (name, record) => <LinkCell to={`${Routes.AdminModels}/${record.model_id}`}>{name}</LinkCell>,
  },
  {
    title: "Type",
    dataIndex: "device_type",
    key: "device_type",
    sorter: (a, b) => a.device_type.localeCompare(b.device_type),
  },
  {
    title: "Manufacturer",
    dataIndex: "manufacturer",
    key: "manufacturer",
    sorter: (a, b) => a.manufacturer.localeCompare(b.manufacturer),
  },
];
