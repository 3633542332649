import React from "react";
import { Flex, FlowLine } from "components";
import { Metrics } from "constants/metrics";
import { NavLink } from "react-router-dom";
import { Routes } from "constants/routes";
import { Styles } from "../../styles";
import { IHomeDeviceProps } from "../common";

export const Battery: React.FC<IHomeDeviceProps> = ({ metric, isDarkTheme, position }) => {
  if (!metric) {
    return null;
  }
  const levelValue = Math.round(metric[Metrics.Level]?.value || 0);
  const powerValue = +(metric[Metrics.Power]?.value || 0).toFixed(2);
  return (
    <Styles.AbsoluteDiv left={position.container.left} top={position.container.top}>
      <Flex direction="column" rowGap={12} align="center">
        <Styles.DeviceTitle>Batterie</Styles.DeviceTitle>
        <Flex minHeight="18px">
          {!!levelValue && (
            <Styles.DeviceLabel>
              {levelValue}
              {metric[Metrics.Level].unit}
            </Styles.DeviceLabel>
          )}
        </Flex>
        <NavLink to={`${Routes.Devices}/${metric?.[Metrics.Level].deviceId}`}>
          <Styles.BatteryAdaptiveIcon value={levelValue} isDarkTheme={isDarkTheme} />
        </NavLink>
      </Flex>
      {metric[Metrics.Power] && (
        <Styles.AbsoluteDiv right={position.line.right} top={position.line.top} $zIndex={1}>
          <Flex align="center">
            <Styles.PowerLabel
              isDarkTheme={isDarkTheme}
              value={Math.abs(powerValue)}
              unit={metric[Metrics.Power].unit}
            />
            <FlowLine
              reverse={powerValue < 0}
              lineColors={powerValue === 0 ? ["#B9B2A8"] : ["#199473", "#8EEDC7"]}
              color="#6AC99F"
              size={position.line.size}
              noAnimation={powerValue === 0}
              noFlow={powerValue === 0}
            />
          </Flex>
        </Styles.AbsoluteDiv>
      )}
    </Styles.AbsoluteDiv>
  );
};
