import { ThemeModeContext } from "App";
import { ArrowLeftIcon, ArrowRightIcon, CalendarIcon } from "assets";
import { Flex } from "components/Flex";
import { Icon } from "components/Icon";
import { Typography } from "components/Typography";
import { useBreakpoints } from "hooks/useBreakpoints";
import React from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { styled } from "styled-components";

const InputContainer = styled(Flex)`
  background-color: ${(props) => props.theme.colors.white};
  padding: 6px 8px;
  border: 1px solid ${(props) => props.theme.colors.grey100};
  border-radius: 5px;
  user-select: none;

  @media (hover: hover) {
    &:hover {
      border: 1px solid ${(props) => props.theme.colors.grey200};
    }

    #dark-theme &:hover {
      border: 1px solid ${(props) => props.theme.colors.blueGrey200};
    }
  }

  #dark-theme & {
    border: 1px solid ${(props) => props.theme.colors.blueGrey300};
    background-color: ${(props) => props.theme.colors.blueGrey600};
  }
`;

const DatePickerContainer = styled(Flex)<{ $useWeekSelection?: boolean }>`
  > div:first-child {
    width: 100%;
    z-index: 5;
  }

  .react-datepicker {
    font-family: inherit;
    padding: 24px 24px 44px;
    background-color: ${(props) => props.theme.colors.grey50};
    border-radius: 8px;
    box-shadow: 3.269838333129883px 3.269838333129883px 6.539676666259766px 0px rgba(174, 174, 192, 0.4),
      -2.1798925399780273px -2.1798925399780273px 6.539676666259766px 0px #fff;
    border: none;
  }

  #dark-theme & .react-datepicker {
    box-shadow: ${(props) => props.theme.boxShadow.defaultDark};
  }

  .react-datepicker__current-month,
  .react-datepicker-year-header {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px !important;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  .react-datepicker__day-names {
    margin: 0;
    display: flex;
    column-gap: 10px;
  }

  .react-datepicker__day-name {
    color: ${(props) => props.theme.colors.grey400};
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 39px;
  }

  .react-datepicker__month-wrapper,
  .react-datepicker__year-wrapper {
    display: flex;
    gap: 10px 8px;
    max-width: initial;
    width: 332px;
  }

  .react-datepicker__month-text,
  .react-datepicker__year-text {
    margin: 0;
    width: 77px;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${(props) => props.theme.colors.transparent};
    background-color: ${(props) => props.theme.colors.transparent};
  }

  .react-datepicker__navigation {
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.grey100};
    background: ${(props) => props.theme.colors.white};
    top: 24px;
  }

  .react-datepicker__navigation:hover {
    border: 1px solid ${(props) => props.theme.colors.grey200};
  }

  .react-datepicker__navigation--previous {
    left: 24px;
  }

  .react-datepicker__navigation--next {
    right: 24px;
  }

  .react-datepicker__navigation::before {
    width: 16px;
    height: 16px;
    text-indent: 0;
  }

  .react-datepicker__navigation--previous::before {
    content: url(icons/left-arrow.svg);
  }

  .react-datepicker__navigation--next::before {
    content: url(icons/right-arrow.svg);
  }

  .react-datepicker__navigation-icon::before {
    display: none;
  }

  .react-datepicker__header {
    border: none;
    padding: 0;
    background-color: ${(props) => props.theme.colors.transparent};
    margin-bottom: 20px;
  }

  .react-datepicker__month,
  .react-datepicker__year {
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .react-datepicker__week {
    display: flex;
    column-gap: 10px;
  }

  ${(props) =>
    props.$useWeekSelection &&
    `
  .react-datepicker__week:hover .react-datepicker__day:not(.react-datepicker__day--in-range) {
    border-radius: 5px;
    border: 1px solid ${props.theme.colors.grey700};
  }
  
  `}

  .react-datepicker__day {
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.transparent};
    color: ${(props) => props.theme.colors.grey900};
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin: 0;
    border: 1px solid ${(props) => props.theme.colors.transparent};
    box-sizing: border-box;

    width: 39px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__day:hover:not(.react-datepicker__day--disabled):not(.react-datepicker__day--in-range),
  .react-datepicker__month-text:hover:not(.react-datepicker__month-text--disabled),
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__year-text:hover:not(.react-datepicker__year-text--disabled) {
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.grey700};
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__year-text--selected {
    border: 1px solid ${(props) => props.theme.colors.grey600};
    background: ${(props) => props.theme.colors.grey900};
    color: ${(props) => props.theme.colors.white};
  }

  .react-datepicker__day--disabled {
    color: ${(props) => props.theme.colors.grey400};
  }
`;

const DatePickerInput = React.forwardRef<
  HTMLDivElement,
  {
    handleNext?: React.MouseEventHandler<HTMLDivElement>;
    handlePrev?: React.MouseEventHandler<HTMLDivElement>;
    inputValue: string;
  }
>(({ handleNext, handlePrev, inputValue, ...props }, ref) => {
  const isDarkTheme = React.useContext(ThemeModeContext) === "dark";
  return (
    <div ref={ref}>
      <InputContainer justify="space-between" {...props}>
        <Flex onClick={handlePrev}>
          {handlePrev && <Icon icon={ArrowLeftIcon} color={isDarkTheme ? "blueGrey300" : "grey400"} />}
        </Flex>
        <Flex columnGap={8} align="center">
          <CalendarIcon />
          <Typography size={14} height={16} weight={400}>
            {inputValue}
          </Typography>
        </Flex>
        <Flex onClick={handleNext}>
          {handleNext && <Icon icon={ArrowRightIcon} color={isDarkTheme ? "blueGrey300" : "grey400"} />}
        </Flex>
      </InputContainer>
    </div>
  );
});

type DatePickerProps = ReactDatePickerProps & {
  inputValue: string;
  handleNext?: React.MouseEventHandler<HTMLDivElement>;
  handlePrev?: React.MouseEventHandler<HTMLDivElement>;
  useWeekSelection?: boolean;
};

export const DatePicker: React.FC<DatePickerProps> = ({
  inputValue,
  startDate,
  handleNext,
  handlePrev,
  useWeekSelection,
  ...props
}) => {
  const { mobile } = useBreakpoints();
  return (
    <DatePickerContainer width="100%" position="relative" $useWeekSelection={useWeekSelection}>
      <ReactDatePicker
        locale="de"
        selected={startDate}
        startDate={startDate}
        customInput={<DatePickerInput handleNext={handleNext} handlePrev={handlePrev} inputValue={inputValue} />}
        withPortal={mobile}
        popperPlacement="top"
        showFourColumnMonthYearPicker
        useShortMonthInDropdown
        showPopperArrow={false}
        {...props}
      />
    </DatePickerContainer>
  );
};
