import React from "react";

export const DarkKosterLogoIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="130" height="28" viewBox="0 0 130 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_927_9335)">
        <path d="M13.1992 23.1786L17.0317 28.0008H25.7992L17.1367 16.8008L13.1992 23.1786Z" fill="#838DA8" />
        <path d="M7.59961 0H0.599609V23.8L7.59961 11.1844V0Z" fill="#B4BBCB" />
        <path d="M16.5839 0L0.599609 28H8.26402L24.3996 0H16.5839Z" fill="#DC5055" />
        <path
          d="M46.8441 24.1168C48.1427 22.8905 49.1816 21.4088 49.9088 19.6715C50.636 17.9343 50.9996 15.9927 50.9996 13.8978C50.9996 11.7518 50.636 9.86131 49.9088 8.12409C49.1816 6.38686 48.1427 4.9562 46.8441 3.72993C45.5455 2.50365 43.9872 1.58394 42.1692 0.970803C40.3512 0.306569 38.3773 0 36.2477 0C34.1699 0 32.1961 0.306569 30.43 0.970803C28.612 1.63504 27.1056 2.55474 25.7551 3.72993C24.4565 4.9562 23.4176 6.38686 22.6904 8.12409C21.9632 9.86131 21.5996 11.7518 21.5996 13.8978C21.5996 15.9927 21.9632 17.9343 22.6904 19.6715C23.4176 21.4088 24.4565 22.8905 25.7551 24.1168C27.0537 25.3431 28.612 26.3139 30.43 26.9781C32.248 27.6423 34.1699 28 36.2996 28C38.4293 28 40.3512 27.6423 42.1692 26.9781C43.9353 26.3139 45.4936 25.3431 46.8441 24.1168ZM39.4162 21.5109C38.4812 21.9197 37.4424 22.1241 36.2996 22.1241C35.1569 22.1241 34.118 21.9197 33.183 21.5109C32.248 21.1022 31.4169 20.5401 30.7417 19.8248C30.0664 19.1095 29.547 18.2409 29.1834 17.219C28.8198 16.1971 28.612 15.073 28.612 13.8978C28.612 12.7226 28.8198 11.6496 29.1834 10.6788C29.547 9.65693 30.0664 8.83942 30.7417 8.12409C31.4169 7.40876 32.248 6.84672 33.183 6.43796C34.118 6.0292 35.1569 5.82482 36.2996 5.82482C37.4424 5.82482 38.4812 6.0292 39.4682 6.43796C40.4031 6.84672 41.2342 7.40876 41.9095 8.12409C42.5848 8.83942 43.1042 9.70803 43.4678 10.6788C43.8314 11.7007 44.0392 12.7737 44.0392 13.8978C44.0392 15.1241 43.8314 16.1971 43.4678 17.219C43.1042 18.2409 42.5328 19.1095 41.8576 19.8248C41.1304 20.5401 40.3512 21.1022 39.4162 21.5109Z"
          fill="#DC5055"
        />
        <path
          d="M69.7617 15.6124C69.238 14.7422 68.5572 13.9744 67.7193 13.3601C66.8814 12.7459 65.9911 12.2852 64.9961 11.9269C64.0011 11.5686 63.0585 11.2102 62.1158 10.9543C61.4874 10.7495 60.9113 10.5448 60.3876 10.3912C59.8639 10.1865 59.445 9.98172 59.0784 9.77697C58.7118 9.57221 58.45 9.26508 58.2405 9.00914C58.031 8.70201 57.9263 8.34369 57.9263 7.883C57.9263 7.37112 58.031 6.91042 58.2929 6.60329C58.5547 6.24497 58.8166 5.98903 59.1832 5.78428C59.5497 5.57952 59.9687 5.42596 60.3876 5.37477C60.859 5.32358 61.2779 5.2724 61.6969 5.2724C62.2729 5.2724 62.849 5.37477 63.4774 5.57952C63.4774 5.57952 66.6719 0.665448 66.829 0.460695C65.9388 0.255941 63.5298 0 61.854 0C60.5447 0 59.2879 0.153565 58.031 0.460695C56.7742 0.767825 55.6221 1.27971 54.627 1.94516C53.632 2.6106 52.7941 3.4808 52.2181 4.55576C51.5896 5.63071 51.2754 6.85923 51.2754 8.34369C51.2754 9.57221 51.4849 10.5448 51.8515 11.415C52.2704 12.234 52.7941 12.9506 53.4226 13.5649C54.051 14.1792 54.8365 14.6399 55.6744 14.9982C56.5123 15.3565 57.3502 15.7148 58.2405 15.9708C59.1308 16.2267 59.9163 16.4826 60.6495 16.7386C61.3303 16.9945 61.9587 17.2505 62.43 17.5064C62.9014 17.8135 63.2679 18.1207 63.5298 18.479C63.7916 18.8373 63.8964 19.2468 63.8964 19.7587C63.8964 20.2706 63.7916 20.7313 63.5822 21.0896C63.3727 21.4479 63.1108 21.755 62.7442 22.011C62.3777 22.2157 62.0111 22.4205 61.5398 22.5229C61.0684 22.6252 60.5971 22.6764 60.1258 22.6764C59.026 22.6764 57.5073 22.3181 56.46 21.8062C55.3602 21.2431 53.5273 19.7587 52.7418 18.9397L49.5996 23.8026C51.1707 25.3894 52.6894 26.362 54.5223 27.0274C56.3552 27.6929 58.2405 28 60.1782 28C61.4874 28 62.7966 27.8464 64.0535 27.4881C65.3103 27.1298 66.4101 26.6179 67.4051 25.8501C68.4001 25.1335 69.1333 24.2121 69.7093 23.0859C70.2854 21.9598 70.5996 20.6289 70.5996 19.0932C70.5996 17.6088 70.3378 16.4826 69.7617 15.6124Z"
          fill="#DC5055"
        />
        <path
          d="M88.7994 0H87.9494H87.6494H69.7994L66.3994 5.77099H74.4994V28H80.7994V5.77099H85.3494L88.7994 0Z"
          fill="#DC5055"
        />
        <path
          d="M129.4 28L122.247 16.0533C124.022 15.52 125.432 14.56 126.424 13.28C127.416 12 127.938 10.4 127.938 8.48C127.938 6.88 127.625 5.54667 127.05 4.42667C126.476 3.36 125.641 2.45333 124.648 1.81333C123.656 1.17333 122.508 0.693333 121.307 0.426667C120.054 0.16 118.748 0 117.443 0H110.551L107 5.70667V27.9467H113.475V16.9067H115.72L121.672 28H129.4ZM120.941 10.3467C120.628 10.7733 120.262 11.1467 119.793 11.36C119.323 11.5733 118.8 11.7867 118.226 11.84C117.652 11.9467 117.077 12 116.555 12H113.422V5.49333H116.973C117.443 5.49333 117.965 5.54667 118.487 5.6C119.009 5.65333 119.479 5.81333 119.949 6.08C120.367 6.29333 120.732 6.61333 120.993 7.04C121.255 7.46667 121.411 8 121.411 8.64C121.411 9.33333 121.255 9.92 120.941 10.3467Z"
          fill="#DC5055"
        />
        <path
          d="M87.3994 28H105.369V22.229H93.9386V16.4046H103.349V11.0076H93.9386V5.66412H104.837L108.399 0H107.389H107.283H91.0678L87.3994 5.82443V28Z"
          fill="#DC5055"
        />
      </g>
      <defs>
        <clipPath id="clip0_927_9335">
          <rect width="128.8" height="28" fill="white" transform="translate(0.599609)" />
        </clipPath>
      </defs>
    </svg>
  );
};
