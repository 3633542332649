import React from "react";

export const XIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4 12.5L12 4.5M4 4.5L12 12.5"
        stroke="#8A041A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
