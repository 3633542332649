import { Flex, Typography } from "components";
import { styled } from "styled-components";

export const Styles = {
  PageContainer: styled(Flex)`
    width: 100%;
    max-width: 576px;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  `,
  PageSectionContainer: styled(Flex)`
    width: 100%;
    flex-direction: column;
    gap: 72px;
  `,
  PageSection: styled(Flex)`
    width: 100%;
    flex-direction: column;
  `,
  PageTitle: styled(Typography)`
    margin-top: 16px;
    margin-bottom: 32px;
  `,
  StatusTitle: styled(Typography)`
    margin-bottom: 24px;
  `,
  StatusContainer: styled(Flex)`
    width: 100%;
    flex-direction: column;
  `,

  InfoContainer: styled(Flex)`
    width: 100%;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
  `,
  InfoRow: styled(Flex)`
    width: 100%;
    gap: 8px;
    padding: 16px 8px;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.grey100};

    #dark-theme & {
      background: ${(props) => props.theme.colors.blueGrey600};
    }
  `,
  StatusRow: styled(Flex)`
    width: 100%;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
  `,
  Delimiter: styled.div`
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.colors.grey100};
    margin: 16px 0;

    #dark-theme & {
      background-color: ${(props) => props.theme.colors.blueGrey200};
    }
  `,
  GreenCircle: styled.div`
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: ${(props) => props.theme.colors.green400};
  `,
  OrangeBorder: styled(Typography)`
    padding: 0 12px 2px 12px;
    border-radius: 50px;
    border: 1px solid ${(props) => props.theme.colors.orangeBorder};
  `,
  KwContainer: styled(Flex)`
    padding: 0 12px 2px 12px;
    border-radius: 50px;
    border: 1px solid ${(props) => props.theme.colors.grey100};
  `,
};
