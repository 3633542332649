import React from "react";

export const DarkBattery80Icon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="95" height="93" viewBox="0 0 95 93" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.5"
        d="M48.6749 92.0714L0 64.0313L45.8429 36.5469L94.519 64.5869L48.6749 92.0714Z"
        fill="url(#paint0_linear_1291_8102)"
      />
      <path
        d="M45.7191 69.1801C32.6452 68.6815 28.3193 63.3071 27.2627 60.8692V9.00905C29.2646 4.22195 34.9924 1.91706 37.606 1.363C52.1533 -2.22732 60.3501 4.9644 62.6301 9.00905V60.2043C58.2258 68.1828 48.8888 69.1801 45.7191 69.1801Z"
        fill="#E8E6E1"
      />
      <path
        d="M45.7191 69.2153C32.6452 68.7166 28.3193 63.3422 27.2627 60.9043V28.0442C29.2646 23.2571 34.9924 20.9522 37.606 20.3982C52.1533 16.8078 60.3501 23.9996 62.6301 28.0442V60.2395C58.2258 68.218 48.8888 69.2153 45.7191 69.2153Z"
        fill="#8EEDC7"
      />
      <path
        d="M42.4156 39.4008C29.589 37.7363 26.8175 31.134 27.3172 27.5831C30.1157 21.1916 38.5885 19.2608 42.4751 19.0944C54.7348 18.1623 61.0199 24.3652 62.6301 27.5831V30.7456C61.4641 33.9081 53.6304 40.8561 42.4156 39.4008Z"
        fill="#65D6AD"
      />
      <path
        d="M44.7181 31.5107C36.0431 31.2919 27.8744 25.1953 27.2627 22.37V9.07456C28.3749 6.30467 33.3352 0.698408 44.2791 0.432499C55.2229 0.166589 61.073 6.08307 62.6301 9.07456V22.8686C58.4927 30.0482 49.1112 31.6215 44.7181 31.5107Z"
        fill="#838DA8"
      />
      <path
        d="M42.4156 20.8246C29.589 19.1602 26.8175 12.5578 27.3172 9.00694C30.1157 2.61541 38.5885 0.684638 42.4751 0.51819C54.7348 -0.413908 61.0199 5.78898 62.6301 9.00694V12.1694C61.4641 15.3319 53.6304 22.2799 42.4156 20.8246Z"
        fill="#6A7797"
      />
      <path
        d="M34.2588 10.7862V5.3298C36.1004 2.02286 40.1783 0.755212 41.987 0.534752C50.8004 -0.25891 54.7577 3.40075 55.6347 5.3298V10.7862C52.2855 15.0852 47.1882 15.4159 45.1112 15.5813C38.7971 15.3167 35.2454 12.2743 34.2588 10.7862Z"
        fill="#B4BBCB"
      />
      <path
        d="M44.7823 12.4627C38.2051 12.4627 35.0261 9.10907 34.2588 7.43224V4.91699C35.7058 1.1609 41.5485 0.110084 44.289 0.054186C50.6031 -0.214107 53.6615 2.40175 55.6347 4.91699V7.43224C52.2145 11.7249 46.9747 12.5745 44.7823 12.4627Z"
        fill="white"
      />
      <path
        d="M46.3074 44.5062V38.0352L38 48.204H44.4613L44.4613 54.675L52.7688 44.5062L46.3074 44.5062Z"
        fill="#0C6B58"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1291_8102"
          x1="65.6827"
          y1="82.2729"
          x2="-14.8151"
          y2="18.4612"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#475066" />
        </linearGradient>
      </defs>
    </svg>
  );
};
