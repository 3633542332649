import React, { useContext } from "react";
import { FileInput, Flex, Icon, Typography } from "components";
import { NavLink, Navigate, useOutletContext, useSearchParams } from "react-router-dom";
import { Routes } from "constants/routes";
import { isAxiosError } from "axios";
import { ThemeModeContext } from "App";
import { useSendEmail } from "api/modules/email";
import { useBreakpoints } from "hooks/useBreakpoints";
import { CheckIcon } from "assets";
import imageCompression from "browser-image-compression";
import { IOutletContext } from "layouts";
import { Styles } from "./styles";

export const ServicePage: React.FC = () => {
  const { user } = useOutletContext<IOutletContext>();
  const isDarkTheme = useContext(ThemeModeContext) === "dark";

  const [searchParams] = useSearchParams();
  const alarmId = searchParams.get("alarm_id");

  const [comment, setComment] = React.useState("");
  const [uploadedFiles, setUploadedFiles] = React.useState<File[]>([]);

  const { mutate: sendEmail, isLoading, isSuccess, error } = useSendEmail();
  const { mobile } = useBreakpoints();

  React.useEffect(() => {
    if (isSuccess) {
      setComment("");
    }
  }, [isSuccess]);

  if (!alarmId) {
    return <Navigate to={Routes.Overview} replace />;
  }

  const handleServiceRequest = async () => {
    if (!user) {
      return;
    }
    const attachments = await Promise.all(
      uploadedFiles.map(async (f) => {
        const path = await imageCompression.getDataUrlFromFile(f);
        return {
          path,
          filename: f.name,
        };
      }),
    );
    sendEmail({
      alarm_id: +alarmId,
      user_id: user.user_id,
      comment,
      attachments,
    });
  };

  const handleFileChange = async (files: FileList) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const promises: Promise<File>[] = [];
    try {
      for (let i = 0; i < files.length; i++) {
        promises.push(imageCompression(files[i], options));
      }
      const compressedFiles = await Promise.all(promises);
      setUploadedFiles(compressedFiles);
    } catch {}
  };

  return (
    <Styles.Container direction="column" rowGap={24} maxWidth="576px">
      <Typography size={20} height={24} weight={500}>
        Serviceanfrage
      </Typography>
      {isSuccess ? (
        <Flex direction="column" rowGap={24}>
          <Styles.SuccessTile align="center" columnGap={24} padding="16px">
            <Icon icon={CheckIcon} size={mobile ? 40 : 56} />
            <Typography size={16} height={24} weight={400}>
              E-Mail geschickt
            </Typography>
          </Styles.SuccessTile>
          <Styles.Description size={16} height={24} weight={400}>
            Danke für Ihre Anfrage. Wie melden uns möglichst schnell bei Ihnen.
          </Styles.Description>
          <NavLink to={Routes.Overview}>
            <Styles.StyledButton>Zurück zum Dashboard</Styles.StyledButton>
          </NavLink>
        </Flex>
      ) : (
        <Flex direction="column" rowGap={16}>
          <Flex direction="column" rowGap={8}>
            <Styles.SubTitle size={18} weight={400} height={20}>
              Warmwasser Fehler
            </Styles.SubTitle>
            <Styles.Description size={14} weight={400} height={20} color={isDarkTheme ? "blueGrey100" : "grey800"}>
              Bitte teilen Sie uns kurz mit was genau das Problem ist. Sie können zudem Fotos hinzufügen die unserem
              Service helfen, das Problem besser zu verstehen.
            </Styles.Description>
          </Flex>
          <Styles.StyledTextarea
            disabled={isLoading}
            title={
              <Styles.TextareaTitle size={14} weight={400} height={20} color={isDarkTheme ? "blueGrey100" : "grey800"}>
                Bemerkungen
              </Styles.TextareaTitle>
            }
            value={comment}
            onChange={setComment}
            error={isAxiosError(error) ? error.message : undefined}
            hasError={error !== null}
          />
          <Flex columnGap={8} rowGap={8} wrap="wrap">
            {uploadedFiles.map((file) => (
              <Styles.ImagePreview src={URL.createObjectURL(file)} key={file.name} alt={file.name} />
            ))}
          </Flex>
          <FileInput onChange={handleFileChange} multiple />
          <Flex>
            <Styles.StyledButton onClick={handleServiceRequest} disabled={isLoading}>
              Service anfordern (kostenpflichtig)...
            </Styles.StyledButton>
          </Flex>
        </Flex>
      )}
    </Styles.Container>
  );
};
