import { ThemeModeContext } from "App";
import { ChartData, ChartOptions } from "chart.js";
import { Flex, Typography } from "components";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { styled } from "styled-components";

const options: ChartOptions<"doughnut"> = {
  cutout: 30,
  layout: {
    padding: 8,
  },
  borderColor: "transparent",
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

const LegendCircle = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: ${(props) => props.color};
`;

const DoughnutInnerContainer = styled(Flex)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

type DoughnutChartProps = {
  title: string;
  items: { value: number; label: string; color: string }[];
  innerValue: number;
};

export const DoughnutChart: React.FC<DoughnutChartProps> = ({ title, items, innerValue }) => {
  const isDarkTheme = React.useContext(ThemeModeContext) === "dark";
  const data: ChartData<"doughnut"> = React.useMemo(() => {
    return {
      labels: items.map(({ label }) => label),
      datasets: [
        {
          data: items.map(({ value }) => value || 0),
          backgroundColor: items.map(({ color }) => color),
        },
      ],
    };
  }, [items]);
  const itemSum = items.reduce((acc, item) => acc + item.value || 0, 0);
  return (
    <Flex direction="column" rowGap={8} align="center">
      <Typography size={16} height={24} weight={500}>
        {title}
      </Typography>
      <Flex position="relative" width="96px">
        <Doughnut data={data} options={options} />
        <DoughnutInnerContainer direction="column" align="center">
          <Typography size={18} height={20} weight={500} spacing={-0.5}>
            {Math.round(innerValue).toFixed(1)}
          </Typography>
          <Typography spacing={-0.05} size={10} color={isDarkTheme ? "blueGrey100" : "grey500"} height={12}>
            kWh
          </Typography>
        </DoughnutInnerContainer>
      </Flex>
      <Flex direction="column" rowGap={4}>
        {items.map((item) => (
          <Flex key={item.label} columnGap={4} align="center">
            <LegendCircle color={item.color} />
            <Typography size={14} height={18} weight={600} spacing={0.2} noWrap>
              {itemSum ? Math.round(((item.value || 0) / itemSum) * 100) : 0}%{" "}
              <Typography color={isDarkTheme ? "blueGrey100" : "grey600"}>{item.label}</Typography>
            </Typography>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
