import React from "react";

export const AgrolaHouseLogoIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="42" height="42" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 20.1615C0.0670652 9.62268 9.01548 1 20.0046 1C30.9937 1 40.0667 9.62268 39.9996 20.1615C39.9326 30.7003 31.1087 39.3996 20.0046 39.323C9.01548 39.3996 0.0670652 30.8057 0 20.1615Z"
        fill="url(#paint0_radial_2291_32522)"
      />
      <path
        d="M0 20.1615C0.0670652 9.62268 9.01548 1 20.0046 1C30.9937 1 40.0667 9.62268 39.9996 20.1615C39.9326 30.7003 31.1087 39.3996 20.0046 39.323C9.01548 39.3996 0.0670652 30.8057 0 20.1615Z"
        fill="url(#paint1_radial_2291_32522)"
      />
      <mask
        id="mask0_2291_32522"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="1"
        width="40"
        height="39"
      >
        <path
          d="M0 20.1615C0.0670652 9.62268 9.01548 1 20.0046 1C30.9937 1 40.0667 9.62268 39.9996 20.1615C39.9326 30.7003 31.1087 39.3996 20.0046 39.323C9.01548 39.3996 0.0670652 30.8057 0 20.1615Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2291_32522)">
        <path d="M23.336 20.1607L20.0019 22.2493L5.72656 6.77637L17.3672 15.102L23.336 20.1607Z" fill="#005428" />
        <path
          d="M8.4954 36.7161L5.72656 33.5927L17.3384 28.4383L39.9969 20.1797L8.4954 36.7161Z"
          fill="url(#paint2_linear_2291_32522)"
        />
      </g>
      <path d="M5.72656 6.77734L39.9969 20.1808H31.1155L5.72656 6.77734Z" fill="#FFEC00" />
      <path d="M5.72656 6.77734L31.1155 20.1808H22.5983L5.72656 6.77734Z" fill="#F9B339" />
      <path d="M5.72656 33.5937L39.9969 20.1807H31.1155L5.72656 33.5937Z" fill="#F9B339" />
      <path d="M5.72656 33.5937L31.1155 20.1807H22.5983L5.72656 33.5937Z" fill="#FFEC00" />
      <defs>
        <radialGradient
          id="paint0_radial_2291_32522"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.0438 20.1869) scale(19.6209)"
        >
          <stop stopColor="#007C32" />
          <stop offset="0.45" stopColor="#007A31" />
          <stop offset="0.64" stopColor="#00732E" />
          <stop offset="0.79" stopColor="#006628" />
          <stop offset="0.92" stopColor="#00541F" />
          <stop offset="1" stopColor="#004317" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_2291_32522"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.5952 12.4348) scale(21.8686)"
        >
          <stop stopColor="white" stopOpacity="0.8" />
          <stop offset="0.57" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_2291_32522"
          x1="10.7565"
          y1="38.9867"
          x2="34.7754"
          y2="14.9678"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#004317" />
          <stop offset="0.19" stopColor="#005428" />
        </linearGradient>
      </defs>
    </svg>
  );
};
