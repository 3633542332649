import React from "react";

export const SolarDeviceIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.80952 15.4286H1.71588C1.36834 15.4286 1.1268 15.0827 1.24645 14.7564L2.44339 11.4921M7.80952 15.4286L9.64286 4M7.80952 15.4286H12.2619M9.64286 4H5.53969C5.32993 4 5.14247 4.13093 5.07026 4.32787L3.88677 7.55556M9.64286 4H14.881M16.7143 15.4286H22.2686C22.6204 15.4286 22.8622 15.0749 22.7345 14.7471L21.4664 11.4921M16.7143 15.4286L14.881 4M16.7143 15.4286H12.2619M14.881 4H18.2058C18.4119 4 18.5969 4.12646 18.6717 4.3185L19.9328 7.55556M3.88677 7.55556H19.9328M3.88677 7.55556L2.44339 11.4921M19.9328 7.55556L21.4664 11.4921M2.44339 11.4921H21.4664M12.2619 15.4286V20M8.33333 20H15.9286"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
