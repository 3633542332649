import { IHomeDevicePosition } from "./common";

export const batteryPosition: IHomeDevicePosition = {
  desktop: {
    container: {
      left: 0,
      top: 163,
    },
    line: {
      size: 183,
      right: -226,
      top: 84,
    },
  },
  mobile: {
    container: {
      left: 0,
      top: 160,
    },
    line: {
      size: 60,
      right: -105,
      top: 28,
    },
  },
  tablet: {
    container: {
      left: 0,
      top: 140,
    },
    line: {
      size: 115,
      right: -167,
      top: 66,
    },
  },
};

export const carPosition: IHomeDevicePosition = {
  desktop: {
    container: {
      right: 0,
      bottom: 50,
    },
    line: {
      size: 150,
      right: 179,
      bottom: 203,
    },
    level: {
      left: 90,
      top: 0,
    },
  },
  mobile: {
    container: {
      right: 0,
      bottom: -5,
    },
    line: {
      size: 59,
      right: 101,
      bottom: 134,
      rotate: "45deg",
    },
    inner: {
      right: -26,
      bottom: -41,
    },
    level: {
      left: 52,
      top: -6,
    },
  },
  tablet: {
    container: {
      right: 0,
      bottom: 50,
    },
    line: {
      size: 120,
      right: 119,
      bottom: 168,
      rotate: "45deg",
    },
    level: {
      left: 50,
      top: -10,
    },
  },
};

export const heatpumpPosition: IHomeDevicePosition = {
  desktop: {
    container: {
      left: 60,
      bottom: 50,
    },
    line: {
      size: 150,
      left: 121,
      bottom: 203,
    },
    serviceWater: {
      left: -50,
      top: 0,
    },
    heatingWater: {
      left: 100,
      top: 0,
    },
  },
  mobile: {
    container: {
      left: 40,
      bottom: 10,
    },
    line: {
      size: 59,
      left: 71,
      bottom: 119,
      rotate: "-40deg",
    },
    inner: {
      left: -35,
      bottom: -40,
    },
    serviceWater: {
      left: -30,
      top: 0,
    },
    heatingWater: {
      left: 55,
      top: 0,
    },
  },
  tablet: {
    container: {
      left: 60,
      bottom: 50,
    },
    line: {
      size: 120,
      left: 59,
      bottom: 167,
      rotate: "-45deg",
    },
    serviceWater: {
      left: -50,
      top: 0,
    },
    heatingWater: {
      left: 100,
      top: 0,
    },
  },
};

export const powerGridPosition: IHomeDevicePosition = {
  desktop: {
    container: {
      right: 0,
      top: 172,
    },
    line: {
      size: 190,
      left: -259,
      top: 75,
    },
  },
  mobile: {
    container: {
      right: 0,
      top: 150,
    },
    line: {
      size: 55,
      left: -116,
      top: 38,
    },
  },
  tablet: {
    container: {
      right: 0,
      top: 140,
    },
    line: {
      size: 125,
      left: -186,
      top: 66,
    },
  },
};

export const housePosition: IHomeDevicePosition = {
  desktop: {
    container: {
      left: 244,
      top: 143,
    },
    line: {
      size: 114,
      left: 58,
      top: -88,
    },
    inner: {
      left: 81,
      top: 98,
    },
  },
  mobile: {
    container: {
      left: 120,
      top: 130,
    },
    line: {
      size: 74,
      left: 16,
      top: -59,
    },
    inner: {
      left: 46,
      top: 59,
    },
  },
  tablet: {
    container: {
      left: 172,
      top: 143,
    },
    line: {
      size: 80,
      left: 24,
      top: -88,
    },
    inner: {
      left: 54,
      top: 64,
    },
  },
};
