import { useMutation, useQuery } from "@tanstack/react-query";
import { request } from "api/config/axios";
import { queryClient } from "api/config/query";
import { IScene } from "types/common";

const getScenes = async () => {
  return request<IScene[]>({
    url: "/scenes/user",
    method: "GET",
  });
};

export const useScenes = () => {
  return useQuery<IScene[]>(["scenes"], () => getScenes());
};

type UpdateSceneDto = Partial<Omit<IScene, "scene_id">>;

const updateScene = async (id: number, data: UpdateSceneDto) => {
  return request<IScene>({
    url: `/scenes/${id}`,
    method: "PATCH",
    data,
  });
};

export const useUpdateScene = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: UpdateSceneDto }) => {
      return updateScene(id, data);
    },
    onSuccess: (data: IScene) => {
      queryClient.setQueryData<IScene[]>(["scenes"], (scenes) =>
        scenes?.map((scene) => (scene.scene_id === data.scene_id ? data : scene)),
      );
    },
  });
};
