import {
  AirConditionIcon,
  BatteryDeviceIcon,
  CarDeviceIcon,
  HeatpumpDeviceIcon,
  PowerGridDeviceIcon,
  SolarDeviceIcon,
  WashingMachineIcon,
} from "assets";
import { IconProps } from "components";

export const DeviceIconMap: Record<string, IconProps["icon"]> = {
  air_condition: AirConditionIcon,
  washing_machine: WashingMachineIcon,
  charger: CarDeviceIcon,
  heatpump: HeatpumpDeviceIcon,
  batterie: BatteryDeviceIcon,
  power_grid: PowerGridDeviceIcon,
  photovoltaic: SolarDeviceIcon,
};
