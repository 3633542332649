import React from "react";

export const HeatpumpDeviceIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.9841 9.90734C13.5077 9.90734 14.7427 8.66845 14.7427 7.14019C14.7427 5.61194 13.5077 4.37305 11.9841 4.37305C10.4606 4.37305 9.22559 5.61194 9.22559 7.14019C9.22559 8.66845 10.4606 9.90734 11.9841 9.90734Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M10.2383 23.0002V19.8809"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7607 23.0002V19.8809"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3115 19.2156C17.3115 19.2156 15.857 19.8495 13.7605 19.8797C11.7141 20.091 10.2395 19.8797 10.2395 19.8797C8.14304 19.8495 6.68853 19.2156 6.68853 19.2156C5.88604 18.6822 6.00641 17.7968 6.00641 17.7968V2.83404C6.04653 1.97874 6.88915 1.73724 6.88915 1.73724C9.60758 0.972506 11.7141 0.99263 11.985 1.00269H12.015C12.2859 0.99263 14.3924 0.972506 17.1109 1.73724C17.1109 1.73724 17.9535 1.97874 17.9936 2.8441V17.7968C17.9936 17.7968 18.114 18.6822 17.3115 19.2156Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
