import React from "react";
import { ErrorTile } from "components";
import { Routes } from "constants/routes";
import { useNavigate } from "react-router-dom";

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <ErrorTile
      title="404"
      message="Die gesuchte Seite konnte nicht gefunden werden. Sie wurde möglicherweise entfernt, umbenannt oder
    existierte von vornherein nicht."
      onButtonClick={() => navigate(Routes.Overview)}
      buttonText="Zur Startseite"
    />
  );
};
