import React from "react";

export const VacationIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.8281 9.90031C10.3677 9.2708 16.0381 10.0787 16.0381 10.0787C15.0515 6.14426 10.6279 7.16196 10.6279 7.16196C11.5169 5.79803 14.0757 4.43411 14.0757 4.43411C10.5303 2.01051 7.60295 6.80524 7.60295 6.80524C7.60295 6.80524 2.04097 5.4623 1.30371 9.60654C1.30371 9.60654 4.11181 8.81966 5.73812 9.17638C5.73812 9.17638 1.85666 11.4846 4.03591 14.9363C4.03591 14.9363 6.86569 10.3305 8.83895 9.88981L8.8281 9.90031Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.80371 9.70117C9.80371 9.70117 14.7911 15.2303 16.5041 21.6827"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.53809 10.6133C7.53809 10.6133 11.1702 14.0756 11.5822 22.1647"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4536 11.5569C22.1309 11.0113 17.252 11.7143 17.252 11.7143C18.0976 8.32542 21.914 9.20673 21.914 9.20673C21.1443 8.03165 18.9433 6.85658 18.9433 6.85658C22.0008 4.76872 24.5161 8.90247 24.5161 8.90247C24.5161 8.90247 29.3083 7.73788 29.948 11.3156C29.948 11.3156 27.5302 10.6336 26.1316 10.9484C26.1316 10.9484 29.471 12.9418 27.5953 15.911C27.5953 15.911 25.1558 11.9451 23.4536 11.5674V11.5569Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8622 21.6916C18.3367 16.1414 22.6193 11.3887 22.6193 11.3887"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0684 22.4367C21.3503 15.2499 24.5703 12.1758 24.5703 12.1758"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 28.2502C14.3683 13.4358 1 28.2502 1 28.2502H31Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
