import { useMutation } from "@tanstack/react-query";
import { request } from "api/config/axios";
import { queryClient } from "api/config/query";
import { IHelpChatResponse, IHelpChatRequest } from "types/common";

const sendMessages = async (data: IHelpChatRequest) => {
  return request<IHelpChatResponse>({
    url: "/help/messages",
    method: "POST",
    data,
  });
};

export const useSendMessages = () => {
  return useMutation({
    mutationFn: (data: IHelpChatRequest) => {
      return sendMessages(data);
    },
    onSuccess: (data) => {
      return queryClient.setQueryData(["help_messages"], data);
    },
  });
};

const reindexModel = async (modelId: number) => {
  return request<IHelpChatResponse>({
    url: "/help/reindex",
    method: "POST",
    data: { modelId },
  });
};

export const useReindexModel = () => {
  return useMutation({
    mutationFn: (modelId: number) => {
      return reindexModel(modelId);
    },
  });
};

export const getLatestMessages = () => {
  return queryClient.getQueryData(["help_messages"]);
};
