export enum TimeRangeId {
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year",
  Custom = "custom",
}

export const timeRanges: Array<{ value: TimeRangeId; label: string }> = [
  {
    label: "Tag",
    value: TimeRangeId.Day,
  },
  {
    label: "Woche",
    value: TimeRangeId.Week,
  },
  {
    label: "Monat",
    value: TimeRangeId.Month,
  },
  {
    label: "Jahr",
    value: TimeRangeId.Year,
  },
  {
    label: "Abrechnung",
    value: TimeRangeId.Custom,
  },
];
