import { Flex, ToastContainer } from "components";
import { UserRole } from "constants/roles";
import { Routes } from "constants/routes";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUser } from "api/modules/users";
import styled from "styled-components";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { AdminNavbar } from "./components";

const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 64px;
`;

const AdminToastContainer = styled(ToastContainer)`
  position: fixed !important;
  top: 176px !important;

  ${(props) => props.theme.breakpoints.tablet} {
    top: 128px !important;
  }
`;

export const AdminLayout: React.FC = () => {
  const { data: user, isError, isLoading } = useUser();

  React.useEffect(() => {
    toast.dismiss();
  }, []);

  if (isLoading) {
    return <Skeleton count={3} />;
  }

  if (isError || user.role !== UserRole.Admin) {
    return <Navigate to={Routes.Overview} replace />;
  }
  return (
    <Flex direction="column">
      <AdminNavbar />
      <Content>
        <AdminToastContainer autoClose={5000} />
        <Outlet />
      </Content>
    </Flex>
  );
};
