import React from "react";

export const CarAreaIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="177" height="104" viewBox="0 0 177 104" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M112.057 103.097L0.908203 39.3895L65.7087 0.733887L176.859 64.4417L112.057 103.097Z"
        fill="url(#paint0_linear_646_46)"
      />
      <path d="M29.1261 49.821L13.3672 40.8585L68.6741 8.02844L84.4344 16.991L29.1261 49.821Z" fill="#D3CEC4" />
      <path d="M49.5187 61.1607L33.7598 52.1981L89.0667 19.3681L104.827 28.3307L49.5187 61.1607Z" fill="#D3CEC4" />
      <path d="M69.9122 72.5002L54.1533 63.5377L109.46 30.7076L125.22 39.6702L69.9122 72.5002Z" fill="#8EEDC7" />
      <path d="M90.3058 83.8398L74.5469 74.8773L129.854 42.0472L145.614 51.0098L90.3058 83.8398Z" fill="#8EEDC7" />
      <path d="M110.961 95.4445L95.2021 86.4819L150.509 53.6519L166.269 62.6145L110.961 95.4445Z" fill="#8EEDC7" />
      <defs>
        <linearGradient
          id="paint0_linear_646_46"
          x1="123.179"
          y1="85.0332"
          x2="-25.5468"
          y2="-34.0125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E8E6E1" />
          <stop offset="1" stopColor="#E8E6E1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
