import { IDeviceAlarm } from "api/modules/alarms";
import { Routes } from "constants/routes";
import React from "react";
import { toast } from "utils/toast";

export const useAlarmsListener = (
  initialAlarms: IDeviceAlarm[] | undefined,
  isLoading: boolean,
  options: { enabled: boolean } = { enabled: true },
) => {
  const [alarms, setAlarms] = React.useState<IDeviceAlarm[]>();
  React.useEffect(() => {
    let eventSource: EventSource;
    if (options.enabled) {
      eventSource = new EventSource(`${process.env.REACT_APP_API_URL || "/api/"}sse/alarm`, {
        withCredentials: true,
      });
      eventSource.onmessage = ({ data }) => {
        try {
          const alarm: IDeviceAlarm = JSON.parse(data);
          if (alarm.active) {
            const params = `alarm_id=${alarm.alarm_id}&device_id=${alarm.device_id}`;
            toast.error(alarm.msg, `${Routes.Help}?${params}`, {
              toastId: params,
            });
          } else {
            toast.dismiss(alarm.alarm_id);
          }
          setAlarms((prevAlarms) =>
            prevAlarms?.map((prevAlarm) => (alarm.alarm_id === prevAlarm.alarm_id ? alarm : prevAlarm)),
          );
        } catch {}
      };
    }
    return () => eventSource?.close();
  }, [options.enabled]);

  React.useEffect(() => {
    if (initialAlarms && !isLoading) {
      initialAlarms.forEach((alarm) => {
        if (alarm.active) {
          const params = `alarm_id=${alarm.alarm_id}&device_id=${alarm.device_id}`;
          toast.error(alarm.msg, `${Routes.Help}?${params}`, {
            toastId: params,
          });
        }
      });
      setAlarms(initialAlarms);
    }
  }, [initialAlarms, isLoading]);

  return [alarms];
};
