import React from "react";

export const ExclamationErrorIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.0513 4.42641C10.9172 2.92557 13.0833 2.92557 13.9492 4.42641L21.3035 17.1739C22.1689 18.6739 21.0863 20.5483 19.3546 20.5483H4.64593C2.9142 20.5483 1.83163 18.6739 2.69702 17.1739L10.0513 4.42641Z"
        fill="#FFE3E3"
      />
      <path d="M12.0003 16.7983H12.0078V16.8058H12.0003V16.7983Z" fill="#FFE3E3" />
      <path
        d="M12.0003 10.0483V13.7983M2.69702 17.1739C1.83163 18.6739 2.9142 20.5483 4.64593 20.5483H19.3546C21.0863 20.5483 22.1689 18.6739 21.3035 17.1739L13.9492 4.42641C13.0833 2.92557 10.9172 2.92557 10.0513 4.42641L2.69702 17.1739ZM12.0003 16.7983H12.0078V16.8058H12.0003V16.7983Z"
        stroke="#AB091E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
