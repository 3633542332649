import { CircleCheckIcon, ExclamationTriangleIcon, ExclemationWarningIcon, InfoIcon } from "assets";
import { Icon } from "components";
import { useScroll } from "hooks/useScroll";
import React from "react";
import { ToastContainer as NotificationContainer, ToastContainerProps, TypeOptions } from "react-toastify";
import { styled } from "styled-components";

const TypeByIconMap: Record<TypeOptions, React.FC> = {
  default: InfoIcon,
  info: InfoIcon,
  error: ExclamationTriangleIcon,
  warning: ExclemationWarningIcon,
  success: CircleCheckIcon,
};

const Container = styled(NotificationContainer).attrs({
  icon: ({ type }) => <Icon icon={TypeByIconMap[type]} />,
})<{ $shouldOverlap: boolean }>`
  position: sticky;

  transition: top 0.5s ease;
  width: calc(100vw - 64px);
  left: auto;
  max-width: 1136px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 0;
  z-index: 999;
  transform: none;
  ${(props) => props.$shouldOverlap && "position: fixed; top: 144px;"}

  & .Toastify__toast {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    margin: 0 0 8px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.grey100};
    color: ${(props) => props.theme.colors.grey800};
  }

  & .Toastify__toast--success {
    background-color: ${(props) => props.theme.colors.green50};
    color: ${(props) => props.theme.colors.green800};
  }

  & .Toastify__toast--error {
    background-color: ${(props) => props.theme.colors.red50};
    color: ${(props) => props.theme.colors.red800};
  }

  & .Toastify__toast--warning {
    background-color: ${(props) => props.theme.colors.yellow100};
    color: ${(props) => props.theme.colors.yellow800};
  }

  ${(props) => props.theme.breakpoints.mobile} {
  }

  ${(props) => props.theme.breakpoints.tablet} {
    width: calc(100vw - 32px);
    ${(props) => props.$shouldOverlap && "top: 80px;"};

    & .Toastify__toast-body {
      align-items: flex-start;
    }
  }
`;

export const ToastContainer: React.FC<ToastContainerProps> = ({ ...rest }) => {
  const { scrollPosition } = useScroll();

  const [shouldOverlap, setShouldOverlap] = React.useState(false);

  React.useEffect(() => {
    if (scrollPosition > 70) {
      setShouldOverlap(true);
    } else if (scrollPosition < 50) {
      setShouldOverlap(false);
    }
  }, [scrollPosition]);

  return (
    <Container
      position="top-center"
      newestOnTop
      closeOnClick
      closeButton={false}
      hideProgressBar
      autoClose={false}
      $shouldOverlap={shouldOverlap}
      {...rest}
    />
  );
};
