import React from "react";

export const PartyIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M24.4131 28.4997L28.4999 27.5996"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.4921 18.9626C20.9335 19.3772 19.7174 12.1867 19.7174 12.1867L18.2422 5.3198L24.5718 3.91406L26.0471 10.781C26.0471 10.781 27.8911 17.8198 24.4921 18.9626ZM24.4921 18.9626L26.4558 28.0544"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path d="M19.7676 12.3998L26.0972 10.9941" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
      <path
        d="M3.5 26.8008L7.5071 28.0245"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.13446 18.4979C4.82511 17.1023 7.20744 10.2253 7.20744 10.2253L9.19106 3.5L15.3911 5.39118L13.3975 12.1165C13.3975 12.1165 11.6432 19.1856 8.12449 18.4979H8.13446ZM8.13446 18.4979L5.50293 27.4178"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path d="M7.13867 10.4375L13.3487 12.3186" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  );
};
