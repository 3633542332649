import React from "react";

export const DarkHouseIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="229" height="226" viewBox="0 0 229 226" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.3"
        d="M228.837 168.96L92.1805 84.3477L0.330078 141.138L137.476 225.75L228.837 168.96Z"
        fill="url(#paint0_linear_1184_16040)"
      />
      <path
        d="M88.9082 87.7943L191.183 151.194L195.118 81.0438L88.9082 5.53125V87.7943Z"
        fill="url(#paint1_linear_1184_16040)"
      />
      <path
        d="M2.10742 54.7957L88.9083 5.53125V87.7943L6.86181 138.492L2.10742 54.7957Z"
        fill="url(#paint2_linear_1184_16040)"
      />
      <path d="M132.255 82.9639L147.691 92.3348V93.0198L132.255 83.6489V82.9639Z" fill="#F2F2F2" />
      <path d="M195.118 75.0606L137.923 0.25L94.5879 26.0373L151.781 100.849L195.118 75.0606Z" fill="#B4BBCB" />
      <path d="M51.9418 52.5465L137.923 0.25L88.089 2.49865L2.10742 54.7951L51.9418 52.5465Z" fill="#838DA8" />
      <path d="M51.9404 52.5463L94.5867 26.0371V32.7738L51.9404 59.283V52.5463Z" fill="#6A7797" />
      <path d="M2.10742 54.7955L51.9418 52.5469V59.2836L2.10742 61.5323V54.7955Z" fill="#838DA8" />
      <path d="M94.5879 26.0371L151.781 100.849V107.572L94.5879 32.7615V26.0371Z" fill="#9BA5BA" />
      <path d="M151.78 100.849L195.117 75.0605V81.785L151.78 107.572V100.849Z" fill="#9BA5BA" />
      <path
        d="M2.10742 61.5314L6.86181 61.3164V144.378L2.10742 141.406V61.5314Z"
        fill="url(#paint3_linear_1184_16040)"
      />
      <path d="M195.12 81.7852L191.185 84.126V155.963L195.12 153.596V81.7852Z" fill="url(#paint4_linear_1184_16040)" />
      <path
        d="M141.87 82.0044L102.845 29.2057L136.727 8.13379L175.145 61.3119L141.87 82.0044Z"
        fill="url(#paint5_linear_1184_16040)"
      />
      <path
        d="M142.293 80.269L103.268 27.4688L137.15 6.39844L175.568 59.575L142.293 80.269Z"
        fill="url(#paint6_linear_1184_16040)"
      />
      <path
        d="M175.567 59.5742L175.143 61.3114L141.868 82.0039L142.292 80.2682L175.567 59.5742Z"
        fill="url(#paint7_linear_1184_16040)"
      />
      <path
        d="M103.269 27.4688L102.845 29.2059L141.87 82.0046L142.294 80.269L103.269 27.4688Z"
        fill="url(#paint8_linear_1184_16040)"
      />
      <path d="M137.355 16.8019L135.096 13.7791L138.008 11.9697L140.266 14.9925L137.355 16.8019Z" fill="#838DA8" />
      <path d="M133.689 19.2204L131.43 16.1976L134.342 14.3867L136.6 17.4095L133.689 19.2204Z" fill="#838DA8" />
      <path d="M130.022 21.6345L127.763 18.6117L130.675 16.8008L132.932 19.8251L130.022 21.6345Z" fill="#838DA8" />
      <path d="M126.358 24.0524L124.101 21.0296L127.011 19.2188L129.27 22.2415L126.358 24.0524Z" fill="#838DA8" />
      <path d="M122.69 26.4684L120.433 23.4457L123.343 21.6348L125.602 24.6591L122.69 26.4684Z" fill="#838DA8" />
      <path d="M119.025 28.8864L116.768 25.8636L119.678 24.0527L121.937 27.0755L119.025 28.8864Z" fill="#838DA8" />
      <path d="M115.364 31.3024L113.104 28.2797L116.015 26.4688L118.274 29.4931L115.364 31.3024Z" fill="#838DA8" />
      <path d="M111.696 33.7204L109.437 30.6976L112.349 28.8867L114.606 31.9095L111.696 33.7204Z" fill="#838DA8" />
      <path d="M140.271 20.9001L138.012 17.8773L140.923 16.0664L143.182 19.0907L140.271 20.9001Z" fill="#838DA8" />
      <path d="M136.603 23.3171L134.344 20.2943L137.256 18.4834L139.514 21.5062L136.603 23.3171Z" fill="#838DA8" />
      <path d="M132.937 25.7331L130.678 22.7103L133.59 20.8994L135.848 23.9237L132.937 25.7331Z" fill="#838DA8" />
      <path d="M129.269 28.1501L127.01 25.1273L129.922 23.3164L132.18 26.3392L129.269 28.1501Z" fill="#838DA8" />
      <path d="M125.605 30.5671L123.348 27.5443L126.258 25.7334L128.516 28.7562L125.605 30.5671Z" fill="#838DA8" />
      <path d="M121.937 32.985L119.68 29.9623L122.59 28.1514L124.85 31.1741L121.937 32.985Z" fill="#838DA8" />
      <path d="M118.276 35.4011L116.019 32.3783L118.929 30.5674L121.188 33.5902L118.276 35.4011Z" fill="#838DA8" />
      <path d="M114.607 37.818L112.35 34.7953L115.26 32.9844L117.519 36.0071L114.607 37.818Z" fill="#838DA8" />
      <path d="M143.181 24.9968L140.924 21.974L143.835 20.1631L146.094 23.1874L143.181 24.9968Z" fill="#838DA8" />
      <path d="M139.516 27.4147L137.259 24.392L140.17 22.5811L142.429 25.6038L139.516 27.4147Z" fill="#838DA8" />
      <path d="M135.85 29.8307L133.591 26.808L136.502 24.9971L138.761 28.0198L135.85 29.8307Z" fill="#838DA8" />
      <path d="M132.184 32.2487L129.925 29.2259L132.837 27.415L135.095 30.4378L132.184 32.2487Z" fill="#838DA8" />
      <path d="M128.52 34.6647L126.261 31.642L129.173 29.8311L131.431 32.8538L128.52 34.6647Z" fill="#838DA8" />
      <path d="M124.852 37.0827L122.593 34.0599L125.505 32.249L127.763 35.2718L124.852 37.0827Z" fill="#838DA8" />
      <path d="M121.187 39.4977L118.93 36.475L121.84 34.6641L124.1 37.6868L121.187 39.4977Z" fill="#838DA8" />
      <path d="M117.522 41.9157L115.265 38.8914L118.175 37.082L120.434 40.1048L117.522 41.9157Z" fill="#838DA8" />
      <path d="M146.092 29.0954L143.834 26.0726L146.745 24.2617L149.004 27.2845L146.092 29.0954Z" fill="#838DA8" />
      <path d="M142.428 31.5124L140.171 28.4896L143.082 26.6787L145.341 29.7015L142.428 31.5124Z" fill="#838DA8" />
      <path d="M138.763 33.9294L136.506 30.9066L139.417 29.0957L141.676 32.1185L138.763 33.9294Z" fill="#838DA8" />
      <path d="M135.098 36.3474L132.839 33.323L135.75 31.5137L138.009 34.5364L135.098 36.3474Z" fill="#838DA8" />
      <path d="M131.432 38.7624L129.173 35.7396L132.085 33.9287L134.343 36.9515L131.432 38.7624Z" fill="#838DA8" />
      <path d="M127.765 41.1794L125.506 38.1551L128.418 36.3457L130.676 39.3685L127.765 41.1794Z" fill="#838DA8" />
      <path d="M124.101 43.5954L121.842 40.5726L124.754 38.7617L127.012 41.7845L124.101 43.5954Z" fill="#838DA8" />
      <path d="M120.436 46.0134L118.179 42.9891L121.09 41.1797L123.349 44.2025L120.436 46.0134Z" fill="#838DA8" />
      <path d="M149.008 33.194L146.749 30.1712L149.661 28.3604L151.919 31.3831L149.008 33.194Z" fill="#838DA8" />
      <path d="M145.343 35.612L143.085 32.5877L145.996 30.7783L148.255 33.8011L145.343 35.612Z" fill="#838DA8" />
      <path d="M141.677 38.0261L139.419 35.0033L142.33 33.1924L144.589 36.2152L141.677 38.0261Z" fill="#838DA8" />
      <path d="M138.011 40.444L135.753 37.4197L138.664 35.6104L140.923 38.6331L138.011 40.444Z" fill="#838DA8" />
      <path d="M134.343 42.86L132.085 39.8373L134.996 38.0264L137.255 41.0492L134.343 42.86Z" fill="#838DA8" />
      <path d="M130.681 45.278L128.422 42.2537L131.333 40.4443L133.592 43.4671L130.681 45.278Z" fill="#838DA8" />
      <path d="M127.014 47.695L124.755 44.6722L127.667 42.8613L129.925 45.8841L127.014 47.695Z" fill="#838DA8" />
      <path d="M123.349 50.1105L121.09 47.0877L124.002 45.2783L126.26 48.3011L123.349 50.1105Z" fill="#838DA8" />
      <path d="M151.921 37.2907L149.662 34.2679L152.574 32.457L154.832 35.4798L151.921 37.2907Z" fill="#838DA8" />
      <path d="M148.256 39.7087L145.997 36.6844L148.909 34.875L151.167 37.8978L148.256 39.7087Z" fill="#838DA8" />
      <path d="M144.589 42.1247L142.33 39.1019L145.242 37.291L147.5 40.3138L144.589 42.1247Z" fill="#838DA8" />
      <path d="M140.925 44.5427L138.667 41.5184L141.578 39.709L143.837 42.7318L140.925 44.5427Z" fill="#838DA8" />
      <path d="M137.259 46.9587L135.001 43.9359L137.912 42.125L140.171 45.1478L137.259 46.9587Z" fill="#838DA8" />
      <path d="M133.594 49.3751L131.336 46.3523L134.247 44.543L136.506 47.5657L133.594 49.3751Z" fill="#838DA8" />
      <path d="M129.93 51.7927L127.671 48.7699L130.582 46.959L132.841 49.9818L129.93 51.7927Z" fill="#838DA8" />
      <path d="M126.262 54.2097L124.003 51.1869L126.915 49.376L129.173 52.4003L126.262 54.2097Z" fill="#838DA8" />
      <path d="M154.836 41.3893L152.577 38.3666L155.488 36.5557L157.747 39.5784L154.836 41.3893Z" fill="#838DA8" />
      <path d="M151.169 43.8063L148.91 40.782L151.822 38.9727L154.08 41.9954L151.169 43.8063Z" fill="#838DA8" />
      <path d="M147.503 46.2224L145.244 43.1996L148.156 41.3887L150.414 44.4114L147.503 46.2224Z" fill="#838DA8" />
      <path d="M143.838 48.6388L141.579 45.616L144.491 43.8066L146.749 46.8294L143.838 48.6388Z" fill="#838DA8" />
      <path d="M140.171 51.0573L137.913 48.0345L140.824 46.2236L143.083 49.2464L140.171 51.0573Z" fill="#838DA8" />
      <path d="M136.506 53.4733L134.248 50.4505L137.159 48.6396L139.418 51.664L136.506 53.4733Z" fill="#838DA8" />
      <path d="M132.84 55.8893L130.582 52.8666L133.493 51.0557L135.752 54.0784L132.84 55.8893Z" fill="#838DA8" />
      <path d="M129.176 58.3063L126.918 55.2836L129.829 53.4727L132.088 56.497L129.176 58.3063Z" fill="#838DA8" />
      <path d="M157.748 45.487L155.49 42.4642L158.401 40.6533L160.66 43.6761L157.748 45.487Z" fill="#838DA8" />
      <path d="M154.08 47.9034L151.822 44.8807L154.733 43.0713L156.992 46.0941L154.08 47.9034Z" fill="#838DA8" />
      <path d="M150.418 50.322L148.159 47.2992L151.071 45.4883L153.329 48.5111L150.418 50.322Z" fill="#838DA8" />
      <path d="M146.749 52.737L144.49 49.7142L147.402 47.9033L149.66 50.9276L146.749 52.737Z" fill="#838DA8" />
      <path d="M143.088 55.154L140.829 52.1312L143.741 50.3203L145.999 53.3431L143.088 55.154Z" fill="#838DA8" />
      <path d="M139.42 57.57L137.161 54.5472L140.073 52.7363L142.331 55.7606L139.42 57.57Z" fill="#838DA8" />
      <path d="M135.757 59.987L133.499 56.9642L136.41 55.1533L138.669 58.1761L135.757 59.987Z" fill="#838DA8" />
      <path d="M132.087 62.404L129.829 59.3812L132.74 57.5703L134.999 60.5931L132.087 62.404Z" fill="#838DA8" />
      <path d="M160.661 49.5856L158.403 46.5629L161.314 44.752L163.573 47.7747L160.661 49.5856Z" fill="#838DA8" />
      <path d="M156.996 52.0007L154.738 48.9779L157.649 47.167L159.908 50.1913L156.996 52.0007Z" fill="#838DA8" />
      <path d="M153.33 54.4186L151.072 51.3959L153.983 49.585L156.242 52.6077L153.33 54.4186Z" fill="#838DA8" />
      <path d="M149.665 56.8337L147.406 53.8109L150.317 52L152.576 55.0243L149.665 56.8337Z" fill="#838DA8" />
      <path d="M145.999 59.2516L143.74 56.2289L146.652 54.418L148.91 57.4407L145.999 59.2516Z" fill="#838DA8" />
      <path d="M142.334 61.6686L140.075 58.6459L142.987 56.835L145.245 59.8577L142.334 61.6686Z" fill="#838DA8" />
      <path d="M138.667 64.0866L136.408 61.0638L139.32 59.2529L141.578 62.2757L138.667 64.0866Z" fill="#838DA8" />
      <path d="M135.005 66.5016L132.747 63.4789L135.658 61.668L137.917 64.6907L135.005 66.5016Z" fill="#838DA8" />
      <path d="M163.574 53.6833L161.315 50.6605L164.228 48.8496L166.485 51.8724L163.574 53.6833Z" fill="#838DA8" />
      <path d="M159.908 56.0983L157.65 53.0756L160.561 51.2646L162.82 54.289L159.908 56.0983Z" fill="#838DA8" />
      <path d="M156.244 58.5163L153.986 55.4935L156.897 53.6826L159.156 56.7054L156.244 58.5163Z" fill="#838DA8" />
      <path d="M152.577 60.9333L150.319 57.9105L153.23 56.0996L155.489 59.1224L152.577 60.9333Z" fill="#838DA8" />
      <path d="M148.913 63.3513L146.655 60.3285L149.566 58.5176L151.825 61.5404L148.913 63.3513Z" fill="#838DA8" />
      <path d="M145.246 65.7663L142.987 62.7435L145.9 60.9326L148.157 63.9554L145.246 65.7663Z" fill="#838DA8" />
      <path d="M141.581 68.1843L139.322 65.1599L142.234 63.3506L144.492 66.3734L141.581 68.1843Z" fill="#838DA8" />
      <path d="M137.915 70.6012L135.656 67.5785L138.568 65.7676L140.826 68.7904L137.915 70.6012Z" fill="#838DA8" />
      <path d="M166.488 57.7809L164.229 54.7582L167.141 52.9473L169.398 55.97L166.488 57.7809Z" fill="#838DA8" />
      <path d="M162.822 60.196L160.562 57.1732L163.475 55.3623L165.732 58.3851L162.822 60.196Z" fill="#838DA8" />
      <path d="M159.156 62.6139L156.897 59.5912L159.81 57.7803L162.067 60.803L159.156 62.6139Z" fill="#838DA8" />
      <path d="M155.491 65.0309L153.233 62.0082L156.144 60.1973L158.403 63.22L155.491 65.0309Z" fill="#838DA8" />
      <path d="M151.824 67.4489L149.566 64.4246L152.477 62.6152L154.736 65.638L151.824 67.4489Z" fill="#838DA8" />
      <path d="M148.16 69.8659L145.902 66.8431L148.813 65.0322L151.072 68.055L148.16 69.8659Z" fill="#838DA8" />
      <path d="M144.494 72.2809L142.235 69.2566L145.146 67.4473L147.405 70.47L144.494 72.2809Z" fill="#838DA8" />
      <path d="M140.828 74.6979L138.569 71.6752L141.482 69.8643L143.739 72.887L140.828 74.6979Z" fill="#838DA8" />
      <path d="M191.183 151.194L88.9088 87.7939L6.8623 138.491L109.625 201.891L191.183 151.194Z" fill="#FFF3C4" />
      <path d="M109.627 201.891L191.185 151.193V155.963L109.627 207.282V201.891Z" fill="#9BA5BA" />
      <path d="M6.8623 138.491L109.625 201.891V207.282L6.8623 144.379V138.491Z" fill="#B4BBCB" />
      <defs>
        <linearGradient
          id="paint0_linear_1184_16040"
          x1="159.123"
          y1="200.797"
          x2="-43.2017"
          y2="48.5417"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#475066" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1184_16040"
          x1="148.622"
          y1="53.3196"
          x2="128.093"
          y2="105.457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F7EABA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1184_16040"
          x1="39.1169"
          y1="59.9781"
          x2="55.6976"
          y2="79.7702"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFDFD" />
          <stop offset="1" stopColor="#FBEFC2" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1184_16040"
          x1="33.387"
          y1="64.8491"
          x2="-19.56"
          y2="125.566"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#838DA8" />
          <stop offset="1" stopColor="#B4BBCB" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1184_16040"
          x1="191.185"
          y1="118.874"
          x2="195.12"
          y2="118.874"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9BA5BA" />
          <stop offset="1" stopColor="#9BA5BA" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1184_16040"
          x1="104.532"
          y1="30.5189"
          x2="170.696"
          y2="54.6055"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#626363" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1184_16040"
          x1="104.954"
          y1="28.782"
          x2="171.119"
          y2="52.8686"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDD2DC" />
          <stop offset="0.32" stopColor="#E6E8ED" />
          <stop offset="1" stopColor="#9BA5BA" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1184_16040"
          x1="148.038"
          y1="66.3202"
          x2="168.482"
          y2="73.7627"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6A7797" />
          <stop offset="1" stopColor="#9BA5BA" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1184_16040"
          x1="98.5129"
          y1="44.67"
          x2="144.563"
          y2="61.4337"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6A7797" />
          <stop offset="1" stopColor="#9BA5BA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
