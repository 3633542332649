import React from "react";

export const HeatPumpIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="95" height="93" viewBox="0 0 95 93" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.5"
        d="M48.6749 92.5245L0 64.4845L45.8429 37L94.519 65.0401L48.6749 92.5245Z"
        fill="url(#paint0_linear_1540_5348)"
      />
      <path
        d="M64.9648 8.87227L52.5113 0L26 15.455L38.4536 24.3273L64.9648 8.87227Z"
        fill="url(#paint1_linear_1540_5348)"
      />
      <path d="M26 15.4561V64.9842L38.4536 73.8565V24.3283L26 15.4561Z" fill="url(#paint2_linear_1540_5348)" />
      <path
        d="M64.9644 8.87012L64.9628 58.3983L38.4531 73.8533V24.3252L64.9644 8.87012Z"
        fill="url(#paint3_linear_1540_5348)"
      />
      <path
        d="M61.0938 23.4347L61.0815 31.5416C61.0815 32.3731 60.6563 33.1401 59.964 33.5486L45.0743 42.2174C44.1503 42.768 43.0573 42.3708 42.583 41.5022C42.9238 41.5409 43.2753 41.4634 43.6038 41.2729L58.4827 32.5911C59.175 32.1939 59.6125 31.427 59.6125 30.5971V22.4917C59.6125 22.2867 59.5756 22.0945 59.5035 21.9282C60.3063 21.8265 61.0953 22.4917 61.0953 23.4379L61.0938 23.4347Z"
        fill="#625D52"
      />
      <path
        d="M59.6114 22.4889V30.5943C59.6114 31.4258 59.1739 32.1927 58.4817 32.5883L43.6027 41.2701C43.2742 41.4622 42.9227 41.5381 42.582 41.4994C42.4239 41.2184 42.3271 40.8858 42.3271 40.5144V33.3795C42.3271 32.3833 42.8383 31.4613 43.6641 30.9882L58.9314 22.0901C59.1141 21.9884 59.309 21.9238 59.5024 21.9238C59.5761 22.0901 59.6114 22.2823 59.6114 22.4873V22.4889Z"
        fill="#857F72"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1540_5348"
          x1="65.6827"
          y1="82.7261"
          x2="-14.8151"
          y2="18.9144"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3CEC4" />
          <stop offset="1" stopColor="#E8E6E1" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint1_linear_1540_5348" x1="42" y1="6" x2="65" y2="27.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D3CEC4" />
          <stop offset="1" stopColor="#E3E2E0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1540_5348"
          x1="14"
          y1="-25.5"
          x2="-3.01129"
          y2="-12.573"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D6D5D5" />
          <stop offset="1" stopColor="#BCB4A8" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1540_5348"
          x1="52.7824"
          y1="12.7057"
          x2="13.4061"
          y2="58.6261"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBEBEB" />
          <stop offset="1" stopColor="#B9B2A8" />
        </linearGradient>
      </defs>
    </svg>
  );
};
