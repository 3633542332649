import React from "react";

export const DarkCarAreaIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="177" height="102" viewBox="0 0 177 102" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M111.454 102.001L0 38.9868L64.9787 0.751953L176.434 63.7664L111.454 102.001Z"
        fill="url(#paint0_linear_1184_15980)"
      />
      <path d="M28.2944 49.3035L12.4922 40.4385L67.9512 7.96582L83.7548 16.8308L28.2944 49.3035Z" fill="#B4BBCB" />
      <path d="M48.7436 60.5213L32.9414 51.6563L88.4004 19.1836L104.204 28.0486L48.7436 60.5213Z" fill="#B4BBCB" />
      <path d="M69.1938 71.7381L53.3916 62.8731L108.851 30.4004L124.654 39.2654L69.1938 71.7381Z" fill="#8EEDC7" />
      <path d="M89.6431 82.9529L73.8408 74.0879L129.3 41.6152L145.103 50.4802L89.6431 82.9529Z" fill="#8EEDC7" />
      <path d="M110.356 94.4324L94.5537 85.5674L150.013 53.0947L165.816 61.9597L110.356 94.4324Z" fill="#8EEDC7" />
      <defs>
        <linearGradient
          id="paint0_linear_1184_15980"
          x1="122.607"
          y1="84.1337"
          x2="-24.9285"
          y2="-35.587"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#475066" />
        </linearGradient>
      </defs>
    </svg>
  );
};
