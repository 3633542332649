import React from "react";

export const AgrolaLogoIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="130" height="32" viewBox="0 0 130 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2291_33352)">
        <path
          d="M0 15.9996C0.0526012 7.73371 7.0711 0.970703 15.6902 0.970703C24.3092 0.970703 31.4254 7.73371 31.3728 15.9996C31.3202 24.2655 24.3994 31.0886 15.6902 31.0285C7.0711 31.0886 0.0526012 24.3482 0 15.9996Z"
          fill="url(#paint0_radial_2291_33352)"
        />
        <path
          d="M0 15.9996C0.0526012 7.73371 7.0711 0.970703 15.6902 0.970703C24.3092 0.970703 31.4254 7.73371 31.3728 15.9996C31.3202 24.2655 24.3994 31.0886 15.6902 31.0285C7.0711 31.0886 0.0526012 24.3482 0 15.9996Z"
          fill="url(#paint1_radial_2291_33352)"
        />
        <mask
          id="mask0_2291_33352"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="32"
          height="32"
        >
          <path
            d="M0 15.9996C0.0526012 7.73371 7.0711 0.970703 15.6902 0.970703C24.3092 0.970703 31.4254 7.73371 31.3728 15.9996C31.3202 24.2655 24.3994 31.0886 15.6902 31.0285C7.0711 31.0886 0.0526012 24.3482 0 15.9996Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_2291_33352)">
          <path d="M18.3037 15.9996L15.6887 17.6378L4.49219 5.50195L13.6222 12.032L18.3037 15.9996Z" fill="#005428" />
          <path
            d="M6.66386 28.9846L4.49219 26.5349L13.5997 22.4921L31.3714 16.0146L6.66386 28.9846Z"
            fill="url(#paint2_linear_2291_33352)"
          />
        </g>
        <path d="M4.49219 5.50195L31.3714 16.0147H24.4055L4.49219 5.50195Z" fill="#FFEC00" />
        <path d="M4.49219 5.50195L24.4055 16.0147H17.7251L4.49219 5.50195Z" fill="#F9B339" />
        <path d="M4.49219 26.5349L31.3714 16.0146H24.4055L4.49219 26.5349Z" fill="#F9B339" />
        <path d="M4.49219 26.5349L24.4055 16.0146H17.7251L4.49219 26.5349Z" fill="#FFEC00" />
        <path
          d="M41.1176 16H45.6262L51.0968 30.4277H46.8361L45.6863 27.1139H40.4863L39.3141 30.4277H35.3164L41.1176 16ZM41.5534 24.2659H44.7696L43.2291 19.2913H43.184L41.5534 24.2659Z"
          fill="#007C32"
        />
        <path
          d="M66.0739 29.8183C64.2439 30.5534 62.2815 30.9014 60.3103 30.8403C55.2456 30.8403 52.1797 27.7594 52.1797 23.0929C52.1797 18.5842 55.3358 15.5785 60.8138 15.5785C62.4505 15.5149 64.0759 15.8747 65.5329 16.623L64.3381 19.3282C63.362 18.849 62.2918 18.5923 61.2045 18.5767C58.3566 18.5767 56.2225 20.3877 56.2225 23.1605C56.2225 25.7605 57.9207 27.827 60.7311 27.827C61.2528 27.8549 61.7722 27.7406 62.234 27.4964L62.3693 22.597H66.1265L66.0739 29.8183Z"
          fill="#007C32"
        />
        <path
          d="M73.3859 30.4277H69.4258L69.561 16H75.4749C78.6084 16 81.2385 16.789 81.2385 20.0578C81.2631 20.545 81.1878 21.0321 81.0172 21.4892C80.8466 21.9462 80.5843 22.3635 80.2465 22.7154C79.9087 23.0673 79.5024 23.3464 79.0527 23.5355C78.603 23.7246 78.1194 23.8198 77.6316 23.815V23.8601C78.5032 24.1081 79.1344 25.0023 79.698 25.9416L82.3281 30.4503H77.9171L75.7605 26.4526C75.3021 25.5809 74.9339 24.9497 74.0622 24.9497H73.4761L73.3859 30.4277ZM73.3183 22.1619H75.0767C76.0084 22.1619 77.331 21.9139 77.331 20.4561C77.331 19.2688 76.5795 18.7503 75.009 18.7503H73.4084L73.3183 22.1619Z"
          fill="#007C32"
        />
        <path
          d="M91.6775 15.5938C96.7873 15.5938 99.7856 19.0279 99.7856 23.2134C99.7856 27.399 96.7798 30.8331 91.6775 30.8331C86.5752 30.8331 83.5469 27.399 83.5469 23.2134C83.5469 19.0279 86.5827 15.5938 91.6775 15.5938ZM91.6775 27.8423C93.9318 27.8423 95.7654 26.3845 95.7654 23.2209C95.7654 20.0573 93.9318 18.592 91.6775 18.592C89.4232 18.592 87.6122 20.0498 87.6122 23.2134C87.6122 26.377 89.4232 27.8423 91.6775 27.8423Z"
          fill="#007C32"
        />
        <path d="M102.565 16H106.48L106.352 27.2717H113.115L113.048 30.3977H102.43L102.565 16Z" fill="#007C32" />
        <path
          d="M120.02 16H124.529L129.999 30.4277H125.738L124.589 27.1139H119.396L118.224 30.4277H114.219L120.02 16ZM120.456 24.2659H123.672L122.131 19.2913H122.086L120.456 24.2659Z"
          fill="#007C32"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_2291_33352"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.7209 16.0195) scale(15.3892)"
        >
          <stop stopColor="#007C32" />
          <stop offset="0.45" stopColor="#007A31" />
          <stop offset="0.64" stopColor="#00732E" />
          <stop offset="0.79" stopColor="#006628" />
          <stop offset="0.92" stopColor="#00541F" />
          <stop offset="1" stopColor="#004317" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_2291_33352"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.075 9.93935) scale(17.1522)"
        >
          <stop stopColor="white" stopOpacity="0.8" />
          <stop offset="0.57" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_2291_33352"
          x1="8.43727"
          y1="30.7655"
          x2="27.276"
          y2="11.9268"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#004317" />
          <stop offset="0.19" stopColor="#005428" />
        </linearGradient>
        <clipPath id="clip0_2291_33352">
          <rect width="130" height="30.0578" fill="white" transform="translate(0 0.970703)" />
        </clipPath>
      </defs>
    </svg>
  );
};
