import { ThemeModeContext } from "App";
import { useScenes, useUpdateScene } from "api/modules/scenes";
import { InfoIcon, LongDriveIcon, OutsideIcon, PartyIcon, VacationIcon } from "assets";
import { Button, Card, Flex, Icon, IconProps, Modal, Typography } from "components";
import { useOpenableState } from "hooks/useOpenableState";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { styled } from "styled-components";
import { IScene } from "types/common";

const SceneIconMap: Record<string, IconProps["icon"]> = {
  party: PartyIcon,
  car: LongDriveIcon,
  holiday: VacationIcon,
  walking_man: OutsideIcon,
};

const Container = styled(Flex)`
  width: 100%;
  flex-direction: column;
  row-gap: 24px;
  max-width: 576px;
  margin: 0 auto;
`;

const TemplateCard = styled(Card)<{ $active: boolean }>`
  background-color: ${(props) => (props.$active ? props.theme.colors.green100 : props.theme.colors.white)};
  border: 1px solid ${(props) => (props.$active ? props.theme.colors.green500 : props.theme.colors.transparent)};

  @media (hover: hover) {
    &:hover {
      background-color: ${(props) => (props.$active ? props.theme.colors.green50 : props.theme.colors.white)};
    }
    #dark-theme &:hover {
      background-color: ${(props) => (props.$active ? props.theme.colors.green50 : props.theme.colors.blueGrey600)};
    }
  }

  #dark-theme & {
    background-color: ${(props) => (props.$active ? props.theme.colors.green100 : props.theme.colors.blueGrey600)};
    border: 1px solid ${(props) => (props.$active ? props.theme.colors.green500 : props.theme.colors.transparent)};
  }
`;

const StyledInfoIcon = styled(Icon)<{ $active: boolean }>`
  @media (hover: hover) {
    & > path:hover {
      fill: ${(props) => (props.$active ? props.theme.colors.green200 : props.theme.colors.grey50)};
    }

    #dark-theme & > path:hover {
      fill: ${(props) => (props.$active ? props.theme.colors.green200 : props.theme.colors.blueGrey500)};
    }
  }
`;

export const ScenePage: React.FC = () => {
  const { data: scenes, isLoading } = useScenes();
  const { mutate: updateScene, isLoading: isUpdateLoading } = useUpdateScene();
  const [selectedScene, setSelectedScene] = React.useState<IScene>();

  const [isOpen, { open, close }] = useOpenableState(false);

  const handleOpenSceneModal = (scene: IScene) => {
    setSelectedScene(scene);
    open();
  };

  const handleCloseSceneModal = () => {
    close();
    setSelectedScene(undefined);
  };

  const handleSceneClick = (scene: IScene) => {
    if (isUpdateLoading) {
      return;
    }
    updateScene({ id: scene.scene_id, data: { active: !scene.active } });
  };

  const isDarkTheme = React.useContext(ThemeModeContext) === "dark";
  return (
    <>
      <Container>
        <Typography size={20} height={26} weight={500}>
          Meine Szenen
        </Typography>
        {isLoading ? (
          <Skeleton count={20} />
        ) : (
          scenes?.map((scene) => (
            <TemplateCard key={scene.scene_id} onClick={() => handleSceneClick(scene)} $active={scene.active}>
              <Flex columnGap={16} width="100%">
                <Icon
                  icon={SceneIconMap[scene.icon]}
                  size={32}
                  color={scene.active ? "green600" : isDarkTheme ? "blueGrey100" : "grey600"}
                />
                <Flex direction="column" rowGap={8} width="100%">
                  <Flex justify="space-between" align="center">
                    <Typography
                      size={16}
                      height={24}
                      weight={500}
                      color={scene.active ? "green900" : isDarkTheme ? "white" : "grey800"}
                    >
                      {scene.title}
                    </Typography>
                    <Flex columnGap={8} align="center">
                      {scene.active && (
                        <Typography size={14} weight={300} height={21} color="green700">
                          eingeschaltet
                        </Typography>
                      )}
                      <StyledInfoIcon
                        icon={InfoIcon}
                        color={scene.active ? "green400" : isDarkTheme ? "blueGrey300" : "grey500"}
                        onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                          event.stopPropagation();
                          handleOpenSceneModal(scene);
                        }}
                        $active={scene.active}
                      />
                    </Flex>
                  </Flex>
                  <Typography
                    size={14}
                    height={21}
                    weight={300}
                    color={scene.active ? "green700" : isDarkTheme ? "blueGrey50" : "grey800"}
                  >
                    {scene.description}
                  </Typography>
                </Flex>
              </Flex>
            </TemplateCard>
          ))
        )}
      </Container>

      <Modal
        isOpen={isOpen}
        onClose={handleCloseSceneModal}
        header={selectedScene?.title}
        footer={<Button onClick={handleCloseSceneModal}>Schliessen</Button>}
      >
        {selectedScene?.info}
      </Modal>
    </>
  );
};
