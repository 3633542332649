import React from "react";

export const CarDeviceIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.29927 15.275C1.93543 15.2179 1.91108 14.3771 1.91108 14.32C1.91108 14.32 1.8299 13.9854 1.91108 12.4917C1.99226 10.998 3.54282 10.345 3.54282 10.345L6.55463 9.65938C6.55463 9.65938 6.65205 9.76549 8.40556 8.32077C10.2727 6.78628 12.2616 6.78628 12.2616 6.78628C12.2616 6.78628 17.9443 6.70465 18.7724 6.78628C19.6085 6.87606 20.4366 7.34131 20.8749 7.98612C21.3214 8.62278 21.841 9.61041 21.841 9.61041"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3456 15.2894L8.61719 15.2812"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15.0859 11.0713H20.6387C21.9376 11.0876 22.1243 12.0426 22.1243 12.0426V14.4341C21.7671 15.7401 20.3221 15.2912 20.3221 15.2912H20.249"
        fill="currentColor"
      />
      <path
        d="M15.0859 11.0713H20.6387C21.9376 11.0876 22.1243 12.0426 22.1243 12.0426V14.4341C21.7671 15.7401 20.3221 15.2912 20.3221 15.2912H20.249"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8633 9.20877V12.9716H14.0552C14.0552 12.9716 15.1511 13.045 15.0943 11.7635V10.2454C15.0943 10.2454 15.0943 9.11899 14.047 9.20061H11.8714L11.8633 9.20877Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8638 10.0107H9.56641"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.8638 12.1554H9.56641"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M18.3022 17.2511C19.3827 17.2511 20.2586 16.3704 20.2586 15.284C20.2586 14.1976 19.3827 13.3169 18.3022 13.3169C17.2216 13.3169 16.3457 14.1976 16.3457 15.284C16.3457 16.3704 17.2216 17.2511 18.3022 17.2511Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M5.25626 17.2511C6.33679 17.2511 7.21273 16.3704 7.21273 15.284C7.21273 14.1976 6.33679 13.3169 5.25626 13.3169C4.17574 13.3169 3.2998 14.1976 3.2998 15.284C3.2998 16.3704 4.17574 17.2511 5.25626 17.2511Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
