import {
  BrokenClouds,
  ClearSky,
  FewClouds,
  FreezingRain,
  Mist,
  PatchyLightSnowWithThunder,
  Rain,
  RainWithThunder,
  ScatteredClouds,
  ShowerRain,
  SleetShower,
  Snow,
  SnowShower,
  SnowWithThunder,
  Thunderstorm,
  DarkBrokenClouds,
  DarkClearSky,
  DarkFewClouds,
  DarkFreezingRain,
  DarkMist,
  DarkPatchyLightSnowWithThunder,
  DarkRain,
  DarkRainWithThunder,
  DarkScatteredClouds,
  DarkShowerRain,
  DarkSleetShower,
  DarkSnow,
  DarkSnowShower,
  DarkSnowWithThunder,
  DarkThundershorm,
} from "assets";

// Weather icon codes https://www.weatherapi.com/docs/weather_conditions.json
const IllustrationMap: Record<string, React.FC> = {
  "day/113": ClearSky,
  "day/116": FewClouds,
  "day/119": ScatteredClouds,
  "day/122": BrokenClouds,
  "day/143": Mist,
  "day/176": Rain,
  "day/179": SnowShower,
  "day/182": SleetShower,
  "day/185": BrokenClouds,
  "day/200": RainWithThunder,
  "day/227": Snow,
  "day/230": Snow,
  "day/248": Mist,
  "day/260": Mist,
  "day/263": ShowerRain,
  "day/266": ShowerRain,
  "day/281": ShowerRain,
  "day/284": ShowerRain,
  "day/293": Rain,
  "day/296": ShowerRain,
  "day/299": Rain,
  "day/302": ShowerRain,
  "day/305": Rain,
  "day/308": ShowerRain,
  "day/311": ShowerRain,
  "day/314": ShowerRain,
  "day/317": FreezingRain,
  "day/320": FreezingRain,
  "day/323": SnowShower,
  "day/326": Snow,
  "day/329": SnowShower,
  "day/332": Snow,
  "day/335": SnowShower,
  "day/338": Snow,
  "day/350": Snow,
  "day/353": Rain,
  "day/356": Rain,
  "day/359": Rain,
  "day/362": SleetShower,
  "day/365": SleetShower,
  "day/368": SnowShower,
  "day/371": SnowShower,
  "day/374": SnowShower,
  "day/377": SnowShower,
  "day/386": RainWithThunder,
  "day/389": Thunderstorm,
  "day/392": PatchyLightSnowWithThunder,
  "day/395": SnowWithThunder,
  "night/113": DarkClearSky,
  "night/116": DarkFewClouds,
  "night/119": DarkScatteredClouds,
  "night/122": DarkBrokenClouds,
  "night/143": DarkMist,
  "night/176": DarkRain,
  "night/179": DarkSnowShower,
  "night/182": DarkSleetShower,
  "night/185": DarkBrokenClouds,
  "night/200": DarkRainWithThunder,
  "night/227": DarkSnow,
  "night/230": DarkSnow,
  "night/248": DarkMist,
  "night/260": DarkMist,
  "night/263": DarkShowerRain,
  "night/266": DarkShowerRain,
  "night/281": DarkShowerRain,
  "night/284": DarkShowerRain,
  "night/293": DarkRain,
  "night/296": DarkShowerRain,
  "night/299": DarkRain,
  "night/302": DarkShowerRain,
  "night/305": DarkRain,
  "night/308": DarkShowerRain,
  "night/311": DarkShowerRain,
  "night/314": DarkShowerRain,
  "night/317": DarkFreezingRain,
  "night/320": DarkFreezingRain,
  "night/323": DarkSnowShower,
  "night/326": DarkSnow,
  "night/329": DarkSnowShower,
  "night/332": DarkSnow,
  "night/335": DarkSnowShower,
  "night/338": DarkSnow,
  "night/350": DarkSnow,
  "night/353": DarkRain,
  "night/356": DarkRain,
  "night/359": DarkRain,
  "night/362": DarkSleetShower,
  "night/365": DarkSleetShower,
  "night/368": DarkSnowShower,
  "night/371": DarkSnowShower,
  "night/374": DarkSnowShower,
  "night/377": DarkSnowShower,
  "night/386": DarkRainWithThunder,
  "night/389": DarkThundershorm,
  "night/392": DarkPatchyLightSnowWithThunder,
  "night/395": DarkSnowWithThunder,
};

// iconPath example: day/113.png
export const getIconByIconName = (iconPath?: string | null): string | null => {
  if (!iconPath) {
    return null;
  }
  return `/weather/${iconPath.replace("png", "svg")}`;
};

export const getIllustrationByIconName = (iconPath?: string): React.FC | null => {
  if (!iconPath) {
    return null;
  }
  const iconCode = iconPath.slice(0, -4);
  return IllustrationMap[iconCode];
};
