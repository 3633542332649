import React from "react";

export const PhotoIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1782_34729)">
        <path
          d="M8.78984 10.8902C9.95525 10.8902 10.9 9.94549 10.9 8.78008C10.9 7.61467 9.95525 6.66992 8.78984 6.66992C7.62444 6.66992 6.67969 7.61467 6.67969 8.78008C6.67969 9.94549 7.62444 10.8902 8.78984 10.8902Z"
          fill="currentColor"
        />
        <path
          d="M6.81226 2.18604L5.60551 3.50488H3.51514C2.78977 3.50488 2.19629 4.09836 2.19629 4.82373V12.7368C2.19629 13.4622 2.78977 14.0557 3.51514 14.0557H14.0659C14.7913 14.0557 15.3848 13.4622 15.3848 12.7368V4.82373C15.3848 4.09836 14.7913 3.50488 14.0659 3.50488H11.9755L10.7688 2.18604H6.81226ZM8.79053 12.0774C6.97052 12.0774 5.49341 10.6003 5.49341 8.78028C5.49341 6.96027 6.97052 5.48316 8.79053 5.48316C10.6105 5.48316 12.0877 6.96027 12.0877 8.78028C12.0877 10.6003 10.6105 12.0774 8.79053 12.0774Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1782_34729">
          <rect width="15.8262" height="15.8262" fill="white" transform="translate(0.876953 0.867188)" />
        </clipPath>
      </defs>
    </svg>
  );
};
