import { Flex, FlexProps } from "components";
import React from "react";
import { styled } from "styled-components";

const Tile = styled(Flex)`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.boxShadow.default};

  @media (hover: hover) {
    &:hover {
      background-color: ${(props) => props.theme.colors.grey50};
    }
    #dark-theme &:hover {
      background-color: ${(props) => props.theme.colors.blueGrey800};
    }
  }

  #dark-theme & {
    background-color: ${(props) => props.theme.colors.blueGrey600};
    box-shadow: 1px 3px 8px 0px rgba(153, 153, 153, 0.2);
  }
`;

export const Card: React.FC<React.PropsWithChildren<FlexProps>> = ({ children, ...rest }) => {
  return (
    <Tile padding="16px" {...rest}>
      {children}
    </Tile>
  );
};
