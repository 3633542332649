import React from "react";

import { Table, Button, Dropdown, Input } from "antd";
import { styled } from "styled-components";
import { Typography, Flex, Icon } from "components";
import { capitalizeFirstLetter } from "utils/string";
import { MoreIcon, PlusIcon } from "assets";
import { breakpoints } from "theme";
import { ColumnsType } from "antd/es/table";
import Skeleton from "react-loading-skeleton";

type AdminTableProps = {
  data?: object[];
  columns?: object[];
  isLoading?: boolean;
  rowKey?: string;
  entity: string;
  handleButtonClick?(): void;
  buttonTitle?: string;
  onSearch?(value: string): void;
  includePadding?: boolean;

  actions?: {
    key: string;
    label: string;
    onAction(row: object): void;
  }[];
};

const TableContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
`;

const HeaderContainer = styled(Flex)`
  justify-content: space-between;
  height: 90px;
`;

const Container = styled.div<{ $includePadding: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 32px;

  ${(props) =>
    props.$includePadding &&
    `
      & ${TableContainer} {
        padding: 32px 64px;

        ${props.theme.breakpoints.tablet} {
          padding: 16px 24px;
        }
      }
      & ${HeaderContainer} {
        padding-left: 64px;

        ${props.theme.breakpoints.tablet} {
          padding-left: 24px;
        }
      }
  `}
`;

export const AdminTable: React.FC<AdminTableProps> = ({
  data = [],
  columns = [],
  actions,
  isLoading,
  rowKey,
  entity,
  handleButtonClick,
  buttonTitle,
  onSearch,
  includePadding = false,
}) => {
  const capitalizedEntity = capitalizeFirstLetter(entity);
  const title = capitalizedEntity + (!data.length || data.length > 1 ? "s" : "");

  const columnsWithActions: ColumnsType<object> = [
    ...columns,
    ...(actions
      ? [
          {
            key: "actions",
            title: "Action",
            align: "center",
            render: (_: never, row: object) => (
              <Dropdown
                trigger={["click"]}
                placement="bottom"
                menu={{
                  items: actions,
                  onClick: (event) => {
                    actions.find(({ key }) => key === event.key)?.onAction(row);
                  },
                }}
              >
                <Icon icon={MoreIcon} />
              </Dropdown>
            ),
          },
        ]
      : []),
  ];

  return (
    <Container $includePadding={includePadding}>
      <HeaderContainer>
        <Flex direction="column">
          {isLoading ? (
            <Skeleton count={5} width={150} />
          ) : (
            <>
              <Typography size={32} height={60}>
                {title}
              </Typography>
              <Typography>
                {data.length} {title}
              </Typography>
            </>
          )}
        </Flex>
        {handleButtonClick && (
          <Flex alignSelf="flex-end" onClick={handleButtonClick}>
            <Button type="primary" size="large">
              <Flex columnGap={8} align="center">
                <PlusIcon />
                <Typography size={16} height={24} weight={500}>
                  {buttonTitle || `Create ${capitalizedEntity}`}
                </Typography>
              </Flex>
            </Button>
          </Flex>
        )}
      </HeaderContainer>
      {onSearch && (
        <Flex width="300px">
          <Input.Search
            placeholder="Search"
            allowClear
            enterButton="Search"
            size="large"
            onSearch={onSearch}
            onPressEnter={(event) => onSearch(event.currentTarget.value)}
          />
        </Flex>
      )}
      <TableContainer>
        <Table
          rowKey={rowKey}
          columns={columnsWithActions}
          dataSource={data}
          loading={isLoading}
          pagination={{ position: ["bottomCenter"] }}
          scroll={{ x: breakpoints.tablet }}
        />
      </TableContainer>
    </Container>
  );
};
