import { useMutation } from "@tanstack/react-query";
import { request } from "api/config/axios";
import { queryClient } from "api/config/query";
import { ISolarUser } from "types/common";

const loginUser = async (email: string, password: string) => {
  return request<ISolarUser>({
    url: "/auth/login",
    method: "POST",
    data: { email, password },
  });
};

export const useLogin = () => {
  return useMutation({
    mutationFn: (data: { email: string; password: string }) => {
      return loginUser(data.email, data.password);
    },
    onSuccess: (data) => {
      return queryClient.setQueryData(["me"], data);
    },
  });
};

const logoutUser = async () => {
  return request<ISolarUser>({
    url: "/auth/logout",
    method: "GET",
  });
};

export const useLogout = () => {
  return useMutation({
    mutationFn: () => {
      return logoutUser();
    },
    onSuccess: () => {
      queryClient.clear();
      queryClient.invalidateQueries();
    },
  });
};
