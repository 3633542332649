import React from "react";
import { styled } from "styled-components";
import { Flex, Typography } from "components";
import { PdfRef } from "./PdfRef";

const Answer = styled(Typography)`
  white-space: pre-wrap;

  * {
    display: inline-flex;
    white-space: pre-wrap;
  }
`;

interface ChatItemProps {
  answer?: string;
  pages: number[];
}

export const ChatItem: React.FC<ChatItemProps> = ({ answer = "", pages }) => {
  return (
    <Flex direction="row" columnGap={4} maxWidth="600px">
      <Flex direction="column" rowGap={8}>
        <Answer>
          {answer}
          {pages.map((page) => (
            <PdfRef key={page} page={page} prefix="Seite " pdfFile={""} />
          ))}
        </Answer>
      </Flex>
    </Flex>
  );
};
