import React from "react";
import type { ColumnsType } from "antd/es/table";
import { INodeModelDevice } from "api/modules/devices";
import { Routes } from "constants/routes";
import { NavLink } from "react-router-dom";
import { styled } from "styled-components";

const LinkCell = styled(NavLink)`
  text-decoration: underline;
  color: ${(props) => props.theme.colors.blue500};
`;

export const columns: ColumnsType<INodeModelDevice> = [
  {
    title: "ID",
    dataIndex: "device_id",
    key: "device_id",
    sorter: (a, b) => a.device_id - b.device_id,
  },
  {
    title: "Name",
    dataIndex: "description",
    key: "description",
    sorter: (a, b) => a.description.localeCompare(b.description),
    render: (name, record) => <LinkCell to={`${Routes.AdminDevices}/${record.device_id}`}>{name}</LinkCell>,
  },
  {
    title: "Equipment ID",
    dataIndex: "equip_id",
    key: "equip_id",
    sorter: (a, b) => a.equip_id.localeCompare(b.equip_id),
  },
];
