import React from "react";

export const useStack = <T>(initialList: T[] = []): [T[], { push: (content: T) => void; pop: () => void }] => {
  const [list, setList] = React.useState<T[]>(initialList);

  const push = (item: T) => {
    const newList = [...list, item];
    setList(newList);
    return newList.length;
  };
  const pop = () => {
    if (list.length > 0) {
      const lastItem = list[list.length - 1];
      setList([...list.slice(0, list.length - 1)]);
      return lastItem;
    }
    return undefined;
  };
  return [list, { push, pop }];
};
