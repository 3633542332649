import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { request } from "api/config/axios";
import { IMqttCredentials, INode } from "types/common";
import { queryClient } from "api/config/query";
import { INodeModelDevice } from "../devices";

export interface INodeFormData extends Omit<INode, "node_id"> {}
export interface IMqttNode extends INode, IMqttCredentials {}

const getNode = async () => {
  return request<INode>({
    url: "/nodes/user",
    method: "GET",
  });
};

export const useNode = (options?: UseQueryOptions<INode>) => {
  return useQuery<INode>(["node"], getNode, options);
};

const getNodes = async () => {
  return request<INode[]>({
    url: "/nodes",
    method: "GET",
  });
};

export const useNodes = (options?: UseQueryOptions<INode[]>) => {
  return useQuery<INode[]>(["nodes"], getNodes, options);
};

const getNodeById = async (id: string) => {
  return request<IMqttNode>({
    url: `/nodes/${id}`,
    method: "GET",
  });
};

export const useNodeById = (id: string, enabled = true) => {
  return useQuery<IMqttNode>(["node", id], () => getNodeById(id), { enabled });
};

const getNodeDevices = async (id: string | number) => {
  return request<INodeModelDevice[]>({
    url: `/nodes/${id}/devices`,
    method: "GET",
  });
};

export const useNodeDevices = (id: string | number, enabled = true) => {
  return useQuery<INodeModelDevice[]>(["node-devices", id], () => getNodeDevices(id), { enabled });
};

const createNode = async (data: INodeFormData) => {
  return request<IMqttNode>({
    url: "/nodes",
    method: "POST",
    data,
  });
};

export const useCreateNode = () =>
  useMutation({
    mutationFn: createNode,
  });

const updateNode = async (id: string, data: Partial<INodeFormData>) => {
  return request<IMqttCredentials>({
    url: `/nodes/${id}`,
    method: "PATCH",
    data,
  });
};

export const useUpdateNode = (id: string) =>
  useMutation({
    mutationFn: (data: Partial<INodeFormData>) => updateNode(id, data),
    onSuccess: (data) => {
      queryClient.setQueryData(["node"], data);
    },
  });

const generateNodePassword = async (id: string) => {
  return request<IMqttCredentials>({
    url: `/nodes/${id}/generate`,
    method: "POST",
  });
};

export const useGenerateNodePassword = () =>
  useMutation({
    mutationFn: generateNodePassword,
  });

const deleteNode = async (id: number | string) => {
  return request<boolean>({
    url: `/nodes/${id}`,
    method: "DELETE",
  });
};

export const useDeleteNode = () =>
  useMutation({
    mutationFn: deleteNode,
    onSuccess: () => queryClient.invalidateQueries(["nodes"]),
  });
