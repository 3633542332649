import { ThemeModeContext } from "App";
import { EyeOnIcon, EyeOffIcon } from "assets";
import { Flex, Icon, Input, InputProps, Typography } from "components";
import React from "react";
import { styled } from "styled-components";

const PasswordSwitchContainer = styled(Flex)<{ $withError?: boolean }>`
  position: absolute;
  right: 13px;
  bottom: ${(props) => (props.$withError ? "32px" : 0)};
  height: 44px;
`;

export const PasswordInput: React.FC<InputProps> = (props) => {
  const [showText, setShowText] = React.useState(false);
  const isDarkTheme = React.useContext(ThemeModeContext) === "dark";
  return (
    <Flex position="relative">
      <PasswordSwitchContainer
        align="center"
        columnGap={8}
        onClick={() => setShowText(!showText)}
        $withError={props.hasError && Boolean(props.error)}
      >
        <Icon icon={showText ? EyeOffIcon : EyeOnIcon} color={isDarkTheme ? "blueGrey300" : "grey200"} />
      </PasswordSwitchContainer>
      <Input
        type={showText ? "text" : "password"}
        title={
          <Flex justify="space-between">
            <Typography size={14} weight={500} height={21}>
              Passwort
            </Typography>
            {/* <Flex
              onClick={() => {
                // Some redirection
              }}
            >
              <Typography size={14} weight={500} height={21} color="grey500">
                Forgot your password?
              </Typography>
            </Flex> */}
          </Flex>
        }
        {...props}
      />
    </Flex>
  );
};
