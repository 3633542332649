import React from "react";

export const PowerGridDeviceIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.0551 17.8333L6.10236 22L8.80816 14.5M12.0551 17.8333L18.0079 22L15.3021 14.5M12.0551 17.8333L8.80816 14.5M12.0551 17.8333L15.3021 14.5M8.80816 14.5H5L12.0551 11.0909L19 14.5H15.3021M8.80816 14.5L12.0551 2L15.3021 14.5M8.80816 14.5H15.3021M5 8.25L12.0551 4.61364L19 8.25H5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};
