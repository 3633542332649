import React from "react";

export const CheckIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="56" height="56" rx="28" fill="#E6F4EC" />
      <path d="M21 29L25 33L35 23" stroke="#50AF78" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
