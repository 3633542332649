import React from "react";
import { ColumnsType } from "antd/es/table";
import { Routes } from "constants/routes";
import { NavLink } from "react-router-dom";
import { styled } from "styled-components";
import { INode } from "types/common";

const LinkCell = styled(NavLink)`
  text-decoration: underline;
  color: ${(props) => props.theme.colors.blue500};
`;

export const columns: ColumnsType<INode> = [
  {
    title: "ID",
    dataIndex: "node_id",
    key: "node_id",
    sorter: (a, b) => a.node_id - b.node_id,
  },
  {
    title: "Name",
    dataIndex: "description",
    key: "description",
    sorter: (a, b) => a.description.localeCompare(b.description),
    render: (name, record) => <LinkCell to={`${Routes.AdminNodes}/${record.node_id}`}>{name}</LinkCell>,
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    sorter: (a, b) => a.address.localeCompare(b.address),
  },
  {
    title: "Zip",
    dataIndex: "zip",
    key: "zip",
    sorter: (a, b) => a.zip - b.zip,
  },
];
