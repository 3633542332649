import { useMutation } from "@tanstack/react-query";
import { request } from "api/config/mail-axios";
import { IEmailData } from "types/common";

const sendEmail = async (data: IEmailData) => {
  return request<{ message: string }>({
    url: "/send-mail",
    method: "POST",
    data,
  });
};

export const useSendEmail = () => {
  return useMutation({
    mutationFn: (data: IEmailData) => {
      return sendEmail(data);
    },
  });
};
