import axios, { AxiosRequestConfig } from "axios";

const mailAxios = axios.create({
  baseURL: process.env.REACT_APP_MAILER_URL || "/mailer",
  withCredentials: true,
});

export const request = async <T>(options: AxiosRequestConfig) => {
  const result = await mailAxios<T>(options);
  return result.data;
};
