import React from "react";
import { AdminTable } from "components";
import { INodeModelDevice } from "api/modules/devices";
import { Routes } from "constants/routes";
import { useNavigate } from "react-router-dom";
import { useDeleteModalEntity } from "hooks/useDeleteEntity";
import { useDeleteModel, useDeviceModels } from "api/modules/device-models";
import { columns } from "./columns";

export const AdminModelPage: React.FC = () => {
  const navigate = useNavigate();
  const { data: deviceModels, isLoading } = useDeviceModels();
  const { Component: DeleteModalComponent, openModal } = useDeleteModalEntity(useDeleteModel, "model");

  return (
    <>
      <AdminTable
        data={deviceModels}
        columns={columns}
        isLoading={isLoading}
        entity="model"
        rowKey="model_id"
        handleButtonClick={() => navigate(Routes.AdminNewModel)}
        actions={[
          {
            key: "delete",
            label: "Delete",
            onAction: (model: INodeModelDevice) => openModal(model.model_id),
          },
        ]}
        includePadding
      />
      <DeleteModalComponent />
    </>
  );
};
