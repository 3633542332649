import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { request } from "api/config/axios";
import { IAlarm, IDevice } from "types/common";

export interface IDeviceAlarm extends IDevice, IAlarm {}

const getAlarms = async () => {
  return request<IDeviceAlarm[]>({
    url: "/alarms/user",
    method: "GET",
  });
};

export const useAlarms = (options?: UseQueryOptions<IDeviceAlarm[]>) => {
  return useQuery<IDeviceAlarm[]>(["alarms"], () => getAlarms(), options);
};

const getAlarmById = async (id: string) => {
  return request<IDeviceAlarm>({
    url: `/alarms/user/${id}`,
    method: "GET",
  });
};

export const useAlarm = (id: string) => {
  return useQuery<IDeviceAlarm>(["alarm", id], () => getAlarmById(id));
};
