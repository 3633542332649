import { useMutation, useQuery } from "@tanstack/react-query";
import { request } from "api/config/axios";
import { queryClient } from "api/config/query";
import { IFile, IModel } from "types/common";

export interface ICreateDeviceModelData extends Omit<IModel, "model_id"> {}

export interface IUpdateDeviceModelData extends Partial<ICreateDeviceModelData> {}

const createDeviceModel = async (data: ICreateDeviceModelData) => {
  return request<IModel>({
    url: "/device-models",
    method: "POST",
    data,
  });
};

export const useCreateDeviceModel = () =>
  useMutation({
    mutationFn: createDeviceModel,
    onSuccess: () => {
      queryClient.invalidateQueries(["device-models"]);
    },
  });

const getDeviceModels = async () => {
  return request<IModel[]>({
    url: "/device-models",
    method: "GET",
  });
};

export const useDeviceModels = () => {
  return useQuery<IModel[]>(["device-models"], getDeviceModels);
};

const getModelById = async (id: string) => {
  return request<IModel>({
    url: `/device-models/${id}`,
    method: "GET",
  });
};

export const useModelById = (id: string, enabled = true) => {
  return useQuery<IModel>(["device-models", id], () => getModelById(id), { enabled });
};

const updateModel = async (id: string, data: IUpdateDeviceModelData) => {
  return request<IModel>({
    url: `/device-models/${id}`,
    method: "PATCH",
    data,
  });
};

export const useUpdateModel = (id: string) =>
  useMutation({
    mutationFn: (data: IUpdateDeviceModelData) => updateModel(id, data),
    onSuccess: (data) => {
      queryClient.setQueryData(["device-models", id], data);
    },
  });

const deleteModel = async (id: number | string) => {
  return request<boolean>({
    url: `/device-models/${id}`,
    method: "DELETE",
  });
};

export const useDeleteModel = () =>
  useMutation({
    mutationFn: deleteModel,
    onSuccess: () => queryClient.invalidateQueries(["device-models"]),
  });

const getFilesByModelId = async (id: string) => {
  return request<IFile[]>({
    url: `device-models/${id}/files`,
    method: "GET",
  });
};

export const useFileByModelId = (modelId: string, enabled = true) => {
  return useQuery<IFile[]>(["files", modelId], () => getFilesByModelId(modelId), { enabled });
};

const uploadFiles = async (id: string, data: FormData) => {
  return request({
    url: `device-models/${id}/files`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
};

export const useUploadFiles = (modelId: string) => {
  return useMutation({
    mutationFn: (data: FormData) => uploadFiles(modelId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["files", modelId]);
    },
  });
};

const deleteFiles = async (id: string, data: { id: string; name: string }[]) => {
  return request<boolean>({
    url: `device-models/${id}/files`,
    method: "DELETE",
    data,
  });
};

export const useDeleteFiles = (modelId: string) =>
  useMutation({
    mutationFn: (filesToDelete: { id: string; name: string }[]) => deleteFiles(modelId, filesToDelete),
    onSuccess: () => queryClient.invalidateQueries(["files", modelId]),
  });
