import React from "react";

export const KosterLogoIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="130" height="29" viewBox="0 0 130 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_269_4275)">
        <path d="M13.208 22.8463L17.004 27.6643H25.688L17.108 16.4742L13.208 22.8463Z" fill="#313C4B" />
        <path d="M7.384 0.518082H0V23.7789L7.384 11.4491V0.518082Z" fill="#AA9B8C" />
        <path d="M16.484 0.518082L0 27.6643H7.904L24.544 0.518082H16.484Z" fill="#DC5055" />
        <path
          d="M46.4878 24.4523C47.7878 23.209 48.8278 21.7066 49.5558 19.9452C50.2838 18.1838 50.6478 16.2152 50.6478 14.0912C50.6478 11.9153 50.2838 9.99851 49.5558 8.23711C48.8278 6.47572 47.7878 5.02516 46.4878 3.78182C45.1878 2.53848 43.6278 1.60598 41.8078 0.984309C39.9878 0.310834 38.0118 0 35.8798 0C33.7998 0 31.8238 0.310834 30.0558 0.984309C28.2358 1.65778 26.7278 2.59029 25.3758 3.78182C24.0758 5.02516 23.0358 6.47572 22.3078 8.23711C21.5798 9.99851 21.2158 11.9153 21.2158 14.0912C21.2158 16.2152 21.5798 18.1838 22.3078 19.9452C23.0358 21.7066 24.0758 23.209 25.3758 24.4523C26.6758 25.6956 28.2358 26.6799 30.0558 27.3534C31.8758 28.0269 33.7998 28.3895 35.9318 28.3895C38.0638 28.3895 39.9878 28.0269 41.8078 27.3534C43.5758 26.6799 45.1358 25.6956 46.4878 24.4523ZM39.0518 21.8102C38.1158 22.2247 37.0758 22.4319 35.9318 22.4319C34.7878 22.4319 33.7478 22.2247 32.8118 21.8102C31.8758 21.3958 31.0438 20.8259 30.3678 20.1006C29.6918 19.3753 29.1718 18.4946 28.8078 17.4585C28.4438 16.4224 28.2358 15.2827 28.2358 14.0912C28.2358 12.8996 28.4438 11.8117 28.8078 10.8274C29.1718 9.79128 29.6918 8.96239 30.3678 8.23711C31.0438 7.51183 31.8758 6.94197 32.8118 6.52752C33.7478 6.11308 34.7878 5.90585 35.9318 5.90585C37.0758 5.90585 38.1158 6.11308 39.1038 6.52752C40.0398 6.94197 40.8718 7.51183 41.5478 8.23711C42.2238 8.96239 42.7438 9.84309 43.1078 10.8274C43.4718 11.8635 43.6798 12.9514 43.6798 14.0912C43.6798 15.3345 43.4718 16.4224 43.1078 17.4585C42.7438 18.4946 42.1718 19.3753 41.4958 20.1006C40.7678 20.8259 39.9878 21.3958 39.0518 21.8102Z"
          fill="#DC5055"
        />
        <path
          d="M69.3159 16.008C68.7959 15.1273 68.1199 14.3502 67.2879 13.7285C66.4559 13.1069 65.5719 12.6406 64.5839 12.278C63.5959 11.9153 62.6599 11.5527 61.7239 11.2937C61.0999 11.0864 60.5279 10.8792 60.0079 10.7238C59.4879 10.5166 59.0719 10.3093 58.7079 10.1021C58.3439 9.8949 58.0839 9.58407 57.8759 9.32504C57.6679 9.0142 57.5639 8.65156 57.5639 8.18531C57.5639 7.66725 57.6679 7.201 57.9279 6.89017C58.1879 6.52753 58.4479 6.2685 58.8119 6.06128C59.1759 5.85405 59.5919 5.69864 60.0079 5.64683C60.4759 5.59503 60.8919 5.54322 61.3079 5.54322C61.8799 5.54322 62.4519 5.64683 63.0759 5.85405C63.0759 5.85405 66.2479 0.880704 66.4039 0.673481C65.5199 0.466258 63.1279 0.20723 61.4639 0.20723C60.1639 0.20723 58.9159 0.362647 57.6679 0.673481C56.4199 0.984316 55.2759 1.50237 54.2879 2.17585C53.2999 2.84932 52.4679 3.73002 51.8959 4.81794C51.2719 5.90586 50.9599 7.1492 50.9599 8.65156C50.9599 9.8949 51.1679 10.8792 51.5319 11.7599C51.9479 12.5888 52.4679 13.3141 53.0919 13.9357C53.7159 14.5574 54.4959 15.0237 55.3279 15.3863C56.1599 15.7489 56.9919 16.1116 57.8759 16.3706C58.7599 16.6296 59.5399 16.8887 60.2679 17.1477C60.9439 17.4067 61.5679 17.6658 62.0359 17.9248C62.5039 18.2356 62.8679 18.5465 63.1279 18.9091C63.3879 19.2717 63.4919 19.6862 63.4919 20.2042C63.4919 20.7223 63.3879 21.1885 63.1799 21.5512C62.9719 21.9138 62.7119 22.2247 62.3479 22.4837C61.9839 22.6909 61.6199 22.8981 61.1519 23.0018C60.6839 23.1054 60.2159 23.1572 59.7479 23.1572C58.6559 23.1572 57.1479 22.7945 56.1079 22.2765C55.0159 21.7066 53.1959 20.2042 52.4159 19.3753L49.2959 24.2969C50.8559 25.9029 52.3639 26.8872 54.1839 27.5607C56.0039 28.2341 57.8759 28.545 59.7999 28.545C61.0999 28.545 62.3999 28.3895 63.6479 28.0269C64.8959 27.6643 65.9879 27.1462 66.9759 26.3691C67.9639 25.6438 68.6919 24.7113 69.2639 23.5716C69.8359 22.4319 70.1479 21.0849 70.1479 19.5308C70.1479 18.0284 69.8879 16.8887 69.3159 16.008Z"
          fill="#DC5055"
        />
        <path
          d="M88.6075 0.518051H87.7235H87.4115H68.8475L65.3115 6.11307H73.7355V27.6643H80.2875V6.11307H85.0195L88.6075 0.518051Z"
          fill="#DC5055"
        />
        <path
          d="M129.168 27.6643L122.044 16.0598C123.812 15.5417 125.216 14.6092 126.204 13.3659C127.192 12.1225 127.712 10.5684 127.712 8.70336C127.712 7.14919 127.4 5.85404 126.828 4.76612C126.256 3.73001 125.424 2.84931 124.436 2.22764C123.448 1.60597 122.304 1.13972 121.108 0.880693C119.86 0.621665 118.56 0.466248 117.26 0.466248H110.396L106.86 6.00946V27.6124H113.308V16.8887H115.544L121.472 27.6643H129.168ZM120.744 10.5166C120.432 10.931 120.068 11.2936 119.6 11.5009C119.132 11.7081 118.612 11.9153 118.04 11.9671C117.468 12.0707 116.896 12.1225 116.376 12.1225H113.256V5.80224H116.792C117.26 5.80224 117.78 5.85404 118.3 5.90585C118.82 5.95765 119.288 6.11307 119.756 6.3721C120.172 6.57932 120.536 6.89016 120.796 7.3046C121.056 7.71905 121.212 8.23711 121.212 8.85878C121.212 9.53225 121.056 10.1021 120.744 10.5166Z"
          fill="#DC5055"
        />
        <path
          d="M87.4121 27.6643H104.988V22.0692H93.8081V16.4224H103.012V11.19H93.8081V6.00946H104.468L107.952 0.518051H106.964H106.86H91.0001L87.4121 6.16487V27.6643Z"
          fill="#DC5055"
        />
      </g>
      <defs>
        <clipPath id="clip0_269_4275">
          <rect width="130" height="28.4932" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
