import React from "react";
import { Flex, FlowLine, Icon, IconProps, UnitLabel } from "components";
import { IHomeMetrics } from "pages";
import { IDataMetric } from "types/common";
import { HomeIcon } from "assets";
import { DeviceType } from "constants/device-types";
import { Metrics } from "constants/metrics";
import { useBreakpoints } from "hooks/useBreakpoints";
import { useNavigate } from "react-router-dom";
import { Routes } from "constants/routes";
import { DeviceIconMap } from "utils/icons";

import { CARD_TOP_OFFSET, Styles } from "../../styles";

const EnergyFlowCard: React.FC<{
  icon: IconProps["icon"];
  label: React.ReactNode;
  title: string;
  active?: boolean;
  isDark: boolean;
  isEmptyDevices: boolean;
  onClick?(): void;
  noAnimation?: boolean;
}> = ({ icon, label, title, active = false, isDark, isEmptyDevices, onClick, noAnimation }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  return (
    <div ref={ref}>
      <Styles.FlowCard $active={active} onClick={onClick}>
        <Flex justify="space-between" wrap="wrap" rowGap={8}>
          {icon ? <Icon icon={icon} color={isDark ? "blueGrey100" : "grey400"} /> : <div />}
          {label}
        </Flex>
        <Flex direction="column" rowGap={8}>
          <Styles.FlowCardTitle color={isDark ? "white" : "grey800"}>{title}</Styles.FlowCardTitle>
        </Flex>
        {!isEmptyDevices && (
          <Styles.AbsoluteDiv left={ref.current ? ref.current.clientWidth / 2 - 1 : 0} top={-CARD_TOP_OFFSET - 1}>
            <FlowLine
              vertical
              lineColors={noAnimation ? ["#B9B2A8"] : ["#FFB088"]}
              color="#FFB088"
              size={24}
              noFlow
              noAnimation={noAnimation}
            />
          </Styles.AbsoluteDiv>
        )}
      </Styles.FlowCard>
    </div>
  );
};

interface IDevicesProps {
  metrics: IHomeMetrics;
  devices: IDataMetric[];
  lineWidth: number;
  lineOffsetLeft: number;
  lineCenter: number;
  lineHeight: number;
}

export const Devices = React.forwardRef<HTMLDivElement, IDevicesProps & { isDarkTheme: boolean }>(
  ({ metrics, devices, lineCenter, lineOffsetLeft, lineWidth, lineHeight, isDarkTheme }, ref) => {
    const powerGridValue = +(metrics[DeviceType.PowerGrid]?.[Metrics.Power]?.value || 0);
    const batteryValue = +(metrics[DeviceType.Battery]?.[Metrics.Power]?.value || 0);
    const solarValue = +(metrics[DeviceType.SolarPanel]?.[Metrics.Power]?.value || 0);
    const heatpumpValue = +(metrics[DeviceType.Heatpump]?.[Metrics.Power]?.value || 0);
    const chargerValue = +(metrics[DeviceType.Charger]?.[Metrics.Power]?.value || 0);

    const deviceTotal = devices.reduce((acc, device) => acc + +(device.float_value || 0).toFixed(2), 0);

    const restDeviceNumberValue =
      solarValue + powerGridValue + batteryValue - chargerValue - heatpumpValue - deviceTotal;
    const restDeviceValue = restDeviceNumberValue <= 0 ? 0 : restDeviceNumberValue.toFixed(2);
    const totalDevicePower = +(deviceTotal + +restDeviceValue).toFixed(2);
    const powerUnit = "kW";

    const isEmptyDevices = devices.length === 0;

    const { mobile } = useBreakpoints();
    const navigate = useNavigate();

    return (
      <Styles.RelativeDiv>
        <Styles.AbsoluteDiv
          left={lineCenter + lineOffsetLeft}
          top={(isEmptyDevices ? 0 : -CARD_TOP_OFFSET) - lineHeight}
        >
          <FlowLine
            vertical
            lineColors={totalDevicePower ? ["#FFB088", "#DE3A11"] : ["#B9B2A8"]}
            color="orange500"
            size={lineHeight}
            noFlow={totalDevicePower === 0}
            noAnimation={totalDevicePower === 0}
          />
          <Styles.AbsoluteDiv right={mobile ? -31 : -37} top={lineHeight - 45} $zIndex={1}>
            <Styles.PowerLabel isDarkTheme={isDarkTheme} value={totalDevicePower} unit={powerUnit} />
          </Styles.AbsoluteDiv>
        </Styles.AbsoluteDiv>
        {!isEmptyDevices && (
          <Styles.AbsoluteDiv left={lineOffsetLeft} top={-CARD_TOP_OFFSET}>
            <FlowLine
              reverse
              lineColors={totalDevicePower ? ["#FFB088"] : ["#B9B2A8"]}
              color="orange500"
              size={lineCenter}
              delay={6}
              noFlow={totalDevicePower === 0}
              noAnimation={totalDevicePower === 0}
            />
          </Styles.AbsoluteDiv>
        )}
        {lineWidth - lineCenter > 0 && (
          <Styles.AbsoluteDiv left={lineOffsetLeft + lineCenter} top={-CARD_TOP_OFFSET}>
            <FlowLine
              lineColors={totalDevicePower ? ["#FFB088"] : ["#B9B2A8"]}
              color="orange500"
              size={lineWidth - lineCenter}
              delay={6}
              noFlow={totalDevicePower === 0}
              noAnimation={totalDevicePower === 0}
            />
          </Styles.AbsoluteDiv>
        )}
        <Styles.FlowCardContainer ref={ref} $deviceCount={devices.length}>
          {devices.map((device) => {
            const deviceValue = device.float_value ? device.float_value.toFixed(2) : 0;
            return (
              <EnergyFlowCard
                icon={DeviceIconMap[device.icon]}
                title={device.label}
                label={<UnitLabel isDarkTheme={isDarkTheme} value={deviceValue} unit={device.unit} />}
                active={!!deviceValue}
                isDark={isDarkTheme}
                key={device.device_id}
                isEmptyDevices={isEmptyDevices}
                noAnimation={!deviceValue}
                onClick={() => navigate(`${Routes.Devices}/${device.device_id}`)}
              />
            );
          })}
          <EnergyFlowCard
            icon={HomeIcon}
            title="Restlicher Verbrauch"
            label={<UnitLabel isDarkTheme={isDarkTheme} value={restDeviceValue} unit={powerUnit} />}
            active={!!+restDeviceValue}
            isDark={isDarkTheme}
            isEmptyDevices={isEmptyDevices}
            noAnimation={!+restDeviceValue}
            // onClick={() => navigate(`${Routes.Devices}/8`)}
          />
        </Styles.FlowCardContainer>
      </Styles.RelativeDiv>
    );
  },
);
