import React from "react";

export const RadiatorIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.50008 6.75L1.5 7.75309V9.76016L6.50008 10.7501V6.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4999 26.0719C18.4999 27.0024 17.7206 27.7504 16.75 27.7504C15.7932 27.7504 15 27.0022 15 26.0719L15.0001 5.92844C15.0001 5.01037 15.7933 4.25 16.7501 4.25C17.721 4.25 18.5001 5.01041 18.5001 5.92844L18.4999 26.0719Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4999 26.0719C12.4999 27.0024 11.7206 27.7504 10.75 27.7504C9.79316 27.7504 9 27.0022 9 26.0719L9.00013 5.92844C9.00013 5.01037 9.79329 4.25 10.7501 4.25C11.721 4.25 12.5001 5.01041 12.5001 5.92844L12.4999 26.0719Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5001 26.0719C24.5001 27.0024 23.7207 27.7504 22.75 27.7504C21.7928 27.7504 21 27.0022 21 26.0719V5.92844C21 5.01037 21.7932 4.25 22.75 4.25C23.721 4.25 24.5001 5.01041 24.5001 5.92844V26.0719Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.4999 26.0719C30.4999 27.0024 29.7206 27.7504 28.75 27.7504C27.7928 27.7504 27 27.0022 27 26.0719L27.0001 5.92844C27.0001 5.01037 27.7933 4.25 28.7501 4.25C29.721 4.25 30.5001 5.01041 30.5001 5.92844L30.4999 26.0719Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
