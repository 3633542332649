import React from "react";
import { Bar, Line } from "react-chartjs-2";
import { styled } from "styled-components";
import { useBreakpoints } from "hooks/useBreakpoints";
import { TimeRangeId } from "constants/time-range";
import { ActiveElement, ChartData, ChartOptions } from "chart.js";
import { ThemeModeContext } from "App";
import { Flex, Typography } from "components";
import { IChartData } from "types/common";
import dayjs from "dayjs";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { getDatasets, getOptions } from "./options";

const Container = styled.div`
  width: 100%;
  // ${(props) => props.theme.breakpoints.mobile} {
  overflow: auto;
  // }
`;
const ContainerBody = styled.div<{ $width: number }>`
  height: 400px;
  position: relative;

  ${(props) => props.theme.breakpoints.mobile} {
    height: 450px;
    // min-width: ${(props) => `${props.$width}px`};
  }
`;

const DraggableCircle = styled.div`
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.grey300};
  background-color: ${(props) => props.theme.colors.white};

  #dark-theme & {
    background-color: ${(props) => props.theme.colors.blueGrey600};
    border: 1px solid ${(props) => props.theme.colors.blueGrey300};
  }
`;

const DraggableLine = styled.div`
  width: 1px;
  height: 357px;
  background-color: ${(props) => props.theme.colors.grey300};

  ${(props) => props.theme.breakpoints.mobile} {
    height: 407px;
  }

  #dark-theme & {
    background-color: ${(props) => props.theme.colors.blueGrey300};
  }
`;

const DraggableLabel = styled.div`
  padding: 4px 8px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.grey300};
  background-color: ${(props) => props.theme.colors.grey100};
  user-select: none;
  min-width: 52px;
  box-sizing: border-box;
  text-align: center;

  #dark-theme & {
    background-color: ${(props) => props.theme.colors.blueGrey800};
    border: 1px solid ${(props) => props.theme.colors.blueGrey300};
  }
`;

const DraggableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 24.5px;
  cursor: grab;
`;

const LegendContainer = styled.div`
  display: grid;
  gap: 8px 16px;
  grid-template-columns: 1fr 1fr;
  max-width: 400px;
  min-height: 48px;
  margin-bottom: 60px;

  ${(props) => props.theme.breakpoints.mobile} {
    grid-template-columns: 1fr;
  }
`;

const LegendCircle = styled.div<{ $color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.$color}; // props.theme.colors[props.$color]};
`;

type EnergyBalanceChartProps = {
  labels: string[];
  timeRange: TimeRangeId;
  chartsData?: IChartData;
  isBatteryConnected: boolean;
};

const EnergyBalanceChartComponent: React.FC<EnergyBalanceChartProps> = ({
  labels,
  timeRange,
  chartsData,
  isBatteryConnected,
}) => {
  const { mobile } = useBreakpoints();

  const lineRef = React.useRef<ChartJSOrUndefined<"line">>(null);
  const circleRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [activeElements, setActiveElements] = React.useState<ActiveElement[]>([]);

  React.useEffect(() => {
    const circle = circleRef.current;
    const container = containerRef.current;
    const chart = lineRef.current;
    if (circle && container && chart) {
      const columnWidth = chart.chartArea.width / (chart.data.labels!.length - 1);

      const minX = chart.chartArea.left - circle.getBoundingClientRect().width / 2;
      const maxX = chart.chartArea.right - circle.getBoundingClientRect().width / 2 - 1;

      const onMouseDown = (event: MouseEvent | TouchEvent) => {
        document.body.style.cursor = "grabbing";
        circle.style.cursor = "grabbing";

        const innerLeft =
          ("touches" in event ? event.touches[0].clientX : event.clientX) - circle.getBoundingClientRect().left;

        const onMouseMove = (e: MouseEvent | TouchEvent) => {
          const pageLeft = ("touches" in e ? e.touches[0].clientX : e.clientX) - container.getBoundingClientRect().left;
          const positionX = pageLeft - innerLeft;

          const circleLeft = Math.min(Math.max(positionX, minX), maxX);
          circle.style.left = `${circleLeft}px`;

          const activeIndex = Math.floor((circleLeft - minX) / columnWidth);
          chart.setActiveElements([{ datasetIndex: 0, index: activeIndex }]);
          setActiveElements(chart.getActiveElements());
        };

        const onMouseUp = () => {
          document.body.style.cursor = "";
          circle.style.cursor = "";
          container.removeEventListener("mousemove", onMouseMove);
          container.removeEventListener("touchmove", onMouseMove);
          document.removeEventListener("mouseup", onMouseUp);
          document.removeEventListener("touchend", onMouseUp);
        };

        container.addEventListener("mousemove", onMouseMove);
        container.addEventListener("touchmove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
        document.addEventListener("touchend", onMouseUp);
        circle.addEventListener("dragstart", () => false);
      };

      circle.addEventListener("mousedown", onMouseDown);
      circle.addEventListener("touchstart", onMouseDown);

      return () => {
        circle.removeEventListener("mousedown", onMouseDown);
        circle.removeEventListener("touchstart", onMouseDown);
      };
    }
    return () => {};
  }, [circleRef.current, containerRef.current, lineRef.current]);

  React.useEffect(() => {
    const chart = lineRef.current;
    const circle = circleRef.current;
    if (chart && circle) {
      const currentDate = dayjs().subtract(1, "m");
      const currentLabelIndex = chart.data.labels?.findIndex(
        (label) => label === currentDate.subtract(currentDate.minute() % 5, "m").format("H:mm"),
      );
      if (currentLabelIndex && currentLabelIndex > -1) {
        chart.setActiveElements([{ datasetIndex: 0, index: currentLabelIndex }]);
        setActiveElements(chart.getActiveElements());
        const columnWidth = chart.chartArea.width / (chart.data.labels!.length - 1);
        const minX = chart.chartArea.left - circle.getBoundingClientRect().width / 2;
        circle.style.left = `${columnWidth * currentLabelIndex + minX}px`;
      }
    }
  }, [lineRef.current, circleRef.current]);

  const isDaySelected = timeRange === TimeRangeId.Day;

  const theme = React.useContext(ThemeModeContext);
  const isDarkTheme = theme === "dark";

  const options: ChartOptions = React.useMemo(() => {
    return getOptions(mobile, isDaySelected, isDarkTheme);
  }, [mobile, isDaySelected, isDarkTheme]);

  const data: ChartData | null = React.useMemo(() => {
    if (!chartsData) {
      return null;
    }
    return {
      labels,
      datasets: getDatasets(labels, timeRange, chartsData, isBatteryConnected),
    };
  }, [labels, timeRange, chartsData, isBatteryConnected]);

  // const plugins: Plugin[] = React.useMemo(() => {
  //   return getPlugins(isDaySelected, isDarkTheme);
  // }, [isDaySelected, isDarkTheme]);

  if (!theme || !data) {
    return null;
  }

  return (
    <Container ref={containerRef}>
      {activeElements[0]?.index !== undefined && (
        <LegendContainer>
          {lineRef.current?.data.datasets.map((dataset) => (
            <Flex columnGap={8} key={dataset.label} align="center">
              <LegendCircle $color={dataset.borderColor as string} />
              <Flex columnGap={2}>
                <Typography size={14} height={20} weight={600}>
                  {`${dataset.data[activeElements?.[0].index] || 0}`}
                  {dataset.yAxisID === "y1" ? "%" : " kW"}
                </Typography>
                <Typography size={14} height={20} weight={400}>
                  {dataset.label}
                </Typography>
              </Flex>
            </Flex>
          ))}
        </LegendContainer>
      )}
      <ContainerBody $width={labels.length * 30}>
        {isDaySelected && (
          <DraggableContainer ref={circleRef}>
            {lineRef.current && activeElements[0] && (
              <DraggableLabel>
                <Typography size={14} height={20} weight={400}>
                  {lineRef.current.data.labels?.[activeElements[0].index] as string}
                </Typography>
              </DraggableLabel>
            )}
            <DraggableLine />
            <DraggableCircle />
          </DraggableContainer>
        )}
        {isDaySelected ? (
          <Line data={data as ChartData<"line">} options={options as ChartOptions<"line">} redraw ref={lineRef} />
        ) : (
          <Bar data={data as ChartData<"bar">} options={options as ChartOptions<"bar">} redraw />
        )}
      </ContainerBody>
    </Container>
  );
};

export const EnergyBalanceChart = React.memo(EnergyBalanceChartComponent);
