import { SetThemeModeContext, ThemeModeContext } from "App";
import { NotificationIcon, CloseIcon, MenuIcon } from "assets";
import { Flex, IconButton, Logo } from "components";
// import { Routes } from "constants/routes";
import { HeaderActiveElement } from "layouts";
import React from "react";
// import { Link } from "react-router-dom";
import { styled } from "styled-components";

const Container = styled(Flex)`
  padding: 40px 0;
  position: sticky;
  top: 0;
  background: ${(props) => props.theme.colors.grey50};
  z-index: 1000;

  #dark-theme & {
    background-color: ${(props) => props.theme.colors.blueGrey800};
  }

  > div {
    padding: 0 32px;
  }

  ${(props) => props.theme.breakpoints.tablet} {
    padding: 16px 0;

    > div {
      padding: 0 16px;
    }
  }
`;

const NotificationBadge = styled.div`
  width: 4px;
  height: 4px;
  position: absolute;
  background: ${(props) => props.theme.colors.red500};
  right: 11px;
  top: 10px;
  border: 2px solid ${(props) => props.theme.colors.grey100};
  border-radius: 50%;
`;

// const RedirectLink = styled(Link)`
//   display: flex;
// `;

export const Header: React.FC<{
  activeElement: HeaderActiveElement;
  toggleActiveElement: (element: HeaderActiveElement) => void;
  hasNotification: boolean;
  handleCloseMenu: () => void;
  handleOpenMenu: () => void;
}> = ({ toggleActiveElement, activeElement, hasNotification, handleCloseMenu, handleOpenMenu }) => {
  const setTheme = React.useContext(SetThemeModeContext);
  const theme = React.useContext(ThemeModeContext);
  return (
    <Container justify="center" width="100%">
      <Flex maxWidth="1200px" width="100%" justify="space-between" align="center">
        <IconButton
          icon={activeElement === "menu" ? CloseIcon : MenuIcon}
          color="grey300"
          onClick={activeElement === "menu" ? handleCloseMenu : () => handleOpenMenu()}
        />
        {/* <RedirectLink to={Routes.Overview}> */}
        <Flex
          onClick={() => {
            const themeId = theme === "dark" ? "light" : "dark";
            setTheme?.(themeId);
            document.body.id = `${themeId}-theme`;
          }}
        >
          <Logo />
        </Flex>
        {/* </RedirectLink> */}
        <Flex position="relative">
          {hasNotification && activeElement !== "notifications" && <NotificationBadge />}
          <IconButton
            icon={activeElement === "notifications" ? CloseIcon : NotificationIcon}
            color="grey300"
            onClick={() => toggleActiveElement("notifications")}
          />
        </Flex>
      </Flex>
    </Container>
  );
};
