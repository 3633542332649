import React from "react";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import {
  OverviewPage,
  ScenePage,
  LoginPage,
  NotFoundPage,
  ErrorPage,
  HelpPage,
  ServicePage,
  DeviceDetailPage,
  DevicePage,
  AdminUserPage,
  AdminDevicePage,
  AdminNodePage,
  AdminUserDetailsPage,
  AdminNodeDetailsPage,
  AdminDeviceDetailsPage,
  AdminModelDetailsPage,
  AdminModelPage,
} from "pages";
import { Routes } from "constants/routes";
import { AdminLayout, PageLayout } from "layouts";
// import { MockDevicePage } from "pages/MockDevicePage";

const router = createBrowserRouter([
  {
    element: <PageLayout />,
    errorElement: (
      <PageLayout>
        <ErrorPage />
      </PageLayout>
    ),
    children: [
      {
        path: Routes.Overview,
        element: <OverviewPage />,
      },
      {
        path: Routes.Scenes,
        element: <ScenePage />,
      },
      {
        path: Routes.Devices,
        children: [
          {
            index: true,
            element: <DevicePage />,
          },
          {
            path: ":deviceId",
            element: <DeviceDetailPage />,
          },
        ],
      },
      {
        path: Routes.Login,
        element: <LoginPage />,
      },
      {
        path: Routes.Help,
        element: <HelpPage />,
      },
      {
        path: Routes.Service,
        element: <ServicePage />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
  {
    path: Routes.Admin,
    element: (
      <PageLayout>
        <AdminLayout />
      </PageLayout>
    ),
    children: [
      {
        index: true,
        element: <Navigate to={Routes.AdminUsers} />,
      },
      {
        path: Routes.AdminUsers,
        children: [
          {
            index: true,
            element: <AdminUserPage />,
          },
          {
            path: Routes.AdminNewUser,
            element: <AdminUserDetailsPage isNew />,
          },
          {
            path: ":userId",
            element: <AdminUserDetailsPage />,
          },
        ],
      },
      {
        path: Routes.AdminDevices,
        children: [
          {
            index: true,
            element: <AdminDevicePage />,
          },
          {
            path: Routes.AdminNewDevice,
            element: <AdminDeviceDetailsPage isNew />,
          },
          {
            path: ":deviceId",
            element: <AdminDeviceDetailsPage />,
          },
        ],
      },
      {
        path: Routes.AdminNodes,
        children: [
          {
            index: true,
            element: <AdminNodePage />,
          },
          {
            path: Routes.AdminNewNode,
            element: <AdminNodeDetailsPage isNew />,
          },
          {
            path: ":nodeId",
            element: <AdminNodeDetailsPage />,
          },
        ],
      },
      {
        path: Routes.AdminModels,
        children: [
          {
            index: true,
            element: <AdminModelPage />,
          },
          {
            path: Routes.AdminNewModel,
            element: <AdminModelDetailsPage isNew />,
          },
          {
            path: ":modelId",
            element: <AdminModelDetailsPage />,
          },
        ],
      },
    ],
  },
]);

export const Router: React.FC = () => {
  return <RouterProvider router={router} />;
};
