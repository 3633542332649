import React from "react";

export const Battery40Icon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="95" height="93" viewBox="0 0 95 93" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.5"
        d="M48.6749 92.0362L0 63.9962L45.8429 36.5117L94.519 64.5518L48.6749 92.0362Z"
        fill="url(#paint0_linear_1291_8129)"
      />
      <path
        d="M45.7191 69.1801C32.6452 68.6815 28.3193 63.3071 27.2627 60.8692V9.00905C29.2646 4.22195 34.9924 1.91706 37.606 1.363C52.1533 -2.22732 60.3501 4.9644 62.6301 9.00905V60.2043C58.2258 68.1828 48.8888 69.1801 45.7191 69.1801Z"
        fill="url(#paint1_linear_1291_8129)"
      />
      <path
        d="M45.7191 69.1801C32.6452 68.6815 28.3193 63.3071 27.2627 60.8692V44.009C29.2646 39.2219 34.9924 36.9171 37.606 36.363C52.1533 32.7727 60.3501 39.9644 62.6301 44.009V60.2043C58.2258 68.1828 48.8888 69.1801 45.7191 69.1801Z"
        fill="#8EEDC7"
      />
      <path
        d="M42.4156 55.3656C29.589 53.7012 26.8175 47.0988 27.3172 43.548C30.1157 37.1564 38.5885 35.2257 42.4751 35.0592C54.7348 34.1271 61.0199 40.33 62.6301 43.548V46.7104C61.4641 49.8729 53.6304 56.8209 42.4156 55.3656Z"
        fill="#65D6AD"
      />
      <path
        d="M44.7181 31.4755C36.0431 31.2567 27.8744 25.1602 27.2627 22.3349V9.0394C28.3749 6.26951 33.3352 0.663252 44.2791 0.397342C55.2229 0.131433 61.073 6.04792 62.6301 9.0394V22.8335C58.4927 30.013 49.1112 31.5863 44.7181 31.4755Z"
        fill="#B9B2A8"
      />
      <path
        d="M42.4156 20.7895C29.589 19.125 26.8175 12.5226 27.3172 8.97179C30.1157 2.58026 38.5885 0.649481 42.4751 0.483034C54.7348 -0.449064 61.0199 5.75383 62.6301 8.97179V12.1343C61.4641 15.2967 53.6304 22.2448 42.4156 20.7895Z"
        fill="#A39E93"
      />
      <path
        d="M34.2588 10.7511V5.29464C36.1004 1.98771 40.1783 0.720055 41.987 0.499596C50.8004 -0.294066 54.7577 3.3656 55.6347 5.29464V10.7511C52.2855 15.0501 47.1882 15.3808 45.1112 15.5461C38.7971 15.2816 35.2454 12.2392 34.2588 10.7511Z"
        fill="#857F72"
      />
      <path
        d="M44.7823 12.4276C38.2051 12.4276 35.0261 9.07391 34.2588 7.39708V4.88184C35.7058 1.12575 41.5485 0.0749281 44.289 0.0190297C50.6031 -0.249263 53.6615 2.3666 55.6347 4.88184V7.39708C52.2145 11.6898 46.9747 12.5394 44.7823 12.4276Z"
        fill="white"
      />
      <path
        d="M46.3074 44.4711V38L38 48.1688H44.4613L44.4613 54.6398L52.7688 44.4711L46.3074 44.4711Z"
        fill="#0C6B58"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1291_8129"
          x1="65.6827"
          y1="82.2378"
          x2="-14.8151"
          y2="18.4261"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3CEC4" />
          <stop offset="1" stopColor="#E8E6E1" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint1_linear_1291_8129" x1="84" y1="56" x2="20" y2="57" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D3CEC4" />
          <stop offset="0.5" stopColor="#E8E6E1" stopOpacity="0" />
          <stop offset="1" stopColor="#E8E6E1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
