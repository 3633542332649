import { ChartOptions } from "chart.js";
import { TimeRangeId } from "constants/time-range";
import dayjs from "dayjs";
import { IChartData, IChartRows } from "types/common";

// Support for Firefox..
CanvasRenderingContext2D.prototype.roundRect = function roundRect(x, y, w, h, r: number) {
  this.beginPath();
  this.moveTo(x + r, y);
  this.arcTo(x + w, y, x + w, y + h, r);
  this.arcTo(x + w, y + h, x, y + h, r);
  this.arcTo(x, y + h, x, y, r);
  this.arcTo(x, y, x + w, y, r);
  this.closePath();
  return this;
};

// let selectedElement: ActiveElement | null = null;
// let selectedLabel: string;
// // let circleClicked = false;

// const CIRCLE_RADIUS = 16;
// const BOTTOM_LINE_OFFSET = 50;
// const TOP_LINE_OFFSET = 10;

// const TIME_RECT_WIDTH = 52;
// const TIME_RECT_HEIGHT = 26;
// const VERTICAL_GAP = 24;

// const drawElementLine = (chart: Chart, elementX: number, isDarkTheme: boolean) => {
//   const { ctx } = chart;
//   const topY = chart.scales.y.top;
//   const bottomY = chart.scales.y.bottom;
//   ctx.save();

//   ctx.beginPath();
//   ctx.strokeStyle = "#B9B2A8";
//   ctx.roundRect(
//     elementX - TIME_RECT_WIDTH / 2,
//     topY - TOP_LINE_OFFSET - TIME_RECT_HEIGHT,
//     TIME_RECT_WIDTH,
//     TIME_RECT_HEIGHT,
//     5,
//   );
//   ctx.fillStyle = "#E8E6E1";
//   ctx.fill();
//   ctx.stroke();

//   if (selectedLabel) {
//     ctx.beginPath();
//     ctx.textAlign = "center";
//     ctx.textBaseline = "middle";
//     ctx.fillStyle = "#27241D";
//     ctx.fillText(selectedLabel, elementX, topY - TOP_LINE_OFFSET - TIME_RECT_HEIGHT / 2);
//     ctx.stroke();
//   }

//   ctx.beginPath();
//   ctx.moveTo(elementX, topY - TOP_LINE_OFFSET);
//   ctx.lineTo(elementX, bottomY + BOTTOM_LINE_OFFSET);
//   ctx.lineWidth = 1;
//   ctx.strokeStyle = isDarkTheme ? "#9BA5BA" : "#B9B2A8";
//   ctx.stroke();

//   ctx.beginPath();
//   ctx.arc(elementX, bottomY + BOTTOM_LINE_OFFSET + CIRCLE_RADIUS, CIRCLE_RADIUS, 0, 2 * Math.PI);
//   ctx.fillStyle = isDarkTheme ? "#58647F" : "white";
//   ctx.fill();
//   ctx.stroke();

//   ctx.restore();
// };

// const LEGEND_OFFSET = 10;
// const LEGEND_CIRCLE_RADIUS = 4;
// const LEGEND_ITEM_GAP = 8;
// const LEGEND_GAP = 200;

// const drawLegendElement = (params: {
//   chart: Chart;
//   dataset: ChartDataset<"line" | "bar">;
//   datasetIndex: number;
//   topOffset: number;
//   index?: number;
//   isDarkTheme: boolean;
// }) => {
//   const { chart, dataset, datasetIndex, topOffset, index, isDarkTheme } = params;
//   if (dataset.data[index ?? -1] === undefined || !dataset.label) {
//     return;
//   }

//   const { ctx } = chart;
//   ctx.save();

//   ctx.beginPath();
//   ctx.arc(
//     LEGEND_OFFSET + datasetIndex * LEGEND_GAP,
//     LEGEND_OFFSET + LEGEND_CIRCLE_RADIUS + topOffset,
//     LEGEND_CIRCLE_RADIUS,
//     0,
//     2 * Math.PI,
//   );
//   ctx.strokeStyle = dataset.borderColor as string;
//   ctx.fillStyle = dataset.borderColor as string;
//   ctx.fill();
//   ctx.stroke();

//   const value = `${
//     index !== undefined
//       ? dataset.data[index]
//       : dataset.data.reduce((total: number, item) => total + ((item as number) || 0), 0)
//   }`;
//   const isLevelMetric = dataset.yAxisID === "y1";
//   const valueLabel = `${isLevelMetric ? value : (+value).toFixed(1)}${isLevelMetric ? "%" : " kW"} `;

//   ctx.beginPath();
//   ctx.textAlign = "left";
//   ctx.textBaseline = "middle";
//   ctx.fillStyle = isDarkTheme ? "#E6E8ED" : "#27241D";
//   ctx.font = "bold 14px Switzer";
//   const valueTextX = LEGEND_OFFSET + LEGEND_CIRCLE_RADIUS * 2 + LEGEND_ITEM_GAP + datasetIndex * LEGEND_GAP;
//   ctx.fillText(valueLabel, valueTextX, LEGEND_OFFSET + LEGEND_CIRCLE_RADIUS + topOffset);
//   const valueTextWidth = ctx.measureText(valueLabel).width;
//   ctx.stroke();

//   ctx.beginPath();
//   ctx.textAlign = "left";
//   ctx.textBaseline = "middle";
//   ctx.fillStyle = isDarkTheme ? "#CDD2DC" : "#27241D";
//   ctx.font = "normal 14px Switzer";
//   ctx.fillText(`${dataset.label}`, valueTextX + valueTextWidth, LEGEND_OFFSET + LEGEND_CIRCLE_RADIUS + topOffset);
//   ctx.stroke();

//   ctx.restore();
// };

// export const getPlugins = (isDaySelected: boolean, isDarkTheme: boolean): Plugin[] => [
//   {
//     id: "hoverHandler",
//     afterDraw: (chart) => {
//       if (!isDaySelected) {
//         selectedElement = null;
//         return;
//       }

//       const activeElement = chart.getActiveElements()[0];
//       if (activeElement?.element) {
//         selectedLabel = (chart.data.labels?.[activeElement?.index] as string) || selectedLabel;
//         selectedElement = activeElement;
//       } else if (!selectedElement) {
//         const currentDate = dayjs().subtract(1, "m");
//         const currentLabelIndex = chart.data.labels?.findIndex(
//           (label) => label === currentDate.subtract(currentDate.minute() % 15, "m").format("HH:mm"),
//         );
//         if (currentLabelIndex && currentLabelIndex > -1) {
//           chart.setActiveElements([{ datasetIndex: 0, index: currentLabelIndex }]);
//         }
//       }
//     },
//     beforeDatasetsDraw: (chart) => {
//       if (selectedElement?.element) {
//         drawElementLine(chart, selectedElement.element.x, isDarkTheme);
//       }
//       (chart.data.datasets as ChartDataset<"line" | "bar">[]).forEach((dataset, index) => {
//         if (!selectedElement?.element && dataset.yAxisID === "y1") {
//           return;
//         }
//         const offsetIndex = window.innerWidth > breakpoints.mobile ? index % 2 : 0;
//         const verticalGap =
//           window.innerWidth > breakpoints.mobile ? (index > 1 ? VERTICAL_GAP : 0) : index * VERTICAL_GAP;
//         drawLegendElement({
//           chart,
//           dataset,
//           datasetIndex: offsetIndex,
//           topOffset: verticalGap,
//           index: isDaySelected && selectedElement ? selectedElement.index : undefined,
//           isDarkTheme,
//         });
//       });
//     },
//   },
// ];

const prepareChartsData = (rows: IChartRows, timeRange: TimeRangeId, isLevelMetric = false) => {
  const labelMap: Record<string, number> = {};
  Object.entries(rows).forEach(([dateString, value]) => {
    const date = dayjs(dateString);
    switch (timeRange) {
      case TimeRangeId.Day: {
        labelMap[date.format("H:mm")] = value;
        break;
      }
      case TimeRangeId.Custom:
      case TimeRangeId.Week: {
        const label = date.format("DD.MM");
        labelMap[label] = (labelMap[label] || 0) + value;
        break;
      }
      case TimeRangeId.Month: {
        const label = date.format("D");
        labelMap[label] = (labelMap[label] || 0) + value;
        break;
      }
      case TimeRangeId.Year: {
        const label = date.format("M");
        labelMap[label] = (labelMap[label] || 0) + value;
        break;
      }
      default:
    }
  });
  Object.keys(labelMap).forEach((key) => {
    if (isLevelMetric) {
      labelMap[key] = Math.round(labelMap[key]);
    } else {
      labelMap[key] = Math.round(labelMap[key] * 10) / 10;
    }
  });
  return labelMap;
};

export const getDatasets = (
  labels: string[],
  timeRange: TimeRangeId,
  chartsData: IChartData,
  isBatteryConnected: boolean,
) => {
  const productionMap = prepareChartsData(chartsData.production, timeRange);
  const consumptionMap = prepareChartsData(chartsData.consumption, timeRange);
  const directMap = prepareChartsData(chartsData.direct, timeRange);
  const batteryMap = prepareChartsData(chartsData.battery, timeRange, true);

  const isDaySelected = timeRange === TimeRangeId.Day;
  return [
    {
      label: "Erzeugung",
      data: labels.map((label) => productionMap[label]),
      borderColor: "#3EBD93",
      backgroundColor: "rgb(62, 189, 147, 0.15)",
      borderWidth: 1,
      stack: "Stack 1",
      ...(isDaySelected
        ? {
            fill: true,
            pointRadius: 0,
            pointHoverRadius: 0,
          }
        : {
            stack: "Stack 0",
          }),
    },
    {
      label: "Verbrauch",
      data: labels.map((label) => consumptionMap[label]),
      borderColor: "#DC5055",
      backgroundColor: "rgb(248, 106, 106, 0.15)",
      borderWidth: 1,
      stack: "Stack 2",
      ...(isDaySelected
        ? {
            fill: true,
            pointRadius: 0,
            pointHoverRadius: 0,
          }
        : {
            stack: "Stack 0",
          }),
    },
    {
      label: "Direktverbrauch",
      data: labels.map((label) => directMap[label]),
      borderColor: "#40C3F7",
      backgroundColor: "rgb(162, 165, 252, 0.25)",
      borderWidth: 1,
      stack: "Stack 3",
      ...(isDaySelected
        ? {
            fill: true,
            pointRadius: 0,
            pointHoverRadius: 0,
          }
        : {
            stack: "Stack 0",
          }),
    },
    ...(isDaySelected && isBatteryConnected
      ? [
          {
            type: "line" as const,
            label: "Batterie",
            data: labels.map((label) => batteryMap[label]),
            borderColor: "#7069FA",
            borderWidth: 1,
            pointRadius: 0,
            pointHoverRadius: 0,
            yAxisID: "y1",
          },
        ]
      : []),
  ];
};

export const getOptions = (isMobile: boolean, isDaySelected: boolean, isDarkTheme: boolean): ChartOptions => ({
  maintainAspectRatio: false,
  layout: {
    padding: {
      bottom: 60,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      enabled: !isDaySelected,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        color: isDarkTheme ? "#E6E8ED" : "#857F72",
      },
    },
    y: {
      beginAtZero: true,
      stacked: true,
      min: 0,
      grid: {
        color: isDarkTheme ? "#58647F" : "#E8E6E1",
      },
      border: {
        display: false,
      },
      ticks: {
        color: isDarkTheme ? "#E6E8ED" : "#857F72",
        callback: (value: string | number) => {
          if (!value) {
            return isDaySelected ? "kW" : "kWh";
          }
          return (+value).toFixed(2);
        },
        count: 5,
      },
    },
    ...(isDaySelected
      ? {
          y1: {
            beginAtZero: true,
            stacked: true,
            border: {
              display: false,
            },
            max: 100,
            position: "right",
            grid: {
              display: false,
            },
            ticks: {
              callback: (value: string | number) => {
                return `${value}%`;
              },
              count: 5,
              color: isDarkTheme ? "#E6E8ED" : "#857F72",
            },
          },
        }
      : {}),
  },
});
