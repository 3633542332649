import React from "react";
import { ErrorTile } from "components";

export const ErrorPage: React.FC = () => {
  return (
    <ErrorTile
      title="Oops."
      message="Leider ist etwas schiefgelaufen. Bitte warte  kurz und versuche es dann noch einmal."
      onButtonClick={() => window.location.reload()}
      buttonText="Noch mal versuchen"
    />
  );
};
