import React, { useState } from "react";
import { styled } from "styled-components";
import { Flex, Typography } from "components";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { PdfViewer } from "./PdfViewer";

const PdfButton = styled(Flex)`
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 8px;
  width: fit-content;

  background: ${(props) => props.theme.colors.clickBlue};
`;

interface PdfRefProps {
  page: number | string;
  pdfFile: string;
  prefix?: string;
  postfix?: string;
}

export const PdfRef: React.FC<PdfRefProps> = ({ page, pdfFile, prefix = "", postfix = "" }) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <PdfViewer pdf={pdfFile} page={+page} isOpen={open} onClose={() => setOpen(false)} />
      {prefix}
      <PdfButton onClick={() => setOpen(true)}>
        <Typography weight={300}>{page}</Typography>
      </PdfButton>
      {postfix}
    </>
  );
};
