import { useMutation, useQuery } from "@tanstack/react-query";
import { request } from "api/config/axios";
import { IDeviceUser, ISolarUser } from "types/common";
import { queryClient } from "api/config/query";
import { INodeModelDevice } from "../devices";

export interface IUserFormData extends Omit<ISolarUser, "user_id" | "enabled"> {
  confirm_password: string;
}

const getUser = async () => {
  return request<ISolarUser>({
    url: "/users/me",
    method: "GET",
  });
};

export const useUser = () => {
  return useQuery<ISolarUser>(["me"], getUser);
};

const getUserById = async (id: string) => {
  return request<ISolarUser>({
    url: `/users/${id}`,
    method: "GET",
  });
};

export const useUserById = (id: string, enabled = true) => {
  return useQuery<ISolarUser>(["user", id], () => getUserById(id), { enabled });
};

const getUsers = async () => {
  return request<ISolarUser[]>({
    url: "/users",
    method: "GET",
  });
};

export const useUsers = () => {
  return useQuery<ISolarUser[]>(["users"], getUsers);
};

const createUser = async (data: IUserFormData) => {
  return request<ISolarUser>({
    url: "/users",
    method: "POST",
    data,
  });
};

export const useCreateUser = () =>
  useMutation({
    mutationFn: createUser,
  });

const updateUser = async (id: string, data: IUserFormData) => {
  return request<ISolarUser>({
    url: `/users/${id}`,
    method: "PATCH",
    data,
  });
};

export const useUpdateUser = (id: string) =>
  useMutation({
    mutationFn: (data: IUserFormData) => updateUser(id, data),
  });

const deleteUser = async (id: number | string) => {
  return request<boolean>({
    url: `/users/${id}`,
    method: "DELETE",
  });
};

export const useDeleteUser = () =>
  useMutation({
    mutationFn: deleteUser,
    onSuccess: () => queryClient.invalidateQueries(["users"]),
  });

const getUserDevices = async (id: string) => {
  return request<INodeModelDevice[]>({
    url: `/users/${id}/devices`,
    method: "GET",
  });
};

export const useUserDevices = (id: string, enabled = true) => {
  return useQuery<INodeModelDevice[]>(["user-devices", id], () => getUserDevices(id), { enabled });
};

const addUserDevice = async (id: string | number, data: { deviceId: number }) => {
  return request<IDeviceUser>({
    url: `/users/${id}/devices`,
    method: "POST",
    data,
  });
};

export const useAddUserDevice = (id: string | number) => {
  return useMutation({
    mutationFn: (data: { deviceId: number }) => addUserDevice(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["user-devices", id]);
      queryClient.invalidateQueries(["available-devices", id]);
    },
  });
};

const deleteUserDevice = async (id: string | number, data: { deviceId: number }) => {
  return request<IDeviceUser>({
    url: `/users/${id}/devices`,
    method: "DELETE",
    data,
  });
};

export const useDeleteUserDevice = (id: string | number) => {
  return useMutation({
    mutationFn: (data: { deviceId: number }) => deleteUserDevice(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["user-devices", id]);
      queryClient.invalidateQueries(["available-devices", id]);
    },
  });
};
