import { Flex } from "components/Flex";
import { Typography } from "components/Typography";
import React, { InputHTMLAttributes } from "react";
import { styled } from "styled-components";

export type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "title"> & {
  title?: React.ReactNode;
  onChange?(value: string, name?: string): void;
  hasError?: boolean;
  error?: string;
};

const Container = styled(Flex)`
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
`;

const StyledInput = styled.input<{ $hasError?: boolean }>`
  padding: 9px 13px;
  border-radius: 6px;
  border: 1px solid ${(props) => (props.$hasError ? props.theme.colors.red300 : props.theme.colors.grey100)};
  background: ${(props) => props.theme.colors.white};
  outline: none;
  width: 100%;
  box-sizing: border-box;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${(props) => props.theme.colors.grey800};

  #dark-theme & {
    border: 1px solid ${(props) => (props.$hasError ? props.theme.colors.red200 : props.theme.colors.blueGrey300)};
    background: ${(props) => props.theme.colors.blueGrey600};
    color: ${(props) => props.theme.colors.blueGrey50};
  }

  &:focus-visible {
    border: 1px solid ${(props) => props.theme.colors.grey800};
    #dark-theme & {
      border: 1px solid ${(props) => props.theme.colors.blueGrey50};
    }
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.grey400};
    #dark-theme & {
      color: ${(props) => props.theme.colors.blueGrey200};
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const ErrorText = styled(Typography)`
  #dark-theme & {
    color: ${(props) => props.theme.colors.red200};
  }
`;

export const Input: React.FC<InputProps> = ({ title, defaultValue = "", onChange, hasError, ...rest }) => {
  const [value, setValue] = React.useState(defaultValue);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    setValue(newValue);
    if (onChange) {
      onChange(newValue, event.target.name);
    }
  };
  return (
    <Container>
      {typeof title === "string" ? (
        <Typography size={14} weight={500} height={21}>
          {title}
        </Typography>
      ) : (
        title
      )}

      <StyledInput value={value} $hasError={hasError} {...rest} onChange={handleChange} />
      {hasError && (
        <ErrorText size={16} height={24} weight={500} color="errorDark">
          {rest.error}
        </ErrorText>
      )}
    </Container>
  );
};
