import React from "react";

export const HomeIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.25 12.0008L11.2045 3.0463C11.6438 2.60696 12.3562 2.60696 12.7955 3.0463L21.75 12.0008M4.5 9.75081V19.8758C4.5 20.4971 5.00368 21.0008 5.625 21.0008H9.75V16.1258C9.75 15.5045 10.2537 15.0008 10.875 15.0008H13.125C13.7463 15.0008 14.25 15.5045 14.25 16.1258V21.0008H18.375C18.9963 21.0008 19.5 20.4971 19.5 19.8758V9.75081M8.25 21.0008H16.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
