import React from "react";

export const LongDriveIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.39903 20.3666C2.58058 20.2905 2.54811 19.1695 2.54811 19.0933C2.54811 19.0933 2.43987 18.6471 2.54811 16.6556C2.65635 14.664 4.72376 13.7933 4.72376 13.7933L8.73951 12.8792C8.73951 12.8792 8.8694 13.0207 11.2074 11.0944C13.697 9.04837 16.3489 9.04837 16.3489 9.04837C16.3489 9.04837 23.9258 8.93954 25.0298 9.04837C26.1447 9.16808 27.2488 9.78841 27.8333 10.6482C28.4286 11.497 29.1213 12.8139 29.1213 12.8139"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.7938 20.3858L11.4893 20.375"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M20.1152 14.7617H27.5189C29.2508 14.7835 29.4997 16.0568 29.4997 16.0568V19.2455C29.0235 20.9868 27.0968 20.3882 27.0968 20.3882H26.9994"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8184 12.2784V17.2954H18.7409C18.7409 17.2954 20.2021 17.3934 20.1264 15.6847V13.6605C20.1264 13.6605 20.1264 12.1587 18.73 12.2675H15.8292L15.8184 12.2784Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8191 13.3477H12.7559"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15.8191 16.2072H12.7559"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M24.4035 23.0014C25.8442 23.0014 27.0121 21.8272 27.0121 20.3787C27.0121 18.9301 25.8442 17.7559 24.4035 17.7559C22.9628 17.7559 21.7949 18.9301 21.7949 20.3787C21.7949 21.8272 22.9628 23.0014 24.4035 23.0014Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M7.00803 23.0014C8.44873 23.0014 9.61664 21.8272 9.61664 20.3787C9.61664 18.9301 8.44873 17.7559 7.00803 17.7559C5.56733 17.7559 4.39941 18.9301 4.39941 20.3787C4.39941 21.8272 5.56733 23.0014 7.00803 23.0014Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
