import { Flex, FlexProps, Typography } from "components";
import React from "react";
import { styled } from "styled-components";

type TileProps = FlexProps & {
  title: React.ReactNode;
  headerInfo?: React.ReactNode;
};

const Container = styled(Flex)`
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  row-gap: 40px;
  padding: 32px 24px;
  background-color: ${(props) => props.theme.colors.grey50};
  box-shadow: 3.269838333129883px 3.269838333129883px 6.539676666259766px 0px rgba(174, 174, 192, 0.4),
    -2.1798925399780273px -2.1798925399780273px 6.539676666259766px 0px #fff;

  #dark-theme & {
    box-shadow: ${(props) => props.theme.boxShadow.defaultDark};
    background-color: ${(props) => props.theme.colors.blueGrey800};
  }

  ${(props) => props.theme.breakpoints.mobile} {
    row-gap: 24px;
    padding: 24px 16px;
  }
`;

const HeaderContainer = styled(Flex)`
  flex-direction: column;
  row-gap: 32px;

  padding-bottom: 32px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey100};

  #dark-theme & {
    border-bottom: 1px solid ${(props) => props.theme.colors.blueGrey600};
  }

  ${(props) => props.theme.breakpoints.mobile} {
    padding-bottom: 24px;
    row-gap: 24px;
  }
`;

const Header = styled(Typography)`
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;

  ${(props) => props.theme.breakpoints.mobile} {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const Tile: React.FC<React.PropsWithChildren<TileProps>> = ({ title, children, headerInfo, ...rest }) => {
  return (
    <Container {...rest}>
      <HeaderContainer>
        <Header>{title}</Header>
        {headerInfo}
      </HeaderContainer>
      {children}
    </Container>
  );
};
