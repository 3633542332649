import {
  AgrolaHouseLogoIcon,
  AgrolaLogoIcon,
  DarkAgrolaHouseLogoIcon,
  DarkAgrolaLogoIcon,
  DarkKosterHouseLogoIcon,
  DarkKosterLogoIcon,
  DefaultHouseLogoIcon,
  DefaultLogoIcon,
  KosterHouseLogoIcon,
  KosterLogoIcon,
} from "assets";
import { IconProps } from "components";

export interface ISettings {
  logo: IconProps["icon"];
  houseLogo: IconProps["icon"];
  darkLogo: IconProps["icon"];
  darkHouseLogo: IconProps["icon"];
  title: string;
  mobileLogoWidth: number;
  name: string;
}

const agrolaSettings: ISettings = {
  logo: AgrolaLogoIcon,
  houseLogo: AgrolaHouseLogoIcon,
  darkLogo: DarkAgrolaLogoIcon,
  darkHouseLogo: DarkAgrolaHouseLogoIcon,
  title: "Agrola Energy Manager",
  mobileLogoWidth: 104,
  name: "agrola",
};

const kosterSettings: ISettings = {
  logo: KosterLogoIcon,
  houseLogo: KosterHouseLogoIcon,
  darkLogo: DarkKosterLogoIcon,
  darkHouseLogo: DarkKosterHouseLogoIcon,
  title: "Koster Home - Energy Manager",
  mobileLogoWidth: 92,
  name: "koster",
};

const defaultSettings: ISettings = {
  logo: DefaultLogoIcon,
  houseLogo: DefaultHouseLogoIcon,
  darkLogo: DefaultLogoIcon,
  darkHouseLogo: DefaultHouseLogoIcon,
  title: "Energy Manager",
  mobileLogoWidth: 92,
  name: "default",
};

export const getSettings = (): ISettings => {
  const { origin } = window.location;
  const settings = [kosterSettings, agrolaSettings].find(({ name }) => origin.includes(name));
  return settings || defaultSettings;
};
