import React from "react";
import styled from "styled-components";
import { AddPrefixToObject } from "types/misc";

export type FlexProps = {
  direction?: "column" | "row" | "row-reverse";
  justify?: "center" | "space-between" | "flex-start" | "flex-end" | "space-around";
  align?: "center" | "space-between" | "flex-start" | "flex-end" | "baseline";
  alignSelf?: "center" | "space-between" | "flex-start" | "flex-end";
  order?: number;
  wrap?: "wrap" | "nowrap";
  columnGap?: number;
  rowGap?: number;
  position?: "relative" | "absolute";
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  minHeight?: string;
  maxHeight?: string;
  flex?: number;
  margin?: string;
  padding?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
  [key: string]: unknown;
};

const Flexbox = styled.div<FlexboxProps & { $clickable: boolean }>`
  display: flex;
  box-sizing: border-box;
  flex-direction: ${(props) => props.$direction};
  justify-content: ${(props) => props.$justify};
  align-items: ${(props) => props.$align};
  align-self: ${(props) => props.$alignSelf};
  row-gap: ${(props) => (props.$rowGap ? `${props.$rowGap}px` : "")};
  column-gap: ${(props) => (props.$columnGap ? `${props.$columnGap}px` : "")};
  position: ${(props) => (props.$position ? props.$position : "")};
  width: ${(props) => (props.$width ? props.$width : "")};
  min-width: ${(props) => (props.$minWidth ? props.$minWidth : "")};
  max-width: ${(props) => (props.$maxWidth ? props.$maxWidth : "")};
  height: ${(props) => (props.$height ? props.$height : "")};
  min-height: ${(props) => (props.$minHeight ? props.$minHeight : "")};
  margin: ${(props) => (props.$margin ? props.$margin : "")};
  padding: ${(props) => (props.$padding ? props.$padding : "")};
  flex: ${(props) => props.$flex};
  flex-wrap: ${(props) => props.$wrap};
  order: ${(props) => props.$order};
  cursor: ${(props) => (props.$clickable ? "pointer;" : "")};
`;

type FlexboxProps = AddPrefixToObject<FlexProps, "$">;

export const Flex: React.FC<React.PropsWithChildren<FlexProps>> = (props) => {
  const { children, className, onClick, ...rest } = props;
  const flexProps: FlexboxProps = Object.entries(rest).reduce(
    (acc, [key, value]) => ({ ...acc, [`$${key}`]: value }),
    {},
  );
  return (
    <Flexbox {...flexProps} className={className} onClick={onClick} $clickable={!!onClick}>
      {children}
    </Flexbox>
  );
};
