import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { IDataMetric } from "types/common";

export const columns: ColumnsType<IDataMetric> = [
  {
    title: "ID",
    dataIndex: "metric_id",
    key: "metric_id",
    sorter: (a, b) => a.metric_id - b.metric_id,
  },
  {
    title: "Label",
    dataIndex: "metric_label",
    key: "metric_label",
    sorter: (a, b) => a.metric_label.localeCompare(b.metric_label),
  },
  {
    title: "Value",
    key: "value",
    render: (_, record) => {
      const value = record.float_value?.toFixed(2) ?? record.string_value ?? record.bool_value;
      return value ? `${value} ${record.unit}` : "";
    },
  },
  {
    title: "Metric",
    dataIndex: "metric",
    key: "metric",
    sorter: (a, b) => a.metric.localeCompare(b.metric),
  },
  {
    title: "Last Update",
    dataIndex: "ts",
    key: "ts",
    render: (date) => (date ? dayjs(date).format("YYYY-MM-DD hh:mm:ss") : ""),
    sorter: (a, b, order) => {
      if (!a.ts) {
        return order === "ascend" ? 1 : -1;
      }
      return dayjs(a.ts).isAfter(dayjs(b.ts)) ? 1 : -1;
    },
  },
];
