import { ThemeModeContext } from "App";
import { Flex, Icon, Typography } from "components";
import React from "react";
import { styled } from "styled-components";
import { ArrowRightIcon } from "assets";
import { MenuContentProps, MenuItem as MenuItemType } from "../../types";

const MenuItemContainer = styled(Flex)`
  padding: 16px 8px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.grey100};
  background: ${(props) => props.theme.colors.white};

  #dark-theme & {
    background: ${(props) => props.theme.colors.blueGrey600};
    border: 1px solid ${(props) => props.theme.colors.blueGrey300};
  }

  @media (hover: hover) {
    &:hover {
      border: 1px solid ${(props) => props.theme.colors.grey200};
    }

    #dark-theme &:hover {
      border: 1px solid ${(props) => props.theme.colors.blueGrey50};
    }
  }

  > div:first-child {
    > span {
      padding-left: 8px;
    }

    > svg + span {
      padding: 0;
    }
  }
`;

type MenuItemProps = {
  item: MenuItemType;
  setActiveContent?: MenuContentProps["setActiveContent"];
};

export const MenuItem: React.FC<MenuItemProps> = ({ item, setActiveContent }) => {
  const handleClick =
    item.nestedContent && setActiveContent ? () => setActiveContent(item.nestedContent!, item.title) : item?.onClick;
  const isDarkTheme = React.useContext(ThemeModeContext) === "dark";
  return (
    <MenuItemContainer key={item.title} columnGap={8} justify="space-between" align="center" onClick={handleClick}>
      <Flex align="center" columnGap={12}>
        {item.icon && <Icon icon={item.icon} color={isDarkTheme ? "blueGrey100" : "grey400"} />}
        <Typography size={16} height={24} weight={400}>
          {item.title}
        </Typography>
      </Flex>
      {item.value && (
        <Flex columnGap={8}>
          <Typography size={14} weight={400} height={21} color={isDarkTheme ? "blueGrey100" : "grey500"}>
            {item.value}
          </Typography>
          <Icon icon={ArrowRightIcon} color={isDarkTheme ? "blueGrey100" : "grey500"} />
        </Flex>
      )}
    </MenuItemContainer>
  );
};
