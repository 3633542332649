import { ThemeModeContext } from "App";
import { useMetrics } from "api/modules/metrics";
import { BoilerIcon } from "assets";
import { Card, Flex, Icon, Typography, UnitLabel } from "components";
import { Metrics } from "constants/metrics";
import { Routes } from "constants/routes";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { NavLink } from "react-router-dom";
import { styled } from "styled-components";
import { DeviceIconMap } from "utils/icons";

const DevicesContainer = styled(Flex)`
  width: 100%;
  max-width: 576px;
  margin: 0 auto;

  > a {
    width: 100%;
  }
`;

export const DevicePage: React.FC = () => {
  const { data: metrics, isLoading } = useMetrics();

  const isDarkTheme = React.useContext(ThemeModeContext) === "dark";
  return (
    <Flex direction="column" rowGap={24} maxWidth="576px" margin="0 auto">
      <Typography size={20} height={26} weight={500}>
        Installierte Geräte
      </Typography>
      {isLoading ? (
        <Skeleton count={15} />
      ) : (
        <DevicesContainer direction="column" rowGap={16} align="center">
          {metrics
            ?.filter(({ metric }) => metric === Metrics.Power)
            .map((metric) => (
              <NavLink to={`${Routes.Devices}/${metric.device_id}`} key={metric.metric_id}>
                <Card>
                  <Flex columnGap={8} width="100%">
                    <Icon
                      icon={DeviceIconMap[metric.icon] || BoilerIcon}
                      color={isDarkTheme ? "blueGrey100" : "grey600"}
                    />
                    <Flex direction="column" rowGap={8} width="100%">
                      <Flex justify="space-between" align="center">
                        <Typography size={16} height={24} weight={400} color={isDarkTheme ? "white" : "grey800"}>
                          {metric.label}
                        </Typography>

                        <UnitLabel
                          isDarkTheme={isDarkTheme}
                          value={Math.abs(metric.float_value)?.toFixed(2)}
                          unit={metric.unit}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Card>
              </NavLink>
            ))}
        </DevicesContainer>
      )}
    </Flex>
  );
};
