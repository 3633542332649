import React from "react";

interface Props {
  filled?: boolean;
}
export const ExclamationTriangleIcon: React.FC<Props> = ({ filled = false, ...props }) => {
  if (filled) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          d="M10.0513 4.42653C10.9172 2.9257 13.0833 2.9257 13.9492 4.42653L21.3035 17.174C22.1689 18.674 21.0863 20.5484 19.3546 20.5484H4.64593C2.9142 20.5484 1.83163 18.674 2.69702 17.174L10.0513 4.42653Z"
          fill="#FFE3E3"
        />
        <path d="M12.0003 16.7984H12.0078V16.8059H12.0003V16.7984Z" fill="#FFE3E3" />
        <path
          d="M12.0003 10.0484V13.7984M2.69702 17.174C1.83163 18.674 2.9142 20.5484 4.64593 20.5484H19.3546C21.0863 20.5484 22.1689 18.674 21.3035 17.174L13.9492 4.42653C13.0833 2.9257 10.9172 2.9257 10.0513 4.42653L2.69702 17.174ZM12.0003 16.7984H12.0078V16.8059H12.0003V16.7984Z"
          stroke="#AB091E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.0003 8.99945V12.7495M2.69702 16.1251C1.83163 17.6251 2.9142 19.4995 4.64593 19.4995H19.3546C21.0863 19.4995 22.1689 17.6251 21.3035 16.1251L13.9492 3.37758C13.0833 1.87674 10.9172 1.87675 10.0513 3.37758L2.69702 16.1251ZM12.0003 15.7495H12.0078V15.757H12.0003V15.7495Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
