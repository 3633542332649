import { Flex, Typography } from "components";
import { Routes } from "constants/routes";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const NavContainer = styled(Flex)`
  width: 100%;
  height: 40px;
  position: sticky;
  top: 0;
  z-index: 10;

  align-items: center;

  background-color: #999999;
`;

const NavItem = styled.div<{ $isActive: boolean }>`
  padding: 8px 16px;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.$isActive && `background-color: ${props.theme.colors.grey700}`};

  &:hover {
    background-color: ${(props) => props.theme.colors.grey700};
  }
`;

const menuItems = [
  {
    label: "Users",
    route: Routes.AdminUsers,
  },
  {
    label: "Nodes",
    route: Routes.AdminNodes,
  },
  {
    label: "Devices",
    route: Routes.AdminDevices,
  },
  {
    label: "Models",
    route: Routes.AdminModels,
  },
];

export const AdminNavbar: React.FC = () => {
  return (
    <NavContainer>
      {menuItems.map((item) => (
        <NavLink key={item.route} to={item.route} style={{ height: "100%" }}>
          {({ isActive }) => (
            <NavItem $isActive={isActive}>
              <Typography color="white">{item.label}</Typography>
            </NavItem>
          )}
        </NavLink>
      ))}
    </NavContainer>
  );
};
