import React from "react";
import { Flex } from "components";
import { MenuContentProps, MenuItem } from "../../types";
import { MenuItem as MenuItemCompoment } from "../MenuItem";
import { EnergyRate } from "../EnergyRate";

export const SettingsMenu: React.FC<MenuContentProps> = (props) => {
  const menuItems: MenuItem[] = [
    {
      title: "Erlöskalkulation",
      value: `${props.node.energy_rate || "0.00"} CHF`,
      nestedContent: EnergyRate,
    },
  ];
  return (
    <Flex direction="column" rowGap={8}>
      {menuItems.map((item) => (
        <MenuItemCompoment
          key={item.title}
          item={item}
          setActiveContent={(content, title) => props.setActiveContent(content, title)}
        />
      ))}
    </Flex>
  );
};
