import React from "react";
import { useDeleteUser, useUsers } from "api/modules/users";
import { AdminTable } from "components";
import { ISolarUser } from "types/common";
import { useDeleteModalEntity } from "hooks/useDeleteEntity";
import { useNavigate } from "react-router-dom";
import { Routes } from "constants/routes";
import { columns } from "./columns";

export const AdminUserPage: React.FC = () => {
  const navigate = useNavigate();
  const { data: users, isLoading } = useUsers();
  const { Component: DeleteModalComponent, openModal } = useDeleteModalEntity(useDeleteUser, "user");

  return (
    <>
      <AdminTable
        data={users}
        columns={columns}
        isLoading={isLoading}
        entity="user"
        rowKey="user_id"
        handleButtonClick={() => navigate(Routes.AdminNewUser)}
        actions={[
          {
            key: "delete",
            label: "Delete",
            onAction: (row: ISolarUser) => openModal(row.user_id),
          },
        ]}
        includePadding
      />
      <DeleteModalComponent />
    </>
  );
};
