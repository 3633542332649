export enum Routes {
  Overview = "/",
  Scenes = "/scenes",
  Devices = "/devices",
  Login = "/login",
  Help = "/help",
  Service = "/help/service",

  Admin = "/admin",

  AdminUsers = "/admin/users",
  AdminNewUser = "/admin/users/new",

  AdminDevices = "/admin/devices",
  AdminNewDevice = "/admin/devices/new",

  AdminNodes = "/admin/nodes",
  AdminNewNode = "/admin/nodes/new",

  AdminModels = "/admin/models",
  AdminNewModel = "/admin/models/new",
}
