import React from "react";

import { SettingsContext, ThemeModeContext } from "App";
import { Icon } from "components/Icon";
import { styled } from "styled-components";

const StyledIcon = styled(Icon)<{ $mobileLogoWidth: number }>`
  ${(props) => props.theme.breakpoints.mobile} {
    width: ${(props) => props.$mobileLogoWidth}px;
    height: auto;
  }
`;

export const Logo: React.FC = () => {
  const isDarkTheme = React.useContext(ThemeModeContext) === "dark";
  const settings = React.useContext(SettingsContext);
  return (
    <StyledIcon icon={isDarkTheme ? settings.darkLogo : settings.logo} $mobileLogoWidth={settings.mobileLogoWidth} />
  );
};
