import { AdminForm, AdminTable, Flex, Typography } from "components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Input, Form, Button, Select, UploadFile, Upload, Divider, Alert, UploadProps, Modal } from "antd";
import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { CheckCircleFilled, LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { Routes } from "constants/routes";
import { useDeleteModalEntity } from "hooks/useDeleteEntity";
import { useDeviceTypes } from "api/modules/device-types";
import { IModel } from "types/common";
import { columns } from "pages/AdminModelPage/columns";
import {
  useModelById,
  useFileByModelId,
  useDeviceModels,
  useCreateDeviceModel,
  useUpdateModel,
  useUploadFiles,
  useDeleteFiles,
  useDeleteModel,
} from "api/modules/device-models";
import { CircleCheckIcon, InfoIcon, PdfFileIcon, TrashCanIcon } from "assets";
import { AttentionCircleIcon } from "assets/icons/AttentionCircleIcon";
import { UploadFileStatus } from "antd/es/upload/interface";
import { useReindexModel } from "api/modules/help";
import { theme } from "theme";

export interface IAdminModelFormData extends Omit<IModel, "model_id" | "files"> {
  files?: { file: File; fileList: UploadFile[] };
}

interface UploadListItemProps {
  originNode: React.ReactElement;
  isUploaded: UploadFileStatus;
}

const ListItem = styled(Flex)`
  width: 100%;
  justify-content: space-between;
`;

const ListItemStatus = styled(Flex)`
  margin-top: 8px;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const NotUpoadedYetLabel = styled.div`
  color: #0b69a3;
  padding-left: 32px;
`;

const UploadListItem = ({ originNode, isUploaded }: UploadListItemProps) => {
  return (
    <ListItem>
      {originNode}
      <ListItemStatus>
        {isUploaded === "uploading" && <NotUpoadedYetLabel>not uploaded yet</NotUpoadedYetLabel>}
        {isUploaded === "done" && <CircleCheckIcon />}
        {isUploaded === "error" && <AttentionCircleIcon />}
      </ListItemStatus>
    </ListItem>
  );
};

const FormRow = styled(Flex)`
  flex-wrap: wrap;
  width: 100%;
`;

const FormItem = styled(Form.Item)`
  min-width: 300px;
  width: 300px;
`;

const StyledUpload = styled(Upload)`
  & .ant-upload-list-item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .ant-upload-list-item {
      width: 315px;

      & .ant-upload-list-item-actions {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & .ant-upload-icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  & .ant-upload-list-item-name {
    pointer-events: none !important;
  }
`;

const StyledAlert = styled(Alert)`
  boxshadow: "0px 1px 2px 0px #0000000F, 0px 1px 3px 0px #0000001A";
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  > span {
    padding-left: 64px;
  }

  ${(props) => props.theme.breakpoints.tablet} {
    > span {
      padding-left: 24px;
    }
  }
`;

const FormTile = styled(Flex)`
  padding: 32px 64px;
  background-color: ${(props) => props.theme.colors.white};
  flex-direction: column;
  row-gap: 64px;

  ${(props) => props.theme.breakpoints.tablet} {
    padding: 16px 24px;
  }
`;

const FormColumnContainer = styled(Flex)`
  display: flex;
  column-gap: 32px;
`;

const FormColumn = styled(Flex)`
  flex-direction: column;
  width: 50%;
  justify-content: space-between;
`;

const FormTileContent = styled.div``;

const ButtonContainer = styled(Flex)`
  column-gap: 24px;
  margin-top: 16px;
`;

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    number: "${label} is not a valid number!",
  },
};

const validateFileType = ({ type }: UploadFile, allowedTypes?: string) => {
  if (!allowedTypes) {
    return true;
  }
  if (type) {
    return allowedTypes.includes(type);
  }
  return false;
};

const { Dragger } = Upload;

export const AdminModelDetailsPage: React.FC<{ isNew?: boolean }> = ({ isNew }) => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const params = useParams();
  const modelId = params.modelId!;

  const { data: model, isFetching: isModelLoading, isError: isModelError } = useModelById(modelId, !isNew);
  const { data: fetchedFiles } = useFileByModelId(modelId, !isNew);
  const { data: deviceModels, isLoading: isDeviceModelsLoading } = useDeviceModels();
  const { data: types } = useDeviceTypes();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileIdsToUpload, setFileIdsToUpload] = useState<string[]>([]);
  const [filesToDelete, setFilesToDelete] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    if (fetchedFiles) {
      setFileList(
        fetchedFiles.map((fetchedFile) => {
          const uploadFile: UploadFile = {
            uid: fetchedFile.file_id.toString(),
            name: fetchedFile.file_name,
            status: "done",
          };
          return uploadFile;
        }),
      );
    }
  }, [fetchedFiles]);

  const uploadProps: UploadProps = useMemo(
    () => ({
      beforeUpload: (uploadedFile: UploadFile) => {
        const isAllowedType = validateFileType(uploadedFile, "application/pdf");
        const isSameFileName = fileList.some((file: UploadFile) => file.name === uploadedFile.name);
        if (isSameFileName) {
          setFileList((state) => [...state]);
          toast.error(`File with the name ${uploadedFile.name} already exists!`);
          return false;
        }
        if (!isAllowedType) {
          setFileList((state) => [...state]);
          toast.error("File type must be .pdf!");
          return false;
        }
        setFileList((state) => [...state, uploadedFile]);
        setFileIdsToUpload((state) => [...state, uploadedFile.uid]);
        setFilesToDelete((state) => state.filter((file) => file.id !== uploadedFile.uid));
        return false;
      },
      onRemove: (removedFile: UploadFile) => {
        setFileList((state) => state.filter((item) => item.uid !== removedFile.uid));
        if (fileIdsToUpload.some((id) => id === removedFile.uid)) {
          setFileIdsToUpload((state) => state.filter((uploadFileId) => uploadFileId !== removedFile.uid));
        } else {
          setFilesToDelete((state) => [...state, { id: removedFile.uid, name: removedFile.name }]);
        }
        return false;
      },
      showUploadList: {
        showRemoveIcon: true,
        removeIcon: <TrashCanIcon />,
      },
    }),
    [fileList],
  );

  React.useEffect(() => {
    if (isModelError) {
      navigate(Routes.AdminNodes);
    }
  }, [isModelError]);

  React.useEffect(() => {
    if (hash && !isModelLoading) {
      document
        .getElementById(hash.replace("#", ""))
        ?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }, [hash, isModelLoading]);

  const [form] = Form.useForm<IAdminModelFormData>();
  const { mutate: createDeviceModel, isLoading: isCreateLoading } = useCreateDeviceModel();
  const { mutate: updateModel, isLoading: isUpdateLoading } = useUpdateModel(modelId);
  const { mutate: uploadFiles } = useUploadFiles(modelId);
  const { mutate: deleteFiles } = useDeleteFiles(modelId);
  const { mutate: reindexModel, isLoading: isReindexLoading } = useReindexModel();

  const switchUploadFileStatus = (fileIdsToUpdate: string[], status: UploadFileStatus) => {
    setFileList((prevState) =>
      prevState.map((file) => {
        if (fileIdsToUpdate.some((fileIdToUpdate) => fileIdToUpdate === file.uid)) {
          file.status = status;
          return file;
        }
        return file;
      }),
    );
  };

  const handleSave = (values: IAdminModelFormData) => {
    const adminModelData = {
      device_type: values.device_type,
      manufacturer: values.manufacturer,
      designation: values.designation,
    };
    if (isNew) {
      const isModelDesignationAndManufacturerAlreadyExisting = deviceModels?.some((deviceModel) => {
        return (
          deviceModel.manufacturer === adminModelData.manufacturer &&
          deviceModel.designation === adminModelData.designation
        );
      });

      if (!isModelDesignationAndManufacturerAlreadyExisting) {
        createDeviceModel(adminModelData, {
          onSuccess: ({ model_id }) => {
            navigate(`${Routes.AdminModels}/${model_id}`);
            toast.success(`Model has been created successfully!`);
          },
        });
      } else {
        toast.warn(
          `Please ensure that model designation "${adminModelData.designation}" and manufaturer "${adminModelData.manufacturer}" do not already exist.`,
        );
      }
    } else {
      updateModel(adminModelData, {
        onSuccess: () => {
          toast.success(`Model has been updated successfully!`);
        },
      });
    }
  };

  const handleStartUpload = () => {
    const formDataForUpload = new FormData();
    if (fileIdsToUpload.length > 0) {
      fileIdsToUpload.forEach((fileIdToUpload) => {
        // sometimes the file inside values.files.fileList is already the originFileObj and can be used directly
        // reason for this behavior of antd upload is unknown
        const newFile = fileList.find((file) => file.uid === fileIdToUpload);
        const newFileData = newFile?.originFileObj;
        if (newFileData) formDataForUpload.append("files", newFileData as Blob);
        else formDataForUpload.append("files", newFile as unknown as Blob);
      });
    }

    if (fileIdsToUpload.length) {
      switchUploadFileStatus(fileIdsToUpload, "uploading");
      uploadFiles(formDataForUpload, {
        onSuccess: () => {
          switchUploadFileStatus(fileIdsToUpload, "done");
          setFileIdsToUpload([]);
          toast.success(`Files have been uploaded successfully!`);
        },
        onError: (error) => {
          switchUploadFileStatus(fileIdsToUpload, "error");
          toast.error(`Something went wrong with the file upload! ${error}`);
        },
      });
    }

    if (filesToDelete.length) {
      deleteFiles(filesToDelete, {
        onSuccess: () => {
          setFilesToDelete([]);
          toast.success(`Files have been uploaded successfully!`);
        },
        onError: (error) => {
          toast.error(`Something went wrong with the file deletion! ${error}`);
        },
      });
    }
  };

  const { Component: DeleteModalComponent, openModal: openDeleteModal } = useDeleteModalEntity(
    useDeleteModel,
    "device",
  );

  const isActionLoading = isCreateLoading || isUpdateLoading;

  const updateModelDeviceDetailsScreen = isModelLoading ? (
    <Skeleton count={10} />
  ) : (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSave}
      size="large"
      validateMessages={validateMessages}
      initialValues={model ? { ...model } : {}}
      disabled={isActionLoading}
    >
      <FormRow>
        <Typography size={32} height={60}>
          Model Details
        </Typography>
      </FormRow>
      <FormRow>
        <FormItem label="Designation" name="designation" rules={[{ required: true }]}>
          <Input placeholder="Designation" />
        </FormItem>
        <FormItem label="Type" name="device_type" rules={[{ required: true }]}>
          <Select
            placeholder="Choose Model Type"
            loading={isModelLoading}
            showSearch
            disabled={!isNew}
            options={types?.map(({ device_type, label }) => ({
              label,
              value: device_type,
            }))}
          />
        </FormItem>
        <FormItem label="Manufacturer" name="manufacturer" rules={[{ required: false }]}>
          <Input placeholder="Manufacturer" />
        </FormItem>
      </FormRow>
      {isNew && (
        <FormRow>
          <FormItem style={{ width: `${100}%`, marginTop: `${4}px` }}>
            <StyledAlert
              description="File Upload Available: After registering a model in the database, you can upload a PDF attachment for additional documentation."
              type="info"
              icon={<InfoIcon />}
              showIcon
            />
          </FormItem>
        </FormRow>
      )}
    </Form>
  );

  const [modal, contextHolder] = Modal.useModal();

  const createIndexCallback = useCallback(() => {
    const instance = modal.info({
      title: "Creating index...",
      icon: <LoadingOutlined style={{ marginRight: `${16}px`, color: theme.colors.blue500 }} />,
      content:
        "Please wait while we create the index for your data. This process may take a few moments. During this time, do not close this window or navigate away, as it could interrupt the process. Thank you for your patience!",
      okText: "Cancel",
      okButtonProps: {
        type: "default",
      },
    });
    reindexModel(+modelId, {
      onSuccess: () => {
        instance.update({
          title: "Index successfully created",
          icon: <CheckCircleFilled style={{ marginRight: `${16}px`, color: theme.colors.green500 }} />,
          content: "Your new index for the AI-Help function has been successfully created.",
          okText: "Cancel",
          okButtonProps: {
            type: "default",
          },
        });
      },
    });
  }, []);

  const updateModelAttachFilesScreen = isModelLoading ? (
    <Skeleton count={10} />
  ) : (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSave}
      size="large"
      validateMessages={validateMessages}
      initialValues={model ? { ...model } : {}}
      disabled={isActionLoading}
    >
      {!isNew && (
        <>
          <FormRow>
            <Typography size={32} height={60}>
              Attachments
            </Typography>
          </FormRow>
          <FormRow>
            <FormItem label="Files" name="files" rules={[{ required: false }]} style={{ width: `${100}%` }}>
              {fileList.length === 0 ? (
                <Dragger
                  {...uploadProps}
                  multiple
                  style={{
                    textAlign: "start",
                    background: "transparent",
                    display: `${fileList.length ? "none" : "block"}`,
                  }}
                >
                  <Typography size={16} weight={400} height={20} color="black">
                    It looks like you haven&apos;t attached any files.&nbsp;
                  </Typography>
                  <Button
                    type="link"
                    style={{ width: "fit-content", padding: "0", height: "0", textDecoration: "underline" }}
                  >
                    Click here
                  </Button>
                  <Typography size={16} weight={400} height={20} color="black">
                    &nbsp;to upload a PDF file.
                  </Typography>
                </Dragger>
              ) : (
                <>
                  <StyledUpload
                    {...uploadProps}
                    multiple
                    fileList={fileList}
                    style={{
                      textAlign: "start",
                      background: "transparent",
                    }}
                    iconRender={() => <PdfFileIcon />}
                    itemRender={(originNode, file) => (
                      <UploadListItem originNode={originNode} isUploaded={file.status ? file.status : "uploading"} />
                    )}
                    disabled={isReindexLoading}
                  />
                  <Button
                    type="link"
                    style={{
                      width: "fit-content",
                      padding: "0",
                      height: "0",
                      marginTop: `${24}px`,
                    }}
                    onClick={createIndexCallback}
                    loading={isReindexLoading}
                    disabled={fileList.filter((file) => file.status === "done").length < 1 || isReindexLoading}
                  >
                    Create index
                  </Button>
                </>
              )}
            </FormItem>
          </FormRow>
        </>
      )}
    </Form>
  );

  return (
    <Flex direction="column" rowGap={40}>
      <Container id="models">
        <Typography size={32} height={60} weight={500}>
          {`${isNew ? "Create" : "Update"} Model`}
        </Typography>
        <FormTile>
          <FormColumnContainer>
            <FormColumn>
              <FormTileContent>{updateModelDeviceDetailsScreen}</FormTileContent>
              {
                <ButtonContainer>
                  <Button size="large" onClick={() => navigate(Routes.AdminModels)} disabled={isActionLoading}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={form.submit}
                    loading={isActionLoading}
                    // disabled={firstColumnPrimaryButtonIsDisabled}
                  >
                    Save
                  </Button>
                </ButtonContainer>
              }
            </FormColumn>
            {!isNew && (
              <>
                <Divider type="vertical" style={{ height: "auto", background: "#E8E6E1" }} />
                <FormColumn>
                  <FormTileContent>{updateModelAttachFilesScreen}</FormTileContent>
                  <ButtonContainer>
                    <Upload {...uploadProps} multiple showUploadList={false}>
                      <Button size="large" disabled={isActionLoading || isReindexLoading}>
                        Select PDF-Files
                      </Button>
                    </Upload>
                    <Button
                      type="primary"
                      size="large"
                      onClick={handleStartUpload}
                      loading={isActionLoading}
                      disabled={fileIdsToUpload.length === 0 && filesToDelete.length === 0}
                    >
                      Start Upload
                    </Button>
                  </ButtonContainer>
                </FormColumn>
              </>
            )}
          </FormColumnContainer>
        </FormTile>
      </Container>
      {!isNew && (
        <AdminForm title="Models" sectionId="models">
          <AdminTable
            data={deviceModels}
            columns={columns}
            isLoading={isDeviceModelsLoading}
            entity="model"
            rowKey="model_id"
            buttonTitle="Add Model"
            handleButtonClick={() => navigate(Routes.AdminNewModel, { state: model })}
            actions={[
              {
                key: "delete",
                label: "Delete",
                onAction: (selectedModel: IModel) => openDeleteModal(selectedModel.model_id),
              },
            ]}
          />
        </AdminForm>
      )}
      <DeleteModalComponent />
      {contextHolder}
    </Flex>
  );
};
