import React from "react";
import { StarsIcon, XIcon } from "assets";
import { Flex, Icon, Typography } from "components";
import { ToastOptions, toast as reactToast } from "react-toastify";
import { styled } from "styled-components";

const ErrorButton = styled.button`
  padding: 8px 16px;
  background-color: ${(props) => props.theme.colors.red50};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.red200};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.red200};
  }
`;

const Container = styled(Flex)`
  ${(props) => props.theme.breakpoints.tablet} {
    align-items: flex-start;
  }
`;

export const toast = {
  error: (message: string, link?: string, options?: ToastOptions) => {
    return reactToast.error(
      <Container columnGap={12} align="center">
        <Flex flex={1} align="center" wrap="wrap" columnGap={12} rowGap={12}>
          <Flex flex={1} minWidth="150px">
            <Typography size={14} height={21} weight={400} color="red800">
              {message}
            </Typography>
          </Flex>
          <a target="_blank" href={link || "#"} rel="noreferrer" onClick={(e) => e.stopPropagation()}>
            <ErrorButton>
              <Flex align="center" columnGap={8}>
                <Icon icon={StarsIcon} />
                <Typography size={14} height={21} weight={400} color="orange900" noWrap>
                  Soforthilfe
                </Typography>
              </Flex>
            </ErrorButton>
          </a>
        </Flex>
        <Flex>
          <Icon icon={XIcon} />
        </Flex>
      </Container>,
      options,
    );
  },
  dismiss: reactToast.dismiss,
};
