import { Flex, Typography } from "components";
import React from "react";
import { styled } from "styled-components";

type TabsProps = {
  items: Array<{ value: string; label: string }>;
  selectedItem: string;
  onChange(item: string): void;
};

const Container = styled(Flex)`
  gap: 24px;

  ${(props) => props.theme.breakpoints.mobile} {
    justify-content: space-between;
    gap: 8px;
  }
`;

const TabContainer = styled(Flex)`
  &:hover > span {
    color: ${(props) => props.theme.colors.grey900};
  }
`;

const TabText = styled(Typography).attrs({ size: 14, height: 18, weight: 500, spacing: -0.3 })<{ $selected?: boolean }>`
  color: ${(props) => (props.$selected ? props.theme.colors.grey900 : props.theme.colors.grey300)};

  #dark-theme & {
    color: ${(props) => (props.$selected ? props.theme.colors.blueGrey50 : props.theme.colors.blueGrey300)};
  }
`;

const ActiveLine = styled.div<{ $selected?: boolean }>`
  background-color: ${(props) => (props.$selected ? props.theme.colors.grey900 : props.theme.colors.transparent)};
  border-radius: 1px;
  width: calc(100% - 10px);
  height: 2px;

  #dark-theme & {
    background-color: ${(props) => (props.$selected ? props.theme.colors.blueGrey50 : props.theme.colors.transparent)};
  }
`;

export const Tabs: React.FC<TabsProps> = ({ items, selectedItem, onChange }) => {
  return (
    <Container wrap="wrap">
      {items.map((item) => {
        const isSelected = selectedItem === item.value;
        return (
          <TabContainer
            key={item.value}
            direction="column"
            rowGap={6}
            align="center"
            onClick={() => onChange(item.value)}
          >
            <TabText $selected={isSelected}>{item.label}</TabText>
            <ActiveLine $selected={isSelected} />
          </TabContainer>
        );
      })}
    </Container>
  );
};
