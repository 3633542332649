import React from "react";
import { styled } from "styled-components";
import { Flex, Icon as IconComponent, IconProps } from "components";
import { ColorName } from "theme";

type IconButtonProps = IconProps & {
  activeColor?: IconProps["color"];
};

const IconButtonContainer = styled(Flex).attrs({ justify: "center", align: "center" })<{
  activeColor?: IconProps["color"];
}>`
  background-color: ${(props) => props.theme.colors.grey100};
  border-radius: 20px;
  box-shadow: 3.269838333129883px 3.269838333129883px 6.539676666259766px 0px rgba(185, 178, 168, 0.4),
    -2.1798925399780273px -2.1798925399780273px 6.539676666259766px 0px #fff;
  width: 40px;
  height: 40px;

  #dark-theme & {
    background-color: ${(props) => props.theme.colors.blueGrey700};
    box-shadow: ${(props) => props.theme.boxShadow.defaultDark};
    > svg {
      color: ${(props) => props.theme.colors.blueGrey100};
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${(props) => props.theme.colors.grey50};
      > svg {
        color: ${(props) => (props.activeColor ? props.theme.colors[props.activeColor as ColorName] : "")};
      }
    }
    #dark-theme &:hover {
      background-color: ${(props) => props.theme.colors.blueGrey500};
    }
  }
`;

export const IconButton: React.FC<IconButtonProps> = ({ activeColor, onClick, ...props }) => {
  return (
    <IconButtonContainer activeColor={activeColor} onClick={onClick}>
      <IconComponent {...props} />
    </IconButtonContainer>
  );
};
