import React from "react";
import { useBreakpoints } from "hooks/useBreakpoints";
import { Icon } from "components";
import { IHomeMetrics, IWeatherMetrics } from "pages";
import { IDataMetric } from "types/common";
import { ThemeModeContext } from "App";
import { DeviceType } from "constants/device-types";
import Skeleton from "react-loading-skeleton";
import { Styles, CARD_TOP_OFFSET } from "./styles";
import { Battery, Car, Devices, Heatpump, House, PowerGrid } from "./components";
import { IHomeDevicePosition } from "./components/common";
import {
  batteryPosition,
  carPosition,
  heatpumpPosition,
  housePosition,
  powerGridPosition,
} from "./components/positions";

export const EnergyFlow: React.FC<{
  devices: IDataMetric[];
  illustration: IWeatherMetrics["illustration"];
  metrics: IHomeMetrics;
  isLoading: boolean;
}> = ({ devices, illustration, metrics, isLoading }) => {
  const [lineWidth, setLineWidth] = React.useState(0);
  const [lineOffsetLeft, setLineOffsetLeft] = React.useState(0);
  const [lineCenter, setLineCenter] = React.useState(0);
  const [lineHeight, setLineHeight] = React.useState(0);

  const houseLogoRef = React.useRef<HTMLDivElement>(null);

  const containerRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const updateLines = () => {
      if (containerRef.current && houseLogoRef.current) {
        const firstLineCards = (Array.from(containerRef.current.children) as HTMLElement[]).filter((child) => {
          return child.offsetTop === 0;
        });
        const firstLeftCard = firstLineCards[0];
        if (!firstLeftCard) {
          return;
        }
        const firstRightCard = firstLineCards[firstLineCards.length - 1];
        setLineWidth(firstRightCard.offsetLeft - firstLeftCard.offsetLeft);
        const offsetLeft = firstLeftCard.clientWidth / 2 + firstLeftCard.offsetLeft;
        setLineOffsetLeft(offsetLeft);
        const houseClientRect = houseLogoRef.current.getBoundingClientRect();
        const containerClientRect = containerRef.current.getBoundingClientRect();
        setLineCenter(
          houseClientRect.left -
            firstLeftCard.getBoundingClientRect().left -
            offsetLeft +
            houseClientRect.width / 2 +
            firstLeftCard.offsetLeft,
        );
        setLineHeight(containerClientRect.top - houseClientRect.bottom - (devices.length > 0 ? CARD_TOP_OFFSET : 0));
      }
    };
    updateLines();
    window.addEventListener("resize", updateLines);
    return () => window.removeEventListener("resize", updateLines);
  }, [containerRef.current, houseLogoRef.current, devices]);

  const isDarkTheme = React.useContext(ThemeModeContext) === "dark";

  const breakpoints = useBreakpoints();

  const getPositionGroup = (position: IHomeDevicePosition) => {
    if (breakpoints.mobile) {
      return position.mobile;
    }
    if (breakpoints.tablet) {
      return position.tablet;
    }
    return position.desktop;
  };

  return isLoading ? (
    <Skeleton count={20} />
  ) : (
    <Styles.EnergyFlow direction="column" rowGap={50} align="center">
      <Styles.HouseContainer>
        <Styles.WeatherContainer>{illustration && <Icon icon={illustration} />}</Styles.WeatherContainer>
        <Battery
          position={getPositionGroup(batteryPosition)}
          isDarkTheme={isDarkTheme}
          metric={metrics[DeviceType.Battery]}
        />
        <House
          position={getPositionGroup(housePosition)}
          isDarkTheme={isDarkTheme}
          metric={metrics[DeviceType.SolarPanel]}
          ref={houseLogoRef}
        />
        <PowerGrid
          position={getPositionGroup(powerGridPosition)}
          isDarkTheme={isDarkTheme}
          metric={metrics[DeviceType.PowerGrid]}
        />
        <Car position={getPositionGroup(carPosition)} isDarkTheme={isDarkTheme} metric={metrics[DeviceType.Charger]} />
        <Heatpump
          position={getPositionGroup(heatpumpPosition)}
          isDarkTheme={isDarkTheme}
          metric={metrics[DeviceType.Heatpump]}
        />
      </Styles.HouseContainer>
      <Devices
        lineCenter={lineCenter}
        lineHeight={lineHeight}
        lineOffsetLeft={lineOffsetLeft}
        lineWidth={lineWidth}
        metrics={metrics}
        devices={devices}
        isDarkTheme={isDarkTheme}
        ref={containerRef}
      />
    </Styles.EnergyFlow>
  );
};
