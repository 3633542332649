import { Flex, Icon, Typography, UnitLabel } from "components";
import React from "react";
import { BackIcon } from "assets";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";
import { Routes } from "constants/routes";
import { ThemeModeContext } from "App";
import { useMetrics } from "api/modules/metrics";
import Skeleton from "react-loading-skeleton";
import { Styles } from "./styles";
// import {
//   ACDevice,
//   BatteryDevice,
//   ElectroCarDevice,
//   HeaterDevice,
//   HeatpumpDevice,
//   PowerGridDevice,
//   ProcessWaterDevice,
//   RestDevice,
//   SolarDevice,
//   WashingMachineDevice,
// } from "./components";

const Delimiter = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey100};
  margin: 16px 0;
`;

export const DeviceDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { deviceId } = useParams();

  const { data: metrics, isLoading, isError } = useMetrics({ deviceId });

  const isDarkTheme = React.useContext(ThemeModeContext) === "dark";

  React.useEffect(() => {
    if (isError) {
      navigate(Routes.Devices);
    }
  }, [isError]);

  return (
    <Styles.PageContainer>
      <Flex direction="column" rowGap={16}>
        <Flex
          align="center"
          columnGap={8}
          onClick={() => (location.key === "default" ? navigate(Routes.Devices) : navigate(-1))}
        >
          <Icon icon={BackIcon} color={isDarkTheme ? "blueGrey200" : "grey200"} />
          <Typography size={14} height={21} color={isDarkTheme ? "blueGrey100" : "grey600"}>
            Zurück
          </Typography>
        </Flex>
      </Flex>
      {isLoading ? (
        <Skeleton count={15} />
      ) : (
        <Flex direction="column">
          <Styles.PageTitle size={22} height={32} weight={500}>
            {metrics?.[0]?.label}
          </Styles.PageTitle>
          <Flex direction="column">
            {metrics?.map((metric) => (
              <React.Fragment key={metric.metric_id}>
                <Flex justify="space-between" align="center">
                  {metric.metric_label}
                  {metric.unit === "°C" ? (
                    <Typography size={14} height={21} weight={500} color={isDarkTheme ? "white" : "grey900"}>
                      {metric.float_value ? `${metric.float_value.toFixed(2)} ${metric.unit}` : null}
                    </Typography>
                  ) : (
                    <UnitLabel
                      isDarkTheme={isDarkTheme}
                      value={Math.abs(metric.float_value)?.toFixed(2)}
                      unit={metric.unit}
                    />
                  )}
                </Flex>
                <Delimiter />
              </React.Fragment>
            ))}
          </Flex>
        </Flex>
      )}
      {/* {deviceId === "1" && <HeatpumpDevice isDarkTheme={isDarkTheme} />} */}
      {/* {deviceId === "2" && <BatteryDevice isDarkTheme={isDarkTheme} />}
      {deviceId === "3" && <WashingMachineDevice isDarkTheme={isDarkTheme} />}
      {deviceId === "4" && <ElectroCarDevice isDarkTheme={isDarkTheme} />}
      {deviceId === "5" && <ProcessWaterDevice isDarkTheme={isDarkTheme} />}
      {deviceId === "6" && <HeaterDevice isDarkTheme={isDarkTheme} />}
      {deviceId === "7" && <ACDevice isDarkTheme={isDarkTheme} />}
      {deviceId === "8" && <RestDevice isDarkTheme={isDarkTheme} />}
      {deviceId === "9" && <SolarDevice isDarkTheme={isDarkTheme} />}
      {deviceId === "10" && <PowerGridDevice isDarkTheme={isDarkTheme} />} */}
    </Styles.PageContainer>
  );
};
