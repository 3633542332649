import React, { useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

import { Typography, Flex, Icon } from "components";
import { CloseIcon } from "assets";
import { ThemeModeContext } from "App";
import { BodyHiddenOverflowStyle } from "theme";

const BackDrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1050;
  position: fixed;
  background: rgb(39, 36, 29, 0.6);
`;

const Container = styled.div<{ width?: string; maxHeight?: string }>`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  box-sizing: border-box;

  outline: none;
  overflow: auto;
  position: fixed;
  max-height: ${(props) => (props.maxHeight ? `${props.maxHeight}` : "70vh")};
  width: ${(props) => (props.width ? props.width : "700px")};
  max-width: 1200px;
  padding: 32px 96px 48px 96px;
  background-color: ${(props) => props.theme.colors.white};

  #dark-theme & {
    background-color: ${(props) => props.theme.colors.blueGrey600};
  }

  ${(props) => props.theme.breakpoints.mobile} {
    padding: 32px 16px 48px 16px;
    width: 100%;
  }
`;

const ModalHeader = styled(Flex)`
  align-items: center;
  box-sizing: content-box;
`;

const ModalBody = styled.div`
  overflow: auto;
  white-space: pre-wrap;
`;

const ModalFooter = styled(Flex)`
  width: 100%;
  align-items: center;
  padding-top: 24px;

  ${(props) => props.theme.breakpoints.mobile} {
    padding-top: 16px;
  }
`;

const ModalCloseIcon = styled(Icon)`
  position: sticky;
  top: 0;
  align-self: flex-end;
  border-radius: 5px;

  @media (hover: hover) {
    &:hover {
      background-color: ${(props) => props.theme.colors.grey100};
    }

    #dark-theme &:hover {
      background-color: ${(props) => props.theme.colors.blueGrey700};
    }
  }
`;

type ModalProps = {
  isOpen: boolean;
  header?: React.ReactNode;
  onClose?: (event?: React.MouseEvent) => void;
  children: React.ReactNode;
  footer?: React.ReactNode;
  width?: string;
  maxHeight?: string;
};

export const Modal: React.FC<ModalProps> = ({ isOpen, header, onClose, footer, width, maxHeight, children }) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    divRef.current?.focus();
  }, [divRef.current]);

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Escape") {
      onClose?.();
    }
  };

  const handleClickAway = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (divRef.current && divRef.current.contains(event.target as Node)) {
      return;
    }

    onClose?.();
  };

  const isDarkTheme = React.useContext(ThemeModeContext) === "dark";

  return isOpen ? (
    <>
      <BodyHiddenOverflowStyle />
      {createPortal(
        <BackDrop onClick={handleClickAway}>
          <Container ref={divRef} tabIndex={-1} width={width} maxHeight={maxHeight} onKeyDown={onKeyDown}>
            <Flex direction="column" rowGap={8}>
              <ModalCloseIcon onClick={onClose} icon={CloseIcon} />
              <ModalHeader>
                {header &&
                  (typeof header === "string" ? (
                    <Typography size={16} height={24} weight={500} color={isDarkTheme ? "blueGrey50" : "grey800"}>
                      {header}
                    </Typography>
                  ) : (
                    header
                  ))}
              </ModalHeader>
            </Flex>
            <ModalBody>{children}</ModalBody>
            {footer && <ModalFooter>{footer}</ModalFooter>}
          </Container>
        </BackDrop>,
        document.body,
      )}
    </>
  ) : null;
};
