import React from "react";

export const DarkHeatPumpIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="95" height="93" viewBox="0 0 95 93" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.5"
        d="M48.6754 92.5251L0 64.4848L45.8434 37L94.52 65.0404L48.6754 92.5251Z"
        fill="url(#paint0_linear_1983_11700)"
      />
      <path
        d="M64.9657 8.87236L52.512 0L26.0005 15.4552L38.4542 24.3276L64.9657 8.87236Z"
        fill="url(#paint1_linear_1983_11700)"
      />
      <path
        d="M26.0005 15.4565V64.9852L38.4542 73.8576V24.3289L26.0005 15.4565Z"
        fill="url(#paint2_linear_1983_11700)"
      />
      <path
        d="M64.9656 8.87012L64.9641 58.3988L38.4541 73.854V24.3253L64.9656 8.87012Z"
        fill="url(#paint3_linear_1983_11700)"
      />
      <path
        d="M61.0949 23.4352L61.0826 31.5422C61.0826 32.3737 60.6575 33.1407 59.9652 33.5492L45.0753 42.2181C44.1512 42.7687 43.0583 42.3715 42.584 41.5029C42.9248 41.5416 43.2763 41.4641 43.6048 41.2736L58.4838 32.5917C59.1761 32.1945 59.6136 31.4276 59.6136 30.5977V22.4922C59.6136 22.2872 59.5768 22.095 59.5046 21.9287C60.3075 21.827 61.0965 22.4922 61.0965 23.4384L61.0949 23.4352Z"
        fill="#58647F"
      />
      <path
        d="M59.6116 22.4889V30.5944C59.6116 31.4259 59.1741 32.1928 58.4818 32.5884L43.6028 41.2703C43.2743 41.4624 42.9227 41.5383 42.582 41.4996C42.4239 41.2186 42.3271 40.886 42.3271 40.5146V33.3796C42.3271 32.3834 42.8383 31.4614 43.6642 30.9883L58.9316 22.0901C59.1143 21.9884 59.3092 21.9238 59.5026 21.9238C59.5763 22.0901 59.6116 22.2823 59.6116 22.4873V22.4889Z"
        fill="#838DA8"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1983_11700"
          x1="65.6834"
          y1="82.7266"
          x2="-14.8153"
          y2="18.9142"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58647F" />
          <stop offset="1" stopColor="#475066" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1983_11700"
          x1="54.0346"
          y1="7.1197"
          x2="44.2184"
          y2="21.762"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDD2DC" />
          <stop offset="1" stopColor="#B1B7C9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1983_11700"
          x1="38.6168"
          y1="-22.4707"
          x2="13.5674"
          y2="-16.4813"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDD2DC" />
          <stop offset="1" stopColor="#79839E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1983_11700"
          x1="60.2252"
          y1="13.1815"
          x2="18.3704"
          y2="38.6277"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6E8ED" />
          <stop offset="1" stopColor="#ACB4C5" />
        </linearGradient>
      </defs>
    </svg>
  );
};
