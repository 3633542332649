import React from "react";
import { Flex, FlowLine } from "components";
import { DarkElectricPoleIcon, ElectricPoleIcon } from "assets";
import { Metrics } from "constants/metrics";
import { NavLink } from "react-router-dom";
import { Routes } from "constants/routes";
import { IHomeDeviceProps } from "../common";
import { Styles } from "../../styles";

export const PowerGrid: React.FC<IHomeDeviceProps> = ({ isDarkTheme, metric, position }) => {
  if (!metric) {
    return null;
  }
  const powerValue = +(metric[Metrics.Power]?.value || 0).toFixed(2);
  return (
    <Styles.AbsoluteDiv right={position.container.right} top={position.container.top}>
      <Flex direction="column" align="center" rowGap={12}>
        <Styles.DeviceTitle>Netz</Styles.DeviceTitle>
        <NavLink to={`${Routes.Devices}/${metric?.[Metrics.Power].deviceId}`}>
          <Styles.AdaptiveIcon
            icon={isDarkTheme ? DarkElectricPoleIcon : ElectricPoleIcon}
            $mobileWidth={36}
            $mobileHeight={99}
          />
        </NavLink>
      </Flex>
      {metric[Metrics.Power] && (
        <Styles.AbsoluteDiv left={position.line.left} top={position.line.top} $zIndex={1}>
          <Flex align="center">
            <FlowLine
              reverse={powerValue > 0}
              lineColors={
                powerValue === 0 ? ["#B9B2A8"] : powerValue < 0 ? ["#199473", "#8EEDC7"] : ["#FFB088", "#DE3A11"]
              }
              color={powerValue < 0 ? "green500" : "orange500"}
              size={position.line.size}
              noAnimation={powerValue === 0}
              noFlow={powerValue === 0}
            />
            <Styles.PowerLabel
              isDarkTheme={isDarkTheme}
              value={Math.abs(powerValue)}
              unit={metric[Metrics.Power].unit}
            />
          </Flex>
        </Styles.AbsoluteDiv>
      )}
    </Styles.AbsoluteDiv>
  );
};
