import React from "react";
import { useUser } from "api/modules/users";
import { Flex, ToastContainer } from "components";
import styled from "styled-components";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Routes } from "constants/routes";
import { useAlarms } from "api/modules/alarms";
import { UserRole } from "constants/roles";
import { IAlarm, INode, ISolarUser } from "types/common";
import { useAlarmsListener } from "hooks/useAlarmsListener";
import { BodyHiddenOverflowStyle } from "theme";
import { useNode } from "api/modules/node";
import { Header, Menu, MenuStackElement, Navigation, Notifications } from "./components";
import { MainMenu } from "./components/Menu/components";

const Container = styled(Flex)<{ $isLoginPage: boolean }>`
  margin: 0 auto;

  > main {
    box-sizing: border-box;
    max-width: 1200px;
    width: 100%;
    padding: 0 32px ${(props) => (props.$isLoginPage ? 0 : "150px")};

    ${(props) => props.theme.breakpoints.tablet} {
      padding: 0 16px 120px;
    }
  }
`;

const Overlay = styled.div`
  width: 100dvw;
  position: fixed;
  left: 0;
  right: 0;
  height: 100dvh;
  z-index: 6;
  background-color: ${(props) => props.theme.colors.grey50};

  #dark-theme & {
    background-color: ${(props) => props.theme.colors.blueGrey800};
  }
`;

const Main = styled.main`
  position: relative;
`;

export type HeaderActiveElement = "menu" | "notifications" | null;

export interface IOutletContext {
  user?: ISolarUser;
  alarms?: IAlarm[];
  node?: INode;
  isNodeLoading: boolean;
  handleOpenMenu: (element: MenuStackElement) => void;
}

export const PageLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAdminPage = location.pathname.includes(Routes.Admin);
  const isLoginPage = location.pathname === Routes.Login;

  const { data: user, isError, isFetching, isSuccess } = useUser();
  const { data: node, isLoading: isNodeLoading } = useNode({ enabled: Boolean(user) });

  const alarmOptions = { enabled: Boolean(user) && !isAdminPage };
  const { data: initialAlarms, isFetching: isAlarmLoading } = useAlarms(alarmOptions);
  const [alarms] = useAlarmsListener(initialAlarms, isAlarmLoading, alarmOptions);

  const [activeElement, setActiveElement] = React.useState<HeaderActiveElement>(null);
  const toggleActiveElement = (element: HeaderActiveElement) => {
    setActiveElement(activeElement === element ? null : element);
  };

  const isAdmin = user?.role === UserRole.Admin;
  React.useEffect(() => {
    if (isError && !isLoginPage) {
      navigate(Routes.Login, { state: { nextRoute: location.pathname } });
    }

    if (isSuccess && isLoginPage) {
      navigate(Routes.Overview);
    }
  }, [isError, isSuccess, isLoginPage]);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  const defaultMenuContent = {
    Content: MainMenu,
    title: "",
  };
  const [initialMenuContent, setInitialMenuContent] = React.useState<MenuStackElement>(defaultMenuContent);
  const handleOpenMenu = (element?: MenuStackElement) => {
    setInitialMenuContent(element || defaultMenuContent);
    setActiveElement("menu");
  };
  const handleCloseMenu = () => {
    setInitialMenuContent(defaultMenuContent);
    setActiveElement(null);
  };

  if ((!user && isFetching) || (isError && !isLoginPage)) {
    return null;
  }

  return (
    <Container direction="column" align="center" $isLoginPage={isLoginPage}>
      {user && (
        <Header
          toggleActiveElement={toggleActiveElement}
          activeElement={activeElement}
          hasNotification={!!alarms?.length}
          handleCloseMenu={handleCloseMenu}
          handleOpenMenu={handleOpenMenu}
        />
      )}
      <Main>
        {(!isAdminPage || !isAdmin) && <ToastContainer />}
        {activeElement && (
          <Overlay>
            <BodyHiddenOverflowStyle />
            {user && node && activeElement === "menu" && (
              <Menu handleCloseMenu={handleCloseMenu} user={user} node={node} initialMenuContent={initialMenuContent} />
            )}
            {user && activeElement === "notifications" && (
              <Notifications
                alarms={alarms || []}
                toggleActiveElement={toggleActiveElement}
                isLoading={isAlarmLoading}
              />
            )}
          </Overlay>
        )}
        {children ?? <Outlet context={{ user, alarms, node, isNodeLoading, handleOpenMenu } as IOutletContext} />}
      </Main>
      {user && activeElement === null && <Navigation />}
    </Container>
  );
};
