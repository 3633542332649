import { Flex } from "components";
import React from "react";
import { styled } from "styled-components";
import { ColorName } from "theme";
import { getRandomId } from "utils/string";

const Container = styled(Flex)<{ $rotate?: string }>`
  ${(props) => props.$rotate && `transform: rotate(${props.$rotate});`}
`;

const Circle = styled.div<{ $delay?: number }>`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 12px;
  animation-delay: ${(props) => props.$delay || 0}s;

  ${(props) => props.theme.breakpoints.mobile} {
    width: 8px;
    height: 8px;
  }
`;

const CircleContainer = styled(Flex)<{
  $color: string;
  $vertical: boolean;
  $delay?: number;
  $reverse: boolean;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: ${(props) => (props.$reverse ? "rotate(180deg)" : "none")};

  > div {
    @keyframes ${(props) => (props.$vertical ? "flow_vertical" : "flow")} {
      0% {
        opacity: 0;
      }
      10% {
        ${(props) => (props.$vertical ? "top: 0" : "left: 0")};
        visibility: hidden;
        opacity: 1;
      }
      100% {
        ${(props) => (props.$vertical ? "top: 100%" : "left: 100%")};
        visibility: visible;
        opacity: 0;
      }
    }
    background-color: ${(props) => props.theme.colors[props.$color as ColorName] || props.$color};
    animation-name: ${(props) => (props.$vertical ? "flow_vertical" : "flow")};
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-fill-mode: both;
    visibility: hidden;
    ${(props) => (props.$vertical ? "left: -4px" : "top: -4px")};

    ${(props) => props.theme.breakpoints.mobile} {
      ${(props) => (props.$vertical ? "left: -3px" : "top: -3px")};
    }
  }
`;

type FlowLineProps = {
  color?: string;
  vertical?: boolean;
  size?: number;
  lineColors?: Array<string>;
  delay?: number;
  reverse?: boolean;
  noFlow?: boolean;
  rotate?: string;
  noAnimation?: boolean;
};

export const FlowLine: React.FC<FlowLineProps> = ({
  color = "red200",
  vertical = false,
  size = 200,
  lineColors = [],
  delay = 0,
  reverse = false,
  noFlow = false,
  noAnimation = false,
  rotate,
}) => {
  const width = vertical ? 2 : size;
  const height = vertical ? size : 2;

  const gradientId = getRandomId();

  return (
    <Container
      position="relative"
      justify="center"
      align="center"
      width={`${width}px`}
      height={`${height}px`}
      $rotate={rotate}
    >
      {!noFlow && (
        <CircleContainer $color={color} $vertical={vertical} $delay={delay} $reverse={reverse}>
          <Circle $delay={delay} />
          {/* <Circle $delay={delay + 2} /> */}
          {/* <Circle $delay={delay + 4} /> */}
        </CircleContainer>
      )}
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d={vertical ? "M1.00001 1000L1.00001 1" : "M1000 1L1.00001 1"}
          stroke={`url(#${gradientId})`}
          strokeWidth="1.34512"
          strokeLinecap="round"
          strokeDasharray="2.69 2.69"
        >
          {!noAnimation && (
            <animate
              attributeName="stroke-dashoffset"
              values={reverse ? "100;0" : "0;100"}
              dur="6s"
              calcMode="linear"
              repeatCount="indefinite"
            />
          )}
        </path>
        <defs>
          <linearGradient
            id={gradientId}
            x1="1.00006"
            y1="-166416"
            x2="484.641"
            y2="-166416"
            gradientUnits="userSpaceOnUse"
          >
            {lineColors.map((cl, index) => (
              <stop stopColor={cl} key={getRandomId()} offset={index} />
            ))}
          </linearGradient>
        </defs>
      </svg>
    </Container>
  );
};
