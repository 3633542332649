import React from "react";

export const StarsIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.4759 14.1247L11.6668 15.5L11.8577 14.1247C12.3747 10.4012 12.7413 10.0079 16.2165 9.45454L17.5002 9.25L16.2165 9.04546C12.7413 8.49207 12.3742 8.09874 11.8577 4.37532L11.6668 3L11.4759 4.37532C10.9589 8.09879 10.5923 8.49212 7.11713 9.04546L5.8335 9.25L7.11713 9.45454C10.5928 10.0085 10.959 10.4007 11.4759 14.1247ZM10.6799 8.1926C11.1848 7.65169 11.4471 6.89376 11.6668 5.6676C11.8866 6.89372 12.1489 7.65162 12.6537 8.1926C13.1586 8.73357 13.866 9.01454 15.0104 9.25C13.866 9.48546 13.1586 9.7665 12.6537 10.3074C12.1488 10.8483 11.8866 11.6062 11.6668 12.8324C11.4471 11.6063 11.1848 10.8484 10.6799 10.3074C10.1751 9.76643 9.46768 9.48546 8.32325 9.25C9.46764 9.01454 10.175 8.7335 10.6799 8.1926Z"
        fill="#DC5055"
        stroke="#DC5055"
      />
      <path
        d="M5.72424 17.2665L5.83333 18L5.94242 17.2665C6.23785 15.2807 6.44734 15.0709 8.43316 14.7758L9.16667 14.6667L8.43316 14.5576C6.44731 14.2624 6.23753 14.0527 5.94242 12.0668L5.83333 11.3333L5.72424 12.0668C5.42882 14.0527 5.21933 14.2625 3.2335 14.5576L2.5 14.6667L3.2335 14.7758C5.21958 15.0712 5.42884 15.2804 5.72424 17.2665ZM5.26939 14.1027C5.55787 13.8142 5.70776 13.41 5.83333 12.7561C5.95891 13.41 6.1088 13.8142 6.39728 14.1027C6.68576 14.3912 7.08999 14.5411 7.74395 14.6667C7.09001 14.7923 6.6858 14.9421 6.39728 15.2306C6.10876 15.5191 5.95891 15.9233 5.83333 16.5773C5.70776 15.9234 5.55787 15.5191 5.26939 15.2306C4.9809 14.9421 4.57667 14.7923 3.92272 14.6667C4.57665 14.5411 4.98087 14.3912 5.26939 14.1027Z"
        fill="#DC5055"
        stroke="#DC5055"
      />
    </svg>
  );
};
