import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import { ColorName, theme } from "theme";

export type IconProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & {
  size?: number;
  color?: string;
  icon: React.ElementType;
};

export const Icon: React.FC<IconProps> = (props) => {
  const { size, color, icon, style: styleArg, ...svgProps } = props;
  const styleColor = color ? theme.colors[color as ColorName] || color : undefined;
  const svgExtraProps = {
    ...(size
      ? {
          width: `${size}px`,
          height: `${size}px`,
        }
      : {}),
    color: styleColor,
    cursor: svgProps.onClick ? "pointer" : "inherit",
  };

  const IconComp: React.ElementType = icon;
  return <IconComp {...svgProps} {...svgExtraProps} style={styleArg} />;
};
