import React from "react";

export const BoilerIcon: React.FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.8302 1.73535H5.70435C5.50091 1.73535 5.33496 1.90129 5.33496 2.10474V21.5083C5.33496 21.7117 5.5009 21.8776 5.70435 21.8776H7.08902V22.5236C7.08902 22.6342 7.1809 22.727 7.29246 22.727C7.40309 22.727 7.4959 22.6351 7.4959 22.5236V21.8776H7.9759V22.5236C7.9759 22.6342 8.06778 22.727 8.17934 22.727C8.28997 22.727 8.38278 22.6351 8.38278 22.5236V21.8776H16.118V22.5236C16.118 22.6342 16.2099 22.727 16.3214 22.727C16.432 22.727 16.5249 22.6351 16.5249 22.5236V21.8776H17.0049V22.5236C17.0049 22.6342 17.0967 22.727 17.2083 22.727C17.3189 22.727 17.4117 22.6351 17.4117 22.5236V21.8776H18.7964C18.9999 21.8776 19.1658 21.7117 19.1658 21.5083L19.163 2.10474C19.1995 1.88348 19.0336 1.73535 18.8302 1.73535ZM6.07371 12.0741H18.4608V15.1576L6.07371 15.1567V12.0741ZM18.4608 2.47407V11.6682H6.07371V2.47407H18.4608ZM6.07371 21.1199V15.5812H18.4608V21.1377L6.07371 21.1386V21.1199Z"
        fill="currentColor"
      />
      <path
        d="M12.2582 16.5413C11.2795 16.5413 10.4863 17.3354 10.4863 18.3132C10.4863 19.2919 11.2804 20.1038 12.2582 20.1038C13.2369 20.1038 14.0488 19.3097 14.0488 18.3132C14.0488 17.3354 13.2548 16.5413 12.2582 16.5413ZM12.2582 19.68C11.5016 19.68 10.8923 19.0707 10.8923 18.3141C10.8923 17.5576 11.5016 16.9482 12.2582 16.9482C13.0148 16.9482 13.6241 17.5576 13.6241 18.3141C13.6241 19.0707 13.0148 19.68 12.2582 19.68Z"
        fill="currentColor"
      />
      <path
        d="M12.2586 12.7568C11.7973 12.7568 11.4092 13.145 11.4092 13.6062C11.4092 14.0675 11.7973 14.4556 12.2586 14.4556C12.7198 14.4556 13.1079 14.0675 13.1079 13.6062C13.1258 13.145 12.7386 12.7568 12.2586 12.7568ZM12.2586 14.0496C12.0186 14.0496 11.8151 13.8462 11.8151 13.6062C11.8151 13.3662 12.0186 13.1627 12.2586 13.1627C12.4986 13.1627 12.702 13.3662 12.702 13.6062C12.7011 13.8462 12.4986 14.0496 12.2586 14.0496Z"
        fill="currentColor"
      />
    </svg>
  );
};
