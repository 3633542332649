import { Flex, Temperature, Typography } from "components";
import React from "react";
import dayjs from "dayjs";
import { IWeatherMetrics } from "pages/OverviewPage";
import { INode } from "types/common";
import Skeleton from "react-loading-skeleton";
import { ThemeModeContext } from "App";
import { useProducedEnergy } from "api/modules/metrics";
import { Styles } from "./styles";

export const PageHeader: React.FC<{
  metrics: IWeatherMetrics;
  node?: INode;
  isNodeLoading: boolean;
  isWeatherLoading: boolean;
}> = ({ metrics, node, isNodeLoading, isWeatherLoading }) => {
  const { data: producedEnergy, isLoading } = useProducedEnergy(
    { startDate: dayjs().format("YYYY-MM-DD") },
    { refetchInterval: 30000 },
  );

  const isDarkTheme = React.useContext(ThemeModeContext) === "dark";
  return (
    <Styles.SectionContainer justify="space-between" align="center" rowGap={24} columnGap={24} width="100%">
      {isNodeLoading ? (
        <Skeleton count={2} width={300} />
      ) : (
        <Flex columnGap={16} align="baseline" wrap="wrap">
          <Styles.Title size={32} height={42} spacing={-0.6} weight={600} noWrap>
            {node?.description}
          </Styles.Title>
          <Styles.SubTitle color={isDarkTheme ? "blueGrey100" : "grey500"} weight={300} size={14} height={18} noWrap>
            {node?.address}
          </Styles.SubTitle>
        </Flex>
      )}
      <Styles.OverviewTile>
        {isLoading || isWeatherLoading ? (
          <Skeleton count={2} width="100%" />
        ) : (
          <>
            <Flex direction="column" rowGap={4}>
              <Flex columnGap={4}>
                <Typography
                  size={32}
                  height={32}
                  weight={500}
                  spacing={-0.5}
                  color={isDarkTheme ? "grey50" : "grey900"}
                >
                  {producedEnergy ? producedEnergy.toFixed(2) : 0}
                </Typography>
                <Typography
                  size={14}
                  height={18}
                  weight={400}
                  spacing={-0.5}
                  color={isDarkTheme ? "blueGrey100" : "grey500"}
                >
                  kWh
                </Typography>
              </Flex>
              <Typography size={10} height={13} weight={300} color={isDarkTheme ? "blueGrey50" : "grey500"}>
                Energieproduktion heute
              </Typography>
            </Flex>
            <Flex align="center" columnGap={4}>
              {metrics.icon && <img src={metrics.icon} alt="weather" />}
              <Flex direction="column" rowGap={4}>
                {metrics.temperature && (
                  <Temperature>
                    <Typography size={32} height={32} weight={500} spacing={-0.5}>
                      {Math.round(metrics.temperature)}
                    </Typography>
                  </Temperature>
                )}
                <Typography size={10} height={13} weight={300} color={isDarkTheme ? "blueGrey50" : "grey500"}>
                  {dayjs().format("dddd D MMM")}
                </Typography>
              </Flex>
            </Flex>
          </>
        )}
      </Styles.OverviewTile>
    </Styles.SectionContainer>
  );
};
