import { Button, Flex, Typography } from "components";
import { styled } from "styled-components";

export const Styles = {
  Container: styled(Flex)`
    gap: 24px;
    flex-wrap: wrap;

    > div {
      flex: 1;
    }
    ${(props) => props.theme.breakpoints.tablet} {
      > div {
        flex: auto;
      }
    }

    ${(props) => props.theme.breakpoints.mobile} {
      flex-direction: column;
      row-gap: 32px;
    }
  `,
  ProducedEnergyGrid: styled(Flex)`
    justify-content: space-between;
    gap: 16px;

    ${(props) => props.theme.breakpoints.tablet} {
      justify-content: flex-start;
      gap: 40px;
      flex-wrap: wrap;
    }

    ${(props) => props.theme.breakpoints.mobile} {
      gap: 10px;
      justify-content: space-between;
    }
  `,
  WeatherTileContainer: styled(Flex)`
    ${(props) => props.theme.breakpoints.tablet} {
      justify-content: flex-start;
      gap: 40px;
      flex-wrap: wrap;
    }

    ${(props) => props.theme.breakpoints.mobile} {
      gap: 10px;
      justify-content: space-between;
    }
  `,
  ContactButton: styled(Button)`
    border-radius: 5px;
    background: ${(props) => props.theme.colors.white};

    #dark-theme & {
      background: ${(props) => props.theme.colors.blueGrey700};
    }
  `,
  ForecastDelimiter: styled.div`
    width: 1px;
    height: 14px;
    background-color: ${(props) => props.theme.colors.grey200};
  `,
  ItemTitle: styled(Typography)`
    ${(props) => props.theme.breakpoints.mobile} {
      font-size: 13px;
    }
  `,
  TemperatureValue: styled(Typography)`
    ${(props) => props.theme.breakpoints.mobile} {
      font-size: 20px;
      line-height: 24px;
    }
  `,
  EnergyValue: styled(Typography)`
    ${(props) => props.theme.breakpoints.mobile} {
      font-size: 24px;
      line-height: 32px;
    }
  `,
  EnergyRateNotification: styled(Flex)`
    padding: 16px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.yellow100};

    column-gap: 12px;
  `,
  EnergyRateNavigationLink: styled(Flex)`
    display: inline;
    text-decoration: underline;
  `,
};
