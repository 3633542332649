import React from "react";
import { styled } from "styled-components";
import { Flex, FlowLine, Icon } from "components";
import { Routes } from "constants/routes";
import { NavLink } from "react-router-dom";
import { Metrics } from "constants/metrics";
import { HeatPumpIcon, DarkHeatPumpIcon, ShowerIcon, RadiatorIcon, ExclamationErrorIcon } from "assets";
import { useBreakpoints } from "hooks/useBreakpoints";
import { IHomeDeviceProps } from "../common";
import { Styles } from "../../styles";

const MetricLine = styled.div`
  width: 30px;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey200};

  ${(props) => props.theme.breakpoints.mobile} {
    display: none;
  }
`;

export const Heatpump: React.FC<IHomeDeviceProps> = ({ metric, isDarkTheme, position }) => {
  const { mobile } = useBreakpoints();
  if (!metric) {
    return null;
  }

  const powerValue = +(metric[Metrics.Power]?.value || 0).toFixed(2);
  const heatingTemperature = +(metric[Metrics.HeatingWater]?.value || 0);
  const serviceTemperature = +(metric[Metrics.ServiceWater]?.value || 0);

  return (
    <Styles.AbsoluteDiv left={position.container.left} bottom={position.container.bottom}>
      <NavLink to={`${Routes.Devices}/${metric?.[Metrics.Power].deviceId}`}>
        <Flex direction="column" align="center" rowGap={12}>
          <Styles.AdaptiveIcon
            icon={isDarkTheme ? DarkHeatPumpIcon : HeatPumpIcon}
            $mobileWidth={55}
            $mobileHeight={53}
          />
          <Styles.DeviceTitle>Wärmepumpe</Styles.DeviceTitle>
        </Flex>
      </NavLink>
      {metric[Metrics.ServiceWater] && (
        <Styles.AbsoluteDiv left={position.serviceWater?.left} top={position.serviceWater?.top}>
          <Flex direction="column" rowGap={8}>
            <Styles.DeviceLabel $error={metric[Metrics.ServiceWater].error}>
              {metric[Metrics.ServiceWater].error && !mobile && (
                <Styles.AbsoluteDiv left={-28} top={2}>
                  <Icon icon={ExclamationErrorIcon} />
                </Styles.AbsoluteDiv>
              )}

              {serviceTemperature.toFixed(1)}
              {metric[Metrics.ServiceWater].unit}
            </Styles.DeviceLabel>
            <Flex align="center">
              {/* <NavLink to={`${Routes.Devices}/5`}> */}
              <Icon
                icon={ShowerIcon}
                color={metric[Metrics.ServiceWater].error ? "red700" : isDarkTheme ? "inherit" : "grey500"}
              />
              {/* </NavLink> */}
              <MetricLine />
            </Flex>
          </Flex>
        </Styles.AbsoluteDiv>
      )}
      {metric[Metrics.HeatingWater] && (
        <Styles.AbsoluteDiv left={position.heatingWater?.left} top={position.heatingWater?.top}>
          <Flex direction="column" rowGap={8} align="center">
            <Styles.DeviceLabel $error={metric[Metrics.HeatingWater].error}>
              {metric[Metrics.HeatingWater].error && !mobile && (
                <Styles.AbsoluteDiv right={-28} top={2}>
                  <Icon icon={ExclamationErrorIcon} />
                </Styles.AbsoluteDiv>
              )}
              {heatingTemperature.toFixed(1)}
              {metric[Metrics.HeatingWater].unit}
            </Styles.DeviceLabel>
            <Flex align="center">
              <MetricLine />
              {/* <NavLink to={`${Routes.Devices}/6`}> */}
              <Icon
                icon={RadiatorIcon}
                color={metric[Metrics.HeatingWater].error ? "red700" : isDarkTheme ? "inherit" : "grey500"}
              />
              {/* </NavLink> */}
            </Flex>
          </Flex>
        </Styles.AbsoluteDiv>
      )}

      {metric[Metrics.Power] && (
        <Styles.AbsoluteDiv left={position.line.left} bottom={position.line.bottom}>
          <FlowLine
            rotate={position.line.rotate || "-31deg"}
            reverse={powerValue > 0}
            lineColors={powerValue === 0 ? ["#B9B2A8"] : ["#FFB088", "#DE3A11"]}
            color="orange500"
            size={position.line.size}
            noAnimation={powerValue === 0}
            noFlow={powerValue === 0}
          />
          <Styles.AbsoluteDiv left={position.inner?.left || -25} bottom={position.inner?.bottom || -67}>
            <Styles.PowerLabel
              isDarkTheme={isDarkTheme}
              value={Math.abs(powerValue)}
              unit={metric[Metrics.Power].unit}
            />
          </Styles.AbsoluteDiv>
        </Styles.AbsoluteDiv>
      )}
    </Styles.AbsoluteDiv>
  );
};
