import { ThemeModeContext } from "App";
import { Button, Card, Flex, Typography } from "components";
import { useBreakpoints } from "hooks/useBreakpoints";
import React from "react";

interface ErrorTileProps {
  title: string;
  message: string;
  buttonText: string;
  onButtonClick(): void;
}

export const ErrorTile: React.FC<ErrorTileProps> = ({ title, message, buttonText, onButtonClick }) => {
  const { mobile } = useBreakpoints();
  const isDarkTheme = React.useContext(ThemeModeContext) === "dark";
  return (
    <Card padding={mobile ? "56px 16px 72px" : "56px 96px 72px"} maxWidth="770px" margin="0 auto">
      <Flex direction="column" rowGap={40} align="center">
        <Flex direction="column" rowGap={24} align="center">
          <Typography
            size={mobile ? 72 : 85}
            height={mobile ? 95 : 112}
            weight={400}
            spacing={-2.6}
            color={isDarkTheme ? "blueGrey50" : "grey700"}
          >
            {title}
          </Typography>
          <Typography
            size={mobile ? 20 : 24}
            height={mobile ? 24 : 32}
            weight={500}
            color={isDarkTheme ? "blueGrey200" : "grey500"}
            center
          >
            {message}
          </Typography>
        </Flex>
        <Button onClick={onButtonClick}>{buttonText}</Button>
      </Flex>
    </Card>
  );
};
